//Search
export const CUSTOMER_SEARCH_START_LOADING = "CUSTOMER_SEARCH_START_LOADING";
export const CUSTOMER_SEARCH_END_LOADING = "CUSTOMER_SEARCH_END_LOADING";
export const CUSTOMER_SEARCH_LOADED = "CUSTOMER_SEARCH_LOADED";
export const SET_CUSTOMER_FILTER = "SET_CUSTOMER_FILTER";

//Edit
export const CUSTOMER_START_LOADING = "CUSTOMER_START_LOADING";
export const CUSTOMER_END_LOADING = "CUSTOMER_END_LOADING";
export const CUSTOMER_LOADED = "CUSTOMER_LOADED";
