import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { GROUP_SEARCH_START_LOADING, GROUP_SEARCH_END_LOADING, GROUP_SEARCH_LOADED, SET_GROUP_FILTER } from "../constants/GroupConstants";
import { GROUP_START_LOADING, GROUP_END_LOADING, GROUP_LOADED } from "../constants/GroupConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        active: ""
    },
    loadingFBO: false,
    fbo: null,
};

const GroupReducer = (state = initialState, action) => {
    switch(action.type) {
        case GROUP_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case GROUP_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case GROUP_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_GROUP_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case GROUP_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case GROUP_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case GROUP_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        default:
            return state;
    }
}

export default GroupReducer;