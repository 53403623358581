//Search
export const PRODUCT_SEARCH_START_LOADING = "PRODUCT_SEARCH_START_LOADING";
export const PRODUCT_SEARCH_END_LOADING = "PRODUCT_SEARCH_END_LOADING";
export const PRODUCT_SEARCH_LOADED = "PRODUCT_SEARCH_LOADED";
export const SET_PRODUCT_FILTER = "SET_PRODUCT_FILTER";

//Edit
export const PRODUCT_START_LOADING = "PRODUCT_START_LOADING";
export const PRODUCT_END_LOADING = "PRODUCT_END_LOADING";
export const PRODUCT_LOADED = "PRODUCT_LOADED";
