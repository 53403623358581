import React, { Fragment } from "react";
import moment from "moment";

const MyDateDisplay = (props) => {
    if(props.value) {
        return (
            <Fragment>{moment(props.value + "Z").fromNow()}</Fragment>
        );
    }
    else {
        return (
            <Fragment>{props.value}</Fragment>
        );
    }
}

export default MyDateDisplay;