import React, { Fragment } from "react";
import dateFormat from "dateformat";

const MyDateInput = (props) => {
    if(props.value) { 
        var dateValue = props.value;

        if(props.value.indexOf(".") !== -1) {
            dateValue = props.value.split(".")[0];
        }

        if(props.dateOnly) {
            return (
                <input className="form-control" readOnly={true} value={dateFormat(dateValue + "Z", props.format)} />
            );
        }
        else if(props.format) {
            return (
                <input className="form-control" readOnly={true} value={dateFormat(dateValue + "Z", props.format)} />
            );
        }
        else {
            return (
                <input className="form-control" readOnly={true} value={dateFormat(dateValue + "Z", "mm/dd/yyyy HH:MM:ss")} />
            );
        }
    }
    else {
        return (
            <input className="form-control" readOnly={true} value={props.value} />
        );
    }
}

export default MyDateInput;