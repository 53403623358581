import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Table, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';

import { api } from "../utils/index";
import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

const FormDecisionSFDA = (props) => {
    const [decisionFormCollapsed, setDecisionFormCollapsed] = React.useState(true);
    
    return(
        <Card className="card-success mb-3">
            <CardHeader className="border-0 d-flex align-items-center">
                <CardLoader loading={props.loading} />
                <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setDecisionFormCollapsed(!decisionFormCollapsed)}>
                    {decisionFormCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                    {!decisionFormCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                    Decision & Review Form ({decisionFormCollapsed ? "Expand" : "Collapse"})
                </h5>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <Collapse isOpen={!decisionFormCollapsed} className="collapse">
                    <Row className="gy-2" style={{backgroundColor:"white"}}>
                        <Col xxl={12} md={12}>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm3 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr>
                                        <td colSpan={5} style={{textAlign:"center", border: "none"}}><h5><b>Review & Decision</b></h5></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="header">
                                        <td>#</td>
                                        <td>Points to be checked<sup>1</sup></td>
                                        <td colSpan={3}>Decision Maker</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.1</td>
                                        <td>Request for Certification (RFC) available</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "RequestAvailable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "RequestAvailable", "Yes")} onChange={e => props.setFieldValue("decisionForm", "RequestAvailable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "RequestAvailable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "RequestAvailable", "No")} onChange={e => props.setFieldValue("decisionForm", "RequestAvailable", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "RequestAvailable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "RequestAvailable", "N/A")} onChange={e => props.setFieldValue("decisionForm", "RequestAvailable", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.2</td>
                                        <td>Request for Certification (RFC) signed and stamped</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "SignedAndStamped") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "SignedAndStamped", "Yes")} onChange={e => props.setFieldValue("decisionForm", "SignedAndStamped", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "SignedAndStamped") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "SignedAndStamped", "No")} onChange={e => props.setFieldValue("decisionForm", "SignedAndStamped", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "SignedAndStamped") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "SignedAndStamped", "N/A")} onChange={e => props.setFieldValue("decisionForm", "SignedAndStamped", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.3</td>
                                        <td>Application and Application Review completed with a positive verdict</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationCompleted", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ApplicationCompleted", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationCompleted", "No")} onChange={e => props.setFieldValue("decisionForm", "ApplicationCompleted", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationCompleted", "N/A")} onChange={e => props.setFieldValue("decisionForm", "ApplicationCompleted", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.4</td>
                                        <td>Evaluation completed a positive verdict</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "EvaluationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "EvaluationCompleted", "Yes")} onChange={e => props.setFieldValue("decisionForm", "EvaluationCompleted", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "EvaluationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "EvaluationCompleted", "No")} onChange={e => props.setFieldValue("decisionForm", "EvaluationCompleted", "No", e.target.checked)} /> No    
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "EvaluationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "EvaluationCompleted", "N/A")} onChange={e => props.setFieldValue("decisionForm", "EvaluationCompleted", "N/A", e.target.checked)} /> N/A    
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.5</td>
                                        <td>Certification evaluation plan completed correctly</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificationEvaluationPlanCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "Yes")} onChange={e => props.setFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificationEvaluationPlanCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "No")} onChange={e => props.setFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificationEvaluationPlanCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "N/A")} onChange={e => props.setFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.6</td>
                                        <td>SFDA Certification Program and related TR are followed</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "StandardFollowed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "StandardFollowed", "Yes")} onChange={e => props.setFieldValue("decisionForm", "StandardFollowed", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "StandardFollowed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "StandardFollowed", "No")} onChange={e => props.setFieldValue("decisionForm", "StandardFollowed", "No", e.target.checked)} /> No    
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "StandardFollowed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "StandardFollowed", "N/A")} onChange={e => props.setFieldValue("decisionForm", "StandardFollowed", "N/A", e.target.checked)} /> N/A    
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.7</td>
                                        <td>Test report results are suitable and acceptable according to related Tr or standard(s)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReportStandard") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReportStandard", "Yes")} onChange={e => props.setFieldValue("decisionForm", "TestReportStandard", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReportStandard") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReportStandard", "No")} onChange={e => props.setFieldValue("decisionForm", "TestReportStandard", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReportStandard") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReportStandard", "N/A")} onChange={e => props.setFieldValue("decisionForm", "TestReportStandard", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.8</td>
                                        <td>If a subcontracted laboratory is used, is it included in TÜV AUSTRIA's approved subcontractor list?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApprovedSubContractorLab", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ApprovedSubContractorLab", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApprovedSubContractorLab", "No")} onChange={e => props.setFieldValue("decisionForm", "ApprovedSubContractorLab", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApprovedSubContractorLab", "N/A")} onChange={e => props.setFieldValue("decisionForm", "ApprovedSubContractorLab", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.9</td>
                                        <td>Factory Inspection has the appropriate conditions been defined?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryInspectionConditions") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryInspectionConditions", "Yes")} onChange={e => props.setFieldValue("decisionForm", "FactoryInspectionConditions", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryInspectionConditions") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryInspectionConditions", "No")} onChange={e => props.setFieldValue("decisionForm", "FactoryInspectionConditions", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryInspectionConditions") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryInspectionConditions", "N/A")} onChange={e => props.setFieldValue("decisionForm", "FactoryInspectionConditions", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm3 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={2}>Review of Conflict of Interest</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>I undertake to inform the Technical Manager or Group Team Leader in the event of any of the following matters related to the specified client.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("decisionForm", "HaveRelationship", "Yes")} onChange={e => props.setFieldValue("decisionForm", "HaveRelationship", "Yes", e.target.checked)} />
                                        </td>
                                        <td>In the management of the client, I don’t have close family members and person/people with whom I have a personal or business relationship that can affect objectivity.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("decisionForm", "PerformedAudit", "Yes")} onChange={e => props.setFieldValue("decisionForm", "PerformedAudit", "Yes", e.target.checked)} />
                                        </td>
                                        <td>In the last two years, I don’t have performed internal audit and training services in the client, including quality consultancy.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("decisionForm", "ProvidedServices", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ProvidedServices", "Yes", e.target.checked)} />
                                        </td>
                                        <td>I didn’t provide services in the supply, design, service provider developer of the client's products, operation, and development of product-related processes.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("decisionForm", "PreventImpartiality", "Yes")} onChange={e => props.setFieldValue("decisionForm", "PreventImpartiality", "Yes", e.target.checked)} />
                                        </td>
                                        <td>There aren’t illegal financial and other conditions that prevent my impartiality.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm3 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={2}>The documents or records described below are accessible</td>
                                        <td colSpan={3}>Decision Maker</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="index-col">2.1</td>
                                        <td>FM-GMAP-PC-SF-002a Request for Certification (RFC) between TÜV AUSTRIA and applicant</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "RFCContract") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "RFCContract", "Yes")} onChange={e => props.setFieldValue("decisionForm", "RFCContract", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "RFCContract") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "RFCContract", "No")} onChange={e => props.setFieldValue("decisionForm", "RFCContract", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "RFCContract") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "RFCContract", "N/A")} onChange={e => props.setFieldValue("decisionForm", "RFCContract", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.2</td>
                                        <td>FM-GMAP-PC-SF-002b Application Review</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationReview") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationReview", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ApplicationReview", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationReview") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationReview", "No")} onChange={e => props.setFieldValue("decisionForm", "ApplicationReview", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationReview") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationReview", "N/A")} onChange={e => props.setFieldValue("decisionForm", "ApplicationReview", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.3</td>
                                        <td>FM-GMAP-PC-SF-002c Evaluation</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "Evaluation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "Evaluation", "Yes")} onChange={e => props.setFieldValue("decisionForm", "Evaluation", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "Evaluation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "Evaluation", "No")} onChange={e => props.setFieldValue("decisionForm", "Evaluation", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "Evaluation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "Evaluation", "N/A")} onChange={e => props.setFieldValue("decisionForm", "Evaluation", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.4</td>
                                        <td>Test report(s)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReport", "Yes")} onChange={e => props.setFieldValue("decisionForm", "TestReport", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReport", "No")} onChange={e => props.setFieldValue("decisionForm", "TestReport", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReport", "N/A")} onChange={e => props.setFieldValue("decisionForm", "TestReport", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.5</td>
                                        <td>Technical Documentation (HALAL Certificate, Health Certificate, MSDS…etc)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TechnicalDocumentation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TechnicalDocumentation", "Yes")} onChange={e => props.setFieldValue("decisionForm", "TechnicalDocumentation", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TechnicalDocumentation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TechnicalDocumentation", "No")} onChange={e => props.setFieldValue("decisionForm", "TechnicalDocumentation", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TechnicalDocumentation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TechnicalDocumentation", "N/A")} onChange={e => props.setFieldValue("decisionForm", "TechnicalDocumentation", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.6</td>
                                        <td>Visible and clear Photos from all dimensions</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ClearPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ClearPhotos", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ClearPhotos", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ClearPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ClearPhotos", "No")} onChange={e => props.setFieldValue("decisionForm", "ClearPhotos", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ClearPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ClearPhotos", "N/A")} onChange={e => props.setFieldValue("decisionForm", "ClearPhotos", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.7</td>
                                        <td>Inspection Report</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "InspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "InspectionReport", "Yes")} onChange={e => props.setFieldValue("decisionForm", "InspectionReport", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "InspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "InspectionReport", "No")} onChange={e => props.setFieldValue("decisionForm", "InspectionReport", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "InspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "InspectionReport", "N/A")} onChange={e => props.setFieldValue("decisionForm", "InspectionReport", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.8</td>
                                        <td>At least 2 (two) visible and clear PHOTOS (one photo for labeling, one photo for product)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "Photos", "Yes")} onChange={e => props.setFieldValue("decisionForm", "Photos", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "Photos", "No")} onChange={e => props.setFieldValue("decisionForm", "Photos", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "Photos", "N/A")} onChange={e => props.setFieldValue("decisionForm", "Photos", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.9</td>
                                        <td>ISO Certification (e.g., ISO 22000, 22716, GMP, .... etc,)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ISOCertification", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ISOCertification", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ISOCertification", "No")} onChange={e => props.setFieldValue("decisionForm", "ISOCertification", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ISOCertification", "N/A")} onChange={e => props.setFieldValue("decisionForm", "ISOCertification", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm3 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={3}>Result of Review & Decision</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"50%"}}>Certificate can be granted?</td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificateGranted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificateGranted", "Yes")} onChange={e => props.setFieldValue("decisionForm", "CertificateGranted", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificateGranted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificateGranted", "No")} onChange={e => props.setFieldValue("decisionForm", "CertificateGranted", "No", e.target.checked)} /> No<sup>2</sup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Certificate Number</td>
                                        <td colSpan={2}>
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-2 pt-1">
                                                    <input type="checkbox" checked={props.getFieldValue("decisionForm", "CertificateNumber", "Yes")} onChange={e => props.setFieldValue("decisionForm", "CertificateNumber", "Yes", e.target.checked)} />
                                                </div>
                                                <div className="flex-grow-1 w-100">
                                                    <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm3)}
                                                        value={props.getFieldValueText("decisionForm", "CertificateNumberText") || ""}
                                                        onChange={e => props.setFieldValueText("decisionForm", "CertificateNumberText", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>Signature</td>
                                        <td>Date</td>
                                    </tr>
                                    <tr>
                                        <td>{(props.fboState && props.fboState.decisionForm && props.fboState.decisionForm.lastUpdateByName) || ""}&nbsp;</td>
                                        <td>
                                            {props.fboState && props.fboState.decisionForm && props.fboState.decisionForm.lastUpdateBySignature &&
                                                <div>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "100px", cursor: "pointer"}} src={`${api}Document/DownloadDocumentByName?fileName=${props.fboState.decisionForm.lastUpdateBySignature}`} alt="Not Found" />
                                                </div>
                                            }&nbsp;
                                        </td>
                                        <td>
                                            <MyDateRenderer value={(props.fboState && props.fboState.decisionForm && props.fboState.decisionForm.ticketForm && props.fboState.decisionForm.ticketForm.dateLastEdit) || ""} format={"dd.mm.yyyy HH:MM:ss"} />&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-form table-form-alt">
                                <tbody>
                                    <tr>
                                        <td style={{width:"30%", paddingTop:50}}>------------------------------------------</td>
                                        <td style={{width:"30%"}}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>1</sup> If there is any missing information in <b>“Points to be Checked”</b> section, please connect with the Technical Coordinator
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>2</sup> If No, a written Rejection Letter Report (on TÜV AUSTRIA letterhead) shall be issued to the applicant.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Collapse>
            </CardBody>
        </Card>
    );
};

export default FormDecisionSFDA;