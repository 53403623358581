import { NOTIFICATION_SEARCH_START_LOADING, NOTIFICATION_SEARCH_END_LOADING, NOTIFICATION_SEARCH_LOADED, SET_NOTIFICATION_FILTER } from "../constants/NotificationConstants";
import { NOTIFICATION_START_LOADING, NOTIFICATION_END_LOADING, NOTIFICATION_LOADED } from "../constants/NotificationConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadNotificationSearch(filters) {
    return async (dispatch) => {
        
        await dispatch({ type: NOTIFICATION_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Notification/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: NOTIFICATION_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: NOTIFICATION_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: NOTIFICATION_SEARCH_END_LOADING });
        }
    };
}

export function setNotificationFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_NOTIFICATION_FILTER,
            payload: filter
        });
    };
}

export function loadNotification(show) {
    return async (dispatch) => {
        
        await dispatch({ type: NOTIFICATION_START_LOADING });

        try {

            var data = await getApiData("Notification/GetUserNotifications", null, null);
            
            if(data.success) {
                var result = data.responseEntities[0];
                result.show = show;

                await dispatch({
                    type: NOTIFICATION_LOADED,
                    payload: result
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: NOTIFICATION_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: NOTIFICATION_END_LOADING });
        }
    };
}

export function saveNotification(id, active, list) {
    return async (dispatch) => {
        
        await dispatch({ type: NOTIFICATION_START_LOADING });

        try {

            if(list) {
                await dispatch({
                    type: NOTIFICATION_SEARCH_LOADED,
                    payload: {
                        dataList: list.dataList
                        , totalCount: list.totalCount
                    }
                });
            }

            var data = await getApiData("Notification/SaveNotification", { id, active }, null);
            
            if(data.success) {
                dispatch(loadNotification(false));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: NOTIFICATION_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: NOTIFICATION_END_LOADING });
        }
    };
}