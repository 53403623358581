export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME';
export const CHANGE_LAYOUT_MODE = 'CHANGE_LAYOUT_MODE';
export const CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH';
export const CHANGE_LAYOUT_POSITION = 'CHANGE_LAYOUT_POSITION';
export const CHANGE_TOPBAR_THEME = 'CHANGE_TOPBAR_THEME';
export const CHANGE_LEFT_SIDEBAR_SIZE_TYPE = 'CHANGE_LEFT_SIDEBAR_SIZE_TYPE'
export const CHANGE_LEFT_SIDEBAR_VIEW_TYPE = 'CHANGE_LEFT_SIDEBAR_VIEW_TYPE';
export const CHANGE_SIDEBAR_IMAGE_TYPE = 'CHANGE_SIDEBAR_IMAGE_TYPE';
export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_PRELOADER = 'CHANGE_PRELOADER';

export const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
  TWOCOLUMN: "twocolumn",
  SEMIBOX: "semibox"
};

export const layoutModeTypes = {
  LIGHTMODE: "light",
  DARKMODE: "dark",
};

export const leftSidebarTypes = {
  LIGHT: "light",
  DARK: "dark",
  GRADIENT: "gradient",
  GRADIENT_2: "gradient-2",
  GRADIENT_3: "gradient-3",
  GRADIENT_4: "gradient-4",
};

export const layoutWidthTypes = {
  FLUID: "lg",
  BOXED: "boxed",
};

export const layoutPositionTypes = {
  FIXED: "fixed",
  SCROLLABLE: "scrollable",
};

export const topbarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
};

export const leftsidbarSizeTypes = {
  DEFAULT: "lg",
  COMPACT: "md",
  SMALLICON: "sm",
  SMALLHOVER: "sm-hover"
};

export const leftSidebarViewTypes = {
  DEFAULT: "default",
  DETACHED: "detached"
};

export const leftSidebarImageTypes = {
  NONE: "none",
  IMG1: "img-1",
  IMG2: "img-2",
  IMG3: "img-3",
  IMG4: "img-4",
};

export const preloaderTypes = {
  ENABLE: "enable",
  DISABLE: "disable"
};

export const sidebarVisibilitytypes = {
  SHOW: "show",
  HIDDEN: "hidden"
};