import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import PaginationComponent from "../components/PaginationComponent";
import MyDateRenderer from "../components/MyDateRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as documentActions from "../actions/DocumentActions";

const DocumentSearchScreen  = () => {
    const dispatch = useDispatch();
        
    const loading = useSelector((state) => state.document.loading);
    const list = useSelector((state) => state.document.list);
    const filter = useSelector((state) => state.document.filter);

    const doSearch = () => {
        var copy_filter = createDeepClone(filter);
        
        if(copy_filter.pageIndex == 1) {
            dispatch(documentActions.loadDocumentSearch(filter));
        }
        else {
            copy_filter.pageIndex = 1;
            dispatch(documentActions.setDocumentFilter(copy_filter));
        }
    }

    React.useEffect(() => {
		dispatch(documentActions.loadDocumentSearch(filter));
    }, [filter.pageIndex]);

    const onFilterChange = (name, value) => {
        var copy_filter = createDeepClone(filter);
        copy_filter[name] = value;
        dispatch(documentActions.setDocumentFilter(copy_filter));
    }

    const onPageChange = (page) => {
        var copy_filter = createDeepClone(filter);
        copy_filter.pageIndex = page;
        dispatch(documentActions.setDocumentFilter(copy_filter));
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Ticket Document Search" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-3">
                                        <Col xxl={10} md={12}>
                                            <div className="search-box">
                                                <input type="text" className="form-control search" placeholder="Search for ticket documents..."
                                                    value={filter.search}
                                                    onChange={e => onFilterChange("search", e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <button type="button" className="btn btn-primary btn-label w-100" 
                                                disabled={loading}
                                                onClick= {() => doSearch()}
                                            >
                                                <i className="ri-search-eye-line label-icon align-middle"></i> Search
                                            </button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardLoader loading={loading} />
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <Table className="table-hover align-middle mb-0">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th style={{width:10}}>&nbsp;</th>
                                                    <th>Ticket #</th>
                                                    <th>Status</th>
                                                    <th>Ticket Type</th>
                                                    <th>Document Type</th>
                                                    <th>Title</th>
                                                    <th>Name</th>
                                                    <th>Date Added</th>
                                                    <th>Added By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.dataList.map(function(item, index){
                                                    return(
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="flex-shrink-0">
                                                                    <ul className="list-inline tasks-list-menu mb-0">
                                                                        <li className="list-inline-item">
                                                                            <Link to={`/request-edit/${item.refId}`}>
                                                                                <i className="ri-pencil-fill fs-20 align-bottom text-primary"></i>
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                            <td>{item.requestNumber}</td>
                                                            <td><span className="badge" style={{backgroundColor: item.statusColorCode}}>{item.statusName}</span></td>
                                                            <td><span className="badge" style={{backgroundColor: item.serviceColorCode}}>{item.serviceName}</span></td>
                                                            <td>{item.documentTypeName}</td>
                                                            <td>{item.title}</td>
                                                            <td>{item.name}</td>
                                                            <td><MyDateRenderer value={item.dateAdded} /></td>
                                                            <td>{item.addedBy}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <PaginationComponent
                                                total={list.totalCount}
                                                currentPage={filter.pageIndex}
                                                onPageChange={page => onPageChange(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default DocumentSearchScreen;