//Search
export const ISO_REQUEST_SEARCH_START_LOADING = "ISO_REQUEST_SEARCH_START_LOADING";
export const ISO_REQUEST_SEARCH_END_LOADING = "ISO_REQUEST_SEARCH_END_LOADING";
export const ISO_REQUEST_SEARCH_LOADED = "ISO_REQUEST_SEARCH_LOADED";
export const SET_ISO_REQUEST_FILTER = "SET_ISO_REQUEST_FILTER";

//Edit
export const ISO_REQUEST_START_LOADING = "ISO_REQUEST_START_LOADING";
export const ISO_REQUEST_END_LOADING = "ISO_REQUEST_END_LOADING";
export const ISO_REQUEST_LOADED = "ISO_REQUEST_LOADED";
