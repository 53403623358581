import { DASHBOARD_START_LOADING, DASHBOARD_END_LOADING, DASHBOARD_LOADED } from "../constants/DashboardConstants";

const initialState = {
    loading: false,
    error: "",
    data: {   
        ticketCountList: []
        , openedClosedTicketCountList: []
        , ticketTrendList: []
        , serviceList: []
        , paymentCountList: []
    }
};

const DashboardReducer = (state = initialState, action) => {
    switch(action.type) {
        case DASHBOARD_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case DASHBOARD_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case DASHBOARD_LOADED:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}

export default DashboardReducer;