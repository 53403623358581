import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";
import Lookup from "../components/Lookup";
import { Highlighter, Menu, MenuItem, } from 'react-bootstrap-typeahead';

import { useDispatch, useSelector } from "react-redux";
import * as productActions from "../actions/ProductActions";

const ProductEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.product.loadingFBO);
    const fbo = useSelector((state) => state.product.fbo);
    
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);
    const [selectedManufacturer, setSelectedManufacturer] = React.useState([]);

    React.useEffect(() => {
	    dispatch(productActions.loadProduct(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        setSelectedManufacturer([]);
        
        if(fbo){
            reset(fbo.product);

            if(fbo.manufacturer != null) {
                setSelectedManufacturer([fbo.manufacturer]);
            }
        }

        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onProductChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.product[name] = value;
        }
        else if(value === false) {
            copy_fboState.product[name] = false;
        }
        else {
            copy_fboState.product[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const ManufacturerRenderer = (results, menuProps, props) => (
        <Menu id="menuManufacturerLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Organization Name</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.organizationName || ''}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    const onManufacturerChange = (item) => {
        setSelectedManufacturer(item);
        
        if(item.length > 0) {
            onProductChange("manufacturerId", item[0].id.toString());
        }
        else {
            onProductChange("manufacturerId", null);
        }
    }

    const onSaveProduct = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(productActions.saveProduct(fboState, navigate));
        }
    }

    const onCancelProduct = () => {
        setFBOState(fbo);

        if(fbo.manufacturer != null) {
            setSelectedManufacturer([fbo.manufacturer]);
        }
        else {
            setSelectedManufacturer([]);
        }

        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={fboState && fboState.product.id > 0 ? "Edit Product" : "Add Product"} />
                    <Row>
                        <Col lg={fboState && fboState.product.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Product</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveProduct}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save Product
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelProduct}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">HSCode<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.hsCode ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.product.hsCode) || ""}
                                                {...register("hsCode", { required: true })}
                                                onChange={e => onProductChange("hsCode", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Product Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.product.name) || ""}
                                                {...register("name", { required: true })}
                                                onChange={e => onProductChange("name", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Country of Origin</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.product.origin) || ""}
                                                onChange={e => onProductChange("origin", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Model Name</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.product.modelName) || ""}
                                                onChange={e => onProductChange("modelName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Brand Name</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.product.brandName) || ""}
                                                onChange={e => onProductChange("brandName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Active</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.product.active) || false}
                                                onChange={e => onProductChange("active", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Description</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.product.description) || ""}
                                                onChange={e => onProductChange("description", e.target.value)}
                                            />
                                        </Col>
                                        {/* <Col xxl={4} md={6}>
                                            <Label className="form-label">Request Type</Label>
                                            <Input type="text" className="form-control"
                                                value={(fboState && fboState.product.service_id) || ""}
                                                onChange={e => onProductChange("service_id", e.target.value)}
                                            />
                                        </Col> */}
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Manufacturer Information</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Organization Name</Label>
                                            <Lookup
                                                clearButton 
                                                allowNew ={false}
                                                id ="fldManufacturerLookup" 
                                                url = "GetManufacturerLookup"
                                                defaultSearch={(fboState && fboState.product.manufacturerId) || ""}
                                                placeholder= "Select Manufacturer..."
                                                labelKey= {option => `${option.organizationName}`}
                                                selected = {selectedManufacturer}
                                                onChange = {(item) => onManufacturerChange(item)}
                                                renderMenu = {ManufacturerRenderer}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Contact Name</Label>
                                            <input className="form-control"
                                                disabled={true}
                                                defaultValue={(selectedManufacturer.length > 0 && selectedManufacturer[0].contactName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Email</Label>
                                            <input className="form-control"
                                                disabled={true}
                                                defaultValue={(selectedManufacturer.length > 0 && selectedManufacturer[0].email) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Telephone Number</Label>
                                            <input className="form-control"
                                                disabled={true}
                                                defaultValue={(selectedManufacturer.length > 0 && selectedManufacturer[0].telephoneNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={8} md={12}>
                                            <Label className="form-label">Address</Label>
                                            <input className="form-control"
                                                disabled={true}
                                                defaultValue={(selectedManufacturer.length > 0 && selectedManufacturer[0].address) || ""}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        {fboState && fboState.product.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.product.id} name={"PRODUCT"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default ProductEditScreen;