
import React from "react";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { getApiData } from "../utils/index";

const Lookup = (props) => {
    const [useDefaultSearch, setUseDefaultSearch] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [list, setList] = React.useState([]);

    React.useEffect(() => {
        if(useDefaultSearch && props.defaultSearch) {
		    doSearch("", props.defaultSearch);
        }
    }, [props.defaultSearch, props.url, props.id]);

    const doSearch = async (search, id) => {
        setLoading(true);
        setUseDefaultSearch(false);

        try {

            var data = await getApiData("Lookup/" + props.url, {search, id});
            
            if(data.success) {
                setList(data.responseEntities[0].dataList);
            }
            else {
                alert(data.errorDesc);
            }
            
			setLoading(false);
        } 
        catch (error) {
            alert(error);
            setLoading(false);
        }
    };

    const filterBy = () => true;

    return (
        <AsyncTypeahead
            {...props}
            filterBy={filterBy}
            isLoading={loading}
            minLength={1}
            onSearch={doSearch}
            options={list}
        />
    );
};

export default Lookup;