import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { PAYMENT_SEARCH_START_LOADING, PAYMENT_SEARCH_END_LOADING, PAYMENT_SEARCH_LOADED, SET_PAYMENT_FILTER } from "../constants/PaymentConstants";
import { PAYMENT_START_LOADING, PAYMENT_END_LOADING, PAYMENT_LOADED } from "../constants/PaymentConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        requestNumber: "",
        hsCode: "",
        status: [],
        service: []
    },
    loadingFBO: false,
    fbo: null
};

const PaymentReducer = (state = initialState, action) => {
    switch(action.type) {
        case PAYMENT_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case PAYMENT_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case PAYMENT_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_PAYMENT_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case PAYMENT_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case PAYMENT_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case PAYMENT_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        default:
            return state;
    }
}

export default PaymentReducer;