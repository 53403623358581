import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col, Label, Input, Row, Table, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import MyDateRenderer from "./MyDateRenderer";
import CardLoader from "./CardLoader";
import ConfirmModal from "./ConfirmModal";
import Lookup from "../components/Lookup";
import { Highlighter, Menu, MenuItem, } from 'react-bootstrap-typeahead';

import { createDeepClone } from "../utils/index";

import { useDispatch, useSelector } from "react-redux";
import * as ticketActions from "../actions/TicketActions";

const TicketProductGrid = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.ticket.loadingProducts);
    const productList = useSelector((state) => state.ticket.productList);

    const [deleteId, setDeleteId] = React.useState(0);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const [updateId, setUpdateId] = React.useState(0);
    const [updateProduct, setUpdateProduct] = React.useState([]);
    const [showUpdateModal, setShowUpdateModal] = React.useState(false);

    const [editProduct, setEditProduct] = React.useState(null);
    const [isDirty, setIsDirty] = React.useState(false);
    
    const [fboState, setFBOState] = React.useState(productList);
    const [selectedProduct, setSelectedProduct] = React.useState([]);
    
    React.useEffect(() => {
		dispatch(ticketActions.loadTicketProducts(props.refId));
    }, [props.serviceId, props.refId]);

    React.useEffect(() => {
		setFBOState(productList);
        if(props.onChange) {
            props.onChange();
        }

        setSelectedProduct([]);
    }, [productList]);

    const onAddTicketProduct = async() => {
        if(selectedProduct != null && selectedProduct.length > 0) {
            dispatch(ticketActions.addTicketProduct(props.refId, selectedProduct[0].id));
        }
    }

    const onChangeProductClick = (id) => {
        setUpdateId(id);
        setUpdateProduct([]);
        setShowUpdateModal(true);
    }

    const onChangeProductConfirm = () => {
        if(updateId > 0 && updateProduct != null && updateProduct.length > 0) {
            dispatch(ticketActions.changeTicketProduct(updateId, updateProduct[0].id, props.refId));
        }

        setUpdateProduct([]);
        setShowUpdateModal(false);
    }

    const onDeleteProductClick = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    }

    const onDeleteProductConfirm = () => {
        if(deleteId > 0) {
            dispatch(ticketActions.deleteTicketProduct(deleteId, props.refId));
        }
        
        setShowDeleteModal(false);
    }

    const onEditProductClick = (item) => {
        setEditProduct(item);
        setIsDirty(false);
    }

    const onEditProductChange = (name, value) => {
        var copy_editProduct = createDeepClone(editProduct);
        
        if(value) {
            copy_editProduct[name] = value;
        }
        else {
            copy_editProduct[name] = null;
        }
        
        setEditProduct(copy_editProduct);
        setIsDirty(true);
    }

    const onCancelEditProductClick = () => {
        setEditProduct(null);
        setIsDirty(false);
    }

    const onSaveEditProductClick = () => {
        dispatch(ticketActions.saveTicketProduct(editProduct, props.refId));
        setEditProduct(null);
        setIsDirty(false);
    }

    const hsCodeRenderer = (results, menuProps, props) => (
        <Menu id="menuProductLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">HSCode</div>
                    <div className="col">Product Name</div>
                    <div className="col">Technical Regulation Name</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Products Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.hsCode || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.name || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.description || ''}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    const onTicketProductHsCodeChange = (item) => {
        setSelectedProduct(item);
    }

    const onUpdateProductHsCodeChange = (item) => {
        setUpdateProduct(item);
    }

    return (
        <Fragment>
            <Card className="mb-3">
                <CardHeader className="border-0 d-flex align-items-center">
                    <CardLoader loading={loading} />
                    <h5 className="card-title mb-0 flex-grow-1">Products</h5>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <Row className="gy-2">
                        {props.canChange && ((props.allowMultiple === false && productList.length == 0) || (props.allowMultiple === true)) &&
                        <Fragment>
                            <Col xxl={10} md={10}>
                                <Lookup 
                                    allowNew ={false}
                                    id ="fldProductLookup" 
                                    url = "GetProductLookup"
                                    placeholder= "Select Product..."
                                    labelKey= {option => `${option.hsCode} - ${option.name}`}
                                    selected = {selectedProduct}
                                    onChange = {(item) => onTicketProductHsCodeChange(item)}
                                    renderMenu = {hsCodeRenderer}
                                />
                            </Col>
                            <Col xxl={2} md={6}>
                                <button type="button" className="btn btn-primary btn-label w-100" 
                                    disabled={loading || selectedProduct.length == 0}
                                    onClick={onAddTicketProduct}
                                >
                                    <i className="ri-add-line label-icon align-bottom"></i> Add Product
                                </button>
                            </Col>
                        </Fragment>
                        }
                        <Col xxl={12} md={12}>
                            <Table className="table-bordered align-middle mb-0">
                                <thead className="table-primary">
                                    <tr>
                                        <th>HSCode</th>
                                        <th>Product Name</th>
                                        <th>Technical Regulation Name</th>
                                        <th>Date Added</th>
                                        {props.canChange && <th style={{width:"5%"}}>&nbsp;</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {productList.length == 0 && <tr><td colSpan={5}>No Product(s) Added</td></tr>}
                                    {productList.map(function(item, index){
                                        return(
                                            <Fragment key={index}>
                                                <tr>
                                                    <td>
                                                        {item.hsCode}
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        {item.description}<br /> 
                                                        {item.organizationName && <span>Manufacturer: {item.organizationName}<br /></span>}
                                                        {editProduct && editProduct.id === item.id &&
                                                            <Fragment>
                                                                <Row className="gy-2">
                                                                    <Col xxl={2} md={2}>
                                                                        Model Name:
                                                                    </Col>
                                                                    <Col xxl={10} md={10}>
                                                                        <input type="text" 
                                                                            value={editProduct.modelName || ""}
                                                                            onChange={e => onEditProductChange("modelName", e.target.value)}
                                                                        />
                                                                    </Col>
                                                                    <Col xxl={2} md={2}>
                                                                        Brand Name:
                                                                    </Col>
                                                                    <Col xxl={10} md={10}>
                                                                        <input type="text" 
                                                                            value={editProduct.brandName || ""}
                                                                            onChange={e => onEditProductChange("brandName", e.target.value)}
                                                                        />
                                                                    </Col>
                                                                    <Col xxl={2} md={2}>&nbsp;</Col>
                                                                    <Col xxl={10} md={10}>
                                                                        <button type="button" className="btn btn-success btn-label btn-sm me-1" disabled={!isDirty} onClick={onSaveEditProductClick}>
                                                                            <i className="ri-save-line label-icon align-bottom"></i> Save
                                                                        </button>
                                                                        <button type="button" className="btn btn-danger btn-label btn-sm" onClick={() => onCancelEditProductClick()}>
                                                                            <i className="ri-close-line label-icon align-bottom"></i> Cancel
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                            </Fragment>
                                                        }
                                                        {(editProduct === null || editProduct.id !== item.id) &&
                                                            <Fragment>
                                                                <span>Model Name: {item.modelName}</span><br />
                                                                <span>Brand Name: {item.brandName}</span>
                                                                {props.canChange &&
                                                                <button type="button" className="btn btn-primary btn-sm ms-1" title="Edit Product"
                                                                    onClick={() => onEditProductClick(item)}
                                                                >
                                                                    <i className="ri-pencil-fill" />
                                                                </button>
                                                                }
                                                            </Fragment>
                                                        }
                                                    </td>
                                                    <td><MyDateRenderer value={item.dateAdded} /></td>
                                                    {props.canChange &&
                                                    <td>
                                                        <button type="button" className="btn btn-primary btn-sm me-1" title="Change Product"
                                                            onClick={() => onChangeProductClick(item.id)}
                                                        >
                                                            <i className="ri-pencil-fill" />
                                                        </button>
                                                        <button type="button" className="btn btn-danger btn-sm me-1 mt-1" title="Remove Product"
                                                            onClick={() => onDeleteProductClick(item.id)}
                                                        >
                                                            <i className="ri-delete-bin-2-line" />
                                                        </button>
                                                    </td>
                                                    }
                                                </tr>
                                            </Fragment>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Modal tabIndex="-1" size="xl" isOpen={showUpdateModal} toggle={() => setShowUpdateModal(!showUpdateModal)} centered={true}>
                <ModalHeader>Change Product</ModalHeader>
                <ModalBody>
                    <Row className="gy-2">
                        <Col xxl={12} md={12}>
                            <Lookup 
                                allowNew ={false}
                                id ="fldProductLookup" 
                                url = "GetProductLookup"
                                placeholder= "Select Product..."
                                labelKey= {option => `${option.hsCode} - ${option.name}`}
                                selected = {updateProduct}
                                onChange = {(item) => onUpdateProductHsCodeChange(item)}
                                renderMenu = {hsCodeRenderer}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-success btn-label" disabled={(updateProduct && updateProduct.length > 0) ? false : true} onClick={onChangeProductConfirm}>
                        <i className="ri-save-line label-icon align-bottom"></i> Update
                    </button>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={() => setShowUpdateModal(false)}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <ConfirmModal message={"Are you sure you want to delete the Product?"} show={showDeleteModal} onCloseClick={() => setShowDeleteModal(false)} onYesClick={onDeleteProductConfirm} />
        </Fragment>
    );
};

export default TicketProductGrid;