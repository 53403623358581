import React from "react";

const CardLoader = (props) => {
    return (
        <div className={`card-preloader ${props.loading ? "d-block" : "d-none"}`}>
            <div className="card-status">
                <div className="spinner-border text-primary"><span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    );
};

export default CardLoader;