import React, { Fragment, useRef, useLayoutEffect } from "react";

//import * as am4core from "@amcharts/amcharts4/core";
//import * as am4charts from "@amcharts/amcharts4/charts";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Loader from "../components/Loader";
import StatBox from "../components/StatBox";
import MyNumberRenderer from "../components/MyNumberRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as dashboardActions from "../actions/DashboardActions";

//am4core.useTheme(am4themes_animated);

const DashboardScreen  = () => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.dashboard.loading);
    const data = useSelector((state) => state.dashboard.data);
    const user = useSelector((state) => state.login.user);

    const openVsClosedTicketChart = useRef(null);
    const trendChart = useRef(null);
    const serviceChart = useRef(null);
    const paymentChart = useRef(null);
    
    /*const createOpenVsClosedTicketChart = () => {
        var chart = am4core.create("divOpenVsClosedTicketChart", am4charts.XYChart);
        chart.paddingLeft = 0;
        chart.paddingRight = 10;
        chart.colors.list = [am4core.color("#E22128"), am4core.color("#FF9900")];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "month";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        //categoryAxis.title.text = "Month";
        categoryAxis.title.fontWeight = "bold";
        categoryAxis.renderer.labels.template.rotation = -90;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.minGridDistance = 20;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        //valueAxis.title.text = "Tickets";
        valueAxis.title.fontWeight = "bold";
        valueAxis.min = 0;
        valueAxis.extraMax = 0.1;

        // Create series
        var seriesOpen = chart.series.push(new am4charts.ColumnSeries());
        seriesOpen.dataFields.valueY = "open";
        seriesOpen.dataFields.categoryX = "month";
        seriesOpen.tooltipText = "Open in {categoryX}: [bold]{valueY}[/]";
        seriesOpen.name = "Open";
        seriesOpen.columns.template.propertyFields.fill = "color";
        seriesOpen.columns.template.propertyFields.stroke = "color";
        seriesOpen.columns.template.width = am4core.percent(80);
        seriesOpen.clustered = false;

        var seriesClosed = chart.series.push(new am4charts.ColumnSeries());
        seriesClosed.dataFields.valueY = "closed";
        seriesClosed.dataFields.categoryX = "month";
        seriesClosed.tooltipText = "Closed in {categoryX}: [bold]{valueY}[/]";
        seriesClosed.name = "Closed";
        seriesClosed.columns.template.propertyFields.fill = "color";
        seriesClosed.columns.template.propertyFields.stroke = "color";
        seriesClosed.columns.template.width = am4core.percent(40);
        seriesClosed.clustered = false;

        chart.cursor = new am4charts.XYCursor();

        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        
        chart.data = data.openedClosedTicketCountList;

        return chart;
    }

    const createTrendChart = () => {
        var chart = am4core.create("divTrendChart", am4charts.XYChart);
        chart.paddingLeft = 0;
        chart.paddingRight = 10;
        chart.colors.list = [am4core.color("#FF9900")];

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "span";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        //categoryAxis.title.text = "Month";
        categoryAxis.title.fontWeight = "bold";
        categoryAxis.renderer.labels.template.rotation = -90;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.minGridDistance = 20;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        //valueAxis.title.text = "Tickets";
        valueAxis.title.fontWeight = "bold";
        valueAxis.min = 0;
        valueAxis.extraMax = 0.1;

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "span";
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.propertyFields.fill = "color";
        series.columns.template.propertyFields.stroke = "color";
        series.columns.template.width = am4core.percent(50);

        chart.cursor = new am4charts.XYCursor();

        chart.data = data.ticketTrendList;

        return chart;
    }

    const createServiceChart = () => {
        var chart = am4core.create("divServiceChart", am4charts.PieChart3D);
		
		var pieSeries = chart.series.push(new am4charts.PieSeries3D());
		pieSeries.dataFields.value = "value";
		pieSeries.dataFields.category = "serviceName";
		pieSeries.labels.template.text = "[{color}]{category}: ({value.percent.formatNumber('#.##')}%)";
        pieSeries.labels.template.fontSize = 12;
        //pieSeries.labels.template.fontWeight = "bold";

        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
        chart.legend.itemContainers.template.fontSize = "12px";
        
        pieSeries.hiddenState.properties.endAngle = -90;
        chart.hiddenState.properties.radius = am4core.percent(0);

        //fly-in
        pieSeries.slices.template.showOnInit = true;
        pieSeries.slices.template.hiddenState.properties.shiftRadius = 1;
        pieSeries.slices.template.propertyFields.fill = "color";
			
		chart.data = data.serviceList;

        return chart;
    }

    const createPaymentChart = () => {
        var chart = am4core.create("divPaymentChart", am4charts.XYChart);
        chart.colors.list = [am4core.color("#E22128"), am4core.color("#FF9900")];
        
        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "month";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        //categoryAxis.title.text = "Month";
        categoryAxis.title.fontWeight = "bold";
        categoryAxis.renderer.labels.template.rotation = -90;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.renderer.minGridDistance = 20;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        //valueAxis.title.text = "Payments";
        valueAxis.title.fontWeight = "bold";
        valueAxis.min = 0;
        valueAxis.extraMax = 0.1;

        // Create series
        var seriesInvoiced = chart.series.push(new am4charts.LineSeries());
        seriesInvoiced.dataFields.valueY = "invoiced";
        seriesInvoiced.dataFields.categoryX = "month";
        seriesInvoiced.tensionX = 0.8;
        seriesInvoiced.strokeWidth = 3;
        seriesInvoiced.minBulletDistance = 10;
        seriesInvoiced.tooltipText = "[bold]Invoiced:[/] {invoiced}";
        seriesInvoiced.tooltip.pointerOrientation = "vertical";
        seriesInvoiced.name = "Invoiced";
        seriesInvoiced.bullets.push(new am4charts.CircleBullet());

        // Create series
        var seriesPaid = chart.series.push(new am4charts.LineSeries());
        seriesPaid.dataFields.valueY = "paid";
        seriesPaid.dataFields.categoryX = "month";
        seriesPaid.tensionX = 0.8;
        seriesPaid.strokeWidth = 3;
        seriesPaid.minBulletDistance = 10;
        seriesPaid.tooltipText = "[bold]Paid:[/] {paid}";
        seriesPaid.tooltip.pointerOrientation = "vertical";
        seriesPaid.name = "Paid";
        seriesPaid.bullets.push(new am4charts.CircleBullet());

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";

        chart.data = data.paymentCountList;

        return chart;
    }
    */

    useLayoutEffect(() => {
        /*var chart1 = createOpenVsClosedTicketChart();
        openVsClosedTicketChart.current = chart1;

        var chart2 = createTrendChart();
        trendChart.current = chart2;
        
        var chart3 = createServiceChart();
        serviceChart.current = chart3;

        var chart4 = createPaymentChart();
        paymentChart.current = chart4;

        return () => {
            chart1.dispose();
            chart2.dispose();
            chart3.dispose();
            chart4.dispose();
        };*/
    }, [data.openedClosedTicketCountList, data.ticketTrendList, data.serviceList, data.paymentCountList]);

    React.useEffect(() => {
		dispatch(dashboardActions.loadDashboard());
    }, []);

    return(
        <Fragment>
            <div>
                {loading && <Loader />}
            </div>
            <div className="row mb-4">
                <div className="col">
                    <h1 className="h3 mb-0 text-gray-800">
                        Dashboard
                    </h1>
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-primary float-right mr-1"
                        disabled={loading}
                        onClick= {() => dispatch(dashboardActions.loadDashboard())}
                    >
                        <i className="fas fa-sync"></i> Refresh
                    </button>
                </div>
            </div>
            <div className="row">
                <StatBox label="Total Tickets" color="text-info" icon="fa-stream" border="border-left-info"><MyNumberRenderer value={data.ticketCountList && data.ticketCountList.length > 0 ? data.ticketCountList[0].total : 0 }/></StatBox>
                <StatBox label="Open" color="text-dark" icon="fa-folder-open" border="border-left-dark"><MyNumberRenderer value={data.ticketCountList && data.ticketCountList.length > 0 ? data.ticketCountList[0].open : 0 }/></StatBox>
                <StatBox label="Waiting Response" color="text-warning" icon="fa-clock" border="border-left-warning"><MyNumberRenderer value={data.ticketCountList && data.ticketCountList.length > 0 ? data.ticketCountList[0].waitingResponse : 0 }/></StatBox>
                <StatBox label="Close" color="text-success" icon="fa-folder" border="border-left-success"><MyNumberRenderer value={data.ticketCountList && data.ticketCountList.length > 0 ? data.ticketCountList[0].closed : 0 }/></StatBox>
            </div>
            <div className="row">
                <StatBox label="Span (Min)" color="text-info" icon="fa-stopwatch" border="border-bottom-info">{data.ticketCountList && data.ticketCountList.length > 0 ? data.ticketCountList[0].minSpanValue : "" }</StatBox>
                <StatBox label="Span (Max)" color="text-info" icon="fa-stopwatch" border="border-bottom-info">{data.ticketCountList && data.ticketCountList.length > 0 ? data.ticketCountList[0].maxSpanValue : "" }</StatBox>
                <StatBox label="Unattended" color="text-danger" icon="fa-exclamation" border="border-left-danger"><MyNumberRenderer value={data.ticketCountList && data.ticketCountList.length > 0 ? data.ticketCountList[0].unattended : 0 }/></StatBox>
                <StatBox label="Refused" color="text-danger" icon="fa-ban" border="border-left-danger"><MyNumberRenderer value={data.ticketCountList && data.ticketCountList.length > 0 ? data.ticketCountList[0].refused : 0 }/></StatBox>
            </div>
            {/* <div className="row">
                <div className="col-lg-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">Open Vs Closed Tickets (Last 12 Months)</h6>
                        </div>
                        <div className="card-body">
                            <div id="divOpenVsClosedTicketChart" style={{width:"100%", height: '500px'}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card shadow">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">Trend (Last 12 Months)</h6>
                        </div>
                        <div className="card-body">
                            <div id="divTrendChart" style={{width:"100%", height: '500px'}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card shadow">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">Services (Last 12 Months)</h6>
                        </div>
                        <div className="card-body">
                            <div id="divServiceChart" style={{width:"100%", height: '500px'}}></div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card shadow">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold">Payments (Last 12 Months)</h6>
                        </div>
                        <div className="card-body">
                            <div id="divPaymentChart" style={{width:"100%", height: '500px'}}></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    );
}

export default DashboardScreen;