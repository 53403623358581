import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { CUSTOMER_SEARCH_START_LOADING, CUSTOMER_SEARCH_END_LOADING, CUSTOMER_SEARCH_LOADED, SET_CUSTOMER_FILTER } from "../constants/CustomerConstants";
import { CUSTOMER_START_LOADING, CUSTOMER_END_LOADING, CUSTOMER_LOADED } from "../constants/CustomerConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        active: ""
    },
    loadingFBO: false,
    fbo: null,
};

const CustomerReducer = (state = initialState, action) => {
    switch(action.type) {
        case CUSTOMER_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case CUSTOMER_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case CUSTOMER_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_CUSTOMER_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case CUSTOMER_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case CUSTOMER_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case CUSTOMER_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        default:
            return state;
    }
}

export default CustomerReducer;