import { LOGIN_START_LOADING, LOGIN_END_LOADING, LOGIN_LOADED, LOGIN_TOKEN_LOADED, LOGIN_DATA_LOADED, CHANGE_PASSWORD_LOADED } from "../constants/LoginConstants";

const initialState = {
    loading: false,
    user: null,
    token: "",
    data: {
        serviceList: []
        , actionTypeList: []
        , currencyList: []
        , documentTypeList: []
        , paymentStatusList: []
        , ticketStatusList: []
        , technicalRegulationList: []
    },
    passwordFBO: null
};

const LoginReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOGIN_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case LOGIN_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case LOGIN_LOADED:
            return {
                ...state,
                user: action.payload
            };
        case LOGIN_TOKEN_LOADED:
            return {
                ...state,
                token: action.payload
            };
        case LOGIN_DATA_LOADED:
            return {
                ...state,
                data: action.payload
            };
        case CHANGE_PASSWORD_LOADED:
            return {
                ...state,
                passwordFBO: action.payload
            };
        default:
            return state;
    }
}

export default LoginReducer;