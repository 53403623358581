import { COMPANY_SEARCH_START_LOADING, COMPANY_SEARCH_END_LOADING, COMPANY_SEARCH_LOADED, SET_COMPANY_FILTER } from "../constants/CompanyConstants";
import { COMPANY_START_LOADING, COMPANY_END_LOADING, COMPANY_LOADED } from "../constants/CompanyConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadCompanySearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: COMPANY_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Company/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: COMPANY_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: COMPANY_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: COMPANY_SEARCH_END_LOADING });
        }
    };
}

export function setCompanyFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_COMPANY_FILTER,
            payload: filter
        });
    };
}

export function loadCompany(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: COMPANY_START_LOADING });

        try {

            var data = await getApiData("Company/GetCompanyFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: COMPANY_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: COMPANY_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: COMPANY_END_LOADING });
        }
    };
}

export function saveCompany(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: COMPANY_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("Company/SaveCompanyFBO", null, fbo);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/user-edit/' + data.responseEntities[0].userProfile.id);
                    dispatch(commonActions.setSuccessMessage("Company Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: COMPANY_LOADED,
                        payload: data.responseEntities[0]
                    });
                    
                    dispatch(commonActions.setSuccessMessage("Company Saved Successfully!"));
                }
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: COMPANY_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: COMPANY_END_LOADING });
        }
    };
}