import { 
    ISO_DASHBOARD_START_LOADING, 
    ISO_DASHBOARD_END_LOADING,
    SET_ISO_DASHBOARD_FILTER_SEARCH,
    SET_ISO_DASHBOARD_FILTER_MINE_ONLY, 
    ISO_DASHBOARD_DRAFT_LOADED, 
    ISO_DASHBOARD_NEW_LOADED,
    ISO_DASHBOARD_SALES_ACTION_LOADED,
    ISO_DASHBOARD_CALCULATE_MAN_DAYS_LOADED,
    ISO_DASHBOARD_SALES_SUPPORT_LOADED,
    ISO_DASHBOARD_CUSTOMER_APPROVED_LOADED,
    ISO_DASHBOARD_AUDIT_IN_PROGRESS_LOADED,
    ISO_DASHBOARD_TECHNICAL_REVIEW_LOADED,
    ISO_DASHBOARD_FINAL_DECISION_LOADED,
    ISO_DASHBOARD_COMPLETED_LOADED,
    ISO_DASHBOARD_CLOSED_LOADED,
    ISO_DASHBOARD_ABORTED_LOADED,
    ISO_DASHBOARD_DOCUMENT_USER_LOADED,
    ISO_DASHBOARD_AUDIT_USER_LOADED,
    ISO_DASHBOARD_TECHNICAL_USER_LOADED,
    ISO_DASHBOARD_DECISION_USER_LOADED,
} from "../constants/IsoDashboardConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadIsoDashboard(status, search, mineOnly) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_DASHBOARD_START_LOADING });

        try {

            var data = await getApiData("IsoRequest/GetIsoRequestGroupFBO", { status, search, mineOnly }, null);
            
            if(data.success) {
                if(status === null || status === "" || status === "Draft") {
                    await dispatch({
                        type: ISO_DASHBOARD_DRAFT_LOADED,
                        payload: data.responseEntities[0].draftList
                    });
                }

                if(status === null || status === "" || status === "New") {
                    await dispatch({
                        type: ISO_DASHBOARD_NEW_LOADED,
                        payload: data.responseEntities[0].newList
                    });
                }

                if(status === null || status === "" || status === "Sales Action") {
                    await dispatch({
                        type: ISO_DASHBOARD_SALES_ACTION_LOADED,
                        payload: data.responseEntities[0].salesActionList
                    });
                }

                if(status === null || status === "" || status === "Calculate Man Days") {
                    await dispatch({
                        type: ISO_DASHBOARD_CALCULATE_MAN_DAYS_LOADED,
                        payload: data.responseEntities[0].calculateManDaysList
                    });
                }

                if(status === null || status === "" || status === "Sales Support") {
                    await dispatch({
                        type: ISO_DASHBOARD_SALES_SUPPORT_LOADED,
                        payload: data.responseEntities[0].salesSupportList
                    });
                }

                if(status === null || status === "" || status === "Customer Approved") {
                    await dispatch({
                        type: ISO_DASHBOARD_CUSTOMER_APPROVED_LOADED,
                        payload: data.responseEntities[0].customerApprovedList
                    });
                }

                if(status === null || status === "" || status === "Audit In Progress") {
                    await dispatch({
                        type: ISO_DASHBOARD_AUDIT_IN_PROGRESS_LOADED,
                        payload: data.responseEntities[0].auditInProgressList
                    });
                }

                if(status === null || status === "" || status === "Technical Review") {
                    await dispatch({
                        type: ISO_DASHBOARD_TECHNICAL_REVIEW_LOADED,
                        payload: data.responseEntities[0].technicalReviewList
                    });
                }

                if(status === null || status === "" || status === "Final Decision") {
                    await dispatch({
                        type: ISO_DASHBOARD_FINAL_DECISION_LOADED,
                        payload: data.responseEntities[0].finalDecisionList
                    });
                }

                if(status === null || status === "" || status === "Completed") {
                    await dispatch({
                        type: ISO_DASHBOARD_COMPLETED_LOADED,
                        payload: data.responseEntities[0].completedList
                    });
                }

                if(status === null || status === "" || status === "Closed") {
                    await dispatch({
                        type: ISO_DASHBOARD_CLOSED_LOADED,
                        payload: data.responseEntities[0].closedList
                    });
                }

                if(status === null || status === "" || status === "Aborted") {
                    await dispatch({
                        type: ISO_DASHBOARD_ABORTED_LOADED,
                        payload: data.responseEntities[0].abortedList
                    });
                }

                await dispatch({
                    type: ISO_DASHBOARD_DOCUMENT_USER_LOADED,
                    payload: data.responseEntities[0].documentUserList
                });

                await dispatch({
                    type: ISO_DASHBOARD_AUDIT_USER_LOADED,
                    payload: data.responseEntities[0].auditUserList
                });

                await dispatch({
                    type: ISO_DASHBOARD_TECHNICAL_USER_LOADED,
                    payload: data.responseEntities[0].technicalUserList
                });

                await dispatch({
                    type: ISO_DASHBOARD_DECISION_USER_LOADED,
                    payload: data.responseEntities[0].decisionUserList
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ISO_DASHBOARD_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_DASHBOARD_END_LOADING });
        }
    };
}

export function setIsoDashboardFilterSearch(search) {
    return async (dispatch) => {
        await dispatch({
            type: SET_ISO_DASHBOARD_FILTER_SEARCH,
            payload: search
        });
    };
}

export function setIsoDashboardFilterMineOnly(mineOnly) {
    return async (dispatch) => {
        await dispatch({
            type: SET_ISO_DASHBOARD_FILTER_MINE_ONLY,
            payload: mineOnly
        });
    };
}

export function assignIsoRequest(isoRequestId, userId, assignMe, items, index) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_DASHBOARD_START_LOADING });

        try {

            var data = await postApiJsonData("IsoRequest/AssignIsoRequest", { isoRequestId, userId, assignMe }, null);
            
            if(data.success) {
                if(data.responseEntities[0].status === "New") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: ISO_DASHBOARD_NEW_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "Audit In Progress") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: ISO_DASHBOARD_AUDIT_IN_PROGRESS_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "Technical Review") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: ISO_DASHBOARD_TECHNICAL_REVIEW_LOADED,
                        payload: items
                    });
                }
                else if(data.responseEntities[0].status === "Final Decision") {
                    items[index] = data.responseEntities[0];

                    await dispatch({
                        type: ISO_DASHBOARD_FINAL_DECISION_LOADED,
                        payload: items
                    });
                }
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ISO_DASHBOARD_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_DASHBOARD_END_LOADING });
        }
    };
}