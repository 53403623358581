import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Col, Row, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";

import { useDispatch, useSelector } from "react-redux";
import * as vendorActions from "../actions/VendorActions";
import * as commonActions from "../actions/CommonActions";

const VendorScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const dispatch = useDispatch();

    const [fboState, setFBOState] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [title, setTitle] = React.useState(props.type === "manufacturer" ? "Manufacturer" : (props.type === "importer" ? "Importer" : "Exporter"));

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
        
            var data = await vendorActions.loadVendorAsync(0, props.type);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
            }
    
            setLoading(false);

            reset(fboState);
            clearErrors();
        }

        fetchData();
    }, [props.type]);
    
    const onVendorChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.vendor[name] = value;
        }
        else if(value === false) {
            copy_fboState.vendor[name] = false;
        }
        else {
            copy_fboState.vendor[name] = null;
        }
        
        setFBOState(copy_fboState);
    }

    const onSaveVendor = async() => {
        const valid = await trigger();
        if(valid) {
            setLoading(true);

            var data = await vendorActions.saveVendorAsync(fboState);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                props.hideVendorFormModal(data.fbo.vendor);
            }

            setLoading(false);
        }
    }
    
    return(
        <Fragment>
            <Modal tabIndex="-1" size="xl" scrollable={true} isOpen={props.showVendorFormModal} centered={true}>
                <ModalBody>
                    <CardLoader loading={loading} />
                    <Row className="gy-2">
                        <Col xxl={12} md={12}>
                            <h5 className="card-title flex-grow-1"><b>{title}</b></h5>
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Organization Name<span className="required">*</span></Label>
                            <input type="text" className={`form-control ${errors.organizationName ? "is-invalid" : ""}`}
                                value={(fboState && fboState.vendor.organizationName) || ""}
                                {...register("organizationName", { required: true })}
                                onChange={e => onVendorChange("organizationName", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Commercial Registration Number</Label>
                            <input type="text" className="form-control"
                                value={(fboState && fboState.vendor.commercialRegistrationNumber) || ""}
                                onChange={e => onVendorChange("commercialRegistrationNumber", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Contact Name<span className="required">*</span></Label>
                            <input type="text" className={`form-control ${errors.contactName ? "is-invalid" : ""}`}
                                value={(fboState && fboState.vendor.contactName) || ""}
                                {...register("contactName", { required: true })}
                                onChange={e => onVendorChange("contactName", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Email<span className="required">*</span></Label>
                            <input type="text" className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                value={(fboState && fboState.vendor.email) || ""}
                                {...register("email", { required: true })}
                                onChange={e => onVendorChange("email", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Telephone Number<span className="required">*</span></Label>
                            <input type="text" className={`form-control ${errors.telephoneNumber ? "is-invalid" : ""}`}
                                value={(fboState && fboState.vendor.telephoneNumber) || ""}
                                {...register("telephoneNumber", { required: true })}
                                onChange={e => onVendorChange("telephoneNumber", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Mobile Number</Label>
                            <input type="text" className="form-control"
                                value={(fboState && fboState.vendor.mobileNumber) || ""}
                                onChange={e => onVendorChange("mobileNumber", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Fax Number</Label>
                            <input type="text" className="form-control"
                                value={(fboState && fboState.vendor.faxNumber) || ""}
                                onChange={e => onVendorChange("faxNumber", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Active</Label>
                            <select className="form-select"
                                value={(fboState && fboState.vendor.active) || false}
                                onChange={e => onVendorChange("active", e.target.value == "true" ? true : false)}
                            >
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>
                        </Col>
                        <Col xxl={8} md={8}>
                            <Label className="form-label">Address</Label>
                            <input type="text" className="form-control"
                                value={(fboState && fboState.vendor.address) || ""}
                                onChange={e => onVendorChange("address", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">City<span className="required">*</span></Label>
                            <input type="text" className={`form-control ${errors.city ? "is-invalid" : ""}`}
                                value={(fboState && fboState.vendor.city) || ""}
                                {...register("city", { required: true })}
                                onChange={e => onVendorChange("city", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">State</Label>
                            <input type="text" className="form-control"
                                value={(fboState && fboState.vendor.state) || ""}
                                onChange={e => onVendorChange("state", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Country<span className="required">*</span></Label>
                            <input type="text" className={`form-control ${errors.country ? "is-invalid" : ""}`}
                                value={(fboState && fboState.vendor.country) || ""}
                                {...register("country", { required: true })}
                                onChange={e => onVendorChange("country", e.target.value)}
                            />
                        </Col>
                        <Col xxl={4} md={6}>
                            <Label className="form-label">Zip Code</Label>
                            <input type="text" className="form-control"
                                value={(fboState && fboState.vendor.zipCode) || ""}
                                onChange={e => onVendorChange("zipCode", e.target.value)}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-success btn-label" disabled={loading} onClick={() => onSaveVendor()}>
                        <i className="ri-save-line label-icon align-bottom"></i> Save
                    </button>
                    <button type="button" className="btn btn-danger btn-label" disabled={loading} data-dismiss="modal" onClick={() => props.hideVendorFormModal(null)}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default VendorScreen;