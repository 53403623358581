import { LOOKUP_START_LOADING, LOOKUP_END_LOADING, USER_LOOKUP_LOADED } from "../constants/LookupConstants";

const initialState = {
    loading: false,
    userList: []
};

const LookupReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOOKUP_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case LOOKUP_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case USER_LOOKUP_LOADED:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
}

export default LookupReducer;