import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";

import { useDispatch, useSelector } from "react-redux";
import * as companyActions from "../actions/CompanyActions";

const CompanyEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.company.loadingFBO);
    const fbo = useSelector((state) => state.company.fbo);
    
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);

    const [refreshAction, setRefreshAction] = React.useState(false);

    React.useEffect(() => {
	    dispatch(companyActions.loadCompany(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);

        if(fbo){
            reset(fbo.company);
        }

        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onCompanyChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.company[name] = value;
        }
        else if(value === false) {
            copy_fboState.company[name] = false;
        }
        else {
            copy_fboState.company[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onCompanyCountryChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        copy_fboState.countryList[index].isSelected = value;
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onSaveCompany = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(companyActions.saveCompany(fboState, navigate));
        }
    }

    const onCancelCompany = () => {
        setFBOState(fbo);
        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={fboState && fboState.company.id > 0 ? "Edit Company" : "Add Company"} />
                    <Row>
                        <Col lg={fboState && fboState.company.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />
                            
                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    
                                    <h5 className="card-title mb-0 flex-grow-1">Company</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveCompany}
                                            >
                                                <i className="ri-save-line label-icon align-bottom"></i> Save Company
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelCompany}
                                            >
                                                <i className="ri-close-line label-icon align-bottom"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Company Code<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.code ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.company.code) || ""}
                                                readOnly={fboState && fboState.company.id > 0 ? true : false}
                                                {...register("code", { required: true })}
                                                onChange={e => onCompanyChange("code", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Company Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.company.name) || ""}
                                                {...register("name", { required: true })}
                                                onChange={e => onCompanyChange("name", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Business Name</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.company.businessName) || ""}
                                                onChange={e => onCompanyChange("businessName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Active</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.company.active) || false}
                                                onChange={e => onCompanyChange("active", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Contact Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.company.contactNumber) || ""}
                                                onChange={e => onCompanyChange("contactNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Fax Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.company.faxNumber) || ""}
                                                onChange={e => onCompanyChange("faxNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Address</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.company.address) || ""}
                                                onChange={e => onCompanyChange("address", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">City</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.company.city) || ""}
                                                onChange={e => onCompanyChange("city", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Country</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.company.country) || ""}
                                                onChange={e => onCompanyChange("country", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Zip Code</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.company.zipCode) || ""}
                                                onChange={e => onCompanyChange("zipCode", e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Countries</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    {fboState && fboState.countryList.map(function(item, index){
                                        return (
                                            <div className="form-check mb-1" key={index}>
                                                <input type="checkbox" className="form-check-input"
                                                    checked={item.isSelected}
                                                    onChange={e => onCompanyCountryChange(index, e.target.checked)}
                                                />
                                                <Label className="mt-0">{item.description}</Label>
                                            </div>
                                        );
                                    })}
                                </CardBody>
                            </Card>
                        </Col>
                        
                        {fboState && fboState.company.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.company.id} name={"COMPANY"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default CompanyEditScreen;