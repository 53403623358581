import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { PRODUCT_SEARCH_START_LOADING, PRODUCT_SEARCH_END_LOADING, PRODUCT_SEARCH_LOADED, SET_PRODUCT_FILTER } from "../constants/ProductConstants";
import { PRODUCT_START_LOADING, PRODUCT_END_LOADING, PRODUCT_LOADED } from "../constants/ProductConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        active: ""
    },
    loadingFBO: false,
    fbo: null,
};

const ProductReducer = (state = initialState, action) => {
    switch(action.type) {
        case PRODUCT_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case PRODUCT_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case PRODUCT_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_PRODUCT_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case PRODUCT_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case PRODUCT_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case PRODUCT_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        default:
            return state;
    }
}

export default ProductReducer;