import React from "react";
import { NavLink } from "react-router-dom";
import { ListGroup, ListGroupItem } from 'reactstrap';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';


import MyDateRenderer from "./MyDateRenderer";
import ConfirmModal from "./ConfirmModal";

import { useDispatch, useSelector } from "react-redux";
import * as isoDashboardActions from "../actions/IsoDashboardActions";

const IsoDashboardList = (props) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.login.user);

    const [selectedId, setSelectedId] = React.useState(0);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedUserId, setSelectedUserId] = React.useState(-1);
    const [showAssignMeModal, setShowAssignMeModal] = React.useState(false);
    const [showAssignModal, setShowAssignModal] = React.useState(false);

    const getInvoiceNumberDisplay = (item) => {
        var value = "";

        if(item.crmInvoiceNumber && item.crmInvoiceNumber.length > 0) {
            if(value.length > 0) {
                value += " | ";
            }
            
            value += item.crmInvoiceNumber;
        }

        if(item.taxInvoiceNumber && item.taxInvoiceNumber.length > 0) {
            if(value.length > 0) {
                value += " | ";
            }
            
            value += item.taxInvoiceNumber;
        }

        if(item.referenceNumber && item.referenceNumber.length > 0) {
            if(value.length > 0) {
                value += " | ";
            }
            
            value += item.referenceNumber;
        }

        return value;
    }

    const onCloseModalClick = () => {
        setSelectedId(0);
        setSelectedIndex(-1);
        setSelectedUserId(-1);
        setShowAssignMeModal(false);
        setShowAssignModal(false);
    }

    const assignMeTicket = (id, index) => {
        setSelectedId(id);
        setSelectedIndex(index);
        setSelectedUserId(user.id);
        setShowAssignMeModal(true);
    }

    const onAssignMeTicket = async () => {
        if(selectedId > 0 && selectedIndex >= 0 && selectedUserId > 0) {
            dispatch(isoDashboardActions.assignIsoRequest(selectedId, selectedUserId, true, props.list, selectedIndex));
        }

        onCloseModalClick();
    }

    const assignTicket = (id, index, userId) => {
        setSelectedId(id);
        setSelectedIndex(index);
        setSelectedUserId(userId);
        setShowAssignModal(true);
    }

    const onAssignTicket = async () => {
        console.log(selectedId, '', selectedIndex, '', selectedUserId, '', props.list, '');
        if(selectedId > 0 && selectedIndex >= 0 && selectedUserId > 0) {
            dispatch(isoDashboardActions.assignIsoRequest(selectedId, selectedUserId, false, props.list, selectedIndex));
        }

        onCloseModalClick();
    }

    return (
        <div className="tasks-list">
            <div className="d-flex mb-2">
                <div className="flex-grow-1">
                    <h6 className="fs-14 text-uppercase fw-semibold mb-0">{props.title} <small className={`badge ${props.titleClass} align-bottom ms-1 totaltask-badge`}>{props.list ? props.list.length : 0}</small></h6>
                </div>
            </div>
            <SimpleBar className="tasks-wrapper px-3 mx-n3" forceVisible={true} autoHide={false}>
                <div className="tasks">
                    {props.list && props.list.map(function(item, index){
                        return(
                            <div className={`card tasks-box ${item.ticketType === "CONTAINER" ? ["card-light", "border", "border-1", props.borderClass].join(" ") : ""}`} key={index}>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title">
                                            <NavLink to={`/iso-request-edit/${item.id}`} className="text-body d-block">{item.requestNumber}</NavLink>
                                        </h6>
                                    </div>
                                    {item.customerName &&
                                    <p className="mb-0 fs-11 flex-grow-1 text-truncate text-dark" style={{marginBottom:0}}>
                                        {item.customerName}
                                    </p>
                                    }
                                    <p className="fs-11 flex-grow-1 text-truncate text-muted">
                                        {getInvoiceNumberDisplay(item)}
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <div>
                                                {props.title === "New" && user && user.isIsoDocumentController && !user.isIsoManager && !item.documentUserName &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {/* {props.title === "Audit In Progress" && user && user.isIsoAuditor && !user.isIsoManager && !item.auditUserName &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                } */}
                                                {props.title === "Technical Review" && user && user.isIsoTechnicalReviewer && !user.isIsoManager && !item.technicalUserName &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "Final Decision" && user && user.isIsoDecisionMaker && !user.isIsoManager && !item.decisionUserName &&
                                                    <button type="button" className="btn btn-primary btn-icon rounded-pill ms-1" title="Assign Me"
                                                        onClick={() => assignMeTicket(item.id, index)}
                                                    >
                                                        <i className="ri-user-follow-line" />
                                                    </button>
                                                }
                                                {props.title === "New" && user && user.isIsoManager && !item.documentUserName &&
                                                    <select className="form-select-sm"
                                                        value={selectedUserId}
                                                        onChange={e => assignTicket(item.id, index, e.target.value)}
                                                    >
                                                        <option value={0}>...</option>
                                                        {props.documentUserList.map(function(item, index){
                                                            return (
                                                                <option key={index} value={item.id}>{item.fullName}</option>
                                                            );
                                                        })}
                                                    </select>
                                                }
                                                {props.title === "Audit In Progress" && user && user.isIsoManager && !item.auditUserName &&
                                                    <select className="form-select-sm"
                                                        value={selectedUserId}
                                                        onChange={e => assignTicket(item.id, index, e.target.value)}
                                                    >
                                                        <option value={0}>...</option>
                                                        {props.auditUserList.map(function(item, index){
                                                            return (
                                                                <option key={index} value={item.id}>{item.fullName}</option>
                                                            );
                                                        })}
                                                    </select>
                                                }
                                                {props.title === "Technical Review" && user && user.isIsoManager && !item.technicalUserName &&
                                                    <select className="form-select-sm"
                                                        value={selectedUserId}
                                                        onChange={e => assignTicket(item.id, index, e.target.value)}
                                                    >
                                                        <option value={0}>...</option>
                                                        {props.technicalUserList.map(function(item, index){
                                                            return (
                                                                <option key={index} value={item.id}>{item.fullName}</option>
                                                            );
                                                        })}
                                                    </select>
                                                }
                                                {props.title === "Final Decision" && user && user.isIsoManager && !item.decisionUserName &&
                                                    <select className="form-select-sm"
                                                        value={selectedUserId}
                                                        onChange={e => assignTicket(item.id, index, e.target.value)}
                                                    >
                                                        <option value={0}>...</option>
                                                        {props.decisionUserList.map(function(item, index){
                                                            return (
                                                                <option key={index} value={item.id}>{item.fullName}</option>
                                                            );
                                                        })}
                                                    </select>
                                                }
                                            </div>
                                            <div>
                                                <span className="badge bg-success">{item.documentUserName}</span>
                                            </div>
                                            <div>
                                                <span className="badge bg-success">{item.auditUserName}</span>
                                            </div>
                                            <div>
                                                <span className="badge bg-success">{item.technicalUserName}</span>
                                            </div>
                                            <div>
                                                <span className="badge bg-success">{item.decisionUserName}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top-dashed" style={{paddingTop:5, paddingBottom:5}}>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <ul className="link-inline mb-0" style={{paddingLeft:0}}>
                                                <li className="list-inline-item">
                                                    <i className="ri-user-add-line align-bottom"></i> {item.addedBy} | <MyDateRenderer value={item.dateAdded} format="mm/dd/yy" />
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <ul className="link-inline mb-0">
                                                <li className="list-inline-item">
                                                    <i className="ri-question-answer-line align-bottom"></i> {item.commentCount}
                                                </li>
                                                <li className="list-inline-item">
                                                    <i className="ri-attachment-2 align-bottom"></i> {item.documentCount}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="progress progress-sm bg-soft-success">
                                    <div className={`progress-bar ${props.titleClass}`} style={{width: "100%"}} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        );
                    })}

                    {props.list && props.list.length === 0 &&
                    <div className="">
                        <div className="">
                            <div className="d-flex mb-2">
                                <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title"><div className="text-body d-block">No {props.title} Tickets</div></h6>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </SimpleBar>

            <ConfirmModal message={"Are you sure you want to Assign the Ticket?"} show={showAssignMeModal} onCloseClick={onCloseModalClick} onYesClick={onAssignMeTicket} />
            <ConfirmModal message={"Are you sure you want to Assign the Ticket?"} show={showAssignModal} onCloseClick={onCloseModalClick} onYesClick={onAssignTicket} />
        </div>
    );
};

export default IsoDashboardList;