import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Label, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { api, createDeepClone } from "../utils/index";
import userAvatar from "../assets/images/users/multi-user.jpg";

import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as commentActions from "../actions/CommentActions";

const CommentGrid = (props) => {
    const dispatch = useDispatch();
    
    const loading = useSelector((state) => state.comment.loadingData);
    const data = useSelector((state) => state.comment.data);
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());

    const [fboState, setFBOState] = React.useState(data.fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [selectedFiles, setSelectedFiles] = React.useState([]);

    const [showPhotoModal, setShowPhotoModal] = React.useState(false);
    const [selectedPhoto, setSelectedPhoto] = React.useState(null);
    const [selectedPhotoInCache, setSelectedPhotoInCache] = React.useState(null);
   
    React.useEffect(() => {
		dispatch(commentActions.loadComment(props.refId, props.refName));
    }, [props.refId, props.refName]);

    React.useEffect(() => {
        setEditorState(EditorState.createEmpty());

		setFBOState(data.fbo);
        setIsDirty(false);
        setSelectedFiles([]);
        
        if(props.onCommentAdd) {
            props.onCommentAdd();
        }
    }, [data]);

    const onCommentChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            copy_fboState[name] = value;
            if(value.length > 0) {
                setIsDirty(true);
            }
            else {
                setIsDirty(false);
            }
        }
        else if(value === false) {
            copy_fboState[name] = false;
            setIsDirty(false);
        }
        else {
            copy_fboState[name] = null;
            setIsDirty(false);
        }
        
        setFBOState(copy_fboState);
    }

    const onEditorCommentChange = (editorState) => {
        setEditorState(editorState);

        var value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            copy_fboState["commentText"] = value;
            if(value.length > 0) {
                setIsDirty(true);
            }
            else {
                setIsDirty(false);
            }
        }
        else {
            copy_fboState["commentText"] = null;
            setIsDirty(false);
        }
        
        setFBOState(copy_fboState);
    }

    const onEditorPasteFiles = (files) => {
        const items = [].slice.call(files).filter(function (item) {
            return /^image\//.test(item.type);
        });

        if (items.length === 0) {
            return;
        }
        else {
            
            var arrBlob = [];

            items.forEach(element => {
                Object.assign(element, {
                    preview: URL.createObjectURL(element),
                    formattedSize: getFormatBytes(element.size),
                    description: ""
                });

                arrBlob.push(element);
            });

            setSelectedFiles([...selectedFiles, ...arrBlob]);
        }
    }

    const onSaveComment = () => {
        const formData = new FormData();

        selectedFiles.map(function(item, index){
            formData.append("file", item, item.name);
            formData.append("title", item.title ? item.title : item.name);
            formData.append("description", item.description ? item.description : "");
        });

        formData.append("comment", JSON.stringify(fboState));

        dispatch(commentActions.saveComment(formData));
    }

    const onCancelComment = () => {
        setEditorState(EditorState.createEmpty());

        onCommentChange("commentText", null);
        
        setIsDirty(false);
        setSelectedFiles([]);
    }

    const getFormatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const onPasteData = (event) => {
        const clipboardItems = event.clipboardData.items;
        
        const items = [].slice.call(clipboardItems).filter(function (item) {
            return /^image\//.test(item.type);
        });

        if (items.length === 0) {
            return;
        }
        else {
            
            var arrBlob = [];

            items.forEach(element => {
                const blob = element.getAsFile();

                Object.assign(blob, {
                    preview: URL.createObjectURL(blob),
                    formattedSize: getFormatBytes(blob.size),
                    description: ""
                });

                arrBlob.push(blob);
            });

            setSelectedFiles([...selectedFiles, ...arrBlob]);
        }
    }

    const onFileChange = (event) => {
        if(event.target.files.length > 0) {
            var files = [...event.target.files];
            var copy_selectedFiles = createDeepClone(selectedFiles);
            
            files.map(function(item, index) {
                if(item.type.match(/image-*/)) {
                    Object.assign(item, {
                        preview: URL.createObjectURL(item),
                        formattedSize: getFormatBytes(item.size),
                        description: ""
                    });

                    copy_selectedFiles.push(item);
                }
            });

            setSelectedFiles(copy_selectedFiles);
        }
    }

    const onDocumentDescriptionChange = (index, value) => {
        var copy_selectedFiles = createDeepClone(selectedFiles);
        if(value) {
            copy_selectedFiles[index].description = value;
        }
        else {
            copy_selectedFiles[index].description = "";
        }
        
        setSelectedFiles(copy_selectedFiles);
    }

    const onDeleteDocument = (index) => {
        setSelectedFiles(oldValues => { return oldValues.filter((_, i) => i !== index)});
    }

    const showPhotoModalDialog = (item) => {
        setSelectedPhoto(item);
        setSelectedPhotoInCache(null);
        setShowPhotoModal(true);
    }

    const showPhotoInCacheModalDialog = (item) => {
        setSelectedPhoto(null);
        setSelectedPhotoInCache(item);
        setShowPhotoModal(true);
    }

    const hidePhotoModalDialog = () => {
        setShowPhotoModal(false);
    }

    return (
        <Fragment>
            <Card>
                <CardHeader className="border-0 d-flex align-items-center">
                    <CardLoader loading={loading} />
                    <h5 className="card-title mb-0 flex-grow-1">Comments</h5>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    {data.list.length == 0 && <span>No comments found</span>}
                    {data.list.map(function(item, index){
                        return(
                            <Fragment key={index}>
                                <div className="d-flex" key={index}>
                                    <div className="flex-shrink-0">
                                        <div className="avatar-sm img-thumbnail rounded-circle">
                                            <div className={`avatar-title text-uppercase border rounded-circle ${item.initialColorClass}`}>{item.userNameInitialAdded}</div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="fs-13">{item.userNameAdded} <small className="text-muted ms-2"><MyDateRenderer value={item.dateAdded} /></small></h5>
                                        <div className="text-muted">{parse(item.commentText)}</div>
                                    </div>
                                </div>
                                <Row className="g-3 mb-4 ms-3">
                                    {item.documentList.map((file, index) => {
                                        return (
                                            <Col xs={12} key={index}>
                                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" style={{cursor: "pointer"}}
                                                    onClick={() => showPhotoModalDialog(file)}
                                                >
                                                    <div className="p-2 align-items-center">
                                                        <Row className="align-items-center">
                                                            <Col className="col-auto">
                                                                <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light"
                                                                    alt={file.title}
                                                                    src={`${api}Document/DownloadDocument?id=${file.id}`}
                                                                />
                                                            </Col>
                                                            <Col className="col">
                                                                <p className="mb-0">
                                                                    <Link to="#" className="text-muted font-weight-bold">{file.description || file.title}</Link>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Fragment>
                        );
                    })}
                    <div className="mt-4">
                        <Row className="g-3">
                            <Col xs={12} >
                                <Label className="form-label">Leave a Comment</Label>
                                {/* <textarea className="form-control" rows="3" placeholder="Enter your comment..." onPaste={(event) => onPasteData(event)}
                                    value={(fboState && fboState.commentText) || ""}
                                    onChange={e => onCommentChange("commentText", e.target.value)}
                                /> */}
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="richtext-wrapper"
                                    toolbarClassName="richtext-toolbar"
                                    editorClassName="richtext-editor"
                                    onEditorStateChange={onEditorCommentChange}
                                    handlePastedFiles={onEditorPasteFiles}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker']
                                    }}
                                />
                            </Col>
                            {selectedFiles.map((file, index) => {
                                return (
                                    <Col xs={12} key={index}>
                                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                            <div className="p-2 align-items-center">
                                                <Row className="align-items-center">
                                                    <Col className="col-1" style={{cursor: "pointer"}}
                                                        onClick={() => showPhotoInCacheModalDialog(file)}
                                                    >
                                                        <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light"
                                                            alt={file.name}
                                                            src={file.preview}
                                                        />
                                                    </Col>
                                                    <Col className="col-10">
                                                        <input className="form-control form-control-user"
                                                            placeholder="Enter Photo Description Here..."
                                                            value={file.description}
                                                            onChange={e => onDocumentDescriptionChange(index, e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col className="col-1">
                                                        <button type="button" className="btn btn-danger btn-icon rounded-pill float-end"
                                                            onClick={() => onDeleteDocument(index)}
                                                        >
                                                            <i className="ri-delete-bin-2-line"></i>
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    </Col>
                                );
                            })}
                            <Col xs={12}>
                                <label className="btn btn-primary btn-label mb-0">
                                    <i className="ri-attachment-line label-icon align-bottom"></i> Add Image(s)
                                    <input className="d-none" type="file" accept="image/*" onChange={onFileChange} multiple={true} />
                                </label>
                            </Col>
                            <Col xs={12} className="text-end">
                                <button type="button" className="btn btn-primary btn-label me-2" 
                                    disabled={loading || !isDirty}
                                    onClick={onSaveComment}>
                                        <i className="ri-chat-new-line label-icon align-bottom"></i> Post Comment
                                </button>
                                <button className="btn btn-danger btn-label"
                                    disabled={loading || (!isDirty && selectedFiles.length == 0)}
                                    onClick={onCancelComment}
                                >
                                    <i className="ri-close-line label-icon align-bottom"></i> Cancel
                                </button>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
            <Modal tabIndex="-1" size="xl" isOpen={showPhotoModal} toggle={hidePhotoModalDialog} centered={true}>
                <ModalHeader>
                    {selectedPhoto && <p>{selectedPhoto.description || selectedPhoto.title}</p>}
                    {selectedPhotoInCache && <p>{selectedPhotoInCache.name}</p>}
                </ModalHeader>
                <ModalBody>
                    {selectedPhoto && <img style={{maxWidth:"1000px"}} src={`${api}Document/DownloadDocument?id=${selectedPhoto.id}`} alt="Not Found" />}
                    {selectedPhotoInCache && <img style={{maxWidth:"1000px"}} src={selectedPhotoInCache.preview} alt={selectedPhotoInCache.name} />}
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={hidePhotoModalDialog}>Close</button>
                </ModalFooter>
            </Modal>
        </Fragment>   
    );
};

export default CommentGrid;