import React, { useEffect, useState, Fragment } from "react";
import { Col, Row } from 'reactstrap';
import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import Pagination from "react-bootstrap/Pagination";
import { createUltimatePagination, ITEM_TYPES } from "react-ultimate-pagination";

import MyNumberRenderer from "./MyNumberRenderer"

const Button = ({ value, isActive, disabled, onClick }) => (
    <button
      style={isActive ? { fontWeight: "bold" } : null}
      onClick={onClick}
      disabled={disabled}
    >
      {value}
    </button>
  );
  
const PaginationComponentInternal = createUltimatePagination({
    WrapperComponent: Pagination,
    itemTypeToComponent: {
        [ITEM_TYPES.PAGE]: ({value, isActive}) => (
            <Pagination.Item data-value={value} active={isActive}>{value}</Pagination.Item>
        ),
        [ITEM_TYPES.ELLIPSIS]: ({value, isActive, onClick}) => (
            <Pagination.Ellipsis data-value={value} onClick={onClick}/>
        ),
        [ITEM_TYPES.FIRST_PAGE_LINK]: ({isActive, onClick}) => (
            <Pagination.First data-value={1} disabled={isActive} onClick={onClick}/>
        ),
        [ITEM_TYPES.PREVIOUS_PAGE_LINK]: ({value, isActive, onClick}) => (
            <Pagination.Prev data-value={value} disabled={isActive} onClick={onClick}/>
        ),
        [ITEM_TYPES.NEXT_PAGE_LINK]: ({value, isActive, onClick}) => (
            <Pagination.Next data-value={value} disabled={isActive} onClick={onClick}/>
        ),
        [ITEM_TYPES.LAST_PAGE_LINK]: ({value, isActive, onClick}) => (
            <Pagination.Last data-value={value} disabled={isActive} onClick={onClick}/>
        ),
    }
});

const PaginationComponent = ({
    total = 0,
    itemsPerPage = SEARCH_PAGE_SIZE,
    currentPage = 1,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0) {
            setTotalPages(Math.ceil(total / itemsPerPage));
        }
        else {
            setTotalPages(0);
        }
    }, [total, itemsPerPage]);

    const onClickPage = (event) => {
        var pageNumber = -1;

        if(event.target.dataset && event.target.dataset.value){
            pageNumber = parseInt(event.target.dataset.value);
        }
        else if(event.target.parentNode && event.target.parentNode.dataset && event.target.parentNode.dataset.value) {
            pageNumber = parseInt(event.target.parentNode.dataset.value);
        }

        if(pageNumber > 0) {
            onPageChange(pageNumber);
        }
    }

    if (totalPages === 0) {
        return (
            <div className="mt-4">No results found</div>
        );
    }

    return (
        <Fragment>
            <Row className="d-flex justify-content-center mt-5">
                <Col lg={2} className="pt-2">Total Records: <MyNumberRenderer value={total} /></Col>
                <Col lg={8}>
                    <PaginationComponentInternal className="d-flex justify-content-center" 
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onClick={onClickPage}
                    />
                </Col>
                <Col lg={2}>&nbsp;</Col>
            </Row>
            
        </Fragment>
        
    );
};

export default PaginationComponent;