import { COMMENT_START_LOADING, COMMENT_END_LOADING, COMMENT_LOADED } from "../constants/CommentConstants";

import { getApiData, postApiJsonData, postApiFormData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadComment(refId, refName) {
    return async (dispatch) => {
        
        await dispatch({ type: COMMENT_START_LOADING });

        try {

            var data = await getApiData("Comment/GetCommentFBO", { refId, refName });
            
            if(data.success) {
                await dispatch({
                    type: COMMENT_LOADED,
                    payload: {
                        list: data.responseEntities[0].commentList
                        , fbo: data.responseEntities[0].comment
                    }
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: COMMENT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: COMMENT_END_LOADING });
        }
    };
}

export function saveComment(formData) {
    return async (dispatch) => {
        
        await dispatch({ type: COMMENT_START_LOADING });

        try {

            var data = await postApiFormData("Comment/SaveComment", null, formData);
            
            if(data.success) {
                await dispatch({
                    type: COMMENT_LOADED,
                    payload: {
                        list: data.responseEntities[0].commentList
                        , fbo: data.responseEntities[0].comment
                    }
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: COMMENT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: COMMENT_END_LOADING });
        }
    };
}
