import React, { Fragment } from "react";
import dateFormat from "dateformat";

const MyDateRenderer = (props) => {
    if(props.value) { 
        var dateValue = props.value;

        if(props.value.indexOf(".") !== -1) {
            dateValue = props.value.split(".")[0];
        }

        if(props.format) {
            return (
                <Fragment>{dateFormat(dateValue + "Z", props.format)}</Fragment>
            );
        }
        else {
            return (
                <Fragment>{dateFormat(dateValue + "Z", "mm/dd/yyyy HH:MM:ss")}</Fragment>
            );
        }
    }
    else {
        return (
            <Fragment>{props.value}</Fragment>
        );
    }
}

export default MyDateRenderer;