import React from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Card, Col, Container, Input, Label, Row , Button, Spinner} from 'reactstrap';
import AuthSlider from './authCarousel';

import { useDispatch, useSelector } from "react-redux";
import * as loginActions from "../actions/LoginActions";

const  PasswordForgotScreen = () => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.login.loading);
    
    const [email, setEmail] = React.useState("");
    
    const handleForgotPassword = async () => {
        const valid = await trigger();
        if(valid) {
            dispatch(loginActions.forgotPassword(email));
        }
    }

    return(
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4">
                                                <h5 className="text-primary mb-3">Forgot Password?</h5>

                                                <div className="alert alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                                                    Enter your email and instructions will be sent to you!
                                                </div>
                                                <div className="p-2">
                                                    <div className="mb-4">
                                                        <Label className="form-label">Email</Label>
                                                        <input type="text" placeholder="Enter Email" className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                            value={email}
                                                            {...register("email", { required: true })}
                                                            onChange={e => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                    
                                                    <div className="mt-4">
                                                        <Button color="primary" className="w-100" type="button" disabled={loading} onClick={handleForgotPassword}>
                                                            {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                            Send Reset Link
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">Wait, I remember my password... <Link to="/" className="fw-bold text-primary text-decoration-underline"> Click here </Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; {new Date().getFullYear()} Ticketing System. Crafted with <i className="mdi mdi-heart text-danger"></i> by TUV</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

            </div>
        </React.Fragment>
    );
}
  
export default PasswordForgotScreen;