import { ACTION_START_LOADING, ACTION_END_LOADING, ACTION_LOADED } from "../constants/ActionConstants";

const initialState = {
    loading: false,
    list: []
};

const ActionReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case ACTION_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case ACTION_LOADED:
            return {
                ...state,
                list: action.payload
            };
        default:
            return state;
    }
}

export default ActionReducer;