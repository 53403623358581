import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";

import { useDispatch, useSelector } from "react-redux";
import * as vendorActions from "../actions/VendorActions";

const VendorEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id, type } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.vendor.loadingFBO);
    const fbo = useSelector((state) => state.vendor.fbo);
    
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);

    React.useEffect(() => {
	    dispatch(vendorActions.loadVendor(id, type, navigate));
    }, [id, type]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        
        if(fbo){
            reset(fbo.vendor);
        }

        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onVendorChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.vendor[name] = value;
        }
        else if(value === false) {
            copy_fboState.vendor[name] = false;
        }
        else {
            copy_fboState.vendor[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onSaveVendor = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(vendorActions.saveVendor(fboState, navigate));
        }
    }

    const onCancelVendor = () => {
        setFBOState(fbo);
        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={fboState && fboState.vendor.id > 0 ? `Edit ${fboState && fboState.title}` : `Add ${fboState && fboState.title}`} />
                    <Row>
                        <Col lg={fboState && fboState.vendor.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">{fboState && fboState.title}</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveVendor}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save {fboState && fboState.title}
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelVendor}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Organization Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.organizationName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.vendor.organizationName) || ""}
                                                {...register("organizationName", { required: true })}
                                                onChange={e => onVendorChange("organizationName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Commercial Registration Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.vendor.commercialRegistrationNumber) || ""}
                                                onChange={e => onVendorChange("commercialRegistrationNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Contact Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.contactName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.vendor.contactName) || ""}
                                                {...register("contactName", { required: true })}
                                                onChange={e => onVendorChange("contactName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Email<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.vendor.email) || ""}
                                                {...register("email", { required: true })}
                                                onChange={e => onVendorChange("email", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Telephone Number<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.telephoneNumber ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.vendor.telephoneNumber) || ""}
                                                {...register("telephoneNumber", { required: true })}
                                                onChange={e => onVendorChange("telephoneNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Mobile Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.vendor.mobileNumber) || ""}
                                                onChange={e => onVendorChange("mobileNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Fax Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.vendor.faxNumber) || ""}
                                                onChange={e => onVendorChange("faxNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Active</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.vendor.active) || false}
                                                onChange={e => onVendorChange("active", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={8} md={8}>
                                            <Label className="form-label">Address</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.vendor.address) || ""}
                                                onChange={e => onVendorChange("address", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">City<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.city ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.vendor.city) || ""}
                                                {...register("city", { required: true })}
                                                onChange={e => onVendorChange("city", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">State</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.vendor.state) || ""}
                                                onChange={e => onVendorChange("state", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Country<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.country ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.vendor.country) || ""}
                                                {...register("country", { required: true })}
                                                onChange={e => onVendorChange("country", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Zip Code</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.vendor.zipCode) || ""}
                                                onChange={e => onVendorChange("zipCode", e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        
                        {fboState && fboState.vendor.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.vendor.id} name={"VENDOR"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default VendorEditScreen;