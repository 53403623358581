import { 
    CHANGE_LAYOUT,
    CHANGE_SIDEBAR_THEME,
    CHANGE_LAYOUT_MODE,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_LAYOUT_POSITION,
    CHANGE_TOPBAR_THEME,
    CHANGE_LEFT_SIDEBAR_SIZE_TYPE,
    CHANGE_LEFT_SIDEBAR_VIEW_TYPE,
    CHANGE_SIDEBAR_IMAGE_TYPE,
    CHANGE_SIDEBAR_VISIBILITY,
    CHANGE_PRELOADER,
    layoutTypes,
    leftSidebarTypes,
    layoutModeTypes,
    layoutWidthTypes,
    layoutPositionTypes,
    topbarThemeTypes,
    leftsidbarSizeTypes,
    leftSidebarViewTypes,
    leftSidebarImageTypes,
    preloaderTypes,
    sidebarVisibilitytypes 
} from "../constants/LayoutConstants";

const initialState = {
    layoutType: layoutTypes.VERTICAL,
    leftSidebarType: leftSidebarTypes.DARK,
    layoutModeType: layoutModeTypes.LIGHTMODE,
    layoutWidthType: layoutWidthTypes.FLUID,
    layoutPositionType: layoutPositionTypes.FIXED,
    topbarThemeType: topbarThemeTypes.LIGHT,
    leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
    leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
    leftSidebarImageType: leftSidebarImageTypes.NONE,
    sidebarVisibilitytype: sidebarVisibilitytypes.SHOW,
    preloader: preloaderTypes.DISABLE
};

const LayoutReducer = (state = initialState, action) => {
    switch(action.type) {
        case CHANGE_LAYOUT:
            return {
                ...state,
                layoutType: action.payload
            };
        case CHANGE_SIDEBAR_THEME:
            return {
                ...state,
                leftSidebarType: action.payload
            };
        case CHANGE_LAYOUT_MODE:
            return {
                ...state,
                layoutModeType: action.payload
            };
        case CHANGE_LAYOUT_WIDTH:
            return {
                ...state,
                layoutWidthType: action.payload
            };
        case CHANGE_LAYOUT_POSITION:
            return {
                ...state,
                layoutPositionType: action.payload
            };
        case CHANGE_TOPBAR_THEME:
            return {
                ...state,
                topbarThemeType: action.payload
            };
        case CHANGE_LEFT_SIDEBAR_SIZE_TYPE:
            return {
                ...state,
                leftsidbarSizeType: action.payload
            };
        case CHANGE_LEFT_SIDEBAR_VIEW_TYPE:
            return {
                ...state,
                leftSidebarViewType: action.payload
            };
        case CHANGE_SIDEBAR_IMAGE_TYPE:
            return {
                ...state,
                leftSidebarImageType: action.payload
            };
        case CHANGE_SIDEBAR_VISIBILITY:
            return {
                ...state,
                sidebarVisibilitytype: action.payload
            };
        case CHANGE_PRELOADER:
            return {
                ...state,
                preloader: action.payload
            };
        default:
            return state;
    }
}

export default LayoutReducer;