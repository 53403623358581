import { DASHBOARD_START_LOADING, DASHBOARD_END_LOADING, DASHBOARD_LOADED } from "../constants/DashboardConstants";
import { getApiData } from "../utils/index";

import * as commonActions from "./CommonActions";

export function loadDashboard() {
    return async (dispatch) => {
        
        await dispatch({ type: DASHBOARD_START_LOADING });

        try {
             var objParams = {};
            
            var data = await getApiData("UserProfile/GetDashboard", objParams);

            if(data.success) {
                 await dispatch({
                    type: DASHBOARD_LOADED,
                    payload: {
                        ticketCountList: data.responseEntities[0].ticketCounts
                        , openedClosedTicketCountList: data.responseEntities[0].openedClosedTicketCounts
                        , ticketTrendList: data.responseEntities[0].ticketTrends
                        , serviceList: data.responseEntities[0].services
                        , paymentCountList: data.responseEntities[0].paymentCounts
                    }
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: DASHBOARD_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: DASHBOARD_END_LOADING });
        }
    };
}