import { COMMENT_START_LOADING, COMMENT_END_LOADING, COMMENT_LOADED } from "../constants/CommentConstants";

const initialState = {
    loadingData: false,
    data: {
        list: [],
        fbo: null
    }
};

const CommentReducer = (state = initialState, action) => {
    switch(action.type) {
        case COMMENT_START_LOADING:
            return {
                ...state,
                loadingData: true
            };
        case COMMENT_END_LOADING:
            return {
                ...state,
                loadingData: false
            };
        case COMMENT_LOADED:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}

export default CommentReducer;