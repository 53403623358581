import React, { Fragment } from "react";

import { AsyncTypeahead, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ErrorModal from "./ErrorModal";

import { getApiData } from "../utils/index";

const CustomerLookup = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [list, setList] = React.useState([]);
    const [showCrmNameError, setShowCrmNameError] = React.useState(false);

    const CustomerRenderer = (results, menuProps, props) => (
        <Menu id="menuCustomerLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Code</div>
                    <div className="col">Name</div>
                    <div className="col">Details</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            {result.code || ''} - {result.crmCountry || ''}
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            {result.organizationName || ''}
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            {result.fullName || ''} {result.fullAddress || ''} 
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    const doSearch = async (search) => {
        setLoading(true);
        
        try {
            
            var crmName = props.crmName;
            var customerName = search;

            if(crmName) {
                var data = await getApiData("Invoice/GetCustomer", {crmName, customerName});
                
                if(data.success) {
                    setList(data.responseEntities);
                }
                else {
                    setList([]);
                    alert(data.errorDesc);
                }
            }
            else {
                setShowCrmNameError(true);
                setList([]);
            }
            
			setLoading(false);
        } 
        catch (error) {
            alert(error);
            setLoading(false);
        }
    };

    const filterBy = () => true;

    return (
        <Fragment>
            <AsyncTypeahead
                {...props}
                filterBy={filterBy}
                isLoading={loading}
                minLength={1}
                onSearch={doSearch}
                options={list}
                clearButton
                allowNew ={false}
                placeholder= "Select Customer..."
                labelKey= {option => `${option.code} - ${option.crmCountry}`}
                renderMenu = {CustomerRenderer}
            />
            <ErrorModal message={"Please select the CRM Name."} show={showCrmNameError} onCloseClick={() => setShowCrmNameError(false)} />
        </Fragment>
    );
};

export default CustomerLookup;