import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Table, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import MyDateRenderer from "../components/MyDateRenderer";
import ActionList from "../components/ActionList";
import Lookup from "../components/Lookup";
import ConfirmModal from "../components/ConfirmModal";
import { Highlighter, Menu, MenuItem, } from 'react-bootstrap-typeahead';

import { useDispatch, useSelector } from "react-redux";
import * as groupActions from "../actions/GroupActions";

const GroupEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.group.loadingFBO);
    const fbo = useSelector((state) => state.group.fbo);

    const [deleteId, setDeleteId] = React.useState(0);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showActiveModal, setShowActiveModal] = React.useState(false);
    const [showInActiveModal, setShowInActiveModal] = React.useState(false);
        
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState([]);

    React.useEffect(() => {
	    dispatch(groupActions.loadGroup(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        
        if(fbo) {
            reset(fbo.group);
        }

        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onGroupChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.group[name] = value;
        }
        else if(value === false) {
            copy_fboState.group[name] = false;
        }
        else {
            copy_fboState.group[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onSaveGroup = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(groupActions.saveGroup(fboState, navigate));
        }
    }

    const onCancelGroup = () => {
        setFBOState(fbo);
        setIsDirty(false);
        clearErrors();
    }

    const UserRenderer = (results, menuProps, props) => (
        <Menu id="menuUserLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Name</div>
                    <div className="col">Email</div>
                    <div className="col">Employee ID</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.fullName || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.email || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.employeeId || ''}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    const onUserChange = (item) => {
        setSelectedUser(item);
    }

    const onAddGroupUser = async() => {
        if(selectedUser && selectedUser.length > 0) {
            dispatch(groupActions.addGroupUser(fboState, selectedUser[0].id, navigate));
            setSelectedUser([]);
        }
    }

    const onDeleteGroupUserClick = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    }

    const onDeleteGroupUserConfirm = () => {
        if(deleteId > 0) {
            dispatch(groupActions.deleteGroupUser(fboState, deleteId, navigate));
        }
        
        setShowDeleteModal(false);
    }

    const onActiveGroupUserClick = (id) => {
        setDeleteId(id);
        setShowActiveModal(true);
    }

    const onActiveGroupUserConfirm = () => {
        if(deleteId > 0) {
            dispatch(groupActions.changeGroupUser(fboState, deleteId, true, navigate));
        }
        
        setShowActiveModal(false);
    }

    const onInActiveGroupUserClick = (id) => {
        setDeleteId(id);
        setShowInActiveModal(true);
    }

    const onInActiveGroupUserConfirm = () => {
        if(deleteId > 0) {
            dispatch(groupActions.changeGroupUser(fboState, deleteId, false, navigate));
        }
        
        setShowInActiveModal(false);
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={fboState && fboState.group.id > 0 ? "Edit Group" : "Add Group"} />
                    <Row>
                        <Col lg={fboState && fboState.group.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    
                                    <h5 className="card-title mb-0 flex-grow-1">Group</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveGroup}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save Group
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelGroup}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Group Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.group.name) || ""}
                                                {...register("name", { required: true })}
                                                onChange={e => onGroupChange("name", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Country<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.country ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.group.country) || ""}
                                                {...register("country", { required: true })}
                                                onChange={e => onGroupChange("country", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Description<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.description ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.group.description) || ""}
                                                {...register("description", { required: true })}
                                                onChange={e => onGroupChange("description", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Active</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.group.active) || false}
                                                onChange={e => onGroupChange("active", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Group Manager<span className="required">*</span></Label>
                                            <select className={`form-select ${errors.managerId ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.group.managerId) || ""}
                                                {...register("managerId", { required: true })}
                                                onChange={e => onGroupChange("managerId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.managerList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Group Leader<span className="required">*</span></Label>
                                            <select className={`form-select ${errors.leaderId ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.group.leaderId) || ""}
                                                {...register("leaderId", { required: true })}
                                                onChange={e => onGroupChange("leaderId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.leaderList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">PD Department <i className="ri-information-fill text-primary align-middle" style={{fontSize:14}} title="if checked then this will be PD Department Users Group, otherwise this will be Technical Users Group"></i></Label>
                                            <input type="checkbox" className="form-check" disabled={fboState && fboState.group.id > 0}
                                                checked={(fboState && fboState.group.isPd) || false}
                                                onChange={e => onGroupChange("isPd", e.target.checked)}
                                            />
                                            
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {fboState && fboState.group.id > 0 &&
                                <Card>
                                    <CardHeader className="border-0 d-flex align-items-center">
                                        <h5 className="card-title mb-0 flex-grow-1">Group Users</h5>
                                    </CardHeader>
                                    <CardBody className="border border-dashed border-end-0 border-start-0">
                                        <Row className="gy-2">
                                            <Col xxl={6} md={12}>
                                                <Lookup
                                                    clearButton 
                                                    allowNew ={false}
                                                    id ="fldUserLookup" 
                                                    url = {`GetGroupUserLookup?isPd=${fboState.group.isPd}`}
                                                    placeholder= "Select User..."
                                                    labelKey= {option => `${option.fullName}`}
                                                    selected = {selectedUser}
                                                    onChange = {(item) => onUserChange(item)}
                                                    renderMenu = {UserRenderer}
                                                />
                                            </Col>
                                            <Col xxl={2} md={12}>
                                                <button type="button" className="btn btn-primary btn-label w-100" 
                                                    disabled={loading || selectedUser.length == 0}
                                                    onClick={onAddGroupUser}
                                                >
                                                    <i className="ri-add-line label-icon align-middle"></i> Add User
                                                </button>
                                            </Col>
                                            <Col xxl={12} md={12}>
                                                <Table className="table-hover table-bordered align-middle mb-0">
                                                    <thead className="table-primary">
                                                        <tr>
                                                            <th>User Name</th>
                                                            <th>Email</th>
                                                            <th>Date Added</th>
                                                            <th>Added  By</th>
                                                            <th>Active</th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fboState && fboState.groupUsers.filter(x => x.userType === "Technical User").length == 0 && <tr><td colSpan={6}>No Group User(s) Added</td></tr>}
                                                        {fboState && fboState.groupUsers.filter(x => x.userType === "Technical User").map(function(item, index){
                                                            return(
                                                                <tr key={index}>
                                                                    <td>{item.fullName}</td>
                                                                    <td>{item.email}</td>
                                                                    <td><MyDateRenderer value={item.dateAdded} /></td>
                                                                    <td>{item.addedBy}</td>
                                                                    <td className="text-center">{item.active ? "Yes" : "No"}</td>
                                                                    <td className="text-center">
                                                                        <button type="button" className="btn btn-danger btn-sm me-1 mt-1" title="Remove Group User"
                                                                            onClick={() => onDeleteGroupUserClick(item.id)}
                                                                        >
                                                                            <i className="ri-delete-bin-2-line" />
                                                                        </button>
                                                                        {item.active &&
                                                                        <button type="button" className="btn btn-danger btn-sm me-1 mt-1 w-50" title="Remove Group User"
                                                                            onClick={() => onInActiveGroupUserClick(item.id)}
                                                                        >
                                                                            Set Inactive
                                                                        </button>
                                                                        }
                                                                        {!item.active &&
                                                                        <button type="button" className="btn btn-success btn-sm me-1 mt-1 w-50" title="Remove Group User"
                                                                            onClick={() => onActiveGroupUserClick(item.id)}
                                                                        >
                                                                            Set Active
                                                                        </button>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            }
                        </Col>

                        {fboState && fboState.group.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.group.id} name={"GROUP"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
                <ConfirmModal message={"Are you sure you want to delete the Group User?"} show={showDeleteModal} onCloseClick={() => setShowDeleteModal(false)} onYesClick={onDeleteGroupUserConfirm} />
                <ConfirmModal message={"Are you sure you want to Set \"Active\" the Group User?"} show={showActiveModal} onCloseClick={() => setShowActiveModal(false)} onYesClick={onActiveGroupUserConfirm} />
                <ConfirmModal message={"Are you sure you want to Set \"In Active\" the Group User?"} show={showInActiveModal} onCloseClick={() => setShowInActiveModal(false)} onYesClick={onInActiveGroupUserConfirm} />
            </div>
        </Fragment>
    );
}

export default GroupEditScreen;