import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Table, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import Flatpickr from "react-flatpickr";

import { api } from "../utils/index";
import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

const FormEvaluationSFDA = (props) => {
    const [evaluationFormCollapsed, setEvaluationFormCollapsed] = React.useState(true);
    
    return(
        <Card className="card-warning mb-3">
            <CardHeader className="border-0 d-flex align-items-center">
                <CardLoader loading={props.loading} />
                <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setEvaluationFormCollapsed(!evaluationFormCollapsed)}>
                    {evaluationFormCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                    {!evaluationFormCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                    Evaluation Form ({evaluationFormCollapsed ? "Expand" : "Collapse"})
                </h5>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <Collapse isOpen={!evaluationFormCollapsed} className="collapse">
                    <Row className="gy-2" style={{backgroundColor:"white"}}>
                        <Col xxl={12} md={12}>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header-red">
                                        <td colSpan={5}>PHASE 1 - Pre-Evaluation of Testing Results</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                    </tr>
                                    <tr>
                                        <td>Product(s) Registered in SFDA</td>
                                        <td colSpan={2}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ProductRegistered") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ProductRegistered", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ProductRegistered", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td colSpan={2}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ProductRegistered") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ProductRegistered", "No")} onChange={e => props.setFieldValue("evaluationForm", "ProductRegistered", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Related CAI / AIR</td>
                                        <td colSpan={4}>
                                            Please reach related CAI / AIR over below link<br />
                                            <a target="_blank" rel="noreferrer" href="https://standards.tuvatsa.com/technicalqm">Library System (tuvatsa.com)</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input type="radio" checked={props.fboState && props.fboState.ticket.certificationType === "S-CoC"} readOnly={true} /> Shipment Certificate of Conformity (S-CoC), Please fill <b>Table 1</b>
                                        </td>
                                        <td colSpan={3}>
                                            <input type="radio" checked={props.fboState && props.fboState.ticket.certificationType === "P-CoC"} readOnly={true} /> Product Certificate of Conformity (P-CoC), Please Fill <b>Table 2</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2Table1 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={20}>Table 1 Shipment Certificate of Conformity (S-CoC)</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}>Invoice / Proforma Invoice Number<sup>1</sup></td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "InvoiceNumber") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "InvoiceNumber") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "InvoiceNumber", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            Invoice Date
                                        </td>
                                        <td colSpan={5}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "InvoiceDate") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "InvoiceDate") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "InvoiceDate", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "InvoiceDate") || ""}
                                                />
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}>Sampling Done by Inspector</td>
                                        <td colSpan={15}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "Inspector") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "Inspector") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "Inspector", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="header">
                                        <td colSpan={20}>Test Report Evaluation (Please fill the table for each test report.)</td>
                                    </tr>
                                    <tr className="header">
                                        <td>#</td>
                                        <td colSpan={8}>Verification of Test Report<sup>2</sup></td>
                                        <td>Yes</td>
                                        <td>No</td>
                                        <td>N/A</td>
                                        <td colSpan={8}>Remarks</td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td colSpan={8}>Are the standards and edition in the test report which are used correct?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "StandardsCorrect") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "StandardsCorrect", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "StandardsCorrect", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "StandardsCorrect") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "StandardsCorrect", "No")} onChange={e => props.setFieldValue("evaluationForm", "StandardsCorrect", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "StandardsCorrect") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "StandardsCorrect", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "StandardsCorrect", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td colSpan={8}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "StandardsCorrectRemarks") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "StandardsCorrectRemarks") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "StandardsCorrectRemarks", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={8}>Are each Test Report forms in line with the requirements / standards?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportForms") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportForms", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestReportForms", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportForms") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportForms", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestReportForms", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportForms") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportForms", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "TestReportForms", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td colSpan={8}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportFormsRemarks") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportFormsRemarks") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportFormsRemarks", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={8}>Is each test report signed by Approval personnel?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportSigned") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportSigned", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestReportSigned", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportSigned") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportSigned", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestReportSigned", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportSigned") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportSigned", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "TestReportSigned", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td colSpan={8}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportSignedRemarks") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportSignedRemarks") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportSignedRemarks", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={8}>Is the test report holding a valid Accreditation Logo?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportAccreditationLogo") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportAccreditationLogo", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "TestReportAccreditationLogo", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportAccreditationLogo") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportAccreditationLogo", "No")} onChange={e => props.setFieldValue("evaluationForm", "TestReportAccreditationLogo", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "TestReportAccreditationLogo") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "TestReportAccreditationLogo", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "TestReportAccreditationLogo", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td colSpan={8}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportAccreditationLogoRemarks") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportAccreditationLogoRemarks") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportAccreditationLogoRemarks", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={8}>Plausibility of test requirements and verdicts? Acceptable according to TR requirements and test parameters?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PlausibilityAcceptable") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PlausibilityAcceptable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "PlausibilityAcceptable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PlausibilityAcceptable") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PlausibilityAcceptable", "No")} onChange={e => props.setFieldValue("evaluationForm", "PlausibilityAcceptable", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "PlausibilityAcceptable") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "PlausibilityAcceptable", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "PlausibilityAcceptable", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td colSpan={8}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "PlausibilityAcceptableRemarks") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "PlausibilityAcceptableRemarks") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "PlausibilityAcceptableRemarks", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={8}>The listed product in the test report is matched with requested product such as name or photos.</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ProductMatch") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ProductMatch", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ProductMatch", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ProductMatch") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ProductMatch", "No")} onChange={e => props.setFieldValue("evaluationForm", "ProductMatch", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ProductMatch") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ProductMatch", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "ProductMatch", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td colSpan={8}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "ProductMatchRemarks") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "ProductMatchRemarks") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "ProductMatchRemarks", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={8}>Are the content limits specified in the test report appropriate?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ContentLimit") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ContentLimit", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ContentLimit", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ContentLimit") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ContentLimit", "No")} onChange={e => props.setFieldValue("evaluationForm", "ContentLimit", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ContentLimit") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ContentLimit", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "ContentLimit", "N/A", e.target.checked)} /> N/A
                                        </td>
                                        <td colSpan={8}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "ContentLimitRemarks") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "ContentLimitRemarks") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "ContentLimitRemarks", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="header">
                                        <td>#</td>
                                        <td colSpan={4}>Test Report Number</td>
                                        <td colSpan={5}>Lab Name</td>
                                        <td colSpan={3}>Issuing Date</td>
                                        <td colSpan={5}>Related CAI</td>
                                        <td colSpan={2}>Accredited<sup>3</sup></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber1") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber1") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber1", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName1") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName1") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName1", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate1") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate1") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate1", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate1") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI1") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI1") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI1", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited1") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited1", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited1", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited1") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited1", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited1", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber2") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber2") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber2", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName2") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName2") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName2", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate2") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate2") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate2", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate2") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI2") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI2") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI2", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited2") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited2", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited2", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited2") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited2", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited2", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber3") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber3") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber3", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName3") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName3") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName3", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate3") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate3") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate3", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate3") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI3") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI3") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI3", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited3") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited3", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited3", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited3") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited3", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited3", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber4") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber4") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber4", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName4") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName4") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName4", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate4") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate4") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate4", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate4") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI4") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI4") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI4", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited4") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited4", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited4", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited4") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited4", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited4", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber5") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber5") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber5", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName5") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName5") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName5", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate5") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate5") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate5", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate5") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI5") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI5") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI5", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited5") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited5", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited5", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited5") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited5", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited5", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber6") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber6") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber6", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName6") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName6") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName6", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate6") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate6") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate6", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate6") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI6") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI6") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI6", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited6") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited6", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited6", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited6") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited6", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited6", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber7") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber7") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber7", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName7") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName7") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName7", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate7") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate7") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate7", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate7") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI7") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI7") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI7", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited7") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited7", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited7", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited7") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited7", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited7", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber8") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber8") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber8", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName8") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName8") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName8", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate8") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate8") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate8", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate8") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI8") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI8") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI8", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited8") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited8", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited8", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited8") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited8", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited8", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber9") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber9") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber9", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName9") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName9") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName9", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate9") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate9") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate9", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate9") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI9") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI9") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI9", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited9") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited9", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited9", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited9") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited9", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited9", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td colSpan={4}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "TestReportNumber10") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "TestReportNumber10") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "TestReportNumber10", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "LabName10") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "LabName10") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "LabName10", e.target.value)}
                                            />
                                        </td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.canEditForm2Table1 &&
                                                <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "IssuingDate10") ? "" : ""}`}
                                                    value={props.getFieldValueText("evaluationForm", "IssuingDate10") || ""}
                                                    onChange={([date]) => props.setFieldValueText("evaluationForm", "IssuingDate10", date)}
                                                    options={{
                                                        enableTime: false,
                                                        dateFormat: "m/d/Y"
                                                    }}
                                                />
                                            }
                                            {props.fboState && !props.fboState.canEditForm2Table1 &&
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                    value={props.getFieldValueDate("evaluationForm", "IssuingDate10") || ""}
                                                />
                                            }
                                        </td>
                                        <td colSpan={5}>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "RelatedCAI10") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table1)}
                                                value={props.getFieldValueText("evaluationForm", "RelatedCAI10") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "RelatedCAI10", e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited10") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited10", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "Accredited10", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "Accredited10") ? "" : ""}`} checked={props.getFieldValue("evaluationForm", "Accredited10", "No")} onChange={e => props.setFieldValue("evaluationForm", "Accredited10", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={20}>
                                            Also, you can find the template from below link;<br />
                                            <a target="_blank" rel="noreferrer" href="https://docs.google.com/spreadsheets/d/1EW3xIzGY41P69ftpJJSTNVjg8jtt5a99qsCKyP0Q_VU/edit?usp=sharing">https://docs.google.com/spreadsheets/d/1EW3xIzGY41P69ftpJJSTNVjg8jtt5a99qsCKyP0Q_VU/edit?usp=sharing</a>
                                        </td>
                                    </tr>
                                    <tr className="header">
                                        <td colSpan={20}>Result of Pre-Evaluation</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={8}>Can the inspection be carried out on the basis of positive test results?</td>
                                        <td colSpan={4}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "InspectionApplicable") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "InspectionApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "InspectionApplicable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td colSpan={4}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "InspectionApplicable") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "InspectionApplicable", "No")} onChange={e => props.setFieldValue("evaluationForm", "InspectionApplicable", "No", e.target.checked)} /> No
                                        </td>
                                        <td colSpan={4}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "InspectionApplicable") || (props.fboState && props.fboState.ticket.certificationType === "P-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "InspectionApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationForm", "InspectionApplicable", "N/A", e.target.checked)} /> N/A<sup>4</sup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={8}>Name</td>
                                        <td colSpan={6}>Signature</td>
                                        <td colSpan={6}>Date</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={8}>{(props.fboState && props.fboState.evaluationForm && props.fboState.ticket.certificationType === "S-CoC" && props.fboState.evaluationForm.lastUpdateByName) || ""}&nbsp;</td>
                                        <td colSpan={6}>
                                            {props.fboState && props.fboState.evaluationForm && props.fboState.ticket.certificationType === "S-CoC" && props.fboState.evaluationForm.lastUpdateBySignature &&
                                                <div>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "100px", cursor: "pointer"}} src={`${api}Document/DownloadDocumentByName?fileName=${props.fboState.evaluationForm.lastUpdateBySignature}`} alt="Not Found" />
                                                </div>
                                            }&nbsp;
                                        </td>
                                        <td colSpan={6}>
                                            <MyDateRenderer value={(props.fboState && props.fboState.evaluationForm && props.fboState.ticket.certificationType === "S-CoC" && props.fboState.evaluationForm.ticketForm && props.fboState.evaluationForm.ticketForm.dateLastEdit) || ""} format={"dd.mm.yyyy HH:MM:ss"} />&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm2Table2 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={6}>Table 2 Product Certificate of Conformity (P-CoC)</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"30%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                        <td style={{width:"40%", border:"none", padding: "0"}}></td>
                                    </tr>
                                    <tr className="header">
                                        <td>#</td>
                                        <td colSpan={2}>ISO Certification Situation</td>
                                        <td>Yes</td>
                                        <td>No</td>
                                        <td>Remarks</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>1</td>
                                        <td rowSpan={2} colSpan={2}>Does the client have valid ISO Certificates? (e.g.  ISO 22000, HACCAP, ISO 22716, GMP…. etc.)</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ValidISOCertification") || (props.fboState && props.fboState.ticket.certificationType === "S-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ValidISOCertification", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "ValidISOCertification", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td>&nbsp;</td>
                                        <td>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "ValidISOCertificationRemarks1") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table2)}
                                                placeholder="Please write Certificate No and validitation date..."
                                                value={props.getFieldValueText("evaluationForm", "ValidISOCertificationRemarks1") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "ValidISOCertificationRemarks1", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "ValidISOCertification") || (props.fboState && props.fboState.ticket.certificationType === "S-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "ValidISOCertification", "No")} onChange={e => props.setFieldValue("evaluationForm", "ValidISOCertification", "No", e.target.checked)} /> No
                                        </td>
                                        <td>
                                            <input type="text" className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationForm", "ValidISOCertificationRemarks2") ? "" : ""}`} readOnly={!(props.fboState && props.fboState.canEditForm2Table2)}
                                                placeholder="Please fill FM-GMAP-PC-AI-004h for Factory Audit"
                                                value={props.getFieldValueText("evaluationForm", "ValidISOCertificationRemarks2") || ""}
                                                onChange={e => props.setFieldValueText("evaluationForm", "ValidISOCertificationRemarks2", e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="header">
                                        <td colSpan={6}>Result of Pre-Evaluation</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>Can the audit result acceptable?</td>
                                        <td>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "AuditResultAcceptable") || (props.fboState && props.fboState.ticket.certificationType === "S-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "AuditResultAcceptable", "Yes")} onChange={e => props.setFieldValue("evaluationForm", "AuditResultAcceptable", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td colSpan={2}>
                                            <input type="radio" className={`${props.isValidFieldValue("evaluationForm", "AuditResultAcceptable") || (props.fboState && props.fboState.ticket.certificationType === "S-CoC") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationForm", "AuditResultAcceptable", "No")} onChange={e => props.setFieldValue("evaluationForm", "AuditResultAcceptable", "No", e.target.checked)} /> No
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Name</td>
                                        <td colSpan={3}>Signature</td>
                                        <td>Date</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>{(props.fboState && props.fboState.evaluationForm && props.fboState.ticket.certificationType === "P-CoC" && props.fboState.evaluationForm.lastUpdateByName) || ""}&nbsp;</td>
                                        <td colSpan={3}>
                                            {props.fboState && props.fboState.evaluationForm && props.fboState.ticket.certificationType === "P-CoC" && props.fboState.evaluationForm.lastUpdateBySignature &&
                                                <div>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "100px", cursor: "pointer"}} src={`${api}Document/DownloadDocumentByName?fileName=${props.fboState.evaluationForm.lastUpdateBySignature}`} alt="Not Found" />
                                                </div>
                                            }&nbsp;
                                        </td>
                                        <td>
                                            <MyDateRenderer value={(props.fboState && props.fboState.evaluationForm && props.fboState.ticket.certificationType === "P-CoC" && props.fboState.evaluationForm.ticketForm && props.fboState.evaluationForm.ticketForm.dateLastEdit) || ""} format={"dd.mm.yyyy HH:MM:ss"} />&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-form table-form-alt">
                                <tbody>
                                    <tr>
                                        <td style={{width:"30%", paddingTop:10}}>------------------------------------------</td>
                                        <td style={{width:"30%"}}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>1</sup> If it is a P-CoC, invoice details will be blank.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>2</sup> This section will be applicable for all test reports provided by the lab accordingly.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>3</sup> Please provide a copy or a proof of the Lab Accreditation status. Please be ensure that the accreditation is still valid and scope of accreditation is suitable with the SFDA Requirements. From a Third-Party laboratory having SAC or ILAC traceable EN ISO/IEC 17025 accreditation, scope/standards as applicable.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>4</sup> If it is a P-CoC, inspection for shipment is not applicable.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={`${props.fboState && props.fboState.showForm2Phase2 ? "" : "d-none"}`}>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header-red">
                                            <td colSpan={5}>PHASE 2 - Technical Evaluation</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"20%", border:"none", padding: "0"}}></td>
                                        </tr>
                                        <tr>
                                            <td>Product(s) Registered in SFDA</td>
                                            <td colSpan={2}>
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ProductRegistered") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ProductRegistered", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ProductRegistered", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td colSpan={2}>
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ProductRegistered") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ProductRegistered", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ProductRegistered", "No", e.target.checked)} /> No
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Related CAI</td>
                                            <td colSpan={4}>
                                                Please reach related CAI over below link<br />
                                                <a target="_blank" rel="noreferrer" href="https://standards.tuvatsa.com/technicalqm">Library System (tuvatsa.com)</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td colSpan={2}>Record Check<sup>5</sup></td>
                                            <td colSpan={3}>Technical Reviewer</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="index-col">2.0</td>
                                            <td>
                                                Please verify the records regarding to below link<br />
                                                <a target="_blank" href="https://docs.google.com/document/d/1BLl1QhbtFI1Ynkk1lU6gG1jsbbWM2pe_72m-Uo-dLVM/edit?usp=sharing">https://docs.google.com/document/d/1BLl1QhbtFI1Ynkk1lU6gG1jsbbWM2pe_72m-Uo-dLVM/edit?usp=sharing</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.1</td>
                                            <td>Test Report</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "TestReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "TestReport", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "TestReport", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "TestReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "TestReport", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "TestReport", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.2</td>
                                            <td>Factory Inspection Report</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "FactoryInspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "FactoryInspectionReport", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "FactoryInspectionReport", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "FactoryInspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "FactoryInspectionReport", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "FactoryInspectionReport", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "FactoryInspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "FactoryInspectionReport", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "FactoryInspectionReport", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.3</td>
                                            <td>HALAL Certificate</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificate", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificate", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificate", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificate", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificate", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.4</td>
                                            <td>Halal Slaughtering Certificate</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HalalSlaughteringCertificate", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.5</td>
                                            <td>Phythosanitary Certificate</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificate", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.6</td>
                                            <td>Health Certificate</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificate", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificate", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificate", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificate", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificate", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.7</td>
                                            <td>Organic Certificate</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificate", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificate", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificate", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificate", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificate", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.8</td>
                                            <td>MSDS</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDS") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDS", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDS", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDS") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDS", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDS", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDS") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDS", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDS", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.9</td>
                                            <td>Visible and clear Photos from all dimensions</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ClearPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ClearPhotos", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ClearPhotos", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ClearPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ClearPhotos", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ClearPhotos", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ClearPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ClearPhotos", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ClearPhotos", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.11</td>
                                            <td>PIF</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIF") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIF", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIF", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIF") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIF", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIF", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIF") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIF", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIF", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.12</td>
                                            <td>ISO Certification (ISO 22000/ HACCP) or (ISO 22716/ GMP)</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ISOCertification", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ISOCertification", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ISOCertification", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ISOCertification", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ISOCertification", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ISOCertification", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.13</td>
                                            <td>Other Qualification documents</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherQualificationDocuments") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherQualificationDocuments", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OtherQualificationDocuments", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherQualificationDocuments") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherQualificationDocuments", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OtherQualificationDocuments", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherQualificationDocuments") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherQualificationDocuments", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "therQualificationDocuments", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.14</td>
                                            <td>Undertaking Letter for Inspection</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "UndertakingLetter") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "UndertakingLetter", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "UndertakingLetter", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "UndertakingLetter") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "UndertakingLetter", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "UndertakingLetter", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "UndertakingLetter") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "UndertakingLetter", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "UndertakingLetter", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.15</td>
                                            <td>P-CoC Certificate</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PcocCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PcocCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PcocCertificate", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PcocCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PcocCertificate", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PcocCertificate", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PcocCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PcocCertificate", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PcocCertificate", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.16</td>
                                            <td>Invoice Details</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InvoiceDetails") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InvoiceDetails", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InvoiceDetails", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InvoiceDetails") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InvoiceDetails", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InvoiceDetails", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InvoiceDetails") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InvoiceDetails", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InvoiceDetails", "N/A", e.target.checked)} /> N/A
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr>
                                            <td colSpan={8} style={{textAlign:"center", border: "none"}}><h5><b>Evaluation Plan & Evaluation</b></h5></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{width:"14%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"14%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                            <td style={{width:"12%", border:"none", padding: "0"}}></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <b>Project Number from COC System / FASEH:</b>
                                            </td>
                                            <td colSpan={2}>
                                                {(props.fboState && props.fboState.ticket && props.fboState.ticket.requestNumber) || ""}&nbsp;
                                            </td>
                                            <td colSpan={1}>
                                                <b>Certificate Number</b>
                                            </td>
                                            <td colSpan={3}>
                                                <div>
                                                    <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CertificateNumberType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CertificateNumberType", "New")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CertificateNumberType", "New", e.target.checked)} /> New
                                                </div>
                                                <div>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 me-2 pt-1">
                                                            <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CertificateNumberType") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CertificateNumberType", "Existing")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CertificateNumberType", "Existing", e.target.checked)} /> Existing Certificate No<sup>6</sup>:
                                                        </div>
                                                        <div className="flex-grow-1 w-100">
                                                            <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                                value={props.getFieldValueText("evaluationFormPhase2", "ExistingCertificateNumber")}
                                                                onChange={e => props.setFieldValueText("evaluationFormPhase2", "ExistingCertificateNumber", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>                                            
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="header">
                                            <td colSpan={8}>Product information for P-CoC Product Certification</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Product Name</td>
                                            <td colSpan={6}>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "ProductName")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "ProductName", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Product HS CODE</td>
                                            <td colSpan={6}>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "ProductHsCode")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "ProductHsCode", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Product Description</td>
                                            <td colSpan={6}>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "ProductDescription")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "ProductDescription", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Applicable SFDA Technical Regulation(s)</td>
                                            <td colSpan={6}>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "ProductRegulation")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "ProductRegulation", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td colSpan={6}>Invoice Details / Product information for S-CoC Shipment Certification</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{width:"30%"}}>Invoice Number</td>
                                            <td  style={{width:"20%"}} colSpan={2}>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "InvoiceNumber")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "InvoiceNumber", e.target.value)}
                                                />
                                            </td>
                                            <td style={{width:"20%"}}>Invoice Date</td>
                                            <td style={{width:"30%"}} colSpan={2}>
                                                {props.fboState && props.fboState.canEditForm2Phase2 &&
                                                    <Flatpickr className={`form-control form-control-sm w-100 ${props.isValidFieldValue("evaluationFormPhase2", "InvoiceDate") ? "" : ""}`}
                                                        value={props.getFieldValueText("evaluationFormPhase2", "InvoiceDate") || ""}
                                                        onChange={([date]) => props.setFieldValueText("evaluationFormPhase2", "InvoiceDate", date)}
                                                        options={{
                                                            enableTime: false,
                                                            dateFormat: "m/d/Y"
                                                        }}
                                                    />
                                                }
                                                {props.fboState && !props.fboState.canEditForm2Phase2 &&
                                                    <input type="text" className="form-control form-control-sm w-100" readOnly={true}
                                                        value={props.getFieldValueDate("evaluationFormPhase2", "InvoiceDate") || ""}
                                                    />
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Lot, Batch or Production Date mentioned in the Invoice</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ProductDetailComplete") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ProductDetailComplete", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ProductDetailComplete", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ProductDetailComplete") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ProductDetailComplete", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ProductDetailComplete", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ProductDetailComplete") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ProductDetailComplete", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ProductDetailComplete", "N/A", e.target.checked)} /> N/A
                                            </td>
                                            <td>
                                                Remarks:
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "ProductDetailCompleteRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "ProductDetailCompleteRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Is the description matching with the commercial invoice issued by the Client?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "DescriptionMatching") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "DescriptionMatching", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "DescriptionMatching", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "DescriptionMatching") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "DescriptionMatching", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "DescriptionMatching", "No", e.target.checked)} /> No
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "DescriptionMatching") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "DescriptionMatching", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "DescriptionMatching", "N/A", e.target.checked)} /> N/A
                                            </td>
                                            <td>
                                                Remarks:
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "DescriptionMatchingRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "DescriptionMatchingRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td>#</td>
                                            <td colSpan={3}>Points to be checked<sup>7</sup></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="index-col">1</td>
                                            <td>Application Review have a positive verdict?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ApplicationPositiveVerdict") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ApplicationPositiveVerdict", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ApplicationPositiveVerdict", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ApplicationPositiveVerdict") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ApplicationPositiveVerdict", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ApplicationPositiveVerdict", "No", e.target.checked)} /> No
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2</td>
                                            <td>Certification evaluation plan available and filled in correctly</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CertificationEvaluationPlan") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CertificationEvaluationPlan", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CertificationEvaluationPlan", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CertificationEvaluationPlan") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CertificationEvaluationPlan", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CertificationEvaluationPlan", "No", e.target.checked)} /> No
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">3</td>
                                            <td>Certification Application form / Request for Certification (RFC) correct?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "RFCCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "RFCCorrect", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "RFCCorrect", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "RFCCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "RFCCorrect", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "RFCCorrect", "No", e.target.checked)} /> No
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">4</td>
                                            <td>Are all docs readable when scanned in?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "DocsReadable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "DocsReadable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "DocsReadable", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "DocsReadable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "DocsReadable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "DocsReadable", "No", e.target.checked)} /> No
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">5</td>
                                            <td>Check representative samples photos of the proposed production</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "SamplesPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "SamplesPhotos", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "SamplesPhotos", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "SamplesPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "SamplesPhotos", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "SamplesPhotos", "No", e.target.checked)} /> No
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td colSpan={2}>Review of Conflict of Interest</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>I undertake to inform the Technical Manager or Group Team Leader in the event of any of the following matters related to the specified client.</td>
                                        </tr>
                                        <tr>
                                            <td className="check-col">
                                                <input type="checkbox" checked={props.getFieldValue("evaluationFormPhase2", "HaveRelationship", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HaveRelationship", "Yes", e.target.checked)} />
                                            </td>
                                            <td>In the management of the client, I don’t have close family members and person/people with whom I have a personal or business relationship that can affect objectivity.</td>
                                        </tr>
                                        <tr>
                                            <td className="check-col">
                                                <input type="checkbox" checked={props.getFieldValue("evaluationFormPhase2", "PerformedAudit", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PerformedAudit", "Yes", e.target.checked)} />
                                            </td>
                                            <td>In the last two years, I don’t have performed internal audit and training services in the client, including quality consultancy.</td>
                                        </tr>
                                        <tr>
                                            <td className="check-col">
                                                <input type="checkbox" checked={props.getFieldValue("evaluationFormPhase2", "ProvidedServices", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ProvidedServices", "Yes", e.target.checked)} />
                                            </td>
                                            <td>I didn’t provide services in the supply, design, service provider developer of the client's products, operation, and development of product-related processes.</td>
                                        </tr>
                                        <tr>
                                            <td className="check-col">
                                                <input type="checkbox" checked={props.getFieldValue("evaluationFormPhase2", "PreventImpartiality", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PreventImpartiality", "Yes", e.target.checked)} />
                                            </td>
                                            <td>There aren’t illegal financial and other conditions that prevent my impartiality.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td className="text-center" style={{width:"5%"}}>#</td>
                                            <td style={{width:"50%"}}>Test Report</td>
                                            <td className="text-center" style={{width:"5%"}}>Yes</td>
                                            <td className="text-center" style={{width:"5%"}}>No</td>
                                            <td className="text-center" style={{width:"5%"}}>N/A</td>
                                            <td style={{width:"30%"}}>Remarks</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="index-col">1</td>
                                            <td>Are test reports covering all the shipment products?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "TestReportCoverAll") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "TestReportCoverAll", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "TestReportCoverAll", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "TestReportCoverAll") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "TestReportCoverAll", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "TestReportCoverAll", "No", e.target.checked)} /> <sup>8</sup>
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "TestReportCoverAll") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "TestReportCoverAll", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "TestReportCoverAll", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "TestReportCoverAllRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "TestReportCoverAllRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td className="text-center" style={{width:"5%"}}>#</td>
                                            <td style={{width:"50%"}}>Inspection Report</td>
                                            <td className="text-center" style={{width:"5%"}}>Yes</td>
                                            <td className="text-center" style={{width:"5%"}}>No</td>
                                            <td className="text-center" style={{width:"5%"}}>N/A</td>
                                            <td style={{width:"30%"}}>Remarks</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="index-col">1</td>
                                            <td>New factory / new client</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "NewFactoryOrClient") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "NewFactoryOrClient", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "NewFactoryOrClient", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "NewFactoryOrClient") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "NewFactoryOrClient", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "NewFactoryOrClient", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "NewFactoryOrClient") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "NewFactoryOrClient", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "NewFactoryOrClient", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "NewFactoryOrClientRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "NewFactoryOrClientRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2</td>
                                            <td>Inspection report available</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReport", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReport", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReport", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReport", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReport", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReport", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "InspectionReportRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "InspectionReportRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">3</td>
                                            <td>Is the inspection report valid?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportValid", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportValid", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportValid", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportValid", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportValid", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportValid", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "InspectionReportValidRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "InspectionReportValidRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">4</td>
                                            <td>Are the manufacturer details are matching with RFPC or RFSC?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ManufacturerDetailMatch", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "ManufacturerDetailMatchRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "ManufacturerDetailMatchRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">5</td>
                                            <td>Is the inspector listed in TÜV AUSTRIA inspectors list?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectorListed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectorListed", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectorListed", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectorListed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectorListed", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectorListed", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectorListed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectorListed", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectorListed", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "InspectorListedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "InspectorListedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">6</td>
                                            <td>Is the inspection report including the photos?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportPhotos", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportPhotos", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportPhotos", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportPhotos", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportPhotos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportPhotos", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportPhotos", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "InspectionReportPhotosRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "InspectionReportPhotosRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">7</td>
                                            <td>Is the inspection report acceptable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportAcceptable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportAcceptable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportAcceptable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportAcceptable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "InspectionReportAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "InspectionReportAcceptable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "InspectionReportAcceptable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "InspectionReportAcceptableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "InspectionReportAcceptableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td className="text-center" style={{width:"5%"}}>#</td>
                                            <td style={{width:"50%"}}>Verification of Quality Documentation</td>
                                            <td className="text-center" style={{width:"5%"}}>Yes</td>
                                            <td className="text-center" style={{width:"5%"}}>No</td>
                                            <td className="text-center" style={{width:"5%"}}>N/A</td>
                                            <td style={{width:"30%"}}>Remarks</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="index-col">1</td>
                                            <td>HALAL certificate is applicable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificateApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificateApplicable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificateApplicable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificateApplicable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificateApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificateApplicable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificateApplicableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificateApplicableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">1.1</td>
                                            <td>HALAL Certification body is issued from accredited Islamic centre?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationBody") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationBody", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationBody", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationBody") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationBody", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationBody", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationBody") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationBody", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationBody", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationBodyRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationBodyRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">1.2</td>
                                            <td>HALAL Certificate is valid? </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationValid", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationValid", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationValid", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationValid", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationValid", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationValid", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationValidRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationValidRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">1.3</td>
                                            <td>Product is mentioned in HALAL Certificate?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationProductMentioned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationProductMentionedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationProductMentionedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">1.4</td>
                                            <td>Is HALAL certificate holding a valid Accreditation Logo?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationLogo") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationLogo", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationLogo", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationLogo") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationLogo", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationLogo", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationLogo") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationLogo", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationLogo", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationLogoRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationLogoRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">1.5</td>
                                            <td>Is HALAL certificate signed/ stamped?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSigned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSigned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSigned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSigned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSigned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSigned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationSignedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationSignedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2</td>
                                            <td>Halal Slaughtering Certificate is applicable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughter", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationSlaughterRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationSlaughterRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.1</td>
                                            <td>Halal Slaughtering Certificate is issued from accredited Islamic centre?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationIssued") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationIssued", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationIssued", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationIssued") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationIssued", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationIssued", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationIssued") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationIssued", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationIssued", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationIssuedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationIssuedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.2</td>
                                            <td>Halal Slaughtering Certificate is valid?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterValid", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationSlaughterValidRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationSlaughterValidRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.3</td>
                                            <td>Product is mentioned in HALAL Slaughtering Certificate?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterMentined", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationSlaughterMentinedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationSlaughterMentinedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.4</td>
                                            <td>Halal Slaughtering Certificate is signed/ stamped?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HALALCertificationSlaughterSigned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HALALCertificationSlaughterSignedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HALALCertificationSlaughterSignedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">3</td>
                                            <td>Phythosanitary certificate is applicable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateApplicable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateApplicableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateApplicableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">3.1</td>
                                            <td>Phythosanitary certificate form must be matched with SFDA form (food important conditions & requirements annex 1)</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSFDA", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateSFDARemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateSFDARemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">3.2</td>
                                            <td>Phythosanitary certificate must be issued from the authorized body in COO?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateCOO", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateCOORemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateCOORemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">3.3</td>
                                            <td>Product is mentioned in the Phytosanitary certificate?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateMentioned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateMentionedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateMentionedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">3.4</td>
                                            <td>Phythosanitary certificate is valid?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateValid", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateValidRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateValidRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">3.5</td>
                                            <td>Phythosanitary certificate is signed/stamped?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhythosanitaryCertificateSigned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateSignedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhythosanitaryCertificateSignedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">4</td>
                                            <td>Health Certificate is applicable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateApplicable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateApplicable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateApplicable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateApplicable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HealthCertificateApplicableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HealthCertificateApplicableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">4.1</td>
                                            <td>Health certificate form must be matched with SFDA form (food important conditions & requirements annex 1)</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateSFDA") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateSFDA", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateSFDA", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateSFDA") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateSFDA", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateSFDA", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateSFDA") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateSFDA", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateSFDA", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HealthCertificateSFDARemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HealthCertificateSFDARemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">4.2</td>
                                            <td>Health certificate must be issued from the authorized body in COO?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateCOO", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateCOO", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateCOO", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateCOO", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateCOO", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateCOO", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HealthCertificateCOORemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HealthCertificateCOORemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">4.3</td>
                                            <td>Product is mentioned in the health certificate?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateMentoned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateMentoned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateMentoned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateMentoned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateMentoned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateMentoned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateMentoned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateMentoned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateMentoned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HealthCertificateMentonedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HealthCertificateMentonedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">4.4</td>
                                            <td>Health certificate is valid?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateValid", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateValid", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateValid", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateValid", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateValid", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateValid", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HealthCertificateValidRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HealthCertificateValidRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">4.5</td>
                                            <td>Health certificate is signed/stamped?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateSigned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateSigned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateSigned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateSigned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "HealthCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "HealthCertificateSigned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "HealthCertificateSigned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "HealthCertificateSignedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "HealthCertificateSignedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">5</td>
                                            <td>Organic Certificate is applicable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateApplicable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "OrganicCertificateApplicableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "OrganicCertificateApplicableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">5.1</td>
                                            <td>Organic Certificate must be issued from the authorized body in COO?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateCOO", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateCOO", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateCOO", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateCOO", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateCOO") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateCOO", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateCOO", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "OrganicCertificateCOORemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "OrganicCertificateCOORemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">5.2</td>
                                            <td>Organic Certificate is valid?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateValid", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateValid", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateValid", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateValid", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateValid", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateValid", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "OrganicCertificateValidRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "OrganicCertificateValidRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">5.3</td>
                                            <td>Organic Certificate is signed/stamped?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateSigned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateSigned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateSigned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateSigned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateSigned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateSigned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateSigned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "OrganicCertificateSignedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "OrganicCertificateSignedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">5.4</td>
                                            <td>Product is mentioned in Organic Certificate?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OrganicCertificateMentioned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "OrganicCertificateMentionedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "OrganicCertificateMentionedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">6</td>
                                            <td>MSDS certificate is applicable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDSApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDSApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDSApplicable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDSApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDSApplicable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDSApplicable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDSApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDSApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDSApplicable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "MSDSApplicableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "MSDSApplicableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">6.1</td>
                                            <td>The name/description is mentioned in the MSDS?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDSMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDSMentioned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDSMentioned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDSMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDSMentioned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDSMentioned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "MSDSMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "MSDSMentioned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "MSDSMentioned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "MSDSMentionedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "MSDSMentionedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">7</td>
                                            <td>Ingredients Formulation is applicable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsFormulationApplicable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "IngredientsFormulationApplicableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "IngredientsFormulationApplicableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">7.1</td>
                                            <td>The name/description is mentioned in the Ingredients Formulation?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsFormulationMentioned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "IngredientsFormulationMentionedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "IngredientsFormulationMentionedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">7.2</td>
                                            <td>
                                                Ingredients verification is applicable. <sup>9</sup> (Only applicable for cosmetic products)<br />
                                                For English version: <a href="https://www.sfda.gov.sa/en/AllCosmeticList">please click on</a><br />
                                                For Arabic version: <a href="https://www.sfda.gov.sa/ar/AllCosmeticList">please click on</a><br />
                                                Prohibited Ingredients List: <a href="https://docs.google.com/spreadsheets/d/1nkrgcYtZMdchZpQLMs661NQvFRdUrg-L/edit#gid=1129332338">please click on to reach excel sheet</a>
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "IngredientsVerificationApplicable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "IngredientsVerificationApplicableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "IngredientsVerificationApplicableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">8</td>
                                            <td>PIF certificate is applicable</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIFApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIFApplicable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIFApplicable", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIFApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIFApplicable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIFApplicable", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIFApplicable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIFApplicable", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIFApplicable", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PIFApplicableRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PIFApplicableRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">8.1</td>
                                            <td>The name/description is mentioned in the PIF?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIFMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIFMentioned", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIFMentioned", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIFMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIFMentioned", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIFMentioned", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PIFMentioned") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PIFMentioned", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PIFMentioned", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PIFMentionedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PIFMentionedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">9</td>
                                            <td>Other documents</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherDocuments") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherDocuments", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OtherDocuments", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherDocuments") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherDocuments", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OtherDocuments", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherDocuments") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherDocuments", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OtherDocuments", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "OtherDocumentsRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "OtherDocumentsRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">9.1</td>
                                            <td>
                                                specify
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "OtherSpecifyDescription")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "OtherSpecifyDescription", e.target.value)}
                                                />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherSpecify") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherSpecify", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OtherSpecify", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherSpecify") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherSpecify", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OtherSpecify", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "OtherSpecify") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "OtherSpecify", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "OtherSpecify", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "OtherSpecifyRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "OtherSpecifyRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">10</td>
                                            <td>
                                                Is the request complied with SFDA circulars and announcement?<br />
                                                Please verify any effective circular, guideline, announcement etc from below links<sup>10</sup><br />
                                                For food = <a href="https://www.sfda.gov.sa/en/circulars?date%5Bmin%5D=&date%5Bmax%5D=&tags=1&keys=&field_country_name=All&field_customer_type=All&field_scope_of_regulation=All&field_mainstreaming=Products&field_circular_status=Active&field_food_group=All&field_drug_group=All&field_md_group=All&field_feed_group=All&field_cosmetic_group=All&field_pesticides_group=All">Circulars | Saudi Food and Drug Authority (sfda.gov.sa)</a><br />
                                                For cometic = <a href="https://www.sfda.gov.sa/en/circulars?date%5Bmin%5D=&date%5Bmax%5D=&tags=47&keys=&field_country_name=All&field_customer_type=All&field_scope_of_regulation=All&field_mainstreaming=Products&field_circular_status=Active&field_food_group=All&field_drug_group=All&field_md_group=All&field_feed_group=All&field_cosmetic_group=All&field_pesticides_group=All">Circulars | Saudi Food and Drug Authority (sfda.gov.sa)</a>
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "SFDACirculars") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "SFDACirculars", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "SFDACirculars", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "SFDACirculars") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "SFDACirculars", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "SFDACirculars", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "SFDACirculars") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "SFDACirculars", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "SFDACirculars", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "SFDACircularsRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "SFDACircularsRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">10.1</td>
                                            <td>
                                                Is there any updated circular effecting the product(s)? If yes, please provide information
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "CircularEffectingDescription")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "CircularEffectingDescription", e.target.value)}
                                                />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CircularEffecting") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CircularEffecting", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CircularEffecting", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CircularEffecting") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CircularEffecting", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CircularEffecting", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CircularEffecting") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CircularEffecting", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CircularEffecting", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "CircularEffectingRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "CircularEffectingRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td className="text-center" style={{width:"5%"}}>#</td>
                                            <td style={{width:"50%"}}>Photos</td>
                                            <td className="text-center" style={{width:"5%"}}>Yes</td>
                                            <td className="text-center" style={{width:"5%"}}>No</td>
                                            <td className="text-center" style={{width:"5%"}}>N/A</td>
                                            <td style={{width:"30%"}}>Remarks</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="index-col">1</td>
                                            <td>Photos are visible, accessible, and easily readable</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosVisible") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosVisible", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosVisible", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosVisible") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosVisible", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosVisible", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosVisible") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosVisible", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosVisible", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhotosVisibleRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhotosVisibleRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2</td>
                                            <td>Photos will be used for verifying labelling and marking requirements </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosUsed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosUsed", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosUsed", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosUsed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosUsed", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosUsed", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosUsed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosUsed", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosUsed", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhotosUsedRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhotosUsedRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.1</td>
                                            <td>Labels on the product packaging shall be in accordance with the technical requirements contained in TR and the relevant standards</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelAccordance") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelAccordance", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelAccordance", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelAccordance") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelAccordance", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelAccordance", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelAccordance") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelAccordance", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelAccordance", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhotosLabelAccordanceRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhotosLabelAccordanceRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.2</td>
                                            <td>Labels accordance with the technical requirements contained in TR and the relevant standards</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelInformation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelInformation", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelInformation", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelInformation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelInformation", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelInformation", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelInformation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelInformation", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelInformation", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhotosLabelInformationRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhotosLabelInformationRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.3</td>
                                            <td>All information used in the labelling shall be correct and proven</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelCorrect", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelCorrect", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelCorrect", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelCorrect", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelCorrect") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelCorrect", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelCorrect", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhotosLabelCorrectRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhotosLabelCorrectRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2.4</td>
                                            <td>Images and phrases used on the product packaging shall not violate the public order, public morals and Islamic values prevailing in the KSA. Acceptable?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelViolate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelViolate", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelViolate", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelViolate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelViolate", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelViolate", "No", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "PhotosLabelViolate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "PhotosLabelViolate", "N/A")} onChange={e => props.setFieldValue("evaluationFormPhase2", "PhotosLabelViolate", "N/A", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "PhotosLabelViolateRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "PhotosLabelViolateRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td className="text-center" style={{width:"5%"}}>#</td>
                                            <td style={{width:"50%"}}>ISO Certification Requirements</td>
                                            <td className="text-center" style={{width:"5%"}}>Yes</td>
                                            <td className="text-center" style={{width:"10%"}}>No</td>
                                            <td style={{width:"30%"}}>Remarks</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="index-col">1</td>
                                            <td>ISO Certificate (e.g.  ISO 22000, HACCAP, ISO 22716, GMP…. etc.)</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ISOCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ISOCertificate", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ISOCertificate", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ISOCertificate") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ISOCertificate", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ISOCertificate", "No", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "ISOCertificateRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "ISOCertificateRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="index-col">2</td>
                                            <td>ISO Certificate is valid?</td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ISOCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ISOCertificateValid", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ISOCertificateValid", "Yes", e.target.checked)} />
                                            </td>
                                            <td className="check-col">
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "ISOCertificateValid") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "ISOCertificateValid", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "ISOCertificateValid", "No", e.target.checked)} />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm2Phase2)}
                                                    value={props.getFieldValueText("evaluationFormPhase2", "ISOCertificateValidRemarks")}
                                                    onChange={e => props.setFieldValueText("evaluationFormPhase2", "ISOCertificateValidRemarks", e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={`table-form ${props.fboState && props.fboState.canEditForm2Phase2 ? "" : "table-form-readonly"}`}>
                                    <thead>
                                        <tr className="header">
                                            <td colSpan={3}>Result of Evaluation</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{width:"50%"}}>Is certification recommended?</td>
                                            <td style={{width:"25%"}}>
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CertificationAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CertificationAcceptable", "Yes")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CertificationAcceptable", "Yes", e.target.checked)} /> Yes
                                            </td>
                                            <td style={{width:"25%"}}>
                                                <input type="radio" className={`${props.isValidFieldValue("evaluationFormPhase2", "CertificationAcceptable") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("evaluationFormPhase2", "CertificationAcceptable", "No")} onChange={e => props.setFieldValue("evaluationFormPhase2", "CertificationAcceptable", "No", e.target.checked)} /> No
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>Signature</td>
                                            <td>Date</td>
                                        </tr>
                                        <tr>
                                            <td>{(props.fboState && props.fboState.evaluationFormPhase2 && props.fboState.evaluationFormPhase2.lastUpdateByName) || ""}&nbsp;</td>
                                            <td>
                                                {props.fboState && props.fboState.evaluationFormPhase2 && props.fboState.evaluationFormPhase2.lastUpdateBySignature &&
                                                    <div>
                                                        <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "100px", cursor: "pointer"}} src={`${api}Document/DownloadDocumentByName?fileName=${props.fboState.evaluationFormPhase2.lastUpdateBySignature}`} alt="Not Found" />
                                                    </div>
                                                }&nbsp;
                                            </td>
                                            <td>
                                                <MyDateRenderer value={(props.fboState && props.fboState.evaluationFormPhase2 && props.fboState.evaluationFormPhase2.ticketForm && props.fboState.evaluationFormPhase2.ticketForm.dateLastEdit) || ""} format={"dd.mm.yyyy HH:MM:ss"} />&nbsp;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <table className="table-form table-form-alt">
                                    <tbody>
                                        <tr>
                                            <td style={{width:"30%", paddingTop:50}}>------------------------------------------</td>
                                            <td style={{width:"30%"}}>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <sup>5</sup> Please verify the existence of the relevant records before starting the evaluation. If there is any missing file in <b>“Records Check”</b> section, please connect with the sales team.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <sup>6</sup> Please refer existing certificate number for extension or re-issue.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <sup>7</sup> If there is any missing information in <b>“Points to be Checked”</b> section, please connect with the sales team.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <sup>8</sup> If it is no, please inform the inspector for missing test report requested.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <sup>9</sup> To verify prohibited Ingredients, please use the link and search for related chemical.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <sup>10</sup> Please check updated Announcement from SFDA under Annex - A SFDA Announcement for Cosmetic and Food.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Collapse>
            </CardBody>
        </Card>
    );
};

export default FormEvaluationSFDA;