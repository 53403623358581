import { 
    ISO_DASHBOARD_START_LOADING, 
    ISO_DASHBOARD_END_LOADING, 
    SET_ISO_DASHBOARD_FILTER_SEARCH,
    SET_ISO_DASHBOARD_FILTER_MINE_ONLY,
    ISO_DASHBOARD_DRAFT_LOADED, 
    ISO_DASHBOARD_NEW_LOADED,
    ISO_DASHBOARD_SALES_ACTION_LOADED,
    ISO_DASHBOARD_CALCULATE_MAN_DAYS_LOADED,
    ISO_DASHBOARD_SALES_SUPPORT_LOADED,
    ISO_DASHBOARD_CUSTOMER_APPROVED_LOADED,
    ISO_DASHBOARD_AUDIT_IN_PROGRESS_LOADED,
    ISO_DASHBOARD_TECHNICAL_REVIEW_LOADED,
    ISO_DASHBOARD_FINAL_DECISION_LOADED,
    ISO_DASHBOARD_COMPLETED_LOADED,
    ISO_DASHBOARD_CLOSED_LOADED,
    ISO_DASHBOARD_ABORTED_LOADED,
    ISO_DASHBOARD_DOCUMENT_USER_LOADED,
    ISO_DASHBOARD_AUDIT_USER_LOADED,
    ISO_DASHBOARD_TECHNICAL_USER_LOADED,
    ISO_DASHBOARD_DECISION_USER_LOADED,
} from "../constants/IsoDashboardConstants";

const initialState = {
    loading: false,
    search: "",
    mineOnly: true,
    draftList: [],
    newList: [],
    salesActionList: [],
    calculateManDaysList: [],
    salesSupportList: [],
    customerApprovedList: [],
    auditInProgressList: [],
    technicalReviewList: [],
    finalDecisionList: [],
    completedList: [],
    closedList: [],
    abortedList: [],
    documentUserList: [],
    auditUserList: [],
    technicalUserList: [],
    decisionUserList: [],
};

const IsoDashboardReducer = (state = initialState, action) => {
    switch(action.type) {
        case ISO_DASHBOARD_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case ISO_DASHBOARD_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case SET_ISO_DASHBOARD_FILTER_SEARCH:
            return {
                ...state,
                search: action.payload
            };
        case SET_ISO_DASHBOARD_FILTER_MINE_ONLY:
            return {
                ...state,
                mineOnly: action.payload
            };
        case ISO_DASHBOARD_DRAFT_LOADED:
            return {
                ...state,
                draftList: action.payload
            };
        case ISO_DASHBOARD_NEW_LOADED:
            return {
                ...state,
                newList: action.payload
            };
        case ISO_DASHBOARD_SALES_ACTION_LOADED:
            return {
                ...state,
                salesActionList: action.payload
            };
        case ISO_DASHBOARD_CALCULATE_MAN_DAYS_LOADED:
            return {
                ...state,
                calculateManDaysList: action.payload
            };
        case ISO_DASHBOARD_SALES_SUPPORT_LOADED:
            return {
                ...state,
                salesSupportList: action.payload
            };
        case ISO_DASHBOARD_CUSTOMER_APPROVED_LOADED:
            return {
                ...state,
                customerApprovedList: action.payload
            };
        case ISO_DASHBOARD_AUDIT_IN_PROGRESS_LOADED:
            return {
                ...state,
                auditInProgressList: action.payload
            };
        case ISO_DASHBOARD_TECHNICAL_REVIEW_LOADED:
            return {
                ...state,
                technicalReviewList: action.payload
            };
        case ISO_DASHBOARD_FINAL_DECISION_LOADED:
            return {
                ...state,
                finalDecisionList: action.payload
            };
        case ISO_DASHBOARD_COMPLETED_LOADED:
            return {
                ...state,
                completedList: action.payload
            };
        case ISO_DASHBOARD_CLOSED_LOADED:
            return {
                ...state,
                closedList: action.payload
            };
        case ISO_DASHBOARD_ABORTED_LOADED:
            return {
                ...state,
                abortedList: action.payload
            };
        case ISO_DASHBOARD_DOCUMENT_USER_LOADED:
            return {
                ...state,
                documentUserList: action.payload
            };
        case ISO_DASHBOARD_AUDIT_USER_LOADED:
            return {
                ...state,
                auditUserList: action.payload
            };
        case ISO_DASHBOARD_TECHNICAL_USER_LOADED:
            return {
                ...state,
                technicalUserList: action.payload
            };
        case ISO_DASHBOARD_DECISION_USER_LOADED:
            return {
                ...state,
                decisionUserList: action.payload
            };
        default:
            return state;
    }
}

export default IsoDashboardReducer;