import { ACTION_START_LOADING, ACTION_END_LOADING, ACTION_LOADED } from "../constants/ActionConstants";

import { getApiData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadAction(id, name) {
    return async (dispatch) => {
        
        await dispatch({ type: ACTION_START_LOADING });

        try {

            var data = await getApiData("Action/GetActions", { id, name });
            
            if(data.success) {
                await dispatch({
                    type: ACTION_LOADED,
                    payload: data.responseEntities
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ACTION_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ACTION_END_LOADING });
        }
    };
}