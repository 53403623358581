import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { EMAIL_SEARCH_START_LOADING, EMAIL_SEARCH_END_LOADING, EMAIL_SEARCH_LOADED, SET_EMAIL_FILTER } from "../constants/EmailConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        active: ""
    }
};

const EmailReducer = (state = initialState, action) => {
    switch(action.type) {
        case EMAIL_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case EMAIL_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case EMAIL_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_EMAIL_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        default:
            return state;
    }
}

export default EmailReducer;