import { AUDIT_SEARCH_START_LOADING, AUDIT_SEARCH_END_LOADING, AUDIT_SEARCH_LOADED, SET_AUDIT_FILTER } from "../constants/AuditConstants";

import { postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadAuditSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: AUDIT_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Audit/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: AUDIT_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: AUDIT_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: AUDIT_SEARCH_END_LOADING });
        }
    };
}

export function setAuditFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_AUDIT_FILTER,
            payload: filter
        });
    };
}