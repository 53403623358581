import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Table, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';

import { api } from "../utils/index";
import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

const FormDecisionSASO = (props) => {
    const [decisionFormCollapsed, setDecisionFormCollapsed] = React.useState(true);
    
    return(
        <Card className="card-success mb-3">
            <CardHeader className="border-0 d-flex align-items-center">
                <CardLoader loading={props.loading} />
                <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setDecisionFormCollapsed(!decisionFormCollapsed)}>
                    {decisionFormCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                    {!decisionFormCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                    Decision & Review Form ({decisionFormCollapsed ? "Expand" : "Collapse"})
                </h5>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
                <Collapse isOpen={!decisionFormCollapsed} className="collapse">
                    <Row className="gy-2" style={{backgroundColor:"white"}}>
                        <Col xxl={12} md={12}>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm3 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr>
                                        <td colSpan={5} style={{textAlign:"center", border: "none"}}><h5><b>Review & Decision</b></h5></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="header">
                                        <td>#</td>
                                        <td>Points to be checked<sup>1</sup></td>
                                        <td colSpan={3}>Decision Maker</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.1</td>
                                        <td>Certification Contract / Quotation signed and stamped</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "SignedAndStamped") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "SignedAndStamped", "Yes")} onChange={e => props.setFieldValue("decisionForm", "SignedAndStamped", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "SignedAndStamped") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "SignedAndStamped", "No")} onChange={e => props.setFieldValue("decisionForm", "SignedAndStamped", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.2</td>
                                        <td>Application and Application Review completed with a positive verdict</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationCompleted", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ApplicationCompleted", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationCompleted", "No")} onChange={e => props.setFieldValue("decisionForm", "ApplicationCompleted", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.3</td>
                                        <td>Evaluation completed a positive verdict</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "EvaluationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "EvaluationCompleted", "Yes")} onChange={e => props.setFieldValue("decisionForm", "EvaluationCompleted", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "EvaluationCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "EvaluationCompleted", "No")} onChange={e => props.setFieldValue("decisionForm", "EvaluationCompleted", "No", e.target.checked)} /> No    
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.4</td>
                                        <td>Certification evaluation plan completed correctly</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificationEvaluationPlanCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "Yes")} onChange={e => props.setFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificationEvaluationPlanCompleted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "No")} onChange={e => props.setFieldValue("decisionForm", "CertificationEvaluationPlanCompleted", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.5</td>
                                        <td>SASO Certification Program and related TR are followed</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "StandardFollowed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "StandardFollowed", "Yes")} onChange={e => props.setFieldValue("decisionForm", "StandardFollowed", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "StandardFollowed") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "StandardFollowed", "No")} onChange={e => props.setFieldValue("decisionForm", "StandardFollowed", "No", e.target.checked)} /> No    
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.6</td>
                                        <td>Test report results are suitable and acceptable according to related Tr or standard(s)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReportStandard") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReportStandard", "Yes")} onChange={e => props.setFieldValue("decisionForm", "TestReportStandard", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReportStandard") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReportStandard", "No")} onChange={e => props.setFieldValue("decisionForm", "TestReportStandard", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.7</td>
                                        <td>If a subcontracted laboratory is used, is it included in TÜV AUSTRIA's approved subcontractor list?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApprovedSubContractorLab", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ApprovedSubContractorLab", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApprovedSubContractorLab", "No")} onChange={e => props.setFieldValue("decisionForm", "ApprovedSubContractorLab", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApprovedSubContractorLab", "N/A")} onChange={e => props.setFieldValue("decisionForm", "ApprovedSubContractorLab", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">1.8</td>
                                        <td>If a factory audit is required, has the appropriate conditions been defined?</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryAuditConditions") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryAuditConditions", "Yes")} onChange={e => props.setFieldValue("decisionForm", "FactoryAuditConditions", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryAuditConditions") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryAuditConditions", "No")} onChange={e => props.setFieldValue("decisionForm", "FactoryAuditConditions", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryAuditConditions") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryAuditConditions", "N/A")} onChange={e => props.setFieldValue("decisionForm", "FactoryAuditConditions", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm3 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={2}>Review of Conflict of Interest</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>I undertake to inform the Technical Manager or Group Team Leader in the event of any of the following matters related to the specified client.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("decisionForm", "HaveRelationship", "Yes")} onChange={e => props.setFieldValue("decisionForm", "HaveRelationship", "Yes", e.target.checked)} />
                                        </td>
                                        <td>In the management of the client, I have close family members and person/people with whom I have a personal or business relationship that can affect objectivity.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("decisionForm", "PerformedAudit", "Yes")} onChange={e => props.setFieldValue("decisionForm", "PerformedAudit", "Yes", e.target.checked)} />
                                        </td>
                                        <td>In the last two years, I have performed internal audit and training services in the client, including quality consultancy.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("decisionForm", "ProvidedServices", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ProvidedServices", "Yes", e.target.checked)} />
                                        </td>
                                        <td>I provided services in the supply, design, service provider developer of the client's products, operation, and development of product-related processes.</td>
                                    </tr>
                                    <tr>
                                        <td className="check-col">
                                            <input type="checkbox" checked={props.getFieldValue("decisionForm", "PreventImpartiality", "Yes")} onChange={e => props.setFieldValue("decisionForm", "PreventImpartiality", "Yes", e.target.checked)} />
                                        </td>
                                        <td>There are illegal financial and other conditions that prevent my impartiality.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm3 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={2}>The documents or records described below are accessible</td>
                                        <td colSpan={3}>Decision Maker</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="index-col">2.1</td>
                                        <td>FM-GMAP-PC-SS-001a Offer - Contract Form between TÜV AUSTRIA and applicant</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "QuotationContract") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "QuotationContract", "Yes")} onChange={e => props.setFieldValue("decisionForm", "QuotationContract", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "QuotationContract") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "QuotationContract", "No")} onChange={e => props.setFieldValue("decisionForm", "QuotationContract", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.2</td>
                                        <td>FM-GMAP-PC-SS-001c Evaluation</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationForm") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationForm", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ApplicationForm", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ApplicationForm") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ApplicationForm", "No")} onChange={e => props.setFieldValue("decisionForm", "ApplicationForm", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.3</td>
                                        <td>Test report or other related Quality Documents</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReport", "Yes")} onChange={e => props.setFieldValue("decisionForm", "TestReport", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TestReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TestReport", "No")} onChange={e => props.setFieldValue("decisionForm", "TestReport", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.4</td>
                                        <td>Technical Documentation</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TechnicalDocumentation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TechnicalDocumentation", "Yes")} onChange={e => props.setFieldValue("decisionForm", "TechnicalDocumentation", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "TechnicalDocumentation") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "TechnicalDocumentation", "No")} onChange={e => props.setFieldValue("decisionForm", "TechnicalDocumentation", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.5</td>
                                        <td>Supplier Declaration of Conformity</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "SupplierDeclaration") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "SupplierDeclaration", "Yes")} onChange={e => props.setFieldValue("decisionForm", "SupplierDeclaration", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "SupplierDeclaration") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "SupplierDeclaration", "No")} onChange={e => props.setFieldValue("decisionForm", "SupplierDeclaration", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.6</td>
                                        <td>Risk Assessment Form</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "RiskAssessmentForm") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "RiskAssessmentForm", "Yes")} onChange={e => props.setFieldValue("decisionForm", "RiskAssessmentForm", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "RiskAssessmentForm") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "RiskAssessmentForm", "No")} onChange={e => props.setFieldValue("decisionForm", "RiskAssessmentForm", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.7</td>
                                        <td>At least 2 (two) visible and clear PHOTOS (one photo for labeling, one photo for product)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "Photos", "Yes")} onChange={e => props.setFieldValue("decisionForm", "Photos", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "Photos", "No")} onChange={e => props.setFieldValue("decisionForm", "Photos", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.8</td>
                                        <td>ISO Certification (ISO 9001, ISO 16949, etc. related with TR)</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ISOCertification", "Yes")} onChange={e => props.setFieldValue("decisionForm", "ISOCertification", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ISOCertification", "No")} onChange={e => props.setFieldValue("decisionForm", "ISOCertification", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "ISOCertification", "N/A")} onChange={e => props.setFieldValue("decisionForm", "ISOCertification", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.9</td>
                                        <td>Factory audit / surveillance report</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryAuditReport", "Yes")} onChange={e => props.setFieldValue("decisionForm", "FactoryAuditReport", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryAuditReport", "No")} onChange={e => props.setFieldValue("decisionForm", "FactoryAuditReport", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "FactoryAuditReport", "N/A")} onChange={e => props.setFieldValue("decisionForm", "FactoryAuditReport", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="index-col">2.10</td>
                                        <td>Certificate of Origin</td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificateOfOrigin") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificateOfOrigin", "Yes")} onChange={e => props.setFieldValue("decisionForm", "CertificateOfOrigin", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificateOfOrigin") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificateOfOrigin", "No")} onChange={e => props.setFieldValue("decisionForm", "CertificateOfOrigin", "No", e.target.checked)} /> No
                                        </td>
                                        <td className="check-col">
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificateOfOrigin") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificateOfOrigin", "N/A")} onChange={e => props.setFieldValue("decisionForm", "CertificateOfOrigin", "N/A", e.target.checked)} /> N/A
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={`table-form ${props.fboState && props.fboState.canEditForm3 ? "" : "table-form-readonly"}`}>
                                <thead>
                                    <tr className="header">
                                        <td colSpan={3}>Result of Review & Decision</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{width:"50%"}}>Certificate can be granted?</td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificateGranted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificateGranted", "Yes")} onChange={e => props.setFieldValue("decisionForm", "CertificateGranted", "Yes", e.target.checked)} /> Yes
                                        </td>
                                        <td style={{width:"25%"}}>
                                            <input type="radio" className={`${props.isValidFieldValue("decisionForm", "CertificateGranted") ? "" : "checkbox-is-invalid"}`} checked={props.getFieldValue("decisionForm", "CertificateGranted", "No")} onChange={e => props.setFieldValue("decisionForm", "CertificateGranted", "No", e.target.checked)} /> No<sup>2</sup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Certificate Number</td>
                                        <td colSpan={2}>
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-2 pt-1">
                                                    <input type="checkbox" checked={props.getFieldValue("decisionForm", "CertificateNumber", "Yes")} onChange={e => props.setFieldValue("decisionForm", "CertificateNumber", "Yes", e.target.checked)} />
                                                </div>
                                                <div className="flex-grow-1 w-100">
                                                    <input type="text" className="form-control form-control-sm w-100" readOnly={!(props.fboState && props.fboState.canEditForm3)}
                                                        value={props.getFieldValueText("decisionForm", "CertificateNumberText") || ""}
                                                        onChange={e => props.setFieldValueText("decisionForm", "CertificateNumberText", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>Signature</td>
                                        <td>Date</td>
                                    </tr>
                                    <tr>
                                        <td>{(props.fboState && props.fboState.decisionForm && props.fboState.decisionForm.lastUpdateByName) || ""}&nbsp;</td>
                                        <td>
                                            {props.fboState && props.fboState.decisionForm && props.fboState.decisionForm.lastUpdateBySignature &&
                                                <div>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "100px", cursor: "pointer"}} src={`${api}Document/DownloadDocumentByName?fileName=${props.fboState.decisionForm.lastUpdateBySignature}`} alt="Not Found" />
                                                </div>
                                            }&nbsp;
                                        </td>
                                        <td>
                                            <MyDateRenderer value={(props.fboState && props.fboState.decisionForm && props.fboState.decisionForm.ticketForm && props.fboState.decisionForm.ticketForm.dateLastEdit) || ""} format={"dd.mm.yyyy HH:MM:ss"} />&nbsp;
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table-form table-form-alt">
                                <tbody>
                                    <tr>
                                        <td style={{width:"30%", paddingTop:50}}>------------------------------------------</td>
                                        <td style={{width:"30%"}}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>1</sup> If there is any missing information in <b>“Points to be Checked”</b> section, please connect with the Technical Reviewer
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <sup>2</sup> If No, a written Non-Conformance Letter Report (on TÜV AUSTRIA letterhead) shall be issued to the applicant.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Collapse>
            </CardBody>
        </Card>
    );
};

export default FormDecisionSASO;