import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Table, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import PaginationComponent from "../components/PaginationComponent";
import MyDateRenderer from "../components/MyDateRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as auditActions from "../actions/AuditActions";

const AuditSearchScreen  = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const loading = useSelector((state) => state.audit.loading);
    const list = useSelector((state) => state.audit.list);
    const filter = useSelector((state) => state.audit.filter);

    const doSearch = () => {
        var copy_filter = createDeepClone(filter);
        
        if(copy_filter.pageIndex == 1) {
            dispatch(auditActions.loadAuditSearch(filter, navigate));
        }
        else {
            copy_filter.pageIndex = 1;
            dispatch(auditActions.setAuditFilter(copy_filter));
        }
    }

    React.useEffect(() => {
		dispatch(auditActions.loadAuditSearch(filter, navigate));
    }, [filter.pageIndex]);

    const onFilterChange = (name, value) => {
        var copy_filter = createDeepClone(filter);
        copy_filter[name] = value;
        dispatch(auditActions.setAuditFilter(copy_filter));
    }
    
    const onPageChange = (page) => {
        var copy_filter = createDeepClone(filter);
        copy_filter.pageIndex = page;
        dispatch(auditActions.setAuditFilter(copy_filter));
    }
    
    return(
        <Fragment>
            <div className="page-content">
            <Container fluid>
                    <BreadCrumb title="Audit Search" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-3">
                                        <Col xxl={6} md={12}>
                                            <div className="search-box">
                                                <input type="text" className="form-control search" placeholder="Table Name..."
                                                    value={filter.table_name}
                                                    onChange={e => onFilterChange("table_name", e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>
                                        <Col xxl={4} md={12}>
                                            <div className="search-box">
                                                <input type="text" className="form-control search" placeholder="Key Value..."
                                                    value={filter.primary_key}
                                                    onChange={e => onFilterChange("primary_key", e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <button type="button" className="btn btn-primary btn-label w-100" 
                                                disabled={loading}
                                                onClick= {() => doSearch()}
                                            >
                                                <i className="ri-search-eye-line label-icon align-middle"></i> Search
                                            </button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardLoader loading={loading} />
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <Table className="table-hover align-middle mb-0">
                                            <thead className="table-primary">
                                                <tr>
                                                    <th>User Id</th>
                                                    <th>Audit Type</th>
                                                    <th>Audit Date</th>
                                                    <th>Table Name</th>
                                                    <th>Key Value</th>
                                                    <th>Change</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.dataList.map(function(item, index){
                                                    return(
                                                        <tr key={index}>
                                                            <td>{item.userId}</td>
                                                            <td>{item.auditType}</td>
                                                            <td><MyDateRenderer value={item.auditDate} /></td>
                                                            <td>{item.tableName}</td>
                                                            <td>{item.primaryKey}</td>
                                                            <td>{item.affectedValues}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <PaginationComponent
                                                total={list.totalCount}
                                                currentPage={filter.pageIndex}
                                                onPageChange={page => onPageChange(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default AuditSearchScreen;