import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Collapse } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';
import Flatpickr from "react-flatpickr";
import dateFormat from "dateformat";
import moment from "moment";

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";
import Lookup from "../components/Lookup";
import CustomerLookup from "../components/CustomerLookup";
import MyDateInput from "../components/MyDateInput";
import { Highlighter, Menu, MenuItem, } from 'react-bootstrap-typeahead';
import ConfirmModal from "../components/ConfirmModal";
import ServiceDocumentGrid from "../components/ServiceDocumentGrid";
import CommentGrid from "../components/CommentGrid";

import { useDispatch, useSelector } from "react-redux";
import * as isoRequestActions from "../actions/IsoRequestActions";

const IsoRequestEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.iso.loadingFBO);
    const user = useSelector((state) => state.login.user);
    const fbo = useSelector((state) => state.iso.fbo);

    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);
    const [selectedReporter, setSelectedReporter] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [invoiceInformationCollapsed, setInvoiceInformationCollapsed] = React.useState(true);
    const [selectedManDayStandardFBO, setSelectedManDayStandardFBO] = React.useState(null);

    const [selectedStatus, setSelectedStatus] = React.useState("");
    const [currentMessage, setCurrentMessage] = React.useState("");
    const [showAssignMeModal, setShowAssignMeModal] = React.useState(false);
    const [showSubmitModal, setShowSubmitModal] = React.useState(false);
    const [showSalesActionModal, setShowSalesActionModal] = React.useState(false);
    const [showCalculateManDaysModal, setShowCalculateManDaysModal] = React.useState(false);
    const [showSalesSupportModal, setShowSalesSupportModal] = React.useState(false);
    const [showCustomerApprovedModal, setShowCustomerApprovedModal] = React.useState(false);
    const [showAuditInProgressModal, setShowAuditInProgressModal] = React.useState(false);
    const [showTechnicalReviewModal, setShowTechnicalReviewModal] = React.useState(false);
    const [showFinalDecisionModal, setShowFinalDecisionModal] = React.useState(false);
    const [showCompletedModal, setShowCompletedModal] = React.useState(false);
    const [showClosedModal, setShowClosedModal] = React.useState(false);
    const [showAbortedModal, setShowAbortedModal] = React.useState(false);
    const [showResetToDraftModal, setShowResetToDraftModal] = React.useState(false);
    
    const [invoiceCountryList, setInvoiceCountryList] = React.useState([
        {crmValue: "saudi", countryValue: "Saudi Arabia", countryLabel: "Saudi Arabia"},
        {crmValue: "saudi", countryValue: "UAE", countryLabel: "UAE"},
        {crmValue: "saudi", countryValue: "sri_lanka", countryLabel: "Sri Lanka"},
        {crmValue: "egypt", countryValue: "egypt", countryLabel: "Conformity Assurance"},
        {crmValue: "egypt", countryValue: "egypt_tuv", countryLabel: "Branch of TUV"},
        {crmValue: "china", countryValue: "china", countryLabel: "China"},
        {crmValue: "india", countryValue: "india", countryLabel: "India"},
        {crmValue: "india", countryValue: "srilanka", countryLabel: "Sri Lanka"}
    ]);

    React.useEffect(() => {
	    dispatch(isoRequestActions.loadIsoRequest(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        setSelectedReporter([]);
        setSelectedCustomer([]);
        
        if(fbo) {
            reset(fbo.IsoRequest);
            setSelectedManDayStandardFBO(fbo.manDayStandardFBO);

            if(fbo.reporter != null) {
                setSelectedReporter([fbo.reporter]);
            }

            if(fbo.customer != null) {
                setSelectedCustomer([fbo.customer]);
            }
        }
        
        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onChangeDocument = (isProduct) => {
        setRefreshAction(!refreshAction);
    }

    const getFormattedDate = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy");
        }
        return "";
    }

    const getFormattedDateTime = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy H:i");
        }
        return "";
    }

    const onIsoRequestChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.isoRequest[name] = value;
        }
        else if(value === false) {
            copy_fboState.isoRequest[name] = false;
        }
        else {
            copy_fboState.isoRequest[name] = null;
        }

        if(name === "crmName" || name === "crmCountry") {
            copy_fboState.isoRequest["customerId"] = null;
            setSelectedCustomer([]);
        }

        if(name === "certificateIssueDate") {
            if(value) {
                copy_fboState.isoRequest["certificateExpiryDate"] = moment(value).add(1,'y').subtract(1, 'days');
            }
            else {
                copy_fboState.isoRequest["certificateExpiryDate"] = null;
            }
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onReporterChange = (item) => {
        setSelectedReporter(item);
        
        if(item.length > 0) {
            onIsoRequestChange("reporterUserId", item[0].id.toString());
        }
        else {
            onIsoRequestChange("reporterUserId", null);
        }
    }

    const isCustomerReadonly = () => {
        if(fboState && (fboState.isoRequest.crmInvoiceId || fboState.isoRequest.lineId)) {
            return true;
        }

        return false;
    }

    const onCustomerChange = (item) => {
        setSelectedCustomer(item);
        
        if(item.length > 0) {
            onIsoRequestChange("customerId", item[0].id.toString());
        }
        else {
            onIsoRequestChange("customerId", null);
        }
    }

    const onSaveIsoRequest = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(isoRequestActions.saveIsoRequest(fboState, selectedStatus, currentMessage, false, navigate));
        }

        onCloseModalClick();
    }

    const onCancelIsoRequest = () => {
        setFBOState(fbo);

        if(fbo.reporter != null) {
            setSelectedReporter([fbo.reporter]);
        }
        else {
            setSelectedReporter([]);
        }

        if(fbo.customer != null) {
            setSelectedCustomer([fbo.customer]);
        }
        else {
            setSelectedCustomer([]);
        }

        setIsDirty(false);
        clearErrors();
    }
    
    const onManDayStandardChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        var manDayStandardFBO = fbo.manDayStandardFBOList.find(item => item.manDayStandard.id == value);
        setSelectedManDayStandardFBO(manDayStandardFBO);

        copy_fboState.isoRequest[name] = value;
        copy_fboState.isoRequest["manDayStandardConfigurationId"] = null;
        copy_fboState.isoRequest["complexity"] = null;
        copy_fboState.isoRequest["complexityValue"] = null;

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onManDayStandardNumberOfPersonnelChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        copy_fboState.isoRequest[name] = value
        copy_fboState.isoRequest["complexity"] = null;
        copy_fboState.isoRequest["complexityValue"] = null;

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onManDayStandardComplexityChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        var complexityValue = null;

        if (selectedManDayStandardFBO.manDayStandardConfigurationList != null){
            var selectedManDayStandardConfiguration = selectedManDayStandardFBO.manDayStandardConfigurationList.find(item => item.id == fboState.isoRequest.manDayStandardConfigurationId);
            if (selectedManDayStandardConfiguration != null){
                if (value === 'Low') {
                    complexityValue = selectedManDayStandardConfiguration.complexityLow;
                } else if (value === 'Medium') {
                    complexityValue = selectedManDayStandardConfiguration.complexityMedium;
                } else if (value === 'High') {
                    complexityValue = selectedManDayStandardConfiguration.complexityHigh;
                }
            }
        }
        
        copy_fboState.isoRequest[name] = value
        copy_fboState.isoRequest["complexityValue"] = complexityValue;

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onLocationChange = (index, name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            copy_fboState.isoRequest.locations[index][name] = value;
        }
        else if(value === false) {
            copy_fboState.isoRequest.locations[index][name] = false;
        }
        else {
            copy_fboState.isoRequest.locations[index][name] = null;
        }

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onLocationAdd = () => {
        var copy_fboState = createDeepClone(fboState);
        var copy_location = createDeepClone(fboState.location);
        
        copy_fboState.isoRequest.locations.push(copy_location);
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onAssignMeTicket = async () => {
        dispatch(isoRequestActions.assignMeIsoRequest(fboState.isoRequest.id, user.id, true, navigate));
        onCloseModalClick();
    }

    const onAssignMeTicketClick = () => {
        setShowAssignMeModal(true);
    }

    const onSubmitIsoRequetClick = () => {
        setSelectedStatus("New");
        setCurrentMessage("");
        setShowSubmitModal(true);
    }

    const onSalesActionIsoRequetClick = () => {
        setSelectedStatus("Sales Action");
        setCurrentMessage("");
        setShowSalesActionModal(true);
    }

    const onCalculateManDaysIsoRequetClick = () => {
        setSelectedStatus("Calculate Man Days");
        setCurrentMessage("");
        setShowCalculateManDaysModal(true);
    }

    const onSalesSupportIsoRequetClick = () => {
        setSelectedStatus("Sales Support");
        setCurrentMessage("");
        setShowSalesSupportModal(true);
    }

    const onCustomerApprovedIsoRequetClick = () => {
        setSelectedStatus("Customer Approved");
        setCurrentMessage("");
        setShowCustomerApprovedModal(true);
    }

    const onAuditInProgressIsoRequetClick = () => {
        setSelectedStatus("Audit In Progress");
        setCurrentMessage("");
        setShowAuditInProgressModal(true);
    }

    const onTechnicalReviewIsoRequetClick = () => {
        setSelectedStatus("Technical Review");
        setCurrentMessage("");
        setShowTechnicalReviewModal(true);
    }

    const onFinalDecisionIsoRequetClick = () => {
        setSelectedStatus("Final Decision");
        setCurrentMessage("");
        setShowFinalDecisionModal(true);
    }

    const onCompletedIsoRequetClick = () => {
        setSelectedStatus("Completed");
        setCurrentMessage("");
        setShowCompletedModal(true);
    }

    const onClosedIsoRequetClick = () => {
        setSelectedStatus("Closed");
        setCurrentMessage("");
        setShowClosedModal(true);
    }

    const onAbortedIsoRequetClick = () => {
        setSelectedStatus("Aborted");
        setCurrentMessage("");
        setShowAbortedModal(true);
    }

    const onResetToDraftIsoRequetClick = () => {
        setSelectedStatus("Draft");
        setCurrentMessage("");
        setShowResetToDraftModal(true);
    }

    const onCloseModalClick = () => {
        setSelectedStatus("");
        setCurrentMessage("");

        setShowAssignMeModal(false);
        setShowSubmitModal(false);
        setShowSalesActionModal(false);
        setShowCalculateManDaysModal(false);
        setShowSalesSupportModal(false);
        setShowCustomerApprovedModal(false);
        setShowAuditInProgressModal(false);
        setShowTechnicalReviewModal(false);
        setShowFinalDecisionModal(false);
        setShowCompletedModal(false);
        setShowClosedModal(false);
        setShowAbortedModal(false);
        setShowResetToDraftModal(false);
    }

    const UserRenderer = (results, menuProps, props) => (
        <Menu id="menuUserLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Name</div>
                    <div className="col">Email</div>
                    <div className="col">Employee ID</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.fullName || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.email || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.employeeId || ''}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Edit ISO Ticket"} />
                    <Row>
                        <Col lg={9}>
                            <CardLoader loading={loading} />

                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">ISO Ticket</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            {fboState && fboState.canSave &&
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveIsoRequest}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save ISO Ticket
                                            </button>
                                            }
                                            {fboState && fboState.canSave &&
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelIsoRequest}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                            }
                                            {fboState && fboState.canAssignMe &&
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading}
                                                onClick={() => onAssignMeTicketClick()}
                                            >
                                                <i className="ri-user-follow-line label-icon align-bottom"></i> Assign Me ISO Ticket
                                            </button>
                                            }
                                            {fboState && fboState.canSubmit &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onSubmitIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Submit
                                            </button>
                                            }
                                            {fboState && fboState.canSalesAction &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onSalesActionIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Sales Action
                                            </button>
                                            }
                                            {fboState && fboState.canCalculateManDays &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onCalculateManDaysIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Calculate Man Days
                                            </button>
                                            }
                                            {fboState && fboState.canSalesSupport &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onSalesSupportIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Sales Support
                                            </button>
                                            }
                                            {fboState && fboState.canCustomerApproved &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onCustomerApprovedIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Customer Approved
                                            </button>
                                            }
                                            {fboState && fboState.canAuditInProgress &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onAuditInProgressIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Send to Audit
                                            </button>
                                            }
                                            {fboState && fboState.canTechnicalReview &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onTechnicalReviewIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Technical Review
                                            </button>
                                            }
                                            {fboState && fboState.canFinalDecision &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onFinalDecisionIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Final Decision
                                            </button>
                                            }
                                            {fboState && fboState.canCompleted &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onCompletedIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Complete
                                            </button>
                                            }
                                            {fboState && fboState.canClosed &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onClosedIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Close
                                            </button>
                                            }
                                            {fboState && fboState.canAborted &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onAbortedIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Abort
                                            </button>
                                            }
                                            {fboState && fboState.canResetToDraft &&
                                            <button className="btn btn-secondary btn-label"
                                                disabled={loading}
                                                onClick={() => onResetToDraftIsoRequetClick()}
                                            >
                                                <i className="ri-check-double-line label-icon align-bottom"></i> Reset To Draft
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Ticket #</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.isoRequest.requestNumber) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Status</Label>
                                            <input className="form-control" readOnly={true}
                                                value={(fboState && fboState.isoRequest.status) || ""}
                                            />
                                        </Col>
                                        {fboState && fboState.showReporter &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Allow Access To <span className="text-primary" style={{fontSize:12}}><i>(Ticket will be shared with other user)</i></span></Label>
                                            {fboState && fboState.canEditReporter &&
                                            <div className="input-group">
                                                <Lookup
                                                    clearButton 
                                                    allowNew ={false}
                                                    id ="fldReporter" 
                                                    url = {`GetGroupUserLookup?isReporter=1`}
                                                    defaultSearch={(fboState && fboState.isoRequest.reporterUserId) || ""}
                                                    placeholder= "Select Reporter..."
                                                    labelKey= {option => `${option.fullName}`}
                                                    selected = {selectedReporter}
                                                    onChange = {(item) => onReporterChange(item)}
                                                    renderMenu = {UserRenderer}
                                                />
                                            </div>
                                            }
                                            {fboState && !fboState.canEditReporter &&
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedReporter.length > 0 && selectedReporter[0].fullName) || ""}
                                            />
                                            }
                                        </Col>
                                        }
                                        {fboState && fboState.showDocumentUser &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Document Controller</Label>
                                            <select className="form-select" disabled={fboState && fboState.canEditDocumentUser ? false : true}
                                                value={(fboState && fboState.isoRequest.documentUserId) || ""}
                                                onChange={e => onIsoRequestChange("documentUserId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.documentUserList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        }
                                        {fboState && fboState.showAuditUser &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Auditor</Label>
                                            <select className="form-select" disabled={fboState && fboState.canEditAuditUser ? false : true}
                                                value={(fboState && fboState.isoRequest.auditUserId) || ""}
                                                onChange={e => onIsoRequestChange("auditUserId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.auditUserList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        }
                                        {fboState && fboState.showTechnicalUser &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Technical Reviewer</Label>
                                            <select className="form-select" disabled={fboState && fboState.canEditTechnicalUser ? false : true}
                                                value={(fboState && fboState.isoRequest.technicalUserId) || ""}
                                                onChange={e => onIsoRequestChange("technicalUserId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.technicalUserList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        }
                                        {fboState && fboState.showDecisionUser &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Decision Maker</Label>
                                            <select className="form-select" disabled={fboState && fboState.canEditDecisionUser ? false : true}
                                                value={(fboState && fboState.isoRequest.decisionUserId) || ""}
                                                onChange={e => onIsoRequestChange("decisionUserId", e.target.value)}
                                            >
                                                <option value={0}>...</option>
                                                {fboState && fboState.decisionUserList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.fullName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        }
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Remarks</Label>
                                            <input className="form-control" placeholder="optional..."
                                                value={(fboState && fboState.isoRequest.description) || ""}
                                                onChange={e => onIsoRequestChange("description", e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {fboState && fboState.showCustomer &&
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Customer Information</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">CRM Name</Label>
                                            <select className="form-select" disabled={isCustomerReadonly()}
                                                value={(fboState && fboState.isoRequest.crmName) || ""}
                                                onChange={e => onIsoRequestChange("crmName", e.target.value)}
                                            >
                                                <option value="">...</option>
                                                {fboState && fboState.countryList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.code}>{item.description}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Invoice Country</Label>
                                            <select className="form-select" disabled={isCustomerReadonly()}
                                                value={(fboState && fboState.isoRequest.crmCountry) || ""}
                                                onChange={e => onIsoRequestChange("crmCountry", e.target.value)}
                                            >
                                                <option value="">...</option>
                                                {fboState && invoiceCountryList.filter(x => x.crmValue == fboState.isoRequest.crmName).map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.countryValue}>{item.countryLabel}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>&nbsp;</Col>
                                        {isCustomerReadonly() &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Customer Code</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].code + ' - ' + selectedCustomer[0].crmCountry) || ""}
                                            />
                                        </Col>
                                        }
                                        {!isCustomerReadonly() &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Customer Code</Label>
                                            <CustomerLookup
                                                id ="fldCustomerLookup2"
                                                crmName={(fboState && fboState.isoRequest.crmName) || ""}
                                                selected = {selectedCustomer}
                                                onChange = {(item) => onCustomerChange(item)}
                                            />
                                        </Col>
                                        }
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Organization Name</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].organizationName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Contact Name</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].fullName) || ""}
                                            />
                                        </Col>
                                        <Col xxl={12} md={12}>
                                            <Label className="form-label">Address</Label>
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].fullAddress) || ""}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showInvoice &&
                            <Card className="mb-0 card-light">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setInvoiceInformationCollapsed(!invoiceInformationCollapsed)}>
                                        {invoiceInformationCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                                        {!invoiceInformationCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                                        Invoice Information ({invoiceInformationCollapsed ? "Expand" : "Collapse"})
                                    </h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                                    <Collapse isOpen={!invoiceInformationCollapsed} className="collapse">
                                        <Row className="gy-2">
                                            <Col xxl={3} md={6}>
                                                <Label className="form-label">CRM Invoice #</Label>
                                                <input className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.crmInvoiceNumber) || ""}
                                                />
                                            </Col>
                                            <Col xxl={3} md={6}>
                                                <Label className="form-label">Tax Invoice #</Label>
                                                <input className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.taxInvoiceNumber) || ""}
                                                />
                                            </Col>
                                            <Col xxl={3} md={6}>
                                                <Label className="form-label">Reference #</Label>
                                                <input className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.referenceNumber) || ""}
                                                />
                                            </Col>
                                            <Col xxl={3} md={6}>
                                                <Label className="form-label">Currency</Label>
                                                <select className="form-select" disabled={true}
                                                    value={(fboState && fboState.isoRequest.currency) || ""}
                                                >
                                                    <option value={""}>...</option>
                                                    <option value="AED">UAE Dirham</option>
                                                    <option value="CNY">China Yuan</option>
                                                    <option value="EUR">Euro</option>
                                                    <option value="USD">USA Dollar</option>
                                                    <option value="LKR">Sri Lanka Rupee</option>
                                                    <option value="EGP">Egypt Pound</option>
                                                    <option value="SAR">Saudi Riyal</option>
                                                    <option value="INR">India Rupee</option>
                                                </select>
                                            </Col>
                                                                                        
                                            {fboState && fbo.isoRequest.revenueLines.length > 0 &&
                                            <Fragment>
                                                <Col xxl={4} md={12}>
                                                    <Label className="form-label">Product</Label>
                                                </Col>
                                                <Col xxl={2} md={12}>
                                                    <Label className="form-label">Fee</Label>
                                                </Col>
                                                <Col xxl={2} md={12}>
                                                    <Label className="form-label">VAT</Label>
                                                </Col>
                                                <Col xxl={2} md={12}>
                                                    <Label className="form-label">Total</Label>
                                                </Col>
                                                <Col xxl={2} md={12}>
                                                    <Label className="form-label">Date</Label>
                                                </Col>
                                                {fboState && fboState.isoRequest.revenueLines.map(function(item, index){
                                                    return (
                                                        <Fragment key={index}>
                                                            <Col xxl={4} md={12}>
                                                                <input className="form-control" readOnly={true} value={item.productName || ""} />
                                                            </Col>
                                                            <Col xxl={2} md={12}>
                                                                <input className="form-control" readOnly={true} value={item.fee || ""} />
                                                            </Col>
                                                            <Col xxl={2} md={12}>
                                                                <input className="form-control" readOnly={true} value={item.vat || ""} />
                                                            </Col>
                                                            <Col xxl={2} md={12}>
                                                                <input className="form-control" readOnly={true} value={item.total || ""} />
                                                            </Col>
                                                            <Col xxl={2} md={12}>
                                                                <MyDateInput value={item.isoDate} />
                                                            </Col>
                                                        </Fragment>
                                                    );
                                                })}
                                                <Col xxl={8} md={12}>&nbsp;</Col>
                                                <Col xxl={2} md={12}>
                                                    <input className="form-control" readOnly={true} value={fboState.isoRequest.revenueTotal || ""} />
                                                </Col>
                                                <Col xxl={2} md={12}>&nbsp;</Col>
                                            </Fragment>
                                            }
                                        </Row>
                                    </Collapse>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showManDay &&
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Man Day Calculation</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={6} md={6}>
                                            <Label className="form-label">Man Day Standard{fboState && fboState.manDayRequired && <span className="required">*</span>}</Label>
                                            <select 
                                                className="form-select" disabled={fboState && fboState.canEditManDay ? false : true}
                                                value={(fboState && fboState.isoRequest.manDayStandardId) || ""}
                                                onChange={e => onManDayStandardChange("manDayStandardId", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                {fboState && fboState.manDayStandardFBOList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.manDayStandard.id}>{item.manDayStandard.standardName}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">Number Of Personnel{fboState && fboState.manDayRequired && <span className="required">*</span>}</Label>
                                            <select 
                                                className="form-select" disabled={fboState && fboState.canEditManDay ? false : true}
                                                value={(fboState && fboState.isoRequest.manDayStandardConfigurationId) || ""}
                                                onChange={e => onManDayStandardNumberOfPersonnelChange("manDayStandardConfigurationId", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                {selectedManDayStandardFBO && selectedManDayStandardFBO.manDayStandardConfigurationList 
                                                    && selectedManDayStandardFBO.manDayStandardConfigurationList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.minPersonnel} - {item.maxPersonnel}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">Complexity{fboState && fboState.manDayRequired && <span className="required">*</span>}</Label>
                                            <select 
                                                className="form-select" disabled={fboState && fboState.canEditManDay ? false : true}
                                                value={(fboState && fboState.isoRequest.complexity) || ""}
                                                onChange={e => onManDayStandardComplexityChange("complexity", e.target.value)}
                                            >
                                                <option value="">...</option>
                                                <option key="1" value="Low">Low</option>
                                                <option key="2" value="Medium">Medium</option>
                                                <option key="3" value="High">High</option>   
                                                
                                            </select>
                                        </Col>
                                        <Col xxl={2} md={6}>
                                            <Label className="form-label">Man Day(s)</Label>
                                                <input className="form-control" readOnly={true}
                                                    value={(fboState && fboState.isoRequest.complexityValue) || ""}
                                                />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showLocation &&
                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Audit Locations{fboState && fboState.locationRequired && <span className="required">*</span>}</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            {fboState && fboState.canEditLocation &&
                                            <button className="btn btn-primary btn-label"
                                                onClick={() => onLocationAdd()}
                                            >
                                                <i className="ri-add-line label-icon align-bottom"></i> Add Location
                                            </button>
                                            }
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={3} md={12}>
                                            <Label className="form-label">Location Name</Label>
                                        </Col>
                                        <Col xxl={6} md={12}>
                                            <Label className="form-label">Address</Label>
                                        </Col>
                                        <Col xxl={2} md={12}>
                                            <Label className="form-label">Audit Date / Time</Label>
                                        </Col>
                                        {/* <Col xxl={1} md={12}>
                                            <Label className="form-label">Approved</Label>
                                        </Col> */}
                                        {fboState && fboState.isoRequest.locations.length === 0 && <Col xxl={12} md={12}><Label className="form-label">No Locations added.</Label></Col>}
                                        {fboState && fboState.isoRequest.locations.map(function(item, index){
                                            return (
                                                <Fragment key={index}>
                                                    <Col xxl={3} md={12}>
                                                        <input className="form-control" placeholder="location name..." readOnly={fboState && fboState.canEditLocation ? false : true}
                                                            value={item.locationName || ""}
                                                            onChange={e => onLocationChange(index, "locationName", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={6} md={12}>
                                                        <input className="form-control" placeholder="location address..." readOnly={fboState && fboState.canEditLocation ? false : true}
                                                            value={item.locationAddress || ""}
                                                            onChange={e => onLocationChange(index, "locationAddress", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={2} md={12}>
                                                        {fboState && fboState.canEditLocation &&
                                                        <Flatpickr className="form-control"
                                                            value={getFormattedDateTime(item.auditDate) || ""}
                                                            onChange={([date]) => onLocationChange(index, "auditDate", date)}
                                                            options={{
                                                                enableTime: true,
                                                                dateFormat: "m/d/Y H:i"
                                                            }}
                                                        />
                                                        }
                                                        {fboState && !fboState.canEditLocation &&
                                                        <input className="form-control" readOnly={true}
                                                            defaultValue={(getFormattedDateTime(item.auditDate)) || ""}
                                                        />
                                                        }
                                                    </Col>
                                                    {/* <Col xxl={1} md={12}>
                                                        <div className="form-check pt-2">
                                                            <input type="checkbox" className="form-check-input"
                                                                checked={item.approved}
                                                                onChange={e => onLocationChange(index, "approved", e.target.checked)}
                                                            />
                                                        </div>
                                                    </Col> */}
                                                </Fragment>
                                            );
                                        })}
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.showCertificate &&
                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Certificate Information</h5>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={3} md={6}>
                                            <Label className="form-label">Certificate #{fboState && fboState.certificateRequired && <span className="required">*</span>}</Label>
                                            <input className="form-control" readOnly={!(fboState && fboState.canEditCertificate)}
                                                value={(fboState && fboState.isoRequest.certificateNumber) || ""}
                                                onChange={e => onIsoRequestChange("certificateNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <Label className="form-label">Certificate Issue Date{fboState && fboState.certificateRequired && <span className="required">*</span>}</Label>
                                            {fboState && fboState.canEditCertificate &&
                                            <Flatpickr className="form-control"
                                                value={(fboState && getFormattedDate(fboState.isoRequest.certificateIssueDate)) || ""}
                                                onChange={([date]) => onIsoRequestChange("certificateIssueDate", date)}
                                                options={{
                                                    dateFormat: "m/d/Y"
                                                }}
                                            />
                                            }
                                            {fboState && !fboState.canEditCertificate &&
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(fboState && getFormattedDate(fboState.isoRequest.certificateIssueDate)) || ""}
                                            />
                                            }
                                        </Col>
                                        <Col xxl={3} md={6}>
                                            <Label className="form-label">Certificate Expiry Date{fboState && fboState.certificateRequired && <span className="required">*</span>}</Label>
                                            {fboState && fboState.canEditCertificate &&
                                            <Flatpickr className="form-control"
                                                value={(fboState && getFormattedDate(fboState.isoRequest.certificateExpiryDate)) || ""}
                                                onChange={([date]) => onIsoRequestChange("certificateExpiryDate", date)}
                                                options={{
                                                    dateFormat: "m/d/Y"
                                                }}
                                            />
                                            }
                                            {fboState && !fboState.canEditCertificate &&
                                            <input className="form-control" readOnly={true}
                                                defaultValue={(fboState && getFormattedDate(fboState.isoRequest.certificateExpiryDate)) || ""}
                                            />
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            }
                            {fboState && fboState.isoRequest.id > 0 &&
                                <ServiceDocumentGrid serviceId={-1} refId={fboState.isoRequest.id} refName={"ISO_REQUEST"} parentId={fboState.isoRequest.id} parentName={"ISO_REQUEST"} isProduct={false} collapsed={"false"} canChange={fboState.canSave} canChangeCertificate={fboState.canAddCertificate} onChange={onChangeDocument} />
                            }

                            {fboState && fboState.isoRequest.id > 0 &&
                                <CommentGrid refId={fboState.isoRequest.id} refName="ISO_REQUEST" onCommentAdd={reloadAction} />
                            }
                        </Col>
                        
                        {fboState && fboState.isoRequest.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.isoRequest.id} name={"ISO_REQUEST"} refreshAction={refreshAction} />
                        </Col>
                        }

                        <ConfirmModal message={"Are you sure you want to Assign the ISO Ticket?"} show={showAssignMeModal} onCloseClick={onCloseModalClick} onYesClick={onAssignMeTicket} />
                        <ConfirmModal message={"Are you sure you want to \"Submit\" the ISO Ticket?"} show={showSubmitModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Sales Action\" the ISO Ticket?"} show={showSalesActionModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Calculate Man Days\" the ISO Ticket?"} show={showCalculateManDaysModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Sales Support\" the ISO Ticket?"} show={showSalesSupportModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Customer Approved\" the ISO Ticket?"} show={showCustomerApprovedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Audit In Progress\" the ISO Ticket?"} show={showAuditInProgressModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Technical Review\" the ISO Ticket?"} show={showTechnicalReviewModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Final Decision\" the ISO Ticket?"} show={showFinalDecisionModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Completed\" the ISO Ticket?"} show={showCompletedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Closed\" the ISO Ticket?"} show={showClosedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Aborted\" the ISO Ticket?"} show={showAbortedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                        <ConfirmModal message={"Are you sure you want to \"Reset To Draft\" the ISO Ticket?"} show={showResetToDraftModal} onCloseClick={onCloseModalClick} onYesClick={onSaveIsoRequest} />
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default IsoRequestEditScreen;