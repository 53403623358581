import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { AUDIT_SEARCH_START_LOADING, AUDIT_SEARCH_END_LOADING, AUDIT_SEARCH_LOADED, SET_AUDIT_FILTER } from "../constants/AuditConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        table_name: "",
        primary_key: ""
    }
};

const AuditReducer = (state = initialState, action) => {
    switch(action.type) {
        case AUDIT_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case AUDIT_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case AUDIT_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_AUDIT_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        default:
            return state;
    }
}

export default AuditReducer;