import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "../constants/CommonConstants";

const initialState = {
    successMessage: "",
    errorMessage: ""
};

const CommonReducer = (state = initialState, action) => {
    switch(action.type) {
        case SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload
            };
        case ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            };
        default:
            return state;
    }
}

export default CommonReducer;