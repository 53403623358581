import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "../constants/CommonConstants";

export function setSuccessMessage(message) {
    return async (dispatch) => {
        
        await dispatch({
            type: SUCCESS_MESSAGE,
            payload: message
        });

    };
}

export function setErrorMessage(message) {
    return async (dispatch) => {
        
        await dispatch({
            type: ERROR_MESSAGE,
            payload: message
        });

    };
}