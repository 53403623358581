import { VENDOR_SEARCH_START_LOADING, VENDOR_SEARCH_END_LOADING, VENDOR_SEARCH_LOADED, SET_VENDOR_FILTER } from "../constants/VendorConstants";
import { VENDOR_START_LOADING, VENDOR_END_LOADING, VENDOR_LOADED } from "../constants/VendorConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadVendorSearch(filters, type, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: VENDOR_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Vendor/GetPaged", filters[type], null);
            
            if(data.success) {
                await dispatch({
                    type: VENDOR_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: VENDOR_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: VENDOR_SEARCH_END_LOADING });
        }
    };
}

export function setVendorFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_VENDOR_FILTER,
            payload: filter
        });
    };
}

export function loadVendor(id, type, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: VENDOR_START_LOADING });

        try {

            var skipValidation = false;
            var data = await getApiData("Vendor/GetVendorFBO", { id, type, skipValidation });
            
            if(data.success) {
                await dispatch({
                    type: VENDOR_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: VENDOR_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: VENDOR_END_LOADING });
        }
    };
}

export function saveVendor(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: VENDOR_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var skipValidation = false;
            var data = await postApiJsonData("Vendor/SaveVendorFBO", { skipValidation }, fbo);
            
            if(data.success) {
                if(isAdd) {
                    if(data.responseEntities[0].vendor.isImporter) {
                        navigate('/vendor-edit/' + data.responseEntities[0].vendor.id);
                        dispatch(commonActions.setSuccessMessage("Importer Created Successfully!"));
                    }
                    else if(data.responseEntities[0].vendor.isExporter) {
                        navigate('/vendor-edit/' + data.responseEntities[0].vendor.id);
                    dispatch(commonActions.setSuccessMessage("Exporter Created Successfully!"));
                    }
                    else if(data.responseEntities[0].vendor.isManufacturer) {
                        navigate('/vendor-edit/' + data.responseEntities[0].vendor.id);
                        dispatch(commonActions.setSuccessMessage("Manufacturer Created Successfully!"));
                    }
                }
                else {
                    await dispatch({
                        type: VENDOR_LOADED,
                        payload: data.responseEntities[0]
                    });

                    if(data.responseEntities[0].vendor.isImporter) {
                        dispatch(commonActions.setSuccessMessage("Importer Saved Successfully!"));
                    }
                    else if(data.responseEntities[0].vendor.isExporter) {
                        dispatch(commonActions.setSuccessMessage("Exporter Saved Successfully!"));
                    }
                    else if(data.responseEntities[0].vendor.isManufacturer) {
                        dispatch(commonActions.setSuccessMessage("Manufacturer Saved Successfully!"));
                    }
                }
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: VENDOR_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: VENDOR_END_LOADING });
        }
    };
}

export async function loadVendorAsync(id, type) {
    var vendorData = {
        fbo: null
        , error: null
    };

    try {

        var skipValidation = true;
        var data = await getApiData("Vendor/GetVendorFBO", { id, type, skipValidation });
        
        if(data.success) {
            vendorData.fbo= data.responseEntities[0];
        }
        else {
            vendorData.error = data.errorDesc;
        }
    } 
    catch (error) {
        vendorData.error = error;
    }

    return vendorData;
}

export async function saveVendorAsync(fbo) {
    var vendorData = {
        fbo: null
        , error: null
    };

    try {

        var skipValidation = true;
        var data = await postApiJsonData("Vendor/SaveVendorFBO", { skipValidation }, fbo);
        
        if(data.success) {
            vendorData.fbo= data.responseEntities[0];
        }
        else {
            vendorData.error = data.errorDesc;
        }
    } 
    catch (error) {
        vendorData.error = error;
    }

    return vendorData;
}