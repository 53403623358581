import React from "react";

const Badge = (props) => {
    if(props.checked) {
        return(
            <span className="badge badge-secondary badge-user" style={{backgroundColor: props.color, display: "inline-block"}}><span>&nbsp;{props.checked}&nbsp;</span></span>
        );
    }
    else {
        return(
            <span className="badge badge-secondary badge-user" style={{backgroundColor: props.color, display: (props.label ? "block" : "inline-block")}}>{props.label || <span>&nbsp;&nbsp;&nbsp;</span>}</span>
        );
    }
};

export default Badge;