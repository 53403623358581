//Search
export const GROUP_SEARCH_START_LOADING = "GROUP_SEARCH_START_LOADING";
export const GROUP_SEARCH_END_LOADING = "GROUP_SEARCH_END_LOADING";
export const GROUP_SEARCH_LOADED = "GROUP_SEARCH_LOADED";
export const SET_GROUP_FILTER = "SET_GROUP_FILTER";

//Edit
export const GROUP_START_LOADING = "GROUP_START_LOADING";
export const GROUP_END_LOADING = "GROUP_END_LOADING";
export const GROUP_LOADED = "GROUP_LOADED";
