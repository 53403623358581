import React, { Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Table, Row, Label, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import BreadCrumb from '../components/Common/BreadCrumb';
import dateFormat from "dateformat";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from "moment";
import logoApproved from "../assets/images/approved.jpeg";
import logoRejected from "../assets/images/rejected.jpeg";

import { createDeepClone, api } from "../utils/index";

import CardLoader from "../components/CardLoader";
import Lookup from "../components/Lookup";
import CustomerLookup from "../components/CustomerLookup";
import { Highlighter, Menu, MenuItem, } from 'react-bootstrap-typeahead';
import ConfirmModal from "../components/ConfirmModal";
import ConfirmModalAdvanced from "../components/ConfirmModalAdvanced";
import ErrorModal from "../components/ErrorModal";
import InfoModal from "../components/InfoModal";
import TicketGrid from "../components/TicketGrid";
import TicketProductGrid from "../components/TicketProductGrid";
import TicketProductPhotoGrid from "../components/TicketProductPhotoGrid";
import ServiceDocumentGrid from "../components/ServiceDocumentGrid";
import CommentGrid from "../components/CommentGrid";
import ActionList from "../components/ActionList";
import MyNumberRenderer from "../components/MyNumberRenderer";
import ComplianceLabel from "../components/ComplianceLabel";

import FormReviewSASO from "../components/FormReviewSASO";
import FormEvaluationSASO from "../components/FormEvaluationSASO";
import FormDecisionSASO from "../components/FormDecisionSASO";

import FormReviewSFDA from "../components/FormReviewSFDA";
import FormEvaluationSFDA from "../components/FormEvaluationSFDA";
import FormDecisionSFDA from "../components/FormDecisionSFDA";

import VendorScreen from "./VendorScreen";

import { useDispatch, useSelector } from "react-redux";
import * as ticketActions from "../actions/TicketActions";
import * as commonActions from "../actions/CommonActions";

const TicketEditScreen  = () => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.ticket.loadingFBO);
    const loadingTicketInvoice = useSelector((state) => state.ticket.loadingTicketInvoice);
    const loadingTicketInvoiceContracts = useSelector((state) => state.ticket.loadingTicketInvoiceContracts);
    const loadingTicketInvoiceProducts = useSelector((state) => state.ticket.loadingTicketInvoiceProducts);
    
    const user = useSelector((state) => state.login.user);
    const fbo = useSelector((state) => state.ticket.fbo);
    const ticketInvoice = useSelector((state) => state.ticket.ticketInvoice);
    const ticketInvoiceContractList = useSelector((state) => state.ticket.ticketInvoiceContractList);
    const ticketInvoiceProductList = useSelector((state) => state.ticket.ticketInvoiceProductList);
    const serviceList = useSelector((state) => state.login.data.serviceList);
    const technicalRegulationList = useSelector((state) => state.login.data.technicalRegulationList);
    const caiList = useSelector((state) => state.login.data.caiList);

    const [invoiceCountryList, setInvoiceCountryList] = React.useState([
        {crmValue: "saudi", countryValue: "Saudi Arabia", countryLabel: "Saudi Arabia"},
        {crmValue: "saudi", countryValue: "UAE", countryLabel: "UAE"},
        {crmValue: "saudi", countryValue: "sri_lanka", countryLabel: "Sri Lanka"},
        {crmValue: "egypt", countryValue: "egypt", countryLabel: "Conformity Assurance"},
        {crmValue: "egypt", countryValue: "egypt_tuv", countryLabel: "Branch of TUV"},
        {crmValue: "china", countryValue: "china", countryLabel: "China"},
        {crmValue: "india", countryValue: "india", countryLabel: "India"},
        {crmValue: "india", countryValue: "srilanka", countryLabel: "Sri Lanka"}
    ]);

    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [loadingLocal, setLoadingLocal] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([]);
    const [refreshAction, setRefreshAction] = React.useState(false);
    const [isContainer, setIsContainer] = React.useState(false);
    const [isChild, setIsChild] = React.useState(false);
    const [isTicket, setIsTicket] = React.useState(false);
    const [isFactoryAudit, setIsFactoryAudit] = React.useState(false);
    const [selectedTechnicalRegulation, setSelectedTechnicalRegulation] = React.useState([]);
    const [selectedReporter, setSelectedReporter] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState([]);
    const [selectedManufacturer, setSelectedManufacturer] = React.useState([]);
    const [selectedImporter, setSelectedImporter] = React.useState([]);
    const [selectedExporter, setSelectedExporter] = React.useState([]);
    const [selectedReviewer, setSelectedReviewer] = React.useState([]);
    const [selectedSasoRequestNumber, setSelectedSasoRequestNumber] = React.useState([]);
    const [selectedStatus, setSelectedStatus] = React.useState("");
    const [currentMessage, setCurrentMessage] = React.useState("");
    const [productNameQuery, setProductNameQuery] = React.useState("");
    const [showAssignMeModal, setShowAssignMeModal] = React.useState(false);
    const [showSubmitModal, setShowSubmitModal] = React.useState(false);
    const [showSubmitConfirmModal, setShowSubmitConfirmModal] = React.useState(false);
    const [showReSubmitModal, setShowReSubmitModal] = React.useState(false);
    const [showAcceptModal, setShowAcceptModal] = React.useState(false);
    const [showRejectModal, setShowRejectModal] = React.useState(false);
    const [showSalesActionModal, setShowSalesActionModal] = React.useState(false);
    const [showPdReviewModal, setShowPdReviewModal] = React.useState(false);
    const [showApprovePdModal, setShowApprovePdModal] = React.useState(false);
    const [showRejectPdModal, setShowRejectPdModal] = React.useState(false);
    const [showFinalDecisionModal, setShowFinalDecisionModal] = React.useState(false);
    const [showSamplingModal, setShowSamplingModal] = React.useState(false);
    const [showScheduledModal, setShowScheduledModal] = React.useState(false);
    const [showWaitingCorrectiveActionModal, setShowWaitingCorrectiveActionModal] = React.useState(false);
    const [showPhase1EvaluationModal, setShowPhase1EvaluationModal] = React.useState(false);
    const [showInspectionModal, setShowInspectionModal] = React.useState(false);
    const [showPhase2EvaluationModal, setShowPhase2EvaluationModal] = React.useState(false);
    const [showStoppedModal, setShowStoppedModal] = React.useState(false);
    const [showAbortModal, setShowAbortModal] = React.useState(false);
    const [showCompleteModal, setShowCompleteModal] = React.useState(false);
    const [showCloseModal, setShowCloseModal] = React.useState(false);
    const [showResetToDraftModal, setShowResetToDraftModal] = React.useState(false);
    const [showNotifyCreateInvoiceModal, setShowNotifyCreateInvoiceModal] = React.useState(false);
    const [showNotifySadadPaymentModal, setShowNotifySadadPaymentModal] = React.useState(false);
    const [showNotifySadadPaidModal, setShowNotifySadadPaidModal] = React.useState(false);
    const [showNCRModal, setShowNCRModal] = React.useState(false);
    const [showVerifiedModal, setShowVerifiedModal] = React.useState(false);
    const [showSendMailForm3Modal, setShowSendMailForm3Modal] = React.useState(false);
    const [loadingSendMailForm3, setLoadingSendMailForm3] = React.useState(false);
    const [sendMailTo, setSendMailTo] = React.useState("");
    const [sendMailCc, setSendMailCc] = React.useState("");
    const [sendMailSubject, setSendMailSubject] = React.useState("");
    const [sendMailMessage, setSendMailMessage] = React.useState("");
    const [sendMailToValid, setSendMailToValid] = React.useState(false);
    const [sendMailCcValid, setSendMailCcValid] = React.useState(false);
    const [sendMailSubjectValid, setSendMailSubjectValid] = React.useState(false);
    const [sendMailMessageValid, setSendMailMessageValid] = React.useState(false);
    const [showInvoiceCreateModal, setShowInvoiceCreateModal] = React.useState(false);
    const [showInvoiceViewModal, setShowInvoiceViewModal] = React.useState(false);
    const [showContractModal, setShowContractModal] = React.useState(false);
    const [certificateTypeValid, setCertificateTypeValid] = React.useState(true);
    const [certificationTypeValid, setCertificationTypeValid] = React.useState(true);
    const [testReportValid, setTestReportValid] = React.useState(true);
    const [clientAddressValid, setClientAddressValid] = React.useState(true);
    const [clientContactValid, setClientContactValid] = React.useState(true);
    const [productLocationValid, setProductLocationValid] = React.useState(true);
    const [requestSubTypeValid, setRequestSubTypeValid] = React.useState(true);
    const [regionValid, setRegionValid] = React.useState(true);
    const [listPriceValid, setListPriceValid] = React.useState(true);
    const [currencyValid, setCurrencyValid] = React.useState(true);
    const [customerValid, setCustomerValid] = React.useState(true);
    const [crmNameValid, setCrmNameValid] = React.useState(true);
    const [crmCountryValid, setCrmCountryValid] = React.useState(true);
    const [sasoRequestNumberValid, setSasoRequestNumberValid] = React.useState(true);
    const [factoryAuditDateValid, setFactoryAuditDateValid] = React.useState(true);
    const [factoryAuditLocationValid, setFactoryAuditLocationValid] = React.useState(true);
    const [factoryAuditContactNameValid, setFactoryAuditContactNameValid] = React.useState(true);
    const [factoryAuditContactEmailValid, setFactoryAuditContactEmailValid] = React.useState(true);
    const [factoryAuditContactPhoneValid, setFactoryAuditContactPhoneValid] = React.useState(true);
    const [factoryAuditReviewerUserIdValid, setFactoryAuditReviewerUserIdValid] = React.useState(true);
    const [factoryAuditInspectionModeValid, setFactoryAuditInspectionModeValid] = React.useState(true);
    const [showCustomerError, setShowCustomerError] = React.useState(false);
    const [showDecisionMakerError, setShowDecisionMakerError] = React.useState(false);
    const [showDecisionMakerDuplicateError, setShowDecisionMakerDuplicateError] = React.useState(false);
    const [showInvoiceError, setShowInvoiceError] = React.useState(false);
    const [showVendorFormModal, setShowVendorFormModal] = React.useState(false);
    const [vendorFormType, setVendorFormType] = React.useState("");

    const [invoiceInformationCollapsed, setInvoiceInformationCollapsed] = React.useState(true);
    const [checklistFormCollapsed, setChecklistFormCollapsed] = React.useState(true);
    const [reviewFormCollapsed, setReviewFormCollapsed] = React.useState(true);
    const [evaluationFormCollapsed, setEvaluationFormCollapsed] = React.useState(true);
    const [decisionFormCollapsed, setDecisionFormCollapsed] = React.useState(true);

    React.useEffect(() => {
	    dispatch(ticketActions.loadTicket(id, navigate));
    }, [id]);
    
    React.useEffect(() => {
        setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        setSelectedTechnicalRegulation([]);
        setSelectedReporter([]);
        setSelectedCustomer([]);
        setSelectedManufacturer([]);
        setSelectedImporter([]);
        setSelectedExporter([]);
        setSelectedReviewer([]);
        setSelectedSasoRequestNumber([]);

        setIsContainer(false);
        setIsChild(false);
        setIsTicket(false);
        setIsFactoryAudit(false);

        if(fbo) {
            if(fbo.ticket.ticketType === "CONTAINER") {
                setIsContainer(true);
            }
            else if(fbo.ticket.ticketType === "CHILD") {
                setIsChild(true);
            }
            else if(fbo.ticket.ticketType === "TICKET") {
                setIsTicket(true);
            }

            if(fbo.ticket.serviceId === 4) {
                setIsFactoryAudit(true);
            }
        }
        
        if(fbo != null) {
            reset(fbo.ticket);
            
            if(fbo.reporter != null) {
                setSelectedReporter([fbo.reporter]);
            }
            
            if(fbo.customer != null) {
                setSelectedCustomer([fbo.customer]);
            }

            if(fbo.manufacturer != null) {
                setSelectedManufacturer([fbo.manufacturer]);
            }

            if(fbo.importer != null) {
                setSelectedImporter([fbo.importer]);
            }

            if(fbo.exporter != null) {
                setSelectedExporter([fbo.exporter]);
            }

            if(fbo.reviewer != null) {
                setSelectedReviewer([fbo.reviewer]);
            }

            var sasoItems = [];
            var sasoCounter = 0;

            if(fbo.ticket.sasoRequestNumber) {
                sasoCounter = 1;

                fbo.ticket.sasoRequestNumber.split(",").forEach(element => {
                    sasoItems.push(element);
                    sasoCounter += 1;
                });
            }

            while(sasoCounter < fbo.ticket.quantity) {
                sasoItems.push("");
                sasoCounter += 1;
            }

            setSelectedSasoRequestNumber(sasoItems);

            if(!fbo.canCreateInvoice) {
                setShowInvoiceCreateModal(false);
            }

            if(fbo.ticket.technicalRegulation && fbo.ticket.technicalRegulation !== "") {
                setSelectedTechnicalRegulation([{description: fbo.ticket.technicalRegulation}]);
            }
        }

        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onChangeDocument = (isProduct) => {
        if(isProduct) {
            if(fboState && fboState.ticket.isProductCertificateAvailable) {
                dispatch(ticketActions.loadProductChecklist(fboState, fboState.ticket.id, "ProductChecklist", "SASO"));
            }
        }

        setRefreshAction(!refreshAction);
    }

    const getFormattedDate = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy");
        }
        return "";
    }

    const getFormattedDateTime = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy H:i");
        }
        return "";
    }

    const isCustomerReadonly = () => {
        if(isChild || (fboState && (fboState.ticket.crmInvoiceId || fboState.ticket.lineId))) {
            return true;
        }

        return false;
    }

    const isPricingReadonly = () => {
        if(fboState && fboState.ticket.productType === "PRODUCT") {
            return false;
        }
        
        return true;
    }

    const isDecisionMakerExternal = () => {
        if(fboState && fboState.ticket.region === "Europe" && (fboState.ticket.requestSubType === "Type 1a" || fboState.ticket.requestSubType === "Type 3")) {
            return true;
        }
        
        return false;
    }

    const getGroupList = () => {
        var arrGroupList = [];

        if(fboState) {
            if(fboState.ticket.isCosmetic) {
                fboState.groupList.forEach((group, i) => {
                    if(group.isCosmetic) {
                        arrGroupList.push(group);
                    }
                });
            }
            else {
                fboState.groupList.forEach((group, i) => {
                    arrGroupList.push(group);
                });
            }
        }

        return arrGroupList;
    }

    const getDecisionMakerList = () => {
        var arrDecisionMaker = [];

        if(fboState) {
            if(fboState.ticket.isFactoryAudit) {
                if(fboState.pdGroupUserList) {
                    var currentUserId = fboState.ticket.decisionUserId;
                    var found = false;
        
                    fboState.pdGroupUserList.forEach((pdGroupUser, i) => {
                        if(pdGroupUser.groupId == fboState.ticket.pdGroupId) {
                            arrDecisionMaker.push(pdGroupUser);
        
                            if(pdGroupUser.userId === currentUserId) {
                                found = true;
                            }
                        }
                    });
                            
                    if(found === false) {
                        fboState.pdGroupUserList.forEach((pdGroupUser, i) => {
                            if(pdGroupUser.groupId == fboState.ticket.pdGroupId && found === false) {
                                if(pdGroupUser.userId === currentUserId) {
                                    arrDecisionMaker.push(pdGroupUser)
                                    found = true;
                                }
                            }
                        });
                    }
                }
            }
            else {
                if(fboState.groupUserList) {
                    var currentUserId = fboState.ticket.decisionUserId;
                    var found = false;
        
                    if(isDecisionMakerExternal()) {
                        fboState.groupUserList.forEach((groupUser, i) => {
                            if(groupUser.groupId == fboState.ticket.groupId && groupUser.isDecisionMakerExternal === true) {
                                arrDecisionMaker.push(groupUser);
            
                                if(groupUser.userId === currentUserId) {
                                    found = true;
                                }
                            }
                        });
                    }
                    else{
                        fboState.groupUserList.forEach((groupUser, i) => {
                            if(groupUser.groupId == fboState.ticket.groupId && groupUser.isDecisionMaker === true) {
                                arrDecisionMaker.push(groupUser);
            
                                if(groupUser.userId === currentUserId) {
                                    found = true;
                                }
                            }
                        });
                    }
        
                    if(found === false) {
                        fboState.groupUserList.forEach((groupUser, i) => {
                            if(groupUser.groupId == fboState.ticket.groupId && found === false) {
                                if(groupUser.userId === currentUserId) {
                                    arrDecisionMaker.push(groupUser)
                                    found = true;
                                }
                            }
                        });
                    }
                }
            }
        }
        
        return arrDecisionMaker;
    }

    const onTicketChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            if(name === "nonRegulatedCount") {
                value = value.replace(/[^0-9]/g, '');
            }
            else if(name === "fob" || name === "listPrice" || name === "discountValue") {
                value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
            }

            copy_fboState.ticket[name] = value;
        }
        else if(value === false) {
            copy_fboState.ticket[name] = false;
        }
        else {
            copy_fboState.ticket[name] = null;
        }

        if(name === "listPrice" || name === "discountType" || name === "discountValue" || name === "vatPercentage") {
            copy_fboState = calculationTicketPricing(copy_fboState);
        }

        if(name === "crmName" || name === "crmCountry") {
            copy_fboState.ticket["customerId"] = null;
            setSelectedCustomer([]);
        }

        if(name === "customerId") {
            if(copy_fboState.ticket["productType"] === "CONTRACT") {
                copy_fboState.ticket["productId"] = "";
                copy_fboState.ticket["productType"] = "PRODUCT";
                copy_fboState.ticket["productName"] = "";
                copy_fboState.ticket["productDescription"] = "";
                copy_fboState.ticket["currency"] = "";
                copy_fboState.ticket["listPrice"] = 0;
                copy_fboState.ticket["discountValue"] = 0;
                copy_fboState.ticket["discountType"] = "Percentage";
                copy_fboState.ticket["vatPercentage"] = 0;

                copy_fboState = calculationTicketPricing(copy_fboState);
            }
        }

        if(name === "certificateType") {
            setCertificateTypeValid(true);
        }

        if(name === "certificationType") {
            setCertificationTypeValid(true);
        }

        if(name === "isValidTestReport") {
            setTestReportValid(true);
        }

        if(name === "clientAddress") {
            setClientAddressValid(true);
        }

        if(name === "clientContact") {
            setClientContactValid(true);
        }

        if(name === "productLocation") {
            setProductLocationValid(true);
        }

        if(name === "requestSubType") {
            setRequestSubTypeValid(true);
        }

        if(name === "region") {
            setRegionValid(true);
        }

        if(name === "certificateIssueDate") {
            if(value) {
                if(fboState.ticket.isFactoryAudit) {
                    copy_fboState.ticket["certificateExpiryDate"] = moment(value).add(3,'y').subtract(1, 'days');    
                }
                else {
                    copy_fboState.ticket["certificateExpiryDate"] = moment(value).add(1,'y').subtract(1, 'days');
                }
            }
            else {
                copy_fboState.ticket["certificateExpiryDate"] = null;
            }
        }

        setListPriceValid(true);
        setCurrencyValid(true);
        setCustomerValid(true);
        setCrmNameValid(true);
        setCrmCountryValid(true);
        setSasoRequestNumberValid(true);
        setFactoryAuditDateValid(true);
        setFactoryAuditLocationValid(true);
        setFactoryAuditContactNameValid(true);
        setFactoryAuditContactEmailValid(true);
        setFactoryAuditContactPhoneValid(true);
        setFactoryAuditReviewerUserIdValid(true);
        setFactoryAuditInspectionModeValid(true);
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const calculationTicketPricing = (copy_fboState) => {
        var quantity = copy_fboState.ticket["quantity"] || 0;
        var listPrice = copy_fboState.ticket["listPrice"] || 0;
        var listPriceTotal = 0;
        var discountType = copy_fboState.ticket["discountType"] || "Percentage";
        var discountValue = copy_fboState.ticket["discountValue"] || 0;
        var vatPercentage = copy_fboState.ticket["vatPercentage"] || 0;
        var discountAmount = 0;
        var salePrice = 0;
        var vat = 0;
        var price = 0;
        
        if(listPrice > 0) {
            listPriceTotal = (quantity * listPrice).toFixed(4);

            if(discountValue === 0) {
                discountAmount = 0;
            }
            else {
                if(discountType === "Percentage") {
                    discountAmount = ((discountValue/100) * listPriceTotal).toFixed(4);
                }
                else {
                    discountAmount = discountValue;
                }
            }

            salePrice = (listPriceTotal - discountAmount).toFixed(4);

            if(vatPercentage === 0) {
                vat = 0;
            }
            else {
                vat = ((vatPercentage/100) * salePrice).toFixed(4);
            }

            price = (parseFloat(salePrice) + parseFloat(vat)).toFixed(4);

            copy_fboState.ticket["listPriceTotal"] = listPriceTotal;
            copy_fboState.ticket["discountAmount"] = discountAmount;
            copy_fboState.ticket["salePrice"] = salePrice;
            copy_fboState.ticket["vat"] = vat;
            copy_fboState.ticket["price"] = price;
        }
        else {
            copy_fboState.ticket["listPriceTotal"] = 0;
            copy_fboState.ticket["discountAmount"] = 0;
            copy_fboState.ticket["salePrice"] = 0;
            copy_fboState.ticket["vat"] = 0;
            copy_fboState.ticket["price"] = 0;
        }

        return copy_fboState;
    }

    const onTechnicalRegulationChange = (item) => {
        setSelectedTechnicalRegulation(item);
        
        if(item.length > 0) {
            onTicketChange("technicalRegulation", item[0].description.toString());
        }
        else {
            onTicketChange("technicalRegulation", null);
        }
    }

    const onPcocNumberChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            copy_fboState.pcocNumbers[index] = value;
        }
        else {
            copy_fboState.pcocNumbers[index] = null;
        }

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onPcocNumberAdd = (index) => {
        var copy_fboState = createDeepClone(fboState);
        
        copy_fboState.pcocNumbers.push("");
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onGroupChange = (value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value == 0) {
            copy_fboState.ticket["groupId"] = null;
            copy_fboState.ticket["groupUserId"] = null;
            copy_fboState.ticket["decisionUserId"] = null;
        }
        else {
            copy_fboState.ticket["groupId"] = value;
            copy_fboState.ticket["groupUserId"] = null;
            copy_fboState.ticket["decisionUserId"] = null;
        }
                
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onGroupUserChange = (value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value == 0) {
            copy_fboState.ticket["groupUserId"] = null;
        }
        else {
            copy_fboState.ticket["groupUserId"] = value;
        }
                
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onDecisionMakerChange = (value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value == 0) {
            copy_fboState.ticket["decisionUserId"] = null;
        }
        else {
            copy_fboState.ticket["decisionUserId"] = value;
        }
                
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onPdGroupChange = (value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value == 0) {
            copy_fboState.ticket["pdGroupId"] = null;
            copy_fboState.ticket["pdGroupUserId"] = null;
        }
        else {
            copy_fboState.ticket["pdGroupId"] = value;
            copy_fboState.ticket["pdGroupUserId"] = null;
        }
                
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onPdGroupUserChange = (value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value == 0) {
            copy_fboState.ticket["pdGroupUserId"] = null;
        }
        else {
            copy_fboState.ticket["pdGroupUserId"] = value;
        }
                
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onReporterChange = (item) => {
        setSelectedReporter(item);
        
        if(item.length > 0) {
            onTicketChange("reporterUserId", item[0].id.toString());
        }
        else {
            onTicketChange("reporterUserId", null);
        }
    }
    
    const onCustomerChange = (item) => {
        setSelectedCustomer(item);
        
        if(item.length > 0) {
            onTicketChange("customerId", item[0].id.toString());
        }
        else {
            onTicketChange("customerId", null);
        }
    }

    const onManufacturerChange = (item) => {
        setSelectedManufacturer(item);
        var copy_fboState = createDeepClone(fboState);
        
        if(item.length > 0) {
            copy_fboState.ticket["manufacturerId"] = item[0].id.toString();
            
            if(fbo.ticket.isFactoryAudit) {
                copy_fboState.ticket["factoryAuditLocation"] = item[0].fullAddress;
                copy_fboState.ticket["factoryAuditContactName"] = item[0].contactName;
                copy_fboState.ticket["factoryAuditContactEmail"] = item[0].email;
                copy_fboState.ticket["factoryAuditContactPhone"] = item[0].telephoneNumber || item[0].mobileNumber;
            }
        }
        else {
            copy_fboState.ticket["manufacturerId"] = null;
        }

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onImporterChange = (item) => {
        setSelectedImporter(item);
        
        if(item.length > 0) {
            onTicketChange("importerId", item[0].id.toString());
        }
        else {
            onTicketChange("importerId", null);
        }
    }

    const onExporterChange = (item) => {
        setSelectedExporter(item);
        
        if(item.length > 0) {
            onTicketChange("exporterId", item[0].id.toString());
        }
        else {
            onTicketChange("exporterId", null);
        }
    }

    const onReviewerChange = (item) => {
        setSelectedReviewer(item);
        
        if(item.length > 0) {
            onTicketChange("factoryAuditReviewerUserId", item[0].id.toString());
        }
        else {
            onTicketChange("factoryAuditReviewerUserId", null);
        }
    }

    const onSasoRequestNumberChange = (index, value) => {
        const sasoItems = selectedSasoRequestNumber.map((item, i) => {
            if (i === index) {
                return value;
            } else {
                return item;
            }
        });
        
        setSelectedSasoRequestNumber(sasoItems);
        
        var values = [];

        selectedSasoRequestNumber.forEach((element, i) => {
            if (i === index) {
                if(value) {
                    values.push(value);
                }
            }
            else {
                if(element) {
                    values.push(element);
                }    
            }
        });

        if(values.length > 0) {
            onTicketChange("sasoRequestNumber", values.toString());
        }
        else {
            onTicketChange("sasoRequestNumber", null);
        }
    }

    const getFactoryAuditTypeValue = (fieldValue) => {
        var value = false;

        if(fboState && fboState.ticket.factoryAuditType && fboState.ticket.factoryAuditType === fieldValue) {
            value = true;
        }

        return value;
    }

    const setFactoryAuditTypeValue = (fieldValue) => {
        if(fboState) {
            var copy_fboState = createDeepClone(fboState);

            copy_fboState.ticket.factoryAuditType = fieldValue;
            copy_fboState.ticket.factoryAuditSubType = null;

            setFBOState(copy_fboState);
            setIsDirty(true);
        }
    }

    const getFactoryAuditSubTypeValue = (fieldValue, parentValue) => {
        var value = false;

        if(fboState && fboState.ticket.factoryAuditSubType && fboState.ticket.factoryAuditSubType === fieldValue && fboState.ticket.factoryAuditType === parentValue) {
            value = true;
        }

        return value;
    }

    const setFactoryAuditSubTypeValue = (fieldValue) => {
        if(fboState) {
            var copy_fboState = createDeepClone(fboState);

            copy_fboState.ticket.factoryAuditSubType = fieldValue;

            setFBOState(copy_fboState);
            setIsDirty(true);
        }
    }

    const isValidFieldValue = (formName, fieldName, exactValue) => {
        var value = "";

        if(fboState && fboState[formName] && fboState[formName].ticketFormData && fboState[formName].ticketFormData[fieldName] && fboState[formName].ticketFormData[fieldName].stringValue) {
            value = fboState[formName].ticketFormData[fieldName].stringValue;
        }

        if(exactValue && value && exactValue === value) {
            return true;
        }
        else if(value && !exactValue) {
            return true;
        }

        return false;
    }

    const getFieldValue = (formName, fieldName, fieldValue) => {
        var value = "";

        if(fboState && fboState[formName] && fboState[formName].ticketFormData && fboState[formName].ticketFormData[fieldName] && fboState[formName].ticketFormData[fieldName].stringValue) {
            value = fboState[formName].ticketFormData[fieldName].stringValue;
        }

        if(value === fieldValue) {
            return true;
        }

        return false;
    }

    const setFieldValue = (formName, fieldName, fieldValue, fieldChecked) => {
        if(fboState && fboState[formName] && fboState[formName].canEdit) {
            var copy_fboState = createDeepClone(fboState);

            if(copy_fboState && copy_fboState[formName] && copy_fboState[formName].ticketFormData && copy_fboState[formName].ticketFormData[fieldName]) {
                if(fieldChecked) {
                    copy_fboState[formName].ticketFormData[fieldName].stringValue = fieldValue;
                }
                else {
                    if(copy_fboState[formName].ticketFormData[fieldName].field.fieldType === "Checkbox") {
                       copy_fboState[formName].ticketFormData[fieldName].stringValue = "No";    
                    }
                    else {
                       copy_fboState[formName].ticketFormData[fieldName].stringValue = "";
                    }
                }
            }
    
            copy_fboState[formName].isModified = true;
    
            setFBOState(copy_fboState);
            setIsDirty(true);
        }
    }

    const getFieldValueText = (formName, fieldName) => {
        var value = "";

        if(fboState && fboState[formName] && fboState[formName].ticketFormData && fboState[formName].ticketFormData[fieldName] && fboState[formName].ticketFormData[fieldName].stringValue) {
            value = fboState[formName].ticketFormData[fieldName].stringValue;
        }

        return value;
    }

    const setFieldValueText = (formName, fieldName, fieldValue) => {
        if(fboState && fboState[formName] && fboState[formName].canEdit) {
            var copy_fboState = createDeepClone(fboState);

            if(copy_fboState && copy_fboState[formName] && copy_fboState[formName].ticketFormData && copy_fboState[formName].ticketFormData[fieldName]) {
                if(fieldValue) {
                    copy_fboState[formName].ticketFormData[fieldName].stringValue = fieldValue;
                }
                else {
                    copy_fboState[formName].ticketFormData[fieldName].stringValue = "";
                }
            }
    
            copy_fboState[formName].isModified = true;
    
            setFBOState(copy_fboState);
            setIsDirty(true);
        }
    }

    const setFieldValueNumber = (formName, fieldName, fieldValue) => {
        if(fboState && fboState[formName] && fboState[formName].canEdit) {
            var copy_fboState = createDeepClone(fboState);

            if(copy_fboState && copy_fboState[formName] && copy_fboState[formName].ticketFormData && copy_fboState[formName].ticketFormData[fieldName]) {
                if(fieldValue) {
                    copy_fboState[formName].ticketFormData[fieldName].stringValue = fieldValue.replace(/[^0-9]/g, '');
                }
                else {
                    copy_fboState[formName].ticketFormData[fieldName].stringValue = "";
                }
            }
    
            copy_fboState[formName].isModified = true;
    
            setFBOState(copy_fboState);
            setIsDirty(true);
        }
    }

    const getFieldValueDate = (formName, fieldName) => {
        var value = "";

        if(fboState && fboState[formName] && fboState[formName].ticketFormData && fboState[formName].ticketFormData[fieldName] && fboState[formName].ticketFormData[fieldName].stringValue) {
            value = fboState[formName].ticketFormData[fieldName].stringValue;
        }

        if(value && value.length > 0) {
            try{
                return getFormattedDate(value);
            }
            catch(error) {
                return value;
            }
            
        }

        return value;
    }

    const onSaveTicket = async () => {
        if(fboState.ticket.decisionUserId !== "" && fboState.ticket.decisionUserId !== null && fboState.ticket.groupUserId === fboState.ticket.decisionUserId) {
            setShowDecisionMakerDuplicateError(true);
        }
        else if(fboState.ticket.decisionUserId !== "" && fboState.ticket.decisionUserId !== null && fboState.ticket.pdGroupUserId === fboState.ticket.decisionUserId) {
            setShowDecisionMakerDuplicateError(true);
        }
        else {    
            const valid = await trigger();
            if(valid) {
                dispatch(ticketActions.saveTicket(fboState, selectedStatus, currentMessage, false, navigate));
            }
        }

        onCloseModalClick();
    }

    const onCancelTicket = () => {
        setFBOState(fbo);

        if(fbo.reporter != null) {
            setSelectedReporter([fbo.reporter]);
        }
        else {
            setSelectedReporter([]);
        }
        
        if(fbo.customer != null) {
            setSelectedCustomer([fbo.customer]);
        }
        else {
            setSelectedCustomer([]);
        }

        if(fbo.manufacturer != null) {
            setSelectedManufacturer([fbo.manufacturer]);
        }
        else {
            setSelectedManufacturer([]);
        }

        if(fbo.importer != null) {
            setSelectedImporter([fbo.importer]);
        }
        else {
            setSelectedImporter([]);
        }

        if(fbo.exporter != null) {
            setSelectedExporter([fbo.exporter]);
        }
        else {
            setSelectedExporter([]);
        }

        if(fbo.reviewer != null) {
            setSelectedReviewer([fbo.reviewer]);
        }
        else {
            setSelectedReviewer([]);
        }

        var sasoItems = [];
        var sasoCounter = 0;

        if(fbo.ticket.sasoRequestNumber) {
            sasoCounter = 1;
            
            fbo.ticket.sasoRequestNumber.split(",").forEach(element => {
                sasoItems.push(element);
                sasoCounter += 1;
            });
        }

        while(sasoCounter < fbo.ticket.quantity) {
            sasoItems.push("");
            sasoCounter += 1;
        }

        setSelectedSasoRequestNumber(sasoItems);

        setIsDirty(false);
        clearErrors();
    }

    const onAssignMeTicket = async () => {
        dispatch(ticketActions.assignMeTicket(fboState.ticket.id, user.id, true, navigate));
        onCloseModalClick();
    }

    const onAssignMeTicketClick = () => {
        setShowAssignMeModal(true);
    }

    const validateProductCertificateStatusChange = (status) => {
        var valid = true;

        if(fboState.ticket.isProductCertificateAvailable && (status === "New" || status === "ReSubmit" || status === "PD Review" || status === "Final Decision")) {
            if(!fboState.ticket.certificateType) {
                valid = false;
                dispatch(commonActions.setErrorMessage("Please select Request Type New/Renewal"));
                setCertificateTypeValid(false);
            }
            else if(!fboState.ticket.requestSubType) {
                valid = false;
                dispatch(commonActions.setErrorMessage("Please select type Type 1a/Type 3/Type 5"));
                setRequestSubTypeValid(false);
            }
            else if(!fboState.ticket.region) {
                valid = false;
                dispatch(commonActions.setErrorMessage("Please select Request Region"));
                setRegionValid(false);
            }
        }
        
        return valid;
    }

    const validateCosmeticStatusChange = (status) => {
        var valid = true;

        if(fboState.ticket.isCosmeticAvailable) {
            if(!fboState.ticket.certificateType) {
                valid = false;
                dispatch(commonActions.setErrorMessage("Please select Request Type New/Renewal"));
                setCertificateTypeValid(false);
            }
            else if(!fboState.ticket.certificationType) {
                valid = false;
                dispatch(commonActions.setErrorMessage("Please select Certification Type S-CoC/P-CoC"));
                setCertificationTypeValid(false);
            }
            else  if(!fboState.ticket.isValidTestReport) {
                valid = false;
                dispatch(commonActions.setErrorMessage("Please select Test Report Yes/No"));
                setTestReportValid(false);
            }
            else if(fboState.ticket.isValidTestReport === "No") {
                if(!fboState.ticket.clientAddress) {
                    valid = false;
                    dispatch(commonActions.setErrorMessage("Please input Client Address"));
                    setClientAddressValid(false);
                }
                else if(!fboState.ticket.clientContact) {
                    valid = false;
                    dispatch(commonActions.setErrorMessage("Please input Client Contact"));
                    setClientContactValid(false);
                }
                else if(!fboState.ticket.productLocation) {
                    valid = false;
                    dispatch(commonActions.setErrorMessage("Please input Product Location"));
                    setProductLocationValid(false);
                }
            }
        }
        
        return valid;
    }

    const validateFactoryAuditStatusChange = (status) => {
        var valid = true;

        if(fboState.ticket.isFactoryAuditAvailable && (status === "Scheduled" || status === "Final Decision")) {
            if(!fboState.ticket.factoryAuditType) {
                valid = false;
                dispatch(commonActions.setErrorMessage("Please select options in Document Check"));
            }
        }

        return valid;
    }

    const onSubmitTicketClick = async () => {
        if(fboState.ticket.isProductCertificateAvailable) {
            var valid = validateProductCertificateStatusChange("New");

            if(valid) {            
                setLoadingLocal(true);
                var errors = await ticketActions.validateTicketSubmission(fboState.productChecklistForm, fboState.ticket.id);
                setLoadingLocal(false);
        
                if(errors.length > 0) {
                    setErrorMessages(errors);
        
                    setSelectedStatus("New");
                    setCurrentMessage("");
                    setShowSubmitConfirmModal(true);
                }
                else {
                    setErrorMessages([]);

                    setSelectedStatus("New");
                    setCurrentMessage("");
                    setShowSubmitModal(true);
                }
            }
        }
        else if(fboState.ticket.isCosmeticAvailable) {
            var valid = validateCosmeticStatusChange();

            if(valid) {            
                setErrorMessages([]);

                setSelectedStatus("New");
                setCurrentMessage("");
                setShowSubmitModal(true);
            }
        }
        else {
            setErrorMessages([]);

            setSelectedStatus("New");
            setCurrentMessage("");
            setShowSubmitModal(true);
        }
    }

    const onReSubmitTicketClick = async () => {
        if(fboState.ticket.isProductCertificateAvailable) {
            var valid = validateProductCertificateStatusChange();

            if(valid) {
                setLoadingLocal(true);
                var errors = await ticketActions.validateTicketSubmission(fboState.productChecklistForm, fboState.ticket.id);
                setLoadingLocal(false);
        
                if(errors.length > 0) {
                    setErrorMessages(errors);
        
                    setSelectedStatus("ReSubmit");
                    setCurrentMessage("");
                    setShowSubmitConfirmModal(true);
                }
                else {
                    setErrorMessages([]);

                    setSelectedStatus("ReSubmit");
                    setCurrentMessage("");
                    setShowReSubmitModal(true);
                }
            }
        }
        else if(fboState.ticket.isCosmeticAvailable) {
            var valid = validateCosmeticStatusChange("ReSubmit");

            if(valid) {
                setErrorMessages([]);

                setSelectedStatus("ReSubmit");
                setCurrentMessage("");
                setShowReSubmitModal(true);
            }
        }
        else {
            setErrorMessages([]);

            setSelectedStatus("ReSubmit");
            setCurrentMessage("");
            setShowReSubmitModal(true);
        }
    }

    const onAcceptTicketClick = () => {
        setSelectedStatus("Accept");
        setCurrentMessage("");
        setShowAcceptModal(true);
    }

    const onRejectTicketClick = () => {
        setSelectedStatus("Reject");
        setCurrentMessage("");
        setShowRejectModal(true);
    }

    const onSalesActionTicketClick = () => {
        setSelectedStatus("Sales Action");
        setCurrentMessage("");
        setShowSalesActionModal(true);
    }

    const onPdReviewTicketClick = async () => {
        if(fboState.ticket.isProductCertificateAvailable) {
            var valid = validateProductCertificateStatusChange("PD Review")

            if(valid) {
                setLoadingLocal(true);
                var errors = await ticketActions.validateTicketSubmission(fboState.productChecklistForm, fboState.ticket.id);
                setLoadingLocal(false);
        
                if(errors.length > 0) {
                    setErrorMessages(errors);
        
                    setSelectedStatus("PD Review");
                    setCurrentMessage("");
                    setShowSubmitConfirmModal(true);
                }
                else {
                    setErrorMessages([]);

                    setSelectedStatus("PD Review");
                    setCurrentMessage("");
                    setShowPdReviewModal(true);
                }
            }
        }
        else if(fboState.ticket.isCosmeticAvailable) {
            var valid = validateCosmeticStatusChange()

            if(valid) {
                setErrorMessages([]);

                setSelectedStatus("PD Review");
                setCurrentMessage("");
                setShowPdReviewModal(true);
            }
        }
        else {
            setErrorMessages([]);

            setSelectedStatus("PD Review");
            setCurrentMessage("");
            setShowPdReviewModal(true);
        }
    }

    const onApprovePdTicketClick = () => {
        setSelectedStatus("ApprovePd");
        setCurrentMessage("");
        setShowApprovePdModal(true);
    }

    const onRejectPdTicketClick = () => {
        setSelectedStatus("RejectPd");
        setCurrentMessage("");
        setShowRejectPdModal(true);
    }

    const onFinalDecisionTicketClick = () => {
        if(fboState.ticket.isProductCertificateAvailable) {
            var valid = validateProductCertificateStatusChange("Final Decision")

            if(valid) {
                setErrorMessages([]);

                setSelectedStatus("Final Decision");
                setCurrentMessage("");
                setShowFinalDecisionModal(true);
            }
        }
        else if(fboState.ticket.isFactoryAuditAvailable) {
            var valid = validateFactoryAuditStatusChange("Final Decision")

            if(valid) {
                setErrorMessages([]);

                setSelectedStatus("Final Decision");
                setCurrentMessage("");
                setShowFinalDecisionModal(true);
            }
        }
        else if(fboState.ticket.isCosmeticAvailable) {
            var valid = validateCosmeticStatusChange()

            if(valid) {
                setErrorMessages([]);

                setSelectedStatus("Final Decision");
                setCurrentMessage("");
                setShowFinalDecisionModal(true);
            }
        }
        else {
            setErrorMessages([]);

            setSelectedStatus("Final Decision");
            setCurrentMessage("");
            setShowFinalDecisionModal(true);
        }
    }

    const onSamplingTicketClick = () => {
        if(fboState.ticket.isCosmeticAvailable) {
            var valid = validateCosmeticStatusChange();

            if(valid) {            
                setErrorMessages([]);

                setSelectedStatus("Sampling");
                setCurrentMessage("");
                setShowSamplingModal(true);
            }
        }
        else {
            setErrorMessages([]);

            setSelectedStatus("Sampling");
            setCurrentMessage("");
            setShowSamplingModal(true);
        }
    }

    const onScheduledTicketClick = () => {
        if(fbo.ticket.isFactoryAuditAvailable) {
            var valid = validateFactoryAuditStatusChange("Scheduled");
            
            if(valid) {
                setSelectedStatus("Scheduled");
                setCurrentMessage("");
                setShowScheduledModal(true);

                setFactoryAuditDateValid(true);
                setFactoryAuditLocationValid(true);
                setFactoryAuditContactNameValid(true);
                setFactoryAuditContactEmailValid(true);
                setFactoryAuditContactPhoneValid(true);
                setFactoryAuditReviewerUserIdValid(true);
                setFactoryAuditInspectionModeValid(true);
            }
        }
        else {
            setSelectedStatus("Scheduled");
            setCurrentMessage("");
            setShowScheduledModal(true);

            setFactoryAuditDateValid(true);
            setFactoryAuditLocationValid(true);
            setFactoryAuditContactNameValid(true);
            setFactoryAuditContactEmailValid(true);
            setFactoryAuditContactPhoneValid(true);
            setFactoryAuditReviewerUserIdValid(true);
            setFactoryAuditInspectionModeValid(true);
        }
    }

    const onHideScheduledModal = () => {
        setShowScheduledModal(false);
    }

    const onScheduledTicketSave = () => {
        var valid = true;
        
        var factoryAuditDate = fboState.ticket["factoryAuditDate"] || 0;
        var factoryAuditLocation = fboState.ticket["factoryAuditLocation"] || 0;
        var factoryAuditContactName = fboState.ticket["factoryAuditContactName"] || 0;
        var factoryAuditContactEmail = fboState.ticket["factoryAuditContactEmail"] || 0;
        var factoryAuditContactPhone = fboState.ticket["factoryAuditContactPhone"] || 0;
        var factoryAuditReviewerUserId = fboState.ticket["factoryAuditReviewerUserId"] || 0;
        var factoryAuditInspectionMode = fboState.ticket["factoryAuditInspectionMode"] || 0;

        if(factoryAuditDate === 0) {
            valid = false;
            setFactoryAuditDateValid(false);
        }
        else {
            setFactoryAuditDateValid(true);
        }

        if(factoryAuditLocation === 0) {
            valid = false;
            setFactoryAuditLocationValid(false);
        }
        else {
            setFactoryAuditLocationValid(true);
        }

        if(factoryAuditContactName === 0) {
            valid = false;
            setFactoryAuditContactNameValid(false);
        }
        else {
            setFactoryAuditContactNameValid(true);
        }

        if(factoryAuditContactEmail === 0) {
            valid = false;
            setFactoryAuditContactEmailValid(false);
        }
        else {
            setFactoryAuditContactEmailValid(true);
        }

        if(factoryAuditContactPhone === 0) {
            valid = false;
            setFactoryAuditContactPhoneValid(false);
        }
        else {
            setFactoryAuditContactPhoneValid(true);
        }

        if(factoryAuditReviewerUserId === 0) {
            valid = false;
            setFactoryAuditReviewerUserIdValid(false);
        }
        else {
            setFactoryAuditReviewerUserIdValid(true);
        }

        if(factoryAuditInspectionMode === 0) {
            valid = false;
            setFactoryAuditInspectionModeValid(false);
        }
        else {
            setFactoryAuditInspectionModeValid(true);
        }
        

        if(valid) {
            onSaveTicket();
        }
    }

    const onWaitingCorrectiveActionTicketClick = () => {
        setSelectedStatus("Waiting Corrective Action");
        setCurrentMessage("");
        setShowWaitingCorrectiveActionModal(true);
    }

    const onPhase1EvaluationTicketClick = () => {
        if(fboState.ticket.isCosmeticAvailable) {
            var valid = validateCosmeticStatusChange();

            if(valid) {            
                setErrorMessages([]);

                setSelectedStatus("Phase 1 Evaluation");
                setCurrentMessage("");
                setShowPhase1EvaluationModal(true);
            }
        }
        else {
            setErrorMessages([]);

            setSelectedStatus("Phase 1 Evaluation");
            setCurrentMessage("");
            setShowPhase1EvaluationModal(true);
        }
    }

    const onInspectionTicketClick = () => {
        if(fboState.ticket.isCosmeticAvailable) {
            var valid = validateCosmeticStatusChange();

            if(valid) {            
                setErrorMessages([]);

                setSelectedStatus("Inspection");
                setCurrentMessage("");
                setShowInspectionModal(true);
            }
        }
        else {
            setErrorMessages([]);

            setSelectedStatus("Inspection");
            setCurrentMessage("");
            setShowInspectionModal(true);
        }
    }

    const onPhase2EvaluationTicketClick = () => {
        if(fboState.ticket.isCosmeticAvailable) {
            var valid = validateCosmeticStatusChange();

            if(valid) {            
                setErrorMessages([]);

                setSelectedStatus("Phase 2 Evaluation");
                setCurrentMessage("");
                setShowPhase2EvaluationModal(true);
            }
        }
        else {
            setErrorMessages([]);

            setSelectedStatus("Phase 2 Evaluation");
            setCurrentMessage("");
            setShowPhase2EvaluationModal(true);
        }
    }

    const onStoppedTicketClick = () => {
        setSelectedStatus("Stopped");
        setCurrentMessage("");
        setShowStoppedModal(true);
    }

    const onAbortTicketClick = () => {
        setSelectedStatus("Aborted");
        setCurrentMessage("");
        setShowAbortModal(true);
    }

    const onCompleteTicketClick = () => {
        setSelectedStatus("Completed");
        setCurrentMessage("");
        setShowCompleteModal(true);
    }

    const onCloseTicketClick = () => {
        setSelectedStatus("Closed");
        setCurrentMessage("");
        setShowCloseModal(true);
    }

    const onResetToDraftTicketClick = () => {
        setSelectedStatus("Draft");
        setCurrentMessage("");
        setShowResetToDraftModal(true);
    }

    const onCloseModalClick = () => {
        setSelectedStatus("");
        setCurrentMessage("");

        setShowAssignMeModal(false);
        setShowSubmitModal(false);
        setShowSubmitConfirmModal(false);
        setShowReSubmitModal(false);
        setShowAcceptModal(false);
        setShowRejectModal(false);
        setShowSalesActionModal(false);
        setShowPdReviewModal(false);
        setShowApprovePdModal(false);
        setShowRejectPdModal(false);
        setShowFinalDecisionModal(false);
        setShowSamplingModal(false);
        setShowScheduledModal(false);
        setShowWaitingCorrectiveActionModal(false);
        setShowPhase1EvaluationModal(false);
        setShowInspectionModal(false);
        setShowPhase2EvaluationModal(false);
        setShowStoppedModal(false);
        setShowAbortModal(false);
        setShowCompleteModal(false);
        setShowCloseModal(false);
        setShowResetToDraftModal(false);
        setShowNotifyCreateInvoiceModal(false);
        setShowNotifySadadPaymentModal(false);
        setShowNotifySadadPaidModal(false);
        setShowNCRModal(false);
        setShowVerifiedModal(false);
    }

    const onSendMailForm3 = async () => {
        setSendMailTo(user.email);
        setSendMailCc("");
        setSendMailSubject("New Request # " + fboState.ticket.requestNumber);
        setSendMailMessage("Please review the attached documents to issue the certificate. Also, please fill in the attached decision form and send it back by reply to the same email.\r\n\r\nLooking forward to hearing from you.");

        setSendMailToValid(true);
        setSendMailCcValid(true);
        setSendMailSubjectValid(true);
        setSendMailMessageValid(true);


        setShowSendMailForm3Modal(true);
    }

    const hideSendMailForm3Modal = async () => {
        setShowSendMailForm3Modal(false);
    }

    const onSaveSendMailForm3 = async () => {        
        var valid = true;
        
        var mailTo = sendMailTo || 0;
        var mailCc = sendMailCc || 0;
        var mailSubject = sendMailSubject || 0;
        var mailMessage = sendMailMessage || 0;

        if(mailTo === 0 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mailTo)) {
            valid = false;
            setSendMailToValid(false);
        }
        else {
            setSendMailToValid(true);
        }

        if(mailCc === 0 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mailCc)) {
            //valid = false;
            //setSendMailCcValid(false);
        }
        else {
            //setSendMailCcValid(true);
        }

        if(mailSubject === 0) {
            valid = false;
            setSendMailSubjectValid(false);
        }
        else {
            setSendMailSubjectValid(true);
        }

        if(mailMessage === 0) {
            valid = false;
            setSendMailMessageValid(false);
        }
        else {
            setSendMailMessageValid(true);
        }

        if(valid) {
            setLoadingSendMailForm3(true);
            var error = await ticketActions.sendMailForm3(fboState.ticket.id, mailTo, mailCc, mailSubject, mailMessage);
            setLoadingSendMailForm3(false);

            if(error) {
                dispatch(commonActions.setErrorMessage(error));
            }
            else {
                dispatch(commonActions.setSuccessMessage("Mail Sent Successfully!"));

                setShowSendMailForm3Modal(false);
                dispatch(ticketActions.loadTicket(fboState.ticket.id, navigate));
            }
        }
    }

    const onNotifyInvoiceCreation = async () => {
        setShowNotifyCreateInvoiceModal(true);
    }

    const onSaveNotifyInvoiceCreation = async () => {
        setShowNotifyCreateInvoiceModal(false);

        setLoadingLocal(true);
        var data = await ticketActions.addTicketCreateInvoiceNotification(fboState.ticket.id);
        setLoadingLocal(false);

        if(data.success) {
            dispatch(commonActions.setSuccessMessage(data.successMessage));
            dispatch(ticketActions.loadTicket(fboState.ticket.id, navigate));
        }
        else{
            dispatch(commonActions.setErrorMessage(data.errorMessage));
        }
    }

    const onNotifySADADPayment = async () => {
        setShowNotifySadadPaymentModal(true);
    }

    const onSaveNotifySADADPayment = async () => {
        setShowNotifySadadPaymentModal(false);

        setLoadingLocal(true);
        var data = await ticketActions.addTicketSadadPaymentNotification(fboState.ticket.id);
        setLoadingLocal(false);

        if(data.success) {
            dispatch(commonActions.setSuccessMessage(data.successMessage));
            dispatch(ticketActions.loadTicket(fboState.ticket.id, navigate));
        }
        else{
            dispatch(commonActions.setErrorMessage(data.errorMessage));
        }
    }

    const onNotifySADADPaid = async () => {
        setShowNotifySadadPaidModal(true);
    }

    const onSaveNotifySADADPaid = async () => {
        setShowNotifySadadPaidModal(false);

        setLoadingLocal(true);
        var data = await ticketActions.addTicketSadadPaidNotification(fboState.ticket.id);
        setLoadingLocal(false);

        if(data.success) {
            dispatch(commonActions.setSuccessMessage(data.successMessage));
            dispatch(ticketActions.loadTicket(fboState.ticket.id, navigate));
        }
        else{
            dispatch(commonActions.setErrorMessage(data.errorMessage));
        }
    }

    const onNCR = async () => {
        setShowNCRModal(true);
    }

    const onSaveNCR = async () => {
        setShowNCRModal(false);

        setLoadingLocal(true);
        var data = await ticketActions.updateTicketComplianceState(fboState.ticket.id, "NCR", currentMessage);
        setLoadingLocal(false);

        if(data.success) {
            dispatch(commonActions.setSuccessMessage(data.successMessage));
            dispatch(ticketActions.loadTicket(fboState.ticket.id, navigate));
        }
        else{
            dispatch(commonActions.setErrorMessage(data.errorMessage));
        }

        setCurrentMessage("");
        reloadAction();
    }

    const onVerified = async () => {
        setShowVerifiedModal(true);
    }

    const onSaveVerified = async () => {
        setShowVerifiedModal(false);

        setLoadingLocal(true);
        var data = await ticketActions.updateTicketComplianceState(fboState.ticket.id, "Verified", currentMessage);
        setLoadingLocal(false);

        if(data.success) {
            dispatch(commonActions.setSuccessMessage(data.successMessage));
            dispatch(ticketActions.loadTicket(fboState.ticket.id, navigate));
        }
        else{
            dispatch(commonActions.setErrorMessage(data.errorMessage));
        }

        setCurrentMessage("");
        reloadAction();
    }

    const onCreateInvoice = async () => {
        if(fboState.ticket.lineId) {
            setShowInvoiceError(true);
        }
        else {
            setListPriceValid(true);
            setCurrencyValid(true);
            setCustomerValid(true);
            setCrmNameValid(true);
            setCrmCountryValid(true);
            setSasoRequestNumberValid(true);

            setShowInvoiceCreateModal(true);
        }
    }

    const hideInvoiceCreateModal = async () => {
        setShowInvoiceCreateModal(false);
    }

    const onSaveCreateInvoice = async (saveOnly) => {        
        var valid = true;
        
        var listPrice = fboState.ticket["listPrice"] || 0;
        var currency = fboState.ticket["currency"] || 0;
        var customerId = fboState.ticket["customerId"] || 0;
        var crmName = fboState.ticket["crmName"] || 0;
        var crmCountry = fboState.ticket["crmCountry"] || 0;

        if(listPrice === 0) {
            valid = false;
            setListPriceValid(false);
        }
        else {
            setListPriceValid(true);
        }

        if(currency === 0) {
            valid = false;
            setCurrencyValid(false);
        }
        else {
            setCurrencyValid(true);
        }

        if(customerId === 0) {
            valid = false;
            setCustomerValid(false);
        }
        else {
            setCustomerValid(true);
        }

        if(crmName === 0) {
            valid = false;
            setCrmNameValid(false);
        }
        else {
            setCrmNameValid(true);
        }

        if(crmCountry === 0) {
            valid = false;
            setCrmCountryValid(false);
        }
        else {
            setCrmCountryValid(true);
        }

        setSasoRequestNumberValid(true);

        if(fboState && fboState.ticket.isProductCertificate) {
            selectedSasoRequestNumber.forEach((element, i) => {
                if (element && element.length > 0) {
                    //do nothing
                }
                else {
                    valid = false;
                    setSasoRequestNumberValid(false);
                }
            });
        }

        if(valid) {
            if(saveOnly) {
                dispatch(ticketActions.saveTicket(fboState, selectedStatus, currentMessage, false, navigate));
            }
            else{
                if(isDirty) {
                    dispatch(ticketActions.saveTicket(fboState, selectedStatus, currentMessage, true, navigate));
                }
                else {
                    dispatch(ticketActions.createInvoice(fboState.ticket.id, navigate));
                }
            }
        }
    }

    const onViewInvoice = async () => {
        dispatch(ticketActions.viewInvoice(fboState.ticket.id, navigate));
        setShowInvoiceViewModal(true);
    }

    const hideInvoiceViewModal = async () => {
        setShowInvoiceViewModal(false);
    }

    const onViewContract = async () => {
        if(fboState && fboState.ticket.customerId && fboState.ticket.serviceId) {
            dispatch(ticketActions.loadTicketInvoiceContract(fboState.ticket.crmName, fboState.ticket.customerId, fboState.ticket.serviceId));
            dispatch(ticketActions.loadTicketInvoiceProduct(fboState.ticket.crmName, fboState.ticket.customerId, fboState.ticket.serviceId, productNameQuery));
            setShowContractModal(true);
        }
        else {
            setShowCustomerError(true);
        }
    }

    const hideContractModal = async () => {
        setShowContractModal(false);
    }

    const onSearchInvoiceProduct = async () => {
        dispatch(ticketActions.loadTicketInvoiceProduct(fboState.ticket.crmName, fboState.ticket.customerId, fboState.ticket.serviceId, productNameQuery));
    }

    const onContractSelect = (item) => {
        if(item) {
            var copy_fboState = createDeepClone(fboState);
            copy_fboState.ticket["productId"] = item.productId;
            copy_fboState.ticket["productType"] = item.productType;
            copy_fboState.ticket["productName"] = item.productName;
            copy_fboState.ticket["productDescription"] = item.productDescription;
            copy_fboState.ticket["currency"] = item.currency;
            copy_fboState.ticket["listPrice"] = item.listPrice;
            copy_fboState.ticket["discountValue"] = item.discountValue;
            copy_fboState.ticket["discountType"] = item.discountType;
            copy_fboState.ticket["vatPercentage"] = item.vatPercentage;

            copy_fboState = calculationTicketPricing(copy_fboState);

            setFBOState(copy_fboState);
            setIsDirty(true);
        }

        hideContractModal();
    }

    const onViewVendorForm = async (type) => {
        setVendorFormType(type);
        setShowVendorFormModal(true);
    }

    const hideVendorFormModal = async (vendor) => {
        setShowVendorFormModal(false);
        if(vendor) {
            if(vendorFormType === "manufacturer") {
                setSelectedManufacturer([vendor]);
                onManufacturerChange([vendor]);
            }
            else if(vendorFormType === "importer") {
                setSelectedImporter([vendor]);
                onImporterChange([vendor]);
            }
            else if(vendorFormType === "exporter") {
                setSelectedExporter([vendor]);
                onExporterChange([vendor]);
            }
        }
    }

    const UserRenderer = (results, menuProps, props) => (
        <Menu id="menuUserLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Name</div>
                    <div className="col">Email</div>
                    <div className="col">Employee ID</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.fullName || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.email || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.employeeId || ''}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    const VendorRenderer = (results, menuProps, props) => (
        <Menu id="menuVendorLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Organization Name</div>
                    <div className="col">Contact Name</div>
                    <div className="col">Email</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.organizationName || ""}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.contactName || ""}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.email || ""}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={`Edit Ticket ${isContainer ? "Container" : ""}`} />
                    <Row>
                        <Col lg={9}>
                            <Card className="mb-0">
                                <CardLoader loading={loading || loadingLocal} />

                                <CardBody style={{margin:0, padding:0}}>
                                    <Card className="mb-0">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">
                                                Ticket {isContainer ? "Container" : ""}
                                                {fboState && fboState.ticket.ticketType === "CHILD" && 
                                                <span style={{fontSize:"70%"}} className="ms-1">
                                                    <Link to={`/request-edit/${fboState && fboState.ticket.parentId}`}>
                                                        (view container)
                                                    </Link>
                                                </span>
                                                }
                                                {fboState && fboState.ticket.pdApproved === true && <span><img src={logoApproved} alt="" height="50" title="PD Approved" /></span>}
                                                {fboState && fboState.ticket.pdApproved === false && <span><img src={logoRejected} alt="" height="50" title="PD Rejected" /></span>}
                                                <ComplianceLabel value={fboState && fboState.ticket.complianceState || ""} />
                                            </h5>
                                            <div className="flex-shrink-0">
                                                <div className="d-flex flex-wrap gap-1">
                                                    {fboState && fboState.canSave &&
                                                    <button className="btn btn-primary btn-label"
                                                        disabled={loading || !isDirty}
                                                        onClick={() => onSaveTicket()}
                                                    >
                                                        <i className="ri-save-line label-icon align-bottom"></i> Save Ticket {isContainer ? "Container" : ""}
                                                    </button>
                                                    }
                                                    {fboState && fboState.canSave &&
                                                    <button className="btn btn-danger btn-label"
                                                        disabled={loading || !isDirty}
                                                        onClick={() => onCancelTicket()}
                                                    >
                                                        <i className="ri-close-line label-icon align-bottom"></i> Cancel
                                                    </button>
                                                    }
                                                    {fboState && fboState.canAssignMe &&
                                                    <button className="btn btn-primary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onAssignMeTicketClick()}
                                                    >
                                                        <i className="ri-user-follow-line label-icon align-bottom"></i> Assign Me Ticket {isContainer ? "Container" : ""}
                                                    </button>
                                                    }
                                                    {fboState && fboState.canSubmit &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onSubmitTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Submit
                                                    </button>
                                                    }
                                                    {fboState && fboState.canReSubmit &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onReSubmitTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Re-Submit
                                                    </button>
                                                    }
                                                    {fboState && fboState.canAccept &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onAcceptTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Accept
                                                    </button>
                                                    }
                                                    {fboState && fboState.canReject &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onRejectTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Reject
                                                    </button>
                                                    }
                                                    {fboState && fboState.canSalesAction &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onSalesActionTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Sales Action
                                                    </button>
                                                    }
                                                    {fboState && fboState.canPdReview &&
                                                    <button className="btn btn-danger btn-label"
                                                        disabled={loading}
                                                        onClick={() => onPdReviewTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> PD Review
                                                    </button>
                                                    }
                                                    {fboState && fboState.canApprovePd &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onApprovePdTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> PD Approved
                                                    </button>
                                                    }
                                                    {fboState && fboState.canRejectPd &&
                                                    <button className="btn btn-danger btn-label"
                                                        disabled={loading}
                                                        onClick={() => onRejectPdTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> PD Rejected
                                                    </button>
                                                    }
                                                    {fboState && fboState.canSampling &&
                                                    <button className="btn btn-danger btn-label"
                                                        disabled={loading}
                                                        onClick={() => onSamplingTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Sampling
                                                    </button>
                                                    }
                                                    {fboState && fboState.canScheduled && fboState.ticket.factoryAuditSubType !== "None" &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onScheduledTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Schedule
                                                    </button>
                                                    }
                                                    {fboState && fboState.canWaitingCorrectiveAction &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onWaitingCorrectiveActionTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Waiting Corrective Action
                                                    </button>
                                                    }
                                                    {fboState && fboState.canPhase1Evaluation &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onPhase1EvaluationTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Phase 1 Evaluation
                                                    </button>
                                                    }
                                                    {fboState && fboState.canInspection &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onInspectionTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Inspection
                                                    </button>
                                                    }
                                                    {fboState && fboState.canPhase2Evaluation &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onPhase2EvaluationTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Phase 2 Evaluation
                                                    </button>
                                                    }
                                                    {fboState && fboState.canFinalDecision &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onFinalDecisionTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Final Decision
                                                    </button>
                                                    }
                                                    {fboState && fboState.canComplete &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onCompleteTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Complete
                                                    </button>
                                                    }
                                                    {fboState && fboState.canClose &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onCloseTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Close
                                                    </button>
                                                    }
                                                    {fboState && fboState.canScheduled && fboState.ticket.factoryAuditSubType === "None" &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onCloseTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Close
                                                    </button>
                                                    }
                                                    {fboState && fboState.canStop &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onStoppedTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Stop
                                                    </button>
                                                    }
                                                    {fboState && fboState.canAbort &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onAbortTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Abort
                                                    </button>
                                                    }
                                                    {fboState && fboState.canResetToDraft &&
                                                    <button className="btn btn-secondary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onResetToDraftTicketClick()}
                                                    >
                                                        <i className="ri-check-double-line label-icon align-bottom"></i> Reset To Draft
                                                    </button>
                                                    }
                                                    {fboState && fboState.canSendMailForm3 &&
                                                    <button className="btn btn-primary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onSendMailForm3()}
                                                    >
                                                        <i className="ri-mail-send-line label-icon align-bottom"></i> Send Mail
                                                    </button>
                                                    }
                                                    {fboState && fboState.canNotifyInvoice && 
                                                    <button className="btn btn-primary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onNotifyInvoiceCreation()}
                                                    >
                                                        <i className="ri-notification-line label-icon align-bottom"></i> Send invoice Reminder
                                                    </button>
                                                    }
                                                    {fboState && fboState.canNotifySadad && 
                                                    <button className="btn btn-primary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onNotifySADADPayment()}
                                                    >
                                                        <i className="ri-notification-line label-icon align-bottom"></i> Pay SADAD
                                                    </button>
                                                    }
                                                    {fboState && fboState.canSadadPaid && 
                                                    <button className="btn btn-primary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onNotifySADADPaid()}
                                                    >
                                                        <i className="ri-notification-line label-icon align-bottom"></i> SADAD Paid
                                                    </button>
                                                    }
                                                    {fboState && fboState.canNCR && 
                                                    <button className="btn btn-dark btn-label"
                                                        disabled={loading}
                                                        onClick={() => onNCR()}
                                                    >
                                                        <i style={{color:"red"}} className="bx bxs-badge-check label-icon align-bottom"></i> NCR
                                                    </button>
                                                    }
                                                    {fboState && fboState.canVerified && 
                                                    <button className="btn btn-dark btn-label"
                                                        disabled={loading}
                                                        onClick={() => onVerified()}
                                                    >
                                                        <i style={{color:"green"}} className="bx bxs-badge-check label-icon align-bottom"></i> Verified
                                                    </button>
                                                    }
                                                    {fboState && fboState.canCreateInvoice && 
                                                    <button className="btn btn-primary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onCreateInvoice()}
                                                    >
                                                        <i className="ri-add-line label-icon align-bottom"></i> Create Invoice
                                                    </button>
                                                    }
                                                    {fboState && fboState.canViewInvoice &&
                                                    <button className="btn btn-primary btn-label"
                                                        disabled={loading}
                                                        onClick={() => onViewInvoice()}
                                                    >
                                                        <i className="ri-bill-line label-icon align-bottom"></i> View Invoice
                                                    </button>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </CardHeader>
                                                                                
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            <Row className="gy-2">
                                                {fboState && fboState.ticket.isProductCertificateAvailable &&
                                                <Col xxl={12} md={12}>
                                                    <div className="d-flex align-items-right">
                                                        <div className="flex-grow-1">
                                                            <div className="form-check pt-2">
                                                                <div className="d-flex">
                                                                    <span className="pe-5">
                                                                        <input type="radio" className="form-check-input" disabled={true}
                                                                            checked={(fboState && fboState.ticket.productAllowed === "Single")}
                                                                            onChange={e => onTicketChange("productAllowed", "Single")}
                                                                        /> Single(P) Request
                                                                    </span>
                                                                    <span className="pe-5">
                                                                        <input type="radio" className="form-check-input" disabled={true}
                                                                            checked={(fboState && fboState.ticket.productAllowed === "Multiple")}
                                                                            onChange={e => onTicketChange("productAllowed", "Multiple")}
                                                                        /> Category Request
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            {fboState && fboState.sadadLabel &&
                                                            <span className={`badge ${fboState.sadadLabel === "SADAD PENDING" ? "bg-danger" : "bg-success"}`} style={{padding:10}}>{fboState && fboState.sadadLabel} {fboState && fboState.sadadTime}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                                }
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Ticket #</Label>
                                                    <input type="text" className="form-control" readOnly={true}
                                                        value={(fboState && fboState.ticket.requestNumber) || ""}
                                                        onChange={e => onTicketChange("requestNumber", e.target.value)}
                                                    />
                                                </Col>
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Status</Label>
                                                    <input className="form-control" readOnly={true}
                                                        value={(fboState && fboState.ticket.status) || ""}
                                                        onChange={e => onTicketChange("status", e.target.value)}
                                                    />
                                                </Col>
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Ticket Type</Label>
                                                    <select className="form-select" disabled={true}
                                                        value={(fboState && fboState.ticket.serviceId) || ""}
                                                        onChange={e => onTicketChange("serviceId", e.target.value)}
                                                    >
                                                        <option value={0}>...</option>
                                                        {serviceList && serviceList.map(function(item, index){
                                                            return (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </Col>
                                                {fboState && fboState.showGroup &&
                                                <Fragment>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Group Name</Label>
                                                        <select className="form-select" disabled={fboState && fboState.canChangeGroup ? false : true}
                                                            value={(fboState && fboState.ticket.groupId) || ""}
                                                            onChange={e => onGroupChange(e.target.value)}
                                                        >
                                                            <option value={0}>...</option>
                                                            {getGroupList().map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Group User</Label>
                                                        <select className="form-select" disabled={fboState && fboState.canChangeGroupUser ? false : true}
                                                            value={(fboState && fboState.ticket.groupUserId) || ""}
                                                            onChange={e => onGroupUserChange(e.target.value)}
                                                        >
                                                            <option value={0}>...</option>
                                                            {fboState && fboState.groupUserList.filter(x => x.groupId == fboState.ticket.groupId && x.isTechnicalUser === true).map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.userId}>{item.fullName}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                </Fragment>
                                                }
                                                {fboState && fboState.showDecisionMaker &&
                                                <Fragment>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Decision Maker</Label>
                                                        <select className="form-select" disabled={fboState && fboState.canChangeDecisionMaker ? false : true}
                                                            value={(fboState && fboState.ticket.decisionUserId) || ""}
                                                            onChange={e => onDecisionMakerChange(e.target.value)}
                                                        >
                                                            <option value={0}>...</option>
                                                            {getDecisionMakerList().map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.userId}>{item.fullName}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                </Fragment>
                                                }
                                                {fboState && fboState.showPdGroup &&
                                                <Fragment>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">PD Services Group</Label>
                                                        <select className="form-select" disabled={fboState && fboState.canChangePdGroup ? false : true}
                                                            value={(fboState && fboState.ticket.pdGroupId) || ""}
                                                            onChange={e => onPdGroupChange(e.target.value)}
                                                        >
                                                            <option value={0}>...</option>
                                                            {fboState && fboState.pdGroupList.map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">PD Services User</Label>
                                                        <select className="form-select" disabled={fboState && fboState.canChangePdGroupUser ? false : true}
                                                            value={(fboState && fboState.ticket.pdGroupUserId) || ""}
                                                            onChange={e => onPdGroupUserChange(e.target.value)}
                                                        >
                                                            <option value={0}>...</option>
                                                            {fboState && fboState.pdGroupUserList.filter(x => x.groupId == fboState.ticket.pdGroupId).map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.userId}>{item.fullName}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                </Fragment>
                                                }
                                                {fboState && fboState.showPdDecisionMaker &&
                                                <Fragment>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Decision Maker</Label>
                                                        <select className="form-select" disabled={fboState && fboState.canChangePdDecisionMaker ? false : true}
                                                            value={(fboState && fboState.ticket.decisionUserId) || ""}
                                                            onChange={e => onDecisionMakerChange(e.target.value)}
                                                        >
                                                            <option value={0}>...</option>
                                                            {getDecisionMakerList().map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.userId}>{item.fullName}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                </Fragment>
                                                }
                                                {fboState && (fboState.ticket.isProductCertificateAvailable || fboState.ticket.isCosmeticAvailable) &&
                                                <Fragment>
                                                    <Col xxl={2} md={4} style={{border: (certificateTypeValid ? "" : "1px solid red")}}>
                                                        <Label className="form-label">Request Type<span className="required">*</span></Label>
                                                        <div className="form-check pt-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <input type="radio" className="form-check-input"
                                                                        checked={(fboState && fboState.ticket.certificateType === "New")}
                                                                        onChange={e => onTicketChange("certificateType", "New")}
                                                                    /> New
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <input type="radio" className="form-check-input"
                                                                        checked={(fboState && fboState.ticket.certificateType === "Renewal")}
                                                                        onChange={e => onTicketChange("certificateType", "Renewal")}
                                                                    /> Renewal
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} md={6}>
                                                        {fboState && fboState.ticket.certificateType === "Renewal" &&
                                                        <Fragment>
                                                            <Label className="form-label">Old certificate number.<sup>2</sup></Label>
                                                            <input className="form-control"
                                                                value={(fboState && fboState.ticket.oldCertificateNumber) || ""}
                                                                onChange={e => onTicketChange("oldCertificateNumber", e.target.value)}
                                                            />
                                                        </Fragment>
                                                        }
                                                    </Col>
                                                </Fragment>
                                                }
                                                {fboState && fboState.ticket.isCosmeticAvailable &&
                                                <Fragment>
                                                    <Col xxl={8} md={12} style={{border: (certificationTypeValid ? "" : "1px solid red")}}>
                                                        <Label className="form-label">Certification Type<span className="required">*</span></Label>
                                                        <div className="form-check pt-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-0 me-5">
                                                                    <input type="radio" className="form-check-input" disabled={fboState && !(fboState.ticket.status === "Draft" || fboState.ticket.status === "Sales Action")}
                                                                        checked={(fboState && fboState.ticket.certificationType === "S-CoC")}
                                                                        onChange={e => onTicketChange("certificationType", "S-CoC")}
                                                                    /> Shipment Certificate of Conformity (S-CoC)
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <input type="radio" className="form-check-input" disabled={fboState && !(fboState.ticket.status === "Draft" || fboState.ticket.status === "Sales Action")}
                                                                        checked={(fboState && fboState.ticket.certificationType === "P-CoC")}
                                                                        onChange={e => onTicketChange("certificationType", "P-CoC")}
                                                                    /> Product Certificate of Conformity (P-CoC)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={4} md={6} style={{border: (testReportValid ? "" : "1px solid red")}}>
                                                        <Label className="form-label">Is there a test report?<span className="required">*</span></Label>
                                                        <div className="form-check pt-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-0 me-5">
                                                                    <input type="radio" className="form-check-input"
                                                                        checked={(fboState && fboState.ticket.isValidTestReport === "Yes")}
                                                                        onChange={e => onTicketChange("isValidTestReport", "Yes")}
                                                                    /> Yes
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <input type="radio" className="form-check-input"
                                                                        checked={(fboState && fboState.ticket.isValidTestReport === "No")}
                                                                        onChange={e => onTicketChange("isValidTestReport", "No")}
                                                                    /> No
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    {fboState && fboState.ticket.isValidTestReport === "No" &&
                                                    <Fragment>
                                                        <Col xxl={4} md={6}>
                                                            <Label className="form-label">Client Address<span className="required">*</span></Label>
                                                            <input className={`form-control ${clientAddressValid ? "" : "is-invalid"}`}
                                                                value={(fboState && fboState.ticket.clientAddress) || ""}
                                                                onChange={e => onTicketChange("clientAddress", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={4} md={6}>
                                                            <Label className="form-label">Client Contact<span className="required">*</span></Label>
                                                            <input className={`form-control ${clientContactValid ? "" : "is-invalid"}`}
                                                                value={(fboState && fboState.ticket.clientContact) || ""}
                                                                onChange={e => onTicketChange("clientContact", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={4} md={6}>
                                                            <Label className="form-label">Product Location<span className="required">*</span></Label>
                                                            <input className={`form-control ${productLocationValid ? "" : "is-invalid"}`}
                                                                value={(fboState && fboState.ticket.productLocation) || ""}
                                                                onChange={e => onTicketChange("productLocation", e.target.value)}
                                                            />
                                                        </Col>
                                                    </Fragment>
                                                    }
                                                </Fragment>
                                                }
                                                {fboState && fboState.ticket.isProductCertificateAvailable &&
                                                <Fragment>
                                                    <Col xxl={4} md={6} style={{border: (requestSubTypeValid ? "" : "1px solid red")}}>
                                                        <Label className="form-label">Please select Type<span className="required">*</span></Label>
                                                        <div className="form-check pt-2">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <input type="radio" className="form-check-input"
                                                                        checked={(fboState && fboState.ticket.requestSubType === "Type 1a")}
                                                                        onChange={e => onTicketChange("requestSubType", "Type 1a")}
                                                                    /> Type 1a
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <input type="radio" className="form-check-input"
                                                                        checked={(fboState && fboState.ticket.requestSubType === "Type 3")}
                                                                        onChange={e => onTicketChange("requestSubType", "Type 3")}
                                                                    /> Type 3
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <input type="radio" className="form-check-input"
                                                                        checked={(fboState && fboState.ticket.requestSubType === "Type 5")}
                                                                        onChange={e => onTicketChange("requestSubType", "Type 5")}
                                                                    /> Type 5<sup>1</sup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Request Region<span className="required">*</span></Label>
                                                        <select className={`form-select ${regionValid ? "" : "is-invalid"}`}
                                                            value={(fboState && fboState.ticket.region) || ""}
                                                            onChange={e => onTicketChange("region", e.target.value)}
                                                        >
                                                            <option value="">...</option>
                                                            <option value="Asia">Asia</option>
                                                            <option value="Europe">Europe</option>
                                                            <option value="Middle East and North Africa">Middle East and North Africa</option>
                                                        </select>
                                                    </Col>
                                                </Fragment>
                                                }
                                                {fboState && fboState.ticket.isProductCertificate &&
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">SASO Request #</Label>
                                                    <input className="form-control" readOnly={isContainer ? true: false}
                                                        value={(fboState && fboState.ticket.sasoRequestNumber) || ""}
                                                        onChange={e => onTicketChange("sasoRequestNumber", e.target.value)}
                                                    />
                                                </Col>
                                                }
                                                {fboState && fboState.ticket.isCosmetic &&
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Faseh Request #</Label>
                                                    <input className="form-control"
                                                        value={(fboState && fboState.ticket.fasehRequestNumber) || ""}
                                                        onChange={e => onTicketChange("fasehRequestNumber", e.target.value)}
                                                    />
                                                </Col>
                                                }
                                                <Col xxl={12} md={12} className="d-none">
                                                    <Label className="form-label">Technical Regulation Name</Label>
                                                    <Typeahead
                                                        id="fldTechnicalRegulation"
                                                        labelKey="description"
                                                        onChange={onTechnicalRegulationChange}
                                                        options={technicalRegulationList}
                                                        placeholder="Select Technical Regulation..."
                                                        selected={selectedTechnicalRegulation}
                                                    />
                                                </Col>
                                                {fboState && (fboState.ticket.isShipmentCertificate || fboState.ticket.isCosmetic) &&
                                                <Fragment>
                                                    <Col xxl={2} md={6}>
                                                        <Label className="form-label">FOB Value</Label>
                                                        <input className="form-control"
                                                            value={(fboState && fboState.ticket.fob) || ""}
                                                            onChange={e => onTicketChange("fob", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={2} md={6}>
                                                        <Label className="form-label">Currency</Label>
                                                        <select className="form-select"
                                                            value={(fboState && fboState.ticket.fobCurrency) || ""}
                                                            onChange={e => onTicketChange("fobCurrency", e.target.value)}
                                                        >
                                                            <option value={""}>...</option>
                                                            <option value="AED">UAE Dirham</option>
                                                            <option value="CNY">China Yuan</option>
                                                            <option value="EUR">Euro</option>
                                                            <option value="USD">USA Dollar</option>
                                                            <option value="LKR">Sri Lanka Rupee</option>
                                                            <option value="EGP">Egypt Pound</option>
                                                            <option value="SAR">Saudi Riyal</option>
                                                            <option value="INR">India Rupee</option>
                                                        </select>
                                                    </Col>
                                                </Fragment>
                                                }
                                                {fboState && fboState.ticket.isShipmentCertificate &&
                                                <Fragment>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Shipment Country</Label>
                                                        <input className="form-control"
                                                            value={(fboState && fboState.ticket.shipmentCountry) || ""}
                                                            onChange={e => onTicketChange("shipmentCountry", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={2} md={6}>
                                                        <Label className="form-label">Non Regulated Included?</Label>
                                                        <div className="form-check pt-2">
                                                            <input type="checkbox" className="form-check-input"
                                                                checked={(fboState && fboState.ticket.nonRegulated)}
                                                                onChange={e => onTicketChange("nonRegulated", e.target.checked)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={2} md={6}>
                                                        <div style={{visibility: (fboState && fboState.ticket.nonRegulated) ? "" : "hidden"}} >
                                                            <Label className="form-label">No of Product Certificates</Label>
                                                            <input className="form-control"
                                                                value={(fboState && fboState.ticket.nonRegulatedCount) || 0}
                                                                onChange={e => onTicketChange("nonRegulatedCount", e.target.value)}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">PCOC Number(s)</Label>
                                                        {fboState && fboState.pcocNumbers.map(function(item, index){
                                                            return(
                                                                <div className="d-flex mb-1" key={index}>
                                                                    <div className="flex-grow-1">
                                                                        <input className="form-control"
                                                                            value={(fboState && fboState.pcocNumbers[index]) || ""}
                                                                            onChange={e => onPcocNumberChange(index, e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="flex-shrink-0" style={{visibility: (index === 0 ? "" : "hidden")}}>
                                                                        <button type="button" className="btn btn-primary btn-sm btn-icon rounded-pill ms-1 mt-1" title="Add"
                                                                            onClick={() => onPcocNumberAdd(index)}
                                                                        >
                                                                            <i className="ri-add-line" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </Col>
                                                </Fragment>
                                                }
                                                {fboState && fbo.showReporter &&
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Allow Access To <span className="text-primary" style={{fontSize:12}}><i>(Ticket will be shared with other user)</i></span></Label>
                                                    {fboState && fboState.canChangeReporter &&
                                                    <div className="input-group">
                                                        <Lookup
                                                            clearButton 
                                                            allowNew ={false}
                                                            id ="fldReporter" 
                                                            url = {`GetGroupUserLookup?isReporter=1`}
                                                            defaultSearch={(fboState && fboState.ticket.reporterUserId) || ""}
                                                            placeholder= "Select Reporter..."
                                                            labelKey= {option => `${option.fullName}`}
                                                            selected = {selectedReporter}
                                                            onChange = {(item) => onReporterChange(item)}
                                                            renderMenu = {UserRenderer}
                                                        />
                                                    </div>
                                                    }
                                                    {fboState && !fboState.canChangeReporter &&
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedReporter.length > 0 && selectedReporter[0].fullName) || ""}
                                                    />
                                                    }
                                                </Col>
                                                }
                                                <Col xxl={12} md={12}>
                                                    <Label className="form-label">Remarks</Label>
                                                    <input className="form-control" placeholder="optional..."
                                                        value={(fboState && fboState.ticket.description) || ""}
                                                        onChange={e => onTicketChange("description", e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    {fboState && fboState.ticket.isFactoryAuditAvailable &&
                                    <Fragment>
                                        <Card className="mb-0">
                                            <CardHeader className="border-0 d-flex align-items-center">
                                                <h5 className="card-title mb-0 flex-grow-1">Factory Audit Document Check</h5>
                                            </CardHeader>
                                            <CardBody className="border border-dashed border-end-0 border-start-0">
                                                <Row className="gy-2">
                                                    <Col xxl={12} md={12}>
                                                        <div className="form-check mb-1">
                                                            <input type="radio" className="form-check-input"
                                                                checked={getFactoryAuditTypeValue("OtherCompany")}
                                                                onChange={e => setFactoryAuditTypeValue("OtherCompany")}
                                                            />
                                                            <Label className="mt-0">Factory Audit By Another CB</Label>
                                                        </div>
                                                    </Col>
                                                    <Fragment>
                                                        <Col xxl={12} md={12} className="ms-3">
                                                            <div className="form-check mb-1">
                                                                <input type="radio" className="form-check-input"
                                                                    checked={getFactoryAuditSubTypeValue("Surveillance", "OtherCompany")}
                                                                    onChange={e => setFactoryAuditSubTypeValue("Surveillance", "OtherCompany")}
                                                                />
                                                                <Label className="mt-0">Less than 3 years: Proceed with Surveillance</Label>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={12} md={12} className="ms-3">
                                                            <div className="form-check mb-1">
                                                                <input type="radio" className="form-check-input"
                                                                    checked={getFactoryAuditSubTypeValue("Factory Audit", "OtherCompany")}
                                                                    onChange={e => setFactoryAuditSubTypeValue("Factory Audit", "OtherCompany")}
                                                                />
                                                                <Label className="mt-0">More than 3 years(Expired): Conduct a new Factory Audit</Label>
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                    <Col xxl={12} md={12}>
                                                        <div className="form-check mb-1">
                                                            <input type="radio" className="form-check-input"
                                                                checked={getFactoryAuditTypeValue("OurCompany")}
                                                                onChange={e => setFactoryAuditTypeValue("OurCompany")}
                                                            />
                                                            <Label className="mt-0">Factory Audit By Our Company</Label>
                                                        </div>
                                                    </Col>
                                                    <Fragment>
                                                        <Col xxl={12} md={12} className="ms-3">
                                                            <div className="form-check mb-1">
                                                                <input type="radio" className="form-check-input"
                                                                    checked={getFactoryAuditSubTypeValue("None", "OurCompany")}
                                                                    onChange={e => setFactoryAuditSubTypeValue("None", "OurCompany")}
                                                                />
                                                                <Label className="mt-0">Less than 1 year: Proceed with Factory Audit</Label>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={12} md={12} className="ms-3">
                                                            <div className="form-check mb-1">
                                                                <input type="radio" className="form-check-input"
                                                                    checked={getFactoryAuditSubTypeValue("Surveillance 1", "OurCompany")}
                                                                    onChange={e => setFactoryAuditSubTypeValue("Surveillance 1", "OurCompany")}
                                                                />
                                                                <Label className="mt-0">More than 1 year and less than 2 years: Conduct Surveillance 1</Label>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={12} md={12} className="ms-3">
                                                            <div className="form-check mb-1">
                                                                <input type="radio" className="form-check-input"
                                                                    checked={getFactoryAuditSubTypeValue("Surveillance 2", "OurCompany")}
                                                                    onChange={e => setFactoryAuditSubTypeValue("Surveillance 2", "OurCompany")}
                                                                />
                                                                <Label className="mt-0">More than 2 years and less than 3 years: Conduct Surveillance 2</Label>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={12} md={12} className="ms-3">
                                                            <div className="form-check mb-1">
                                                                <input type="radio" className="form-check-input"
                                                                    checked={getFactoryAuditSubTypeValue("Factory Audit", "OurCompany")}
                                                                    onChange={e => setFactoryAuditSubTypeValue("Factory Audit", "OurCompany")}
                                                                />
                                                                <Label className="mt-0">More than 3 years(Expired): Conduct a new Factory Audit</Label>
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                    <Col xxl={12} md={12}>
                                                        <div className="form-check mb-1">
                                                            <input type="radio" className="form-check-input"
                                                                checked={getFactoryAuditTypeValue("None")}
                                                                onChange={e => setFactoryAuditTypeValue("None")}
                                                            />
                                                            <Label className="mt-0">New Factory Audit or Factory Audit not accredited or has 17065 in the same region</Label>
                                                        </div>
                                                    </Col>
                                                    <Fragment>
                                                        <Col xxl={12} md={12} className="ms-3">
                                                            <div className="form-check mb-1">
                                                                <input type="radio" className="form-check-input"
                                                                    checked={getFactoryAuditSubTypeValue("Factory Audit", "None")}
                                                                    onChange={e => setFactoryAuditSubTypeValue("Factory Audit", "None")}
                                                                />
                                                                <Label className="mt-0">Conduct a new Factory Audit</Label>
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        <Card className="mb-0">
                                            <CardHeader className="border-0 d-flex align-items-center">
                                                <h5 className="card-title mb-0 flex-grow-1">Factory Audit Schedule Information</h5>
                                            </CardHeader>
                                            <CardBody className="border border-dashed border-end-0 border-start-0">
                                                <Row className="gy-2">
                                                    <Col xxl={3} md={12}>
                                                        <Label className="form-label">Date / Time</Label>
                                                        <Flatpickr className="form-control"
                                                            value={(fboState && getFormattedDateTime(fboState.ticket.factoryAuditDate)) || ""}
                                                            onChange={([date]) => onTicketChange("factoryAuditDate", date)}
                                                            options={{
                                                                enableTime: true,
                                                                dateFormat: "m/d/Y H:i"
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xxl={9} md={12}>
                                                        <Label className="form-label">Location</Label>
                                                        <input type="text" className="form-control" 
                                                            value={(fboState && fboState.ticket.factoryAuditLocation) || ""}
                                                            onChange={e => onTicketChange("factoryAuditLocation", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={4} md={12}>
                                                        <Label className="form-label">Contact Person Name</Label>
                                                        <input type="text" className="form-control"
                                                            value={(fboState && fboState.ticket.factoryAuditContactName) || ""}
                                                            onChange={e => onTicketChange("factoryAuditContactName", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={4} md={12}>
                                                        <Label className="form-label">Contact Person Email</Label>
                                                        <input type="text" className="form-control"
                                                            value={(fboState && fboState.ticket.factoryAuditContactEmail) || ""}
                                                            onChange={e => onTicketChange("factoryAuditContactEmail", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={4} md={12}>
                                                        <Label className="form-label">Contact Person Phone</Label>
                                                        <input type="text" className="form-control"
                                                            value={(fboState && fboState.ticket.factoryAuditContactPhone) || ""}
                                                            onChange={e => onTicketChange("factoryAuditContactPhone", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={4} md={12}>
                                                        <Label className="form-label">TUV Reviewer Name</Label>
                                                        <div className="input-group">
                                                            <Lookup
                                                                clearButton 
                                                                allowNew ={false}
                                                                id ="fldReporter" 
                                                                url = {`GetGroupUserLookup?isPd=1`}
                                                                defaultSearch={(fboState && fboState.ticket.factoryAuditReviewerUserId) || ""}
                                                                placeholder= "Select Reviewer..."
                                                                labelKey= {option => `${option.fullName}`}
                                                                selected = {selectedReviewer}
                                                                onChange = {(item) => onReviewerChange(item)}
                                                                renderMenu = {UserRenderer}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xxl={4} md={12}>
                                                        <Label className="form-label">TUV Reviewer Email</Label>
                                                        <input type="text" className="form-control" readOnly={true}
                                                            defaultValue={(selectedReviewer.length > 0 && selectedReviewer[0].email) || ""}
                                                        />
                                                    </Col>
                                                    <Col xxl={4} md={12}>
                                                        <Label className="form-label">TUV Reviewer Phone</Label>
                                                        <input type="text" className="form-control" readOnly={true}
                                                            defaultValue={(selectedReviewer.length > 0 && selectedReviewer[0].telephoneNumber) || ""}
                                                        />
                                                    </Col>
                                                    <Col xxl={4} md={12}>
                                                        <Label className="form-label">Mode of Inspection</Label>
                                                        <select className="form-select"
                                                            value={(fboState && fboState.ticket.factoryAuditInspectionMode) || ""}
                                                            onChange={e => onTicketChange("factoryAuditInspectionMode", e.target.value)}
                                                        >
                                                            <option value={""}>...</option>
                                                            <option value={"Remote"}>Remote</option>
                                                            <option value={"On-Site Visit"}>On-Site Visit</option>
                                                        </select>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Fragment>
                                    }
                                    {fboState && fboState.canViewInvoiceInfo &&
                                    <Fragment>
                                        <Card className="mb-0">
                                            <CardHeader className="border-0 d-flex align-items-center">
                                                <h5 className="card-title mb-0 flex-grow-1">Customer Information</h5>
                                            </CardHeader>
                                            <CardBody className="border border-dashed border-end-0 border-start-0">
                                                <Row className="gy-2">
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">CRM Name</Label>
                                                        <select className="form-select" disabled={isCustomerReadonly()}
                                                            value={(fboState && fboState.ticket.crmName) || ""}
                                                            onChange={e => onTicketChange("crmName", e.target.value)}
                                                        >
                                                            <option value="">...</option>
                                                            {fboState && fboState.countryList.map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.code}>{item.description}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Invoice Country</Label>
                                                        <select className="form-select" disabled={isCustomerReadonly()}
                                                            value={(fboState && fboState.ticket.crmCountry) || ""}
                                                            onChange={e => onTicketChange("crmCountry", e.target.value)}
                                                        >
                                                            <option value="">...</option>
                                                            {fboState && invoiceCountryList.filter(x => x.crmValue == fboState.ticket.crmName).map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.countryValue}>{item.countryLabel}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={4} md={6}>&nbsp;</Col>
                                                    {isCustomerReadonly() &&
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Customer Code</Label>
                                                        <input className="form-control" readOnly={true}
                                                            defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].code + ' - ' + selectedCustomer[0].crmCountry) || ""}
                                                        />
                                                    </Col>
                                                    }
                                                    {!isCustomerReadonly() &&
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Customer Code</Label>
                                                        <CustomerLookup
                                                            id ="fldCustomerLookup2"
                                                            crmName={(fboState && fboState.ticket.crmName) || ""}
                                                            selected = {selectedCustomer}
                                                            onChange = {(item) => onCustomerChange(item)}
                                                        />
                                                    </Col>
                                                    }
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Organization Name</Label>
                                                        <input className="form-control" readOnly={true}
                                                            defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].organizationName) || ""}
                                                        />
                                                    </Col>
                                                    <Col xxl={4} md={6}>
                                                        <Label className="form-label">Contact Name</Label>
                                                        <input className="form-control" readOnly={true}
                                                            defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].fullName) || ""}
                                                        />
                                                    </Col>
                                                    <Col xxl={12} md={12}>
                                                        <Label className="form-label">Address</Label>
                                                        <input className="form-control" readOnly={true}
                                                            defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].fullAddress) || ""}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        
                                        <Card className="mb-0 card-light">
                                            <CardHeader className="border-0 d-flex align-items-center">
                                                <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setInvoiceInformationCollapsed(!invoiceInformationCollapsed)}>
                                                    {invoiceInformationCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                                                    {!invoiceInformationCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                                                    Invoice Information ({invoiceInformationCollapsed ? "Expand" : "Collapse"})
                                                </h5>
                                            </CardHeader>
                                            <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                                                <Collapse isOpen={!invoiceInformationCollapsed} className="collapse">
                                                    <Row className="gy-2">
                                                        <Col xxl={4} md={6}>
                                                            <Label className="form-label">CRM Invoice #</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.crmInvoiceNumber) || ""}
                                                                onChange={e => onTicketChange("crmInvoiceNumber", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={4} md={6}>
                                                            <Label className="form-label">Tax Invoice #</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.taxInvoiceNumber) || ""}
                                                                onChange={e => onTicketChange("taxInvoiceNumber", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={4} md={6}>
                                                            {/* <Label className="form-label">SASO Request #</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.sasoRequestNumber) || ""}
                                                                onChange={e => onTicketChange("sasoRequestNumber", e.target.value)}
                                                            /> */}
                                                        </Col>
                                                        
                                                        <Col xxl={4} md={6}>
                                                            <Label className="form-label">Product Name</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.productName) || ""}
                                                                onChange={e => onTicketChange("productName", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={7} md={12}>
                                                            <Label className="form-label">Description</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.productDescription) || ""}
                                                                onChange={e => onTicketChange("productDescription", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={1} md={6}>
                                                            <Label className="form-label">Quantity</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.quantity) || ""}
                                                                onChange={e => onTicketChange("quantity", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={2} md={6} className="pe-1">
                                                            <Label className="form-label">List Price</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.listPrice) || ""}
                                                                onChange={e => onTicketChange("listPrice", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={2} md={6} className="pe-1 ps-1">
                                                            <Label className="form-label">Discount</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.discountValue) || ""}
                                                                onChange={e => onTicketChange("discountValue", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={1} md={6} className="pe-1 ps-0">
                                                            <Label className="form-label">&nbsp;</Label>
                                                            <select className="form-select" disabled={true}
                                                                value={(fboState && fboState.ticket.discountType) || ""}
                                                                onChange={e => onTicketChange("discountType", e.target.value)}
                                                            >
                                                                <option value="Percentage">Pct</option>
                                                                <option value="Amount">Amt</option>
                                                            </select>
                                                        </Col>
                                                        <Col xxl={2} md={6} className="pe-1 ps-1">
                                                            <Label className="form-label">Sale Price</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.salePrice) || ""}
                                                                onChange={e => onTicketChange("salePrice", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={2} md={6} className="pe-1 ps-1">
                                                            <Label className="form-label">VAT Amount</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.vat) || ""}
                                                                onChange={e => onTicketChange("vat", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={1} md={6} className="pe-1 ps-0">
                                                            <Label className="form-label">&nbsp;</Label>
                                                            <select className="form-select" disabled={true}
                                                                value={(fboState && fboState.ticket.vatPercentage) || ""}
                                                                onChange={e => onTicketChange("vatPercentage", e.target.value)}
                                                            >
                                                                <option value="0">0%</option>
                                                                <option value="5">5%</option>
                                                                <option value="10">10%</option>
                                                                <option value="15">15%</option>
                                                            </select>
                                                        </Col>
                                                        <Col xxl={2} md={6} className="ps-1">
                                                            <Label className="form-label">Total Price</Label>
                                                            <input className="form-control" readOnly={true}
                                                                value={(fboState && fboState.ticket.price) || ""}
                                                                onChange={e => onTicketChange("price", e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col xxl={3} md={6}>
                                                            <Label className="form-label">Currency</Label>
                                                            <select className="form-select" disabled={true}
                                                                value={(fboState && fboState.ticket.currency) || ""}
                                                                onChange={e => onTicketChange("currency", e.target.value)}
                                                            >
                                                                <option value={""}>...</option>
                                                                <option value="AED">UAE Dirham</option>
                                                                <option value="CNY">China Yuan</option>
                                                                <option value="EUR">Euro</option>
                                                                <option value="USD">USA Dollar</option>
                                                                <option value="LKR">Sri Lanka Rupee</option>
                                                                <option value="EGP">Egypt Pound</option>
                                                                <option value="SAR">Saudi Riyal</option>
                                                                <option value="INR">India Rupee</option>
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                            </CardBody>
                                        </Card>
                                    </Fragment>
                                    }
                                    <Card className={`mb-0 ${(isContainer || fboState && !fboState.canViewCertificate) ? "d-none" : ""}`}>
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">{isFactoryAudit ? "Report Information" : "Certificate Information"}</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            <Row className="gy-2">
                                                <Col xxl={3} md={6}>
                                                    <Label className="form-label">{isFactoryAudit ? "Report #" : "Certificate #"}{fboState && fboState.isCertificateRequired && <span className="required">*</span>}</Label>
                                                    <input className="form-control" readOnly={!(fboState && fboState.canEditCertificate)}
                                                        value={(fboState && fboState.ticket.certificateNumber) || ""}
                                                        onChange={e => onTicketChange("certificateNumber", e.target.value)}
                                                    />
                                                </Col>
                                                {/* {!isFactoryAudit && <Col xxl={3} md={6}>
                                                    <Label className="form-label">Product Certificate #</Label>
                                                    <input className="form-control"
                                                        value={(fboState && fboState.ticket.productCertificateNumber) || ""}
                                                        onChange={e => onTicketChange("productCertificateNumber", e.target.value)}
                                                    />
                                                </Col>
                                                } */}
                                                <Col xxl={3} md={6}>
                                                    <Label className="form-label">{isFactoryAudit ? "Report Issue Date" : "Certificate Issue Date"}{fboState && fboState.isCertificateRequired && <span className="required">*</span>}</Label>
                                                    {fboState && fboState.canEditCertificate &&
                                                    <Flatpickr className="form-control"
                                                        value={(fboState && getFormattedDate(fboState.ticket.certificateIssueDate)) || ""}
                                                        onChange={([date]) => onTicketChange("certificateIssueDate", date)}
                                                        options={{
                                                            dateFormat: "m/d/Y"
                                                        }}
                                                    />
                                                    }
                                                    {fboState && !fboState.canEditCertificate &&
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(fboState && getFormattedDate(fboState.ticket.certificateIssueDate)) || ""}
                                                    />
                                                    }
                                                </Col>
                                                <Col xxl={3} md={6}>
                                                    <Label className="form-label">{isFactoryAudit ? "Report Expiry Date" : "Certificate Expiry Date"}{fboState && fboState.isCertificateRequired && <span className="required">*</span>}</Label>
                                                    {fboState && fboState.canEditCertificate &&
                                                    <Flatpickr className="form-control"
                                                        value={(fboState && getFormattedDate(fboState.ticket.certificateExpiryDate)) || ""}
                                                        onChange={([date]) => onTicketChange("certificateExpiryDate", date)}
                                                        options={{
                                                            dateFormat: "m/d/Y"
                                                        }}
                                                    />
                                                    }
                                                    {fboState && !fboState.canEditCertificate &&
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(fboState && getFormattedDate(fboState.ticket.certificateExpiryDate)) || ""}
                                                    />
                                                    }
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </CardBody>
                            </Card>

                            <Card className={`${isContainer ? "d-none" : ""}`}>
                                <CardLoader loading={loading} />

                                <CardBody style={{margin:0, padding:0}}>
                                    <Card className={`mb-0 ${(isContainer || fboState && !fboState.canViewManufacturer) ? "d-none" : ""}`}>
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Manufacturer Information</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            <Row className="gy-2">
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Organization Name{fboState && fboState.isManufacturerRequired && <span className="required">*</span>}</Label>
                                                    {fboState && fboState.canEditManufacturer &&
                                                    <div className="input-group">
                                                        <Lookup
                                                            clearButton 
                                                            allowNew ={false}
                                                            id ="fldManufacturerLookup" 
                                                            url = "GetManufacturerLookup"
                                                            defaultSearch={(fboState && fboState.ticket.manufacturerId) || ""}
                                                            placeholder= "Select Manufacturer..."
                                                            labelKey= {option => `${option.organizationName}`}
                                                            selected = {selectedManufacturer}
                                                            onChange = {(item) => onManufacturerChange(item)}
                                                            renderMenu = {VendorRenderer}
                                                        />
                                                        <div className="input-group-prepend">
                                                            <button type="button" className="btn btn-primary" onClick= {() => onViewVendorForm("manufacturer")}>
                                                                <i className="ri-add-line"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    }
                                                    {fboState && !fboState.canEditManufacturer &&
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedManufacturer.length > 0 && selectedManufacturer[0].organizationName) || ""}
                                                    />
                                                    }
                                                </Col>
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Contact Name</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedManufacturer.length > 0 && selectedManufacturer[0].contactName) || ""}
                                                    />
                                                </Col>
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Email</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedManufacturer.length > 0 && selectedManufacturer[0].email) || ""}
                                                    />
                                                </Col>
                                                <Col xxl={12} md={12}>
                                                    <Label className="form-label">Address</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedManufacturer.length > 0 && selectedManufacturer[0].fullAddress) || ""}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                
                                    <Card className={`mb-0 ${(isContainer || fboState && !fboState.canViewImporter) ? "d-none" : ""}`}>
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Importer Information</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            <Row className="gy-2">
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Organization Name{fboState && fboState.isImporterRequired && <span className="required">*</span>}</Label>
                                                    {fboState && fboState.canEditImporter &&
                                                    <div className="input-group">
                                                        <Lookup
                                                            clearButton 
                                                            allowNew ={false}
                                                            id ="fldImporterLookup" 
                                                            url = "GetImporterLookup"
                                                            defaultSearch={(fboState && fboState.ticket.importerId) || ""}
                                                            placeholder= "Select Importer..."
                                                            labelKey= {option => `${option.organizationName}`}
                                                            selected = {selectedImporter}
                                                            onChange = {(item) => onImporterChange(item)}
                                                            renderMenu = {VendorRenderer}
                                                        />
                                                        <div className="input-group-prepend">
                                                            <button type="button" className="btn btn-primary" onClick= {() => onViewVendorForm("importer")}>
                                                                <i className="ri-add-line"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    }
                                                    {fboState && !fboState.canEditImporter &&
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedImporter.length > 0 && selectedImporter[0].organizationName) || ""}
                                                    />
                                                    }
                                                </Col>
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Contact Name</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedImporter.length > 0 && selectedImporter[0].contactName) || ""}
                                                    />
                                                </Col>
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Email</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedImporter.length > 0 && selectedImporter[0].email) || ""}
                                                    />
                                                </Col>
                                                <Col xxl={12} md={12}>
                                                    <Label className="form-label">Address</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedImporter.length > 0 && selectedImporter[0].fullAddress) || ""}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                
                                    <Card className={`mb-0 ${(isContainer || fboState && !fboState.canViewExporter) ? "d-none" : ""}`}>
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Exporter Information</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            <Row className="gy-2">
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Organization Name{fboState && fboState.isExporterRequired && <span className="required">*</span>}</Label>
                                                    {fboState && fboState.canEditExporter &&
                                                    <div className="input-group">
                                                        <Lookup
                                                            clearButton 
                                                            allowNew ={false}
                                                            id ="fldExporterLookup" 
                                                            url = "GetExporterLookup"
                                                            defaultSearch={(fboState && fboState.ticket.exporterId) || ""}
                                                            placeholder= "Select Exporter..."
                                                            labelKey= {option => `${option.organizationName}`}
                                                            selected = {selectedExporter}
                                                            onChange = {(item) => onExporterChange(item)}
                                                            renderMenu = {VendorRenderer}
                                                        />
                                                        <div className="input-group-prepend">
                                                            <button type="button" className="btn btn-primary" onClick= {() => onViewVendorForm("exporter")}>
                                                                <i className="ri-add-line"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    }
                                                    {fboState && !fboState.canEditExporter &&
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedExporter.length > 0 && selectedExporter[0].organizationName) || ""}
                                                    />
                                                    }
                                                </Col>
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Contact Name</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedExporter.length > 0 && selectedExporter[0].contactName) || ""}
                                                    />
                                                </Col>
                                                <Col xxl={4} md={6}>
                                                    <Label className="form-label">Email</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedExporter.length > 0 && selectedExporter[0].email) || ""}
                                                    />
                                                </Col>
                                                <Col xxl={12} md={12}>
                                                    <Label className="form-label">Address</Label>
                                                    <input className="form-control" readOnly={true}
                                                        defaultValue={(selectedExporter.length > 0 && selectedExporter[0].fullAddress) || ""}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </CardBody>
                            </Card>

                            {fboState && fboState.ticket.id > 0 && isContainer &&
                                <TicketGrid refId={fboState.ticket.id} groupList={fboState.groupList} groupUserList={fboState.groupUserList} />
                            }

                            {fboState && fboState.ticket.id > 0 && !isContainer &&
                            <Fragment>
                                {fboState && fboState.canViewProduct &&
                                    <TicketProductGrid serviceId={fboState.ticket.serviceId} refId={fboState.ticket.id} parentId={fboState.ticket.id} parentName={"TICKET"} canChange={fboState.canEditProduct} onChange={reloadAction} allowMultiple={fboState && fboState.ticket.productAllowed === "Multiple"} />
                                }
                                <TicketProductPhotoGrid refId={fboState.ticket.id} refName={"TICKET_PRODUCT_PHOTO"} parentId={fboState.ticket.id} parentName={"TICKET"} canChange={fboState.canSave} onChange={onChangeDocument} />
                                {fboState && fboState.showForm0 && fboState.ticket.isProductCertificateAvailable &&
                                <Fragment>
                                    <ServiceDocumentGrid serviceId={fboState.ticket.serviceId} refId={fboState.ticket.id} refName={"TICKET_PRODUCT_DOCUMENT"} parentId={fboState.ticket.id} parentName={"TICKET"} isProduct={true} canChange={fboState.canSave} canChangeCertificate={false} onChange={onChangeDocument} />
                                    <Card className="mb-3">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <CardLoader loading={loading} />
                                            <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setChecklistFormCollapsed(!checklistFormCollapsed)}>
                                                {checklistFormCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                                                {!checklistFormCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                                                Product Checklist ({checklistFormCollapsed ? "Expand" : "Collapse"})
                                            </h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            <Collapse isOpen={!checklistFormCollapsed} className="collapse">
                                                <Row className="gy-2">
                                                    <Col xxl={12} md={12}>
                                                        <table className={`table-form ${fboState && fboState.canEditForm0 ? "" : "table-form-readonly"}`}>
                                                            <tbody>
                                                                <tr>
                                                                    <td style={{width:"5%", border:"none", padding: "0"}}></td>
                                                                    <td style={{width:"35%", border:"none", padding: "0"}}></td>
                                                                    <td style={{width:"30%", border:"none", padding: "0"}}></td>
                                                                    <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                                                    <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                                                    <td style={{width:"10%", border:"none", padding: "0"}}></td>
                                                                </tr>
                                                                <tr className="header">
                                                                    <td colSpan={3}>Record Check<sup>4</sup></td>
                                                                    <td colSpan={3}>Sales Team</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col"><b>1.0</b></td>
                                                                    <td colSpan={5}>
                                                                        Please check related quality documents over below link<br />
                                                                        <a target="_blank" rel="noreferrer" href="https://docs.google.com/spreadsheets/d/1X9IIdK_S1FTRDfOZz8HCXhc3PFwm5hp6uPs6OdQ2JMQ/edit?usp=drive_link">https://docs.google.com/spreadsheets/d/1X9IIdK_S1FTRDfOZz8HCXhc3PFwm5hp6uPs6OdQ2JMQ/edit?usp=drive_link</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col"><b>1.1</b></td>
                                                                    <td colSpan={2}>Quality Documents<sup>5</sup></td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "QualityDocuments") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "QualityDocuments", "Yes")} onChange={e => setFieldValue("productChecklistForm", "QualityDocuments", "Yes", e.target.checked)} /> Yes
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "QualityDocuments") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "QualityDocuments", "No")} onChange={e => setFieldValue("productChecklistForm", "QualityDocuments", "No", e.target.checked)} /> No
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col">&nbsp;</td>
                                                                    <td colSpan={2}>
                                                                        If subcontracting, is it subcontracted to the approved subcontractor?<br />
                                                                        If yes, please check approved subcontractor list - <a target="_blank" rel="noreferrer" href="https://standards.tuvatsa.com/login">TUV Library System (tuvatsa.com)</a>
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "ApprovedSubContractorLab", "Yes")} onChange={e => setFieldValue("productChecklistForm", "ApprovedSubContractorLab", "Yes", e.target.checked)} /> Yes
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "ApprovedSubContractorLab", "No")} onChange={e => setFieldValue("productChecklistForm", "ApprovedSubContractorLab", "No", e.target.checked)} /> No
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "ApprovedSubContractorLab") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "ApprovedSubContractorLab", "N/A")} onChange={e => setFieldValue("productChecklistForm", "ApprovedSubContractorLab", "N/A", e.target.checked)} /> N/A
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col">&nbsp;</td>
                                                                    <td>Verification of Test Report</td>
                                                                    <td colSpan={4}>
                                                                        <div>
                                                                            <div className="d-flex">
                                                                                <div className="flex-shrink-0 me-2 pt-1">
                                                                                    <input type="checkbox" className={`${isValidFieldValue("productChecklistForm", "VerifyAccreditationBody", "Yes") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "VerifyAccreditationBody", "Yes")} onChange={e => setFieldValue("productChecklistForm", "VerifyAccreditationBody", "Yes", e.target.checked)} /> Accreditation Body:
                                                                                </div>
                                                                                <div className="flex-grow-1 w-100">
                                                                                    <input type="text" className={`form-control form-control-sm w-100 ${isValidFieldValue("productChecklistForm", "VerifyAccreditationBodyText") ? "" : "is-invalid"}`} readOnly={!(fboState && fboState.canEditForm0)}
                                                                                        value={getFieldValueText("productChecklistForm", "VerifyAccreditationBodyText") || ""}
                                                                                        onChange={e => setFieldValueText("productChecklistForm", "VerifyAccreditationBodyText", e.target.value)}
                                                                                    />
                                                                                </div>
                                                                            </div>                                            
                                                                        </div>
                                                                        <div>
                                                                            <div className="d-flex">
                                                                                <div className="flex-shrink-0 me-2 pt-1">
                                                                                    <input type="checkbox" className={`${isValidFieldValue("productChecklistForm", "VerifyTestingLaboratory", "Yes") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "VerifyTestingLaboratory", "Yes")} onChange={e => setFieldValue("productChecklistForm", "VerifyTestingLaboratory", "Yes", e.target.checked)} /> Testing Laboratory:
                                                                                </div>
                                                                                <div className="flex-grow-1 w-100">
                                                                                    <input type="text" className={`form-control form-control-sm w-100 ${isValidFieldValue("productChecklistForm", "VerifyTestingLaboratoryText") ? "" : "is-invalid"}`} readOnly={!(fboState && fboState.canEditForm0)}
                                                                                        value={getFieldValueText("productChecklistForm", "VerifyTestingLaboratoryText") || ""}
                                                                                        onChange={e => setFieldValueText("productChecklistForm", "VerifyTestingLaboratoryText", e.target.value)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="d-flex">
                                                                                <div className="flex-shrink-0 me-2 pt-1">
                                                                                    <input type="checkbox" className={`${isValidFieldValue("productChecklistForm", "VerifyReportDate", "Yes") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "VerifyReportDate", "Yes")} onChange={e => setFieldValue("productChecklistForm", "VerifyReportDate", "Yes", e.target.checked)} /> Test Report Date:&nbsp;&nbsp;&nbsp;
                                                                                </div>
                                                                                <div className="flex-grow-1 w-100">
                                                                                    <input type="text" className={`form-control form-control-sm w-100 ${isValidFieldValue("productChecklistForm", "VerifyReportDateText") ? "" : "is-invalid"}`} readOnly={!(fboState && fboState.canEditForm0)}
                                                                                        value={getFieldValueText("productChecklistForm", "VerifyReportDateText") || ""}
                                                                                        onChange={e => setFieldValueText("productChecklistForm", "VerifyReportDateText", e.target.value)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col"><b>1.2</b></td>
                                                                    <td colSpan={2}>Supplier Declaration of Conformity<sup>6</sup></td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "SupplierDeclaration") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "SupplierDeclaration", "Yes")} onChange={e => setFieldValue("productChecklistForm", "SupplierDeclaration", "Yes", e.target.checked)} /> Yes
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "SupplierDeclaration") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "SupplierDeclaration", "No")} onChange={e => setFieldValue("productChecklistForm", "SupplierDeclaration", "No", e.target.checked)} /> No
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col"><b>1.3</b></td>
                                                                    <td colSpan={2}>Risk Assessment Form<sup>7</sup></td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "RiskAssessmentForm") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "RiskAssessmentForm", "Yes")} onChange={e => setFieldValue("productChecklistForm", "RiskAssessmentForm", "Yes", e.target.checked)} /> Yes
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "RiskAssessmentForm") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "RiskAssessmentForm", "No")} onChange={e => setFieldValue("productChecklistForm", "RiskAssessmentForm", "No", e.target.checked)} /> No
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col"><b>1.4</b></td>
                                                                    <td colSpan={2}>At least 2 (two) visible and clear PHOTOS (one photo for labeling, one photo for product)</td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "Photos", "Yes")} onChange={e => setFieldValue("productChecklistForm", "Photos", "Yes", e.target.checked)} /> Yes
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "Photos") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "Photos", "No")} onChange={e => setFieldValue("productChecklistForm", "Photos", "No", e.target.checked)} /> No
                                                                    </td>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col"><b>1.5</b></td>
                                                                    <td colSpan={2}>ISO Certification (ISO 9001, ISO 16949, ISO 14001, etc. related with TR)</td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "ISOCertification", "Yes")} onChange={e => setFieldValue("productChecklistForm", "ISOCertification", "Yes", e.target.checked)} /> Yes
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "ISOCertification", "No")} onChange={e => setFieldValue("productChecklistForm", "ISOCertification", "No", e.target.checked)} /> No
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "ISOCertification") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "ISOCertification", "N/A")} onChange={e => setFieldValue("productChecklistForm", "ISOCertification", "N/A", e.target.checked)} /> N/A
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="index-col"><b>1.6</b></td>
                                                                    <td colSpan={2}>Factory Audit Report (only for Type3)</td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "FactoryAuditReport", "Yes")} onChange={e => setFieldValue("productChecklistForm", "FactoryAuditReport", "Yes", e.target.checked)} /> Yes
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "FactoryAuditReport", "No")} onChange={e => setFieldValue("productChecklistForm", "FactoryAuditReport", "No", e.target.checked)} /> No
                                                                    </td>
                                                                    <td className="check-col">
                                                                        <input type="radio" className={`${isValidFieldValue("productChecklistForm", "FactoryAuditReport") ? "" : "checkbox-is-invalid"}`} checked={getFieldValue("productChecklistForm", "FactoryAuditReport", "N/A")} onChange={e => setFieldValue("productChecklistForm", "FactoryAuditReport", "N/A", e.target.checked)} /> N/A
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </Collapse>
                                        </CardBody>
                                    </Card>
                                </Fragment>
                                }
                            </Fragment>
                            }

                            {(fboState && fboState.ticket.serviceId) &&
                                <ServiceDocumentGrid serviceId={fboState.ticket.serviceId} refId={fboState.ticket.id} refName={"TICKET_DOCUMENT"} parentId={fboState.ticket.id} parentName={"TICKET"} isProduct={false} collapsed={"false"} canChange={fboState.canSave} canChangeCertificate={fboState.canAddCertificate} onChange={onChangeDocument} />
                            }

                            {fboState && fboState.showForm1 && fboState.ticket.isProductCertificateAvailable &&
                                <FormReviewSASO
                                    loading={loading}
                                    fboState={fboState}
                                    isValidFieldValue={isValidFieldValue}
                                    getFieldValue={getFieldValue}
                                    getFieldValueText={getFieldValueText}
                                    getFieldValueDate={getFieldValueDate}
                                    setFieldValue={setFieldValue}
                                    setFieldValueText={setFieldValueText}
                                    setFieldValueNumber={setFieldValueNumber}
                                />
                            }

                            {fboState && fboState.showForm2 && fboState.ticket.isProductCertificateAvailable &&
                                <FormEvaluationSASO
                                    loading={loading}
                                    fboState={fboState}
                                    isValidFieldValue={isValidFieldValue}
                                    getFieldValue={getFieldValue}
                                    getFieldValueText={getFieldValueText}
                                    getFieldValueDate={getFieldValueDate}
                                    setFieldValue={setFieldValue}
                                    setFieldValueText={setFieldValueText}
                                    setFieldValueNumber={setFieldValueNumber}                                    
                                />
                            }

                            {fboState && fboState.showForm3 && fboState.ticket.isProductCertificateAvailable &&
                                <FormDecisionSASO
                                    loading={loading}
                                    fboState={fboState}
                                    isValidFieldValue={isValidFieldValue}
                                    getFieldValue={getFieldValue}
                                    getFieldValueText={getFieldValueText}
                                    getFieldValueDate={getFieldValueDate}
                                    setFieldValue={setFieldValue}
                                    setFieldValueText={setFieldValueText}
                                    setFieldValueNumber={setFieldValueNumber}
                                />
                            }

                            {fboState && fboState.showForm1 && fboState.ticket.isCosmeticAvailable &&
                                <FormReviewSFDA
                                    loading={loading}
                                    fboState={fboState}
                                    isValidFieldValue={isValidFieldValue}
                                    getFieldValue={getFieldValue}
                                    getFieldValueText={getFieldValueText}
                                    getFieldValueDate={getFieldValueDate}
                                    setFieldValue={setFieldValue}
                                    setFieldValueText={setFieldValueText}
                                    setFieldValueNumber={setFieldValueNumber}
                                />
                            }

                            {fboState && fboState.showForm2 && fboState.ticket.isCosmeticAvailable &&
                                <FormEvaluationSFDA
                                    loading={loading}
                                    fboState={fboState}
                                    isValidFieldValue={isValidFieldValue}
                                    getFieldValue={getFieldValue}
                                    getFieldValueText={getFieldValueText}
                                    getFieldValueDate={getFieldValueDate}
                                    setFieldValue={setFieldValue}
                                    setFieldValueText={setFieldValueText}
                                    setFieldValueNumber={setFieldValueNumber}
                                />
                            }

                            {fboState && fboState.showForm3 && fboState.ticket.isCosmeticAvailable &&
                                <FormDecisionSFDA
                                    loading={loading}
                                    fboState={fboState}
                                    isValidFieldValue={isValidFieldValue}
                                    getFieldValue={getFieldValue}
                                    getFieldValueText={getFieldValueText}
                                    getFieldValueDate={getFieldValueDate}
                                    setFieldValue={setFieldValue}
                                    setFieldValueText={setFieldValueText}
                                    setFieldValueNumber={setFieldValueNumber}
                                />
                            }
                            
                            {fboState && fboState.canSave &&
                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-1">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={() => onSaveTicket()}
                                            >
                                                <i className="ri-save-line label-icon align-bottom"></i> Save Ticket {isContainer ? "Container" : ""}
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={() => onCancelTicket()}
                                            >
                                                <i className="ri-close-line label-icon align-bottom"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                            </Card>
                            }
                            
                            {fboState && fboState.ticket.id > 0 &&
                                <CommentGrid refId={fboState.ticket.id} refName="TICKET" onCommentAdd={reloadAction} />
                            }
                        </Col>
                        {fboState && fboState.ticket.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.ticket.id} name={"TICKET"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
            
            <ConfirmModal message={"Are you sure you want to Assign the Ticket?"} show={showAssignMeModal} onCloseClick={onCloseModalClick} onYesClick={onAssignMeTicket} />
            <ConfirmModal message={"Are you sure you want to \"Submit\" the Ticket?"} show={showSubmitModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Re-Submit\" the Ticket?"} show={showReSubmitModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Accept\" the Ticket?"} show={showAcceptModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Reject\" the Ticket?"} show={showRejectModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Sales Action\" the Ticket?"} show={showSalesActionModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"PD Review\" the Ticket?"} show={showPdReviewModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"PD Approve\" the Ticket?"} show={showApprovePdModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"PD Reject\" the Ticket?"} show={showRejectPdModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Sampling\" the Ticket?"} show={showSamplingModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Final Decision\" the Ticket?"} show={showFinalDecisionModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Waiting Corrective Action\" the Ticket?"} show={showWaitingCorrectiveActionModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Phase 1 Evaluation\" the Ticket?"} show={showPhase1EvaluationModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Inspection\" the Ticket?"} show={showInspectionModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Phase 2 Evaluation\" the Ticket?"} show={showPhase2EvaluationModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Stopped\" the Ticket?"} show={showStoppedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Abort\" the Ticket?"} show={showAbortModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Complete\" the Ticket?"} show={showCompleteModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Close\" the Ticket?"} show={showCloseModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />
            <ConfirmModal message={"Are you sure you want to \"Draft\" the Ticket?"} show={showResetToDraftModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket} />

            <ConfirmModal message={"Are you sure you want to Send Create Invoice Reminder?"} show={showNotifyCreateInvoiceModal} onCloseClick={onCloseModalClick} onYesClick={onSaveNotifyInvoiceCreation} />
            <ConfirmModal message={"Are you sure you want to Send SADAD Payment Reminder?"} show={showNotifySadadPaymentModal} onCloseClick={onCloseModalClick} onYesClick={onSaveNotifySADADPayment} />
            <ConfirmModal message={"Are you sure you want to Send SADAD Paid Notification?"} show={showNotifySadadPaidModal} onCloseClick={onCloseModalClick} onYesClick={onSaveNotifySADADPaid} />

            <ConfirmModalAdvanced show={showNCRModal} onCloseClick={onCloseModalClick} onYesClick={onSaveNCR}>
                <div className="pb-2">Are you sure you want to Add "NCR" Label to the Ticket?.</div>
                <Col xxl={12} md={12}>
                    <Label className="form-label">Enter Any Comments</Label>
                    <textarea className="form-control" rows="5"
                        value={currentMessage || ""}
                        onChange={e => setCurrentMessage(e.target.value)}
                    />
                </Col>
            </ConfirmModalAdvanced>

            <ConfirmModalAdvanced show={showVerifiedModal} onCloseClick={onCloseModalClick} onYesClick={onSaveVerified}>
                <div className="pb-2">Are you sure you want to Add "Verified" Label to the Ticket?.</div>
                <Col xxl={12} md={12}>
                    <Label className="form-label">Enter Any Comments</Label>
                    <textarea className="form-control" rows="5"
                        value={currentMessage || ""}
                        onChange={e => setCurrentMessage(e.target.value)}
                    />
                </Col>
            </ConfirmModalAdvanced>

            <ConfirmModalAdvanced show={showSubmitConfirmModal} onCloseClick={onCloseModalClick} onYesClick={onSaveTicket}>
                <div>Below mentioned documents are checked in Product Checklist but not uploaded in the product documents.</div>
                {errorMessages.map(function(item, index){
                    return (
                        <div key={index} style={{whiteSpace:"nowrap"}} className="pt-2"><i className="ri-error-warning-fill align-bottom text-warning"></i>{item}.</div>
                    );
                })}
                <h4 className="text-center pt-2">Are you sure you still want to Submit the Request?</h4>
            </ConfirmModalAdvanced>

            <Modal tabIndex="-1" size="xl" isOpen={showScheduledModal} toggle={onHideScheduledModal} centered={true}>
                <ModalHeader>Schedule Factory Audit / Surveillance</ModalHeader>
                <ModalBody>
                    {/* <CardLoader loading={loadingTicketInvoice} /> */}
                    <Card className="card-light">
                        <CardHeader className="border-0 d-flex align-items-center">
                            <h5 className="card-title mb-0 flex-grow-1">Schedule Information</h5>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                            <Row className="gy-2">
                                <Col xxl={3} md={12}>
                                    <Label className="form-label">Date / Time<span className="required">*</span></Label>
                                    <Flatpickr className={`form-control ${factoryAuditDateValid ? "" : "is-invalid"}`}
                                        value={(fboState && getFormattedDateTime(fboState.ticket.factoryAuditDate)) || ""}
                                        onChange={([date]) => onTicketChange("factoryAuditDate", date)}
                                        options={{
                                            enableTime: true,
                                            dateFormat: "m/d/Y H:i"
                                        }}
                                    />
                                </Col>
                                <Col xxl={9} md={12}>
                                    <Label className="form-label">Location<span className="required">*</span></Label>
                                    <input type="text" className={`form-control ${factoryAuditLocationValid ? "" : "is-invalid"}`}
                                        value={(fboState && fboState.ticket.factoryAuditLocation) || ""}
                                        onChange={e => onTicketChange("factoryAuditLocation", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={4} md={12}>
                                    <Label className="form-label">Contact Person Name<span className="required">*</span></Label>
                                    <input type="text" className={`form-control ${factoryAuditContactNameValid ? "" : "is-invalid"}`}
                                        value={(fboState && fboState.ticket.factoryAuditContactName) || ""}
                                        onChange={e => onTicketChange("factoryAuditContactName", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={4} md={12}>
                                    <Label className="form-label">Contact Person Email<span className="required">*</span></Label>
                                    <input type="text" className={`form-control ${factoryAuditContactEmailValid ? "" : "is-invalid"}`}
                                        value={(fboState && fboState.ticket.factoryAuditContactEmail) || ""}
                                        onChange={e => onTicketChange("factoryAuditContactEmail", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={4} md={12}>
                                    <Label className="form-label">Contact Person Phone<span className="required">*</span></Label>
                                    <input type="text" className={`form-control ${factoryAuditContactPhoneValid ? "" : "is-invalid"}`}
                                        value={(fboState && fboState.ticket.factoryAuditContactPhone) || ""}
                                        onChange={e => onTicketChange("factoryAuditContactPhone", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={4} md={12}>
                                    <Label className="form-label">TUV Reviewer Name<span className="required">*</span></Label>
                                    <div className="input-group">
                                        <Lookup
                                            clearButton 
                                            allowNew ={false}
                                            id ="fldReporter" 
                                            url = {`GetGroupUserLookup?isPd=1`}
                                            defaultSearch={(fboState && fboState.ticket.factoryAuditReviewerUserId) || ""}
                                            placeholder= "Select Reviewer..."
                                            labelKey= {option => `${option.fullName}`}
                                            selected = {selectedReviewer}
                                            onChange = {(item) => onReviewerChange(item)}
                                            renderMenu = {UserRenderer}
                                            inputProps={{
                                                className: (factoryAuditReviewerUserIdValid ? "" : "is-invalid")
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col xxl={4} md={12}>
                                    <Label className="form-label">TUV Reviewer Email</Label>
                                    <input type="text" className="form-control" readOnly={true}
                                        defaultValue={(selectedReviewer.length > 0 && selectedReviewer[0].email) || ""}
                                    />
                                </Col>
                                <Col xxl={4} md={12}>
                                    <Label className="form-label">TUV Reviewer Phone</Label>
                                    <input type="text" className="form-control" readOnly={true}
                                        defaultValue={(selectedReviewer.length > 0 && selectedReviewer[0].telephoneNumber) || ""}
                                    />
                                </Col>
                                <Col xxl={4} md={12}>
                                    <Label className="form-label">Mode of Inspection<span className="required">*</span></Label>
                                    <select className={`form-select ${factoryAuditInspectionModeValid ? "" : "is-invalid"}`}
                                        value={(fboState && fboState.ticket.factoryAuditInspectionMode) || ""}
                                        onChange={e => onTicketChange("factoryAuditInspectionMode", e.target.value)}
                                    >
                                        <option value={""}>...</option>
                                        <option value={"Remote"}>Remote</option>
                                        <option value={"On-Site Visit"}>On-Site Visit</option>
                                    </select>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-success btn-label" disabled={loading} onClick={onScheduledTicketSave}>
                        <i className="ri-save-line label-icon align-bottom"></i> Schedule
                    </button>
                    <button type="button" className="btn btn-danger btn-label" disabled={loading} data-dismiss="modal" onClick={onHideScheduledModal}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <Modal tabIndex="-1" size="xl" isOpen={showSendMailForm3Modal} toggle={hideSendMailForm3Modal} centered={true}>
                <ModalHeader>Send Mail</ModalHeader>
                <ModalBody>
                    <Card className="card-light">
                        <CardLoader loading={loadingSendMailForm3} />
                        <CardHeader className="border-0 d-flex align-items-center">
                            <h5 className="card-title mb-0 flex-grow-1">Mail Information</h5>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                            <Row className="gy-2">
                                <Col xxl={12} md={12}>
                                    <Label className="form-label">To<span className="required">*</span></Label>
                                    <input type="text" className={`form-control ${sendMailToValid ? "" : "is-invalid"}`}
                                        value={sendMailTo || ""}
                                        onChange={e => setSendMailTo(e.target.value)}
                                    />
                                </Col>
                                <Col xxl={12} md={12} className="d-none">
                                    <Label className="form-label">Cc<span className="required">*</span></Label>
                                    <input type="text" className={`form-control ${sendMailCcValid ? "" : "is-invalid"}`}
                                        value={sendMailCc || ""}
                                        onChange={e => setSendMailCc(e.target.value)}
                                    />
                                </Col>
                                <Col xxl={12} md={12}>
                                    <Label className="form-label">Subject<span className="required">*</span></Label>
                                    <input type="text" className={`form-control ${sendMailSubjectValid ? "" : "is-invalid"}`}
                                        value={sendMailSubject || ""}
                                        onChange={e => setSendMailSubject(e.target.value)}
                                    />
                                </Col>
                                <Col xxl={12} md={12}>
                                    <Label className="form-label">Message<span className="required">*</span></Label>
                                    <textarea className={`form-control ${sendMailMessageValid ? "" : "is-invalid"}`} rows="5"
                                        value={sendMailMessage || ""}
                                        onChange={e => setSendMailMessage(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary btn-label" disabled={loading || loadingSendMailForm3} onClick={() => onSaveSendMailForm3()}>
                        <i className="ri-mail-send-line label-icon align-bottom"></i> Send Mail
                    </button>
                    <button type="button" className="btn btn-danger btn-label" disabled={loading || loadingSendMailForm3} data-dismiss="modal" onClick={hideSendMailForm3Modal}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <Modal tabIndex="-1" size="xl" isOpen={showInvoiceCreateModal} toggle={hideInvoiceCreateModal} centered={true}>
                <ModalHeader>Create Invoice</ModalHeader>
                <ModalBody>
                    <CardLoader loading={loadingTicketInvoice} />
                    <Card className="card-light">
                        <CardHeader className="border-0 d-flex align-items-center">
                            <h5 className="card-title mb-0 flex-grow-1">Customer Information</h5>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                            <Row className="gy-2">
                                <Col xxl={4} md={6}>
                                    <Label className="form-label">CRM Name<span className="required">*</span></Label>
                                    <select className={`form-select ${crmNameValid ? "" : "is-invalid"}`}
                                        value={(fboState && fboState.ticket.crmName) || ""}
                                        onChange={e => onTicketChange("crmName", e.target.value)}
                                    >
                                        <option value="">...</option>
                                        <option value={"saudi"}>Saudi Arabia</option>
                                        <option value={"egypt"}>Egypt</option>
                                        <option value={"china"}>China</option>
                                        <option value={"india"}>India</option>
                                    </select>
                                </Col>
                                <Col xxl={4} md={6}>
                                    <Label className="form-label">Invoice Country<span className="required">*</span></Label>
                                    <select className={`form-select ${crmCountryValid ? "" : "is-invalid"}`}
                                        value={(fboState && fboState.ticket.crmCountry) || ""}
                                        onChange={e => onTicketChange("crmCountry", e.target.value)}
                                    >
                                        <option value="">...</option>
                                        {fboState && invoiceCountryList.filter(x => x.crmValue == fboState.ticket.crmName).map(function(item, index){
                                            return (
                                                <option key={index} value={item.countryValue}>{item.countryLabel}</option>
                                            );
                                        })}
                                    </select>
                                </Col>
                                <Col xxl={4} md={6}>&nbsp;</Col>
                                <Col xxl={4} md={6}>
                                    <Label className="form-label">Customer Code</Label>
                                    <CustomerLookup
                                        id ="fldCustomerLookup1"
                                        crmName={(fboState && fboState.ticket.crmName) || ""}
                                        selected = {selectedCustomer}
                                        onChange = {(item) => onCustomerChange(item)}
                                        inputProps={{
                                            className: (customerValid ? "" : "is-invalid")
                                        }}
                                    />
                                </Col>
                                <Col xxl={4} md={6}>
                                    <Label className="form-label">Organization Name</Label>
                                    <input className="form-control" readOnly={true}
                                        defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].organizationName) || ""}
                                    />
                                </Col>
                                <Col xxl={4} md={6}>
                                    <Label className="form-label">Contact Name</Label>
                                    <input className="form-control" readOnly={true}
                                        defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].fullName) || ""}
                                    />
                                </Col>
                                <Col xxl={12} md={12}>
                                    <Label className="form-label">Address</Label>
                                    <input className="form-control" readOnly={true}
                                        defaultValue={(selectedCustomer.length > 0 && selectedCustomer[0].fullAddress) || ""}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="card-light">
                        <CardHeader className="border-0 d-flex align-items-center">
                            <h5 className="card-title mb-0 flex-grow-1">Price Information</h5>
                            <div className="flex-shrink-0">
                                <div className="d-flex flex-wrap gap-2">
                                    <button className="btn btn-primary btn-label"
                                        disabled={loadingTicketInvoice}
                                        onClick={() => onViewContract()}
                                    >
                                        <i className="ri-check-double-line label-icon align-bottom"></i> Select Product
                                    </button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody className="border border-dashed border-end-0 border-start-0 card-text">
                            <Row className="gy-2">
                                <Col xxl={4} md={6}>
                                    <Label className="form-label">Product Name</Label>
                                    <input className="form-control" readOnly={true}
                                        value={(fboState && fboState.ticket.productName) || ""}
                                        onChange={e => onTicketChange("productName", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={7} md={12}>
                                    <Label className="form-label">Description</Label>
                                    <input className="form-control" readOnly={true}
                                        value={(fboState && fboState.ticket.productDescription) || ""}
                                        onChange={e => onTicketChange("productDescription", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={1} md={6}>
                                    <Label className="form-label">Quantity</Label>
                                    <input className="form-control" readOnly={true}
                                        value={(fboState && fboState.ticket.quantity) || ""}
                                        onChange={e => onTicketChange("quantity", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={2} md={6} className="pe-1">
                                    <Label className="form-label">List Price<span className="required">*</span></Label>
                                    <input className={`form-control ${listPriceValid ? "" : "is-invalid"}`} readOnly={true}
                                        value={(fboState && fboState.ticket.listPrice) || ""}
                                        onChange={e => onTicketChange("listPrice", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={2} md={6} className="pe-1 ps-1">
                                    <Label className="form-label">Discount</Label>
                                    <input className="form-control" readOnly={isPricingReadonly()}
                                        value={(fboState && fboState.ticket.discountValue) || ""}
                                        onChange={e => onTicketChange("discountValue", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={1} md={6} className="pe-1 ps-0">
                                    <Label className="form-label">&nbsp;</Label>
                                    <select className="form-select" disabled={isPricingReadonly()}
                                        value={(fboState && fboState.ticket.discountType) || ""}
                                        onChange={e => onTicketChange("discountType", e.target.value)}
                                    >
                                        <option value="Percentage">Pct</option>
                                        <option value="Amount">Amt</option>
                                    </select>
                                </Col>
                                <Col xxl={2} md={6} className="pe-1 ps-1">
                                    <Label className="form-label">Sale Price</Label>
                                    <input className="form-control" readOnly={true}
                                        value={(fboState && fboState.ticket.salePrice) || ""}
                                        onChange={e => onTicketChange("salePrice", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={2} md={6} className="pe-1 ps-1">
                                    <Label className="form-label">VAT Amount</Label>
                                    <input className="form-control" readOnly={true}
                                        value={(fboState && fboState.ticket.vat) || ""}
                                        onChange={e => onTicketChange("vat", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={1} md={6} className="pe-1 ps-0">
                                    <Label className="form-label">&nbsp;</Label>
                                    <select className="form-select" disabled={isPricingReadonly()}
                                        value={(fboState && fboState.ticket.vatPercentage) || ""}
                                        onChange={e => onTicketChange("vatPercentage", e.target.value)}
                                    >
                                        <option value="0">0%</option>
                                        <option value="5">5%</option>
                                        <option value="10">10%</option>
                                        <option value="15">15%</option>
                                    </select>
                                </Col>
                                <Col xxl={2} md={6} className="ps-1">
                                    <Label className="form-label">Total Price</Label>
                                    <input className="form-control" readOnly={true}
                                        value={(fboState && fboState.ticket.price) || ""}
                                        onChange={e => onTicketChange("price", e.target.value)}
                                    />
                                </Col>
                                <Col xxl={3} md={6}>
                                    <Label className="form-label">Currency<span className="required">*</span></Label>
                                    <select className={`form-select ${currencyValid ? "" : "is-invalid"}`} disabled={true}
                                        value={(fboState && fboState.ticket.currency) || ""}
                                        onChange={e => onTicketChange("currency", e.target.value)}
                                    >
                                        <option value={""}>...</option>
                                        <option value="AED">UAE Dirham</option>
                                        <option value="CNY">China Yuan</option>
                                        <option value="EUR">Euro</option>
                                        <option value="USD">USA Dollar</option>
                                        <option value="LKR">Sri Lanka Rupee</option>
                                        <option value="EGP">Egypt Pound</option>
                                        <option value="SAR">Saudi Riyal</option>
                                        <option value="INR">India Rupee</option>
                                    </select>
                                </Col>
                                {fboState && fboState.ticket.isProductCertificate && selectedSasoRequestNumber && selectedSasoRequestNumber.map(function(item, index){
                                    return (
                                        <Col xxl={3} md={6} key={index}>
                                            <Label className="form-label">Saso Request # {selectedSasoRequestNumber.length == 1 ? "" : index + 1} <span className="required">*</span></Label>
                                            <input className={`form-control ${item && item.length > 0 ? "" : "is-invalid"}`}
                                                value={item}
                                                onChange={e => onSasoRequestNumberChange(index, e.target.value)}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </CardBody>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary btn-label" disabled={loading || loadingTicketInvoice} onClick={() => onSaveCreateInvoice(false)}>
                        <i className="ri-save-line label-icon align-bottom"></i> Create Invoice
                    </button>
                    <button type="button" className="btn btn-success btn-label" disabled={loading || loadingTicketInvoice || !isDirty} onClick={() => onSaveCreateInvoice(true)}>
                        <i className="ri-save-line label-icon align-bottom"></i> Save
                    </button>
                    <button type="button" className="btn btn-danger btn-label" disabled={loading || loadingTicketInvoice} data-dismiss="modal" onClick={hideInvoiceCreateModal}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <Modal tabIndex="-1" size="xl" isOpen={showInvoiceViewModal} toggle={hideInvoiceViewModal} centered={true}>
                <ModalHeader>Invoice Information</ModalHeader>
                <ModalBody>
                    <CardLoader loading={loadingTicketInvoice} />
                    <Row className="gy-2">
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Assigned To</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.assignedUserName) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Total Amount</Label>
                            <div className="form-control"><MyNumberRenderer value={(ticketInvoice && ticketInvoice.totalAmount) || ""} /></div>
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Status</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.status) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>&nbsp;</Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Billing Account</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.billingAccount) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Billing City</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.billingAddressCity) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Billing State</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.billingAddressState) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Billing Country</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.billingAddressCountry) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Billing Contact</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.billingContact) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Shipping City</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.shippingAddressCity) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Shipping State</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.shippingAddressState) || ""}
                            />
                        </Col>
                        <Col xxl={3} md={6}>
                            <Label className="form-label">Shipping Country</Label>
                            <input className="form-control" readOnly={true}
                                value={(ticketInvoice && ticketInvoice.shippingAddressCountry) || ""}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={hideInvoiceViewModal}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <Modal tabIndex="-1" size="xl" isOpen={showContractModal} toggle={hideContractModal} centered={true}>
                <ModalBody>
                    <Row className="gy-2">
                        <CardLoader loading={loadingTicketInvoiceContracts} />
                        <Col xxl={12} md={12}>
                            <h5 className="card-title mb-0 flex-grow-1">Contracts</h5>
                        </Col>
                        <Col xxl={12} md={12}>
                            <Table className="table-hover table-bordered align-middle mb-0">
                                <thead className="table-primary">
                                    <tr>
                                        <th>Qty</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Currency</th>
                                        <th>List Price</th>
                                        <th>Discount</th>
                                        <th>VAT</th>
                                        <th>Total Price</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketInvoiceContractList.length == 0 && <tr><td colSpan={9}>No Contracts Found</td></tr>}
                                    {ticketInvoiceContractList.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td className="text-center">1</td>
                                                <td>{item.productName}</td>
                                                <td>{item.productDescription}</td>
                                                <td>{item.currency}</td>
                                                <td>{item.listPrice}</td>
                                                <td>{item.discountAmount}</td>
                                                <td>{item.vatAmount}</td>
                                                <td>{item.totalPrice}</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary" title="Select Product"
                                                        onClick={() => onContractSelect(item)}
                                                    >
                                                        Select
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="gy-2 mt-2">
                        <CardLoader loading={loadingTicketInvoiceProducts} />
                        <Col xxl={12} md={12}>
                            <h5 className="card-title mb-0 flex-grow-1">Products</h5>
                        </Col>
                        <Col xxl={10} md={12}>
                            <div className="search-box">
                                <input type="text" className="form-control search" placeholder="Search for products..."
                                    value={productNameQuery}
                                    onChange={e => setProductNameQuery(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </Col>
                        <Col xxl={2} md={12}>
                            <button type="button" className="btn btn-primary btn-label w-100" 
                                disabled={loading}
                                onClick= {() => onSearchInvoiceProduct()}
                            >
                                <i className="ri-search-eye-line label-icon align-middle"></i> Search
                            </button>
                        </Col>
                        <Col xxl={12} md={12}>
                            <Table className="table-hover table-bordered align-middle mb-0">
                                <thead className="table-primary">
                                    <tr>
                                        <th>Qty</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Currency</th>
                                        <th>List Price</th>
                                        <th>Discount</th>
                                        <th>VAT</th>
                                        <th>Total Price</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketInvoiceProductList.length == 0 && <tr><td colSpan={9}>No Products Found</td></tr>}
                                    {ticketInvoiceProductList.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td className="text-center">1</td>
                                                <td>{item.productName}</td>
                                                <td>{item.productDescription}</td>
                                                <td>{item.currency}</td>
                                                <td>{item.listPrice}</td>
                                                <td>{item.discountAmount}</td>
                                                <td>{item.vatAmount}</td>
                                                <td>{item.totalPrice}</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary" title="Select Product"
                                                        onClick={() => onContractSelect(item)}
                                                    >
                                                        Select
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={hideContractModal}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            {showVendorFormModal && fboState && fboState.ticket.id > 0 && <VendorScreen type={vendorFormType} title={"Add Manufacturer"} showVendorFormModal={showVendorFormModal} hideVendorFormModal={hideVendorFormModal} />}

            <ErrorModal message={"Please select the Customer."} show={showCustomerError} onCloseClick={() => setShowCustomerError(false)} />
            <ErrorModal message={"Please select the Decision Maker."} show={showDecisionMakerError} onCloseClick={() => setShowDecisionMakerError(false)} />
            <ErrorModal message={"Please select a different Decision Maker. Decision Maker can't be Group User / PD Services User as well."} show={showDecisionMakerDuplicateError} onCloseClick={() => setShowDecisionMakerDuplicateError(false)} />
            <InfoModal message={"The quotation has already been created and needs confirmation in the CRM to generate an invoice."} show={showInvoiceError} onCloseClick={() => setShowInvoiceError(false)} />
        </Fragment>
    );
}

export default TicketEditScreen;