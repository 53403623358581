import { EMAIL_SEARCH_START_LOADING, EMAIL_SEARCH_END_LOADING, EMAIL_SEARCH_LOADED, SET_EMAIL_FILTER } from "../constants/EmailConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadEmailSearch(filters) {
    return async (dispatch) => {
        
        await dispatch({ type: EMAIL_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Email/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: EMAIL_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: EMAIL_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: EMAIL_SEARCH_END_LOADING });
        }
    };
}

export function setEmailFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_EMAIL_FILTER,
            payload: filter
        });
    };
}