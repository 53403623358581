//Search
export const LAB_REPORT_SEARCH_START_LOADING = "LAB_REPORT_SEARCH_START_LOADING";
export const LAB_REPORT_SEARCH_END_LOADING = "LAB_REPORT_SEARCH_END_LOADING";
export const LAB_REPORT_SEARCH_LOADED = "LAB_REPORT_SEARCH_LOADED";
export const SET_LAB_REPORT_FILTER = "SET_LAB_REPORT_FILTER";

//Edit
export const LAB_REPORT_START_LOADING = "LAB_REPORT_START_LOADING";
export const LAB_REPORT_END_LOADING = "LAB_REPORT_END_LOADING";
export const LAB_REPORT_LOADED = "LAB_REPORT_LOADED";
