import React from "react";
import { FadingBalls } from "react-cssfx-loading";

const Loader = () => {
    return(
        <div className="d-flex justify-content-center overlay">
            <FadingBalls color="#146EB4" height="20px" width="20px" />
        </div>
        
    );
};

export default Loader;