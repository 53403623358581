//Search
export const USER_SEARCH_START_LOADING = "USER_SEARCH_START_LOADING";
export const USER_SEARCH_END_LOADING = "USER_SEARCH_END_LOADING";
export const USER_SEARCH_LOADED = "USER_SEARCH_LOADED";
export const SET_USER_FILTER = "SET_USER_FILTER";

//Edit
export const USER_START_LOADING = "USER_START_LOADING";
export const USER_END_LOADING = "USER_END_LOADING";
export const USER_LOADED = "USER_LOADED";

//Edit
export const USER_PROFILE_START_LOADING = "USER_PROFILE_START_LOADING";
export const USER_PROFILE_END_LOADING = "USER_PROFILE_END_LOADING";
export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
