import { ISO_REQUEST_SEARCH_START_LOADING, ISO_REQUEST_SEARCH_END_LOADING, ISO_REQUEST_SEARCH_LOADED, SET_ISO_REQUEST_FILTER } from "../constants/IsoRequestConstants";
import { ISO_REQUEST_START_LOADING, ISO_REQUEST_END_LOADING, ISO_REQUEST_LOADED } from "../constants/IsoRequestConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadIsoRequestSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("IsoRequest/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: ISO_REQUEST_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ISO_REQUEST_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_SEARCH_END_LOADING });
        }
    };
}

export function setIsoRequestFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_ISO_REQUEST_FILTER,
            payload: filter
        });
    };
}

export function loadIsoRequest(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_START_LOADING });

        try {

            var data = await getApiData("IsoRequest/GetIsoRequestFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: ISO_REQUEST_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ISO_REQUEST_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_END_LOADING });
        }
    };
}

export function saveIsoRequest(fbo, status, message, invoice, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("IsoRequest/SaveIsoRequestFBO", { status, message }, fbo);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/iso-request-edit/' + data.responseEntities[0].isoRequest.id);
                    dispatch(commonActions.setSuccessMessage("ISO Ticket Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: ISO_REQUEST_LOADED,
                        payload: data.responseEntities[0]
                    });
                    
                    if(invoice) {
                        //TODO:
                        //dispatch(createInvoice(fbo.isoRequest.id, navigate));
                    }
                    else {
                        dispatch(commonActions.setSuccessMessage("ISO Ticket Saved Successfully!"));
                    }
                }
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }

			await dispatch({ type: ISO_REQUEST_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_END_LOADING });
        }
    };
}

export function assignMeIsoRequest(isoRequestId, userId, assignMe, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: ISO_REQUEST_START_LOADING });

        try {

            var data = await postApiJsonData("IsoRequest/AssignIsoRequest", { isoRequestId, userId, assignMe }, null);
            
            if(data.success) {
                dispatch(loadIsoRequest(isoRequestId, navigate));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: ISO_REQUEST_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: ISO_REQUEST_END_LOADING });
        }
    };
}