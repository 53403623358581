import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Label, Input, Table } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { createDeepClone } from "../utils/index";

import Loader from "../components/Loader";
import Badge from "../components/Badge";
import PaginationComponent from "../components/PaginationComponent";
import MyDateRenderer from "../components/MyDateRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as paymentActions from "../actions/PaymentActions";

const PaymentSearchScreen  = () => {
    const dispatch = useDispatch();
    
    const loading = useSelector((state) => state.payment.loading);
    const list = useSelector((state) => state.payment.list);
    const filter = useSelector((state) => state.payment.filter);

    const ticketStatusList = useSelector((state) => state.login.data.ticketStatusList);
    const serviceList = useSelector((state) => state.login.data.serviceList);

    const doSearch = () => {
        var copy_filter = createDeepClone(filter);
        
        if(copy_filter.pageIndex == 1) {
            dispatch(paymentActions.loadPaymentSearch(filter));
        }
        else {
            copy_filter.pageIndex = 1;
            dispatch(paymentActions.setPaymentFilter(copy_filter));
        }
    }

    React.useEffect(() => {
		dispatch(paymentActions.loadPaymentSearch(filter));
    }, [filter.pageIndex]);

    const onFilterChange = (name, value) => {
        var copy_filter = createDeepClone(filter);
        copy_filter[name] = value;
        dispatch(paymentActions.setPaymentFilter(copy_filter));
    }
    
    const onPageChange = (page) => {
        var copy_filter = createDeepClone(filter);
        copy_filter.pageIndex = page;
        dispatch(paymentActions.setPaymentFilter(copy_filter));
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Payment Search" />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody className="card-body">
                                    <div className="live-preview">
                                        <Row className="gy-2">
                                            <Col xxl={3} md={6}>
                                                <Label className="form-label">Ticket #</Label>
                                                <Input type="text" className="form-control"
                                                    value={filter.requestNumber}
                                                    onChange={e => onFilterChange("requestNumber", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={3} md={6}>
                                                <Label className="form-label">HSCode</Label>
                                                <Input type="text" className="form-control"
                                                    value={filter.hsCode}
                                                    onChange={e => onFilterChange("hsCode", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={3} md={6}>
                                                <Label className="form-label">Service</Label>
                                                <Input type="text" className="form-control"
                                                    value={filter.hsCode}
                                                    onChange={e => onFilterChange("hsCode", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={3} md={6}>
                                                <Label className="form-label">Status</Label>
                                                <Input type="text" className="form-control"
                                                    value={filter.hsCode}
                                                    onChange={e => onFilterChange("hsCode", e.target.value)}
                                                />
                                            </Col>
                                            <Col xxl={3} md={6}>
                                                <div>
                                                    <button type="button" className="btn btn-primary" 
                                                        disabled={loading}
                                                        onClick= {() => doSearch()}
                                                    >
                                                        <i className="ri-search-eye-line"></i> Search
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {loading && <Loader />}
                            <Card>
                                <CardBody>
                                    <div className="table-responsive table-card">
                                        <Table className="table-hover align-middle mb-0">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Status</th>
                                                    <th>Currency</th>
                                                    <th>Amount</th>
                                                    <th>Ticket #</th>
                                                    <th>Status</th>
                                                    <th>Service</th>
                                                    <th>HSCode</th>
                                                    <th>Action Date</th>
                                                    <th>Added By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.dataList.map(function(item, index){
                                                    return(
                                                        <tr key={index}>
                                                            <td style={{textAlign:"center"}}>
                                                                <NavLink to={`/payment-edit/${item.id}`}><i className="fas fa-edit"></i></NavLink>
                                                            </td>
                                                            <td><Badge color={item.statusColorCode} label={item.statusName} /></td>
                                                            <td>{item.currency}</td>
                                                            <td>{item.amount}</td>
                                                            <td>{item.requestNumber}</td>
                                                            <td><Badge color={item.ticketStatusColorCode} label={item.ticketStatusName} /></td>
                                                            <td><Badge color={item.serviceColorCode} label={item.serviceName} /></td>
                                                            <td>{item.hsCode}</td>
                                                            <td><MyDateRenderer value={item.lastActionDate} /></td>
                                                            <td>{item.userNameAdded}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <PaginationComponent
                                                total={list.totalCount}
                                                currentPage={filter.pageIndex}
                                                onPageChange={page => onPageChange(page)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default PaymentSearchScreen;