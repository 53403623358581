import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Label, Input, Row, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { createDeepClone } from "../utils/index";

import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";
import ConfirmModal from "./ConfirmModal";

import { useDispatch, useSelector } from "react-redux";
import * as ticketActions from "../actions/TicketActions";

const TicketGrid = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.ticket.loadingItems);
    const itemList = useSelector((state) => state.ticket.itemList);
    
    const [fboState, setFBOState] = React.useState(itemList);
    const [isDirty, setIsDirty] = React.useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showSubmitAllModal, setShowSubmitAllModal] = useState(false);
    const [showAbortModal, setShowAbortModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    
    React.useEffect(() => {
		dispatch(ticketActions.loadTicketItems(props.refId));
    }, [props.serviceId, props.refId]);

    React.useEffect(() => {
		setFBOState(itemList);
        setIsDirty(false);
    }, [itemList]);

    const hasDraftTickets = () => {
        if(fboState) {
            var count = fboState.filter(x=> x.statusName === "Draft").length;
            if(count > 0) {
                return true;
            }
        }

        return false;
    }

    const onGroupChange = (value, index) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value == 0) {
            copy_fboState[index]["groupId"] = null;
            copy_fboState[index]["groupUserId"] = null;
        }
        else {
            copy_fboState[index]["groupId"] = value;
            copy_fboState[index]["groupUserId"] = null;
        }
                
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onGroupUserChange = (value, index) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value == 0) {
            copy_fboState[index]["groupUserId"] = null;
        }
        else {
            copy_fboState[index]["groupUserId"] = value;
        }
                
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onSaveAll = () => {
        if(fboState) {
            var ticketIds = [];

            fboState.filter(x=> x.statusName === "Draft").forEach((item) => {
                ticketIds.push(item.id);
            });

            if(ticketIds.length > 0) {
                dispatch(ticketActions.changeTicketStatus(props.refId, ticketIds.toString(), "New"));
            }
        }

        onCloseModalClick();
    }

    const onSubmitTicketClick = (id) => {
        setSelectedId(id);
        setSelectedStatus("New");
        setShowSubmitModal(true);
    }

    const onSubmitAllTicketClick = (id) => {
        setShowSubmitAllModal(true);
    }

    const onAbortTicketClick = (id) => {
        setSelectedId(id);
        setSelectedStatus("Aborted");
        setShowAbortModal(true);
    }

    const onRemoveTicketClick = (id) => {
        setSelectedId(id);
        setShowRemoveModal(true);
    }

    const onCloseModalClick = () => {
        setSelectedId(0);
        setSelectedStatus("");

        setShowSubmitModal(false);
        setShowSubmitAllModal(false);
        setShowAbortModal(false);
        setShowRemoveModal(false);
    }

    const onSubmitAll = () => {
        if(fboState) {
            var ticketIds = [];

            fboState.filter(x=> x.statusName === "Draft").forEach((item) => {
                ticketIds.push(item.id);
            });

            if(ticketIds.length > 0) {
                dispatch(ticketActions.changeTicketStatus(props.refId, ticketIds.toString(), "New"));
            }
        }

        onCloseModalClick();
    }

    const onChangeTicketStatus = () => {
        if(selectedId > 0 && selectedStatus !== "") {
            dispatch(ticketActions.changeTicketStatus(props.refId, selectedId, selectedStatus));
        }

        onCloseModalClick();
    }

    const onRemoveTicketFromContainer = () => {
        if(selectedId > 0) {
            dispatch(ticketActions.removeTicketFromContainer(props.refId, selectedId));
        }

        onCloseModalClick();
    }

    return (
        <Fragment>
            <Card>
                <CardHeader className="border-0 d-flex align-items-center">
                    <CardLoader loading={loading} />
                    <h5 className="card-title mb-0 flex-grow-1">Container Tickets</h5>
                    <div className="flex-shrink-0">
                        <div className="d-flex flex-wrap gap-2">
                            {hasDraftTickets() &&
                            <button className="btn btn-primary me-1"
                                disabled={loading}
                                onClick={onSubmitAllTicketClick}
                            >
                                <i className="ri-save-line align-bottom me-1"></i> Submit All
                            </button>
                            }
                        </div>
                    </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <Row className="gy-2">
                        <Col xxl={12} md={12}>
                            <Table className="table-hover table-bordered align-middle mb-0">
                                <thead className="table-primary">
                                    <tr>
                                        <th>Ticket #</th>
                                        <th>Status</th>
                                        <th>Group Name</th>
                                        <th>Group User</th>
                                        <th>SASO Request #</th>
                                        <th>Last Action</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fboState.length == 0 && <tr><td colSpan={7}>No Ticket(s) Added</td></tr>}
                                    {fboState.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td>{item.requestNumber}</td>
                                                <td><span className="badge" style={{backgroundColor: item.statusColorCode}}>{item.statusName}</span></td>
                                                <td>{item.groupName}</td>
                                                <td>{item.groupUserName}</td>
                                                <td>{item.sasoRequestNumber}</td>
                                                <td><MyDateRenderer value={item.lastActionDate} /></td>
                                                <td className="text-center">
                                                    <UncontrolledDropdown className="dropdown d-inline-block">
                                                        <DropdownToggle className="btn btn-primary btn-sm" tag="button">
                                                            <i className="ri-more-fill align-middle"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem>
                                                                <Link to={`/request-edit/${item.id}`}>
                                                                    <i className="ri-eye-line align-bottom me-2" /> View Ticket
                                                                </Link>
                                                            </DropdownItem>
                                                            {item.statusName === "Draft" &&
                                                            <DropdownItem onClick={() => onSubmitTicketClick(item.id)}>
                                                                <i className="ri-check-fill align-bottom me-2"></i> Submit
                                                            </DropdownItem>
                                                            }
                                                            {item.statusName === "Draft" &&
                                                            <DropdownItem onClick={() => onAbortTicketClick(item.id)}>
                                                                <i className="ri-close-fill align-bottom me-2"></i> Abort
                                                            </DropdownItem>
                                                            }
                                                            <DropdownItem onClick={() => onRemoveTicketClick(item.id)}>
                                                                <i className="ri-delete-bin-fill align-bottom me-2"></i> Remove from Container 
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown> 
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <ConfirmModal message={"Are you sure you want to \"Submit\" the Ticket?"} show={showSubmitModal} onCloseClick={onCloseModalClick} onYesClick={onChangeTicketStatus} />
            <ConfirmModal message={"Are you sure you want to \"Submit\" all Container Tickets?"} show={showSubmitAllModal} onCloseClick={onCloseModalClick} onYesClick={onSubmitAll} />
            <ConfirmModal message={"Are you sure you want to \"Abort\" the Ticket?"} show={showAbortModal} onCloseClick={onCloseModalClick} onYesClick={onChangeTicketStatus} />
            <ConfirmModal message={"Are you sure you want to \"Remove\" the Ticket from Container?"} show={showRemoveModal} onCloseClick={onCloseModalClick} onYesClick={onRemoveTicketFromContainer} />
        </Fragment>
    );
};

export default TicketGrid;