import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";

import { useDispatch, useSelector } from "react-redux";
import * as customerActions from "../actions/CustomerActions";

const CustomerEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.customer.loadingFBO);
    const fbo = useSelector((state) => state.customer.fbo);
    
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);

    React.useEffect(() => {
	    dispatch(customerActions.loadCustomer(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        
        if(fbo) {
            reset(fbo.customer);
        }
        
        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onCustomerChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.customer[name] = value;
        }
        else if(value === false) {
            copy_fboState.customer[name] = false;
        }
        else {
            copy_fboState.customer[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onSaveCustomer = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(customerActions.saveCustomer(fboState, navigate));
        }
    }

    const onCancelCustomer = () => {
        setFBOState(fbo);
        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={fboState && fboState.customer.id > 0 ? "Edit Customer" : "Add Customer"} />
                    <Row>
                        <Col lg={fboState && fboState.customer.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card>
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Customer</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveCustomer}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save Customer
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelCustomer}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Organization Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.organizationName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.customer.organizationName) || ""}
                                                {...register("organizationName", { required: true })}
                                                onChange={e => onCustomerChange("organizationName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Customer Code<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.code ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.customer.code) || ""}
                                                {...register("code", { required: true })}
                                                onChange={e => onCustomerChange("code", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">CRM Country<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.crmCountry ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.customer.crmCountry) || ""}
                                                {...register("crmCountry", { required: true })}
                                                onChange={e => onCustomerChange("crmCountry", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Organization Name (Arabic)</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.organizationNameArabic) || ""}
                                                onChange={e => onCustomerChange("organizationNameArabic", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Commercial Registration Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.commercialRegistrationNumber) || ""}
                                                onChange={e => onCustomerChange("commercialRegistrationNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">VAT Registration Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.vatRegistrationNumber) || ""}
                                                onChange={e => onCustomerChange("vatRegistrationNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">First Name</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.firstName) || ""}
                                                onChange={e => onCustomerChange("firstName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Last Name</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.lastName) || ""}
                                                onChange={e => onCustomerChange("lastName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Email<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.customer.email) || ""}
                                                {...register("email", { required: true })}
                                                onChange={e => onCustomerChange("email", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Telephone Number<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.telephoneNumber ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.customer.telephoneNumber) || ""}
                                                {...register("telephoneNumber", { required: true })}
                                                onChange={e => onCustomerChange("telephoneNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Mobile Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.mobileNumber) || ""}
                                                onChange={e => onCustomerChange("mobileNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Fax Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.faxNumber) || ""}
                                                onChange={e => onCustomerChange("faxNumber", e.target.value)}
                                            />
                                        </Col>
                                        
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Active</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.customer.active) || false}
                                                onChange={e => onCustomerChange("active", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>&nbsp;</Col>
                                        <Col xxl={8} md={8}>
                                            <Label className="form-label">Address</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.address) || ""}
                                                onChange={e => onCustomerChange("address", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">City</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.city) || ""}
                                                onChange={e => onCustomerChange("city", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">State</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.state) || ""}
                                                onChange={e => onCustomerChange("state", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Country</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.country) || ""}
                                                onChange={e => onCustomerChange("country", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Zip Code</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.customer.zipCode) || ""}
                                                onChange={e => onCustomerChange("zipCode", e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        
                        {fboState && fboState.customer.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.customer.id} name={"CUSTOMER"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default CustomerEditScreen;