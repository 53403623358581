import React, { Fragment } from "react";
import { NumericFormat } from 'react-number-format';

const MyNumberRenderer = (props) => {
    return (
        <NumericFormat value={props.value || 0} displayType={'text'} thousandSeparator={true} decimalScale={2}
            renderText={value => <Fragment>{value || 0}{props.mode === 1 && ' SAR'}</Fragment>}
        />
    );
}

export default MyNumberRenderer;