import { 
    CHANGE_LAYOUT,
    CHANGE_SIDEBAR_THEME,
    CHANGE_LAYOUT_MODE,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_LAYOUT_POSITION,
    CHANGE_TOPBAR_THEME,
    CHANGE_LEFT_SIDEBAR_SIZE_TYPE,
    CHANGE_LEFT_SIDEBAR_VIEW_TYPE,
    CHANGE_SIDEBAR_IMAGE_TYPE,
    CHANGE_SIDEBAR_VISIBILITY,
    CHANGE_PRELOADER
} from "../constants/LayoutConstants";

function changeHTMLAttribute(attribute, value) {
    if (document.documentElement) document.documentElement.setAttribute(attribute, value);
    return true;
}

export function changeLayout(layout) {
    return async (dispatch) => {
        if (layout === "twocolumn") {
            document.documentElement.removeAttribute("data-layout-width");
        }
        else if (layout === "horizontal") {
            document.documentElement.removeAttribute("data-sidebar-size");
        }
        else if (layout === "semibox") {
            changeHTMLAttribute("data-layout-width", "fluid");
            changeHTMLAttribute("data-layout-style", "default");
        }

        changeHTMLAttribute("data-layout", layout);
        
        await dispatch({
            type: CHANGE_LAYOUT,
            payload: layout
        });
    };
}

export function changeSidebarTheme(theme) {
    return async (dispatch) => {
        changeHTMLAttribute("data-sidebar", theme);
        
        await dispatch({
            type: CHANGE_SIDEBAR_THEME,
            payload: theme
        });
    };
}

export function changeLayoutMode(layoutMode) {
    return async (dispatch) => {
        changeHTMLAttribute("data-layout-mode", layoutMode);
        
        await dispatch({
            type: CHANGE_LAYOUT_MODE,
            payload: layoutMode
        });
    };
}

export function changeLayoutWidth(layoutWidth) {
    return async (dispatch) => {
        if (layoutWidth === 'lg') {
            changeHTMLAttribute("data-layout-width", "fluid");
        } 
        else {
            changeHTMLAttribute("data-layout-width", "boxed");
        }

        await dispatch({
            type: CHANGE_LAYOUT_WIDTH,
            payload: layoutWidth
        });
    };
}

export function changeLayoutPosition(layoutposition) {
    return async (dispatch) => {
        changeHTMLAttribute("data-layout-position", layoutposition);

        await dispatch({
            type: CHANGE_LAYOUT_POSITION,
            payload: layoutposition
        });
    };
}

export function changeTopbarTheme(topbarTheme) {
    return async (dispatch) => {
        changeHTMLAttribute("data-topbar", topbarTheme);

        await dispatch({
            type: CHANGE_TOPBAR_THEME,
            payload: topbarTheme
        });
    };
}

export function changeLeftsidebarSizeType(leftsidebarSizetype) {
    return async (dispatch) => {
        switch (leftsidebarSizetype) {
            case 'lg':
                changeHTMLAttribute("data-sidebar-size", "lg");
                break;
            case 'md':
                changeHTMLAttribute("data-sidebar-size", "md");
                break;
            case "sm":
                changeHTMLAttribute("data-sidebar-size", "sm");
                break;
            case "sm-hover":
                changeHTMLAttribute("data-sidebar-size", "sm-hover");
                break;
            default:
                changeHTMLAttribute("data-sidebar-size", "lg");
        }
                
        await dispatch({
            type: CHANGE_LEFT_SIDEBAR_SIZE_TYPE,
            payload: leftsidebarSizetype
        });
    };
}

export function changeLeftsidebarViewType(leftsidebarViewtype) {
    return async (dispatch) => {
        changeHTMLAttribute("data-layout-style", leftsidebarViewtype);

        await dispatch({
            type: CHANGE_LEFT_SIDEBAR_VIEW_TYPE,
            payload: leftsidebarViewtype
        });
    };
}

export function changeSidebarImageType(leftsidebarImagetype) {
    return async (dispatch) => {
        changeHTMLAttribute("data-sidebar-image", leftsidebarImagetype);

        await dispatch({
            type: CHANGE_SIDEBAR_IMAGE_TYPE,
            payload: leftsidebarImagetype
        });
    };
}

export function changeSidebarVisibility(sidebarVisibilitytype) {
    return async (dispatch) => {
        changeHTMLAttribute("data-sidebar-visibility", sidebarVisibilitytype);

        await dispatch({
            type: CHANGE_SIDEBAR_VISIBILITY,
            payload: sidebarVisibilitytype
        });
    };
}

export function changePreLoader(preloaderTypes) {
    return async (dispatch) => {
        changeHTMLAttribute("data-preloader", preloaderTypes);

        await dispatch({
            type: CHANGE_PRELOADER,
            payload: preloaderTypes
        });
    };
}