import React, { Fragment } from "react";
import { Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import CardLoader from "./CardLoader";
import { api } from "../utils/index";

const PdfModal = ({ item, show, onCloseClick }) => {
  const [localLoading, setLocalLoading] = React.useState(true);

  React.useEffect(() => {
    setLocalLoading(true);  
  }, [item]);

  const onDocumentLoaded = () => {
    setLocalLoading(false);
  }

  return (
    <Modal size="xl" isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader>{item && item.title}</ModalHeader>
      <ModalBody>
        <CardLoader loading={localLoading} />
        <Row className="gy-2">
          {item &&
            <Fragment>
              <iframe src={`${api}Document/DownloadDocumentStream?id=${item.id}`} style={{width:"100%", height:"600px"}} onLoad={onDocumentLoaded} />
            </Fragment>
            }
        </Row>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-danger btn-label" data-bs-dismiss="modal" onClick={onCloseClick}>
          <i className="ri-close-line label-icon align-bottom"></i> Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default PdfModal;