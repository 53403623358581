import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col, Label, Row, Table, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { createDeepClone } from "../utils/index";

import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";

import { useDispatch, useSelector } from "react-redux";
import * as documentActions from "../actions/DocumentActions";
import * as commonActions from "../actions/CommonActions";

const DocumentGrid = (props) => {
    const dispatch = useDispatch();

    const downloading = useSelector((state) => state.document.downloading);
    const documentTypeList = useSelector((state) => state.login.data.documentTypeList);

    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileCount, setSelectedFileCount] = useState(1);

    const [loading, setLoading] = React.useState(false);
    const [fboState, setFBOState] = React.useState(null);
    const [documentList, setDocumentList] = React.useState([]);

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
		
            var data = await documentActions.loadDocument(props.refId, props.refName, props.parentId, props.parentName);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                setDocumentList(data.list);
            }

            setLoading(false);
        }

        fetchData();
    }, [props.refId, props.refName, props.parentId, props.parentName]);

    const onDocumentChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            copy_fboState[name] = value;
        }
        else if(value === false) {
            copy_fboState[name] = false;
        }
        else {
            copy_fboState[name] = null;
        }
        
        setFBOState(copy_fboState);
    }

    const onFileChange = (event) => {
        if(event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    }

    const showModalDialog = (documentType) => {
        onDocumentChange("title", "");
        onDocumentChange("documentType", documentType);

        setSelectedFile(null);
        setSelectedFileCount(selectedFileCount + 1);
        
        setShowModal(true);
    }

    const hideModalDialog = () => {
        setShowModal(false);
    }

    const onSaveDocument = async () => {
        if(selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile, selectedFile.name);
            formData.append("document", JSON.stringify(fboState));
            
            setLoading(true);

            var data = await documentActions.saveDocument(formData);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                setDocumentList(data.list);
                
                hideModalDialog();

                if(props.onDocumentAdd) {
                    props.onDocumentAdd();
                }

                dispatch(commonActions.setSuccessMessage("Document Added Successfully!"))
            }
            
            setLoading(false);
        }
    }

    const onDownloadDocument = (id, documentName) => {
        dispatch(documentActions.downloadDocument(id, documentName));
    };

    return (
        <Fragment>
            <Card>
                <CardHeader className="border-0 d-flex align-items-center">
                    <CardLoader loading={loading || downloading} />
                    <h5 className="card-title mb-0 flex-grow-1">Attachments / Documents</h5>
                    {props.canChangeDocument && 
                    <div className="flex-shrink-0">
                        <div className="d-flex flex-wrap gap-2">
                            <button className="btn btn-primary btn-label"
                                onClick={() => showModalDialog("LRD")}
                            >
                                <i className="ri-add-line label-icon align-bottom"></i> Add Document
                            </button>
                        </div>
                    </div>
                    }
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <Col xxl={12} md={12}>
                        <Table className="table-hover table-bordered align-middle mb-0">
                            <thead className="table-primary">
                                <tr>
                                    <th>Document #</th>
                                    <th>Document Name</th>
                                    <th>Date Added</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {documentList.length == 0 && <tr><td colSpan={5}>No Document(s) Added</td></tr>}
                                {documentList.map(function(item, index){
                                    return(
                                        <tr key={index}>
                                            <td>{item.title}</td>
                                            <td>{item.name}</td>
                                            <td><MyDateRenderer value={item.dateAdded} /></td>
                                            <td className="text-center">
                                                <button type="button" className="btn btn-primary btn-sm me-1" title="Download Document"
                                                    onClick={() => onDownloadDocument(item.id, item.name)}
                                                >
                                                    <i className="ri-download-line" /> Download
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </CardBody>
            </Card>

            <Modal tabIndex="-1" size="xl" isOpen={showModal} toggle={hideModalDialog} centered={true}>
                <ModalHeader>
                    Upload Document
                </ModalHeader>
                <ModalBody>
                    <CardLoader loading={loading} />
                    <div className="mb-3">
                        <label className="form-label">Document #</label>
                        <input className="form-control"
                            value={(fboState && fboState.title) || ""}
                            onChange={e => onDocumentChange("title", e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Document Type</label>
                        <select className="form-select" disabled={true}
                            value={(fboState && fboState.documentType) || ""}
                            onChange={e => onDocumentChange("documentType", e.target.value)}
                        >
                            <option value={""}>...</option>
                            {documentTypeList && documentTypeList.map(function(item, index){
                                return (
                                    <option key={index} value={item.code}>{item.description}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Select File</label>
                        <input className="form-control" type="file" key={selectedFileCount} onChange={onFileChange} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary btn-label" onClick={onSaveDocument}>
                        <i className="ri-save-line label-icon align-bottom"></i> Save Document
                    </button>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={hideModalDialog}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default DocumentGrid;