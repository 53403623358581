//Search
export const COMPANY_SEARCH_START_LOADING = "COMPANY_SEARCH_START_LOADING";
export const COMPANY_SEARCH_END_LOADING = "COMPANY_SEARCH_END_LOADING";
export const COMPANY_SEARCH_LOADED = "COMPANY_SEARCH_LOADED";
export const SET_COMPANY_FILTER = "SET_COMPANY_FILTER";

//Edit
export const COMPANY_START_LOADING = "COMPANY_START_LOADING";
export const COMPANY_END_LOADING = "COMPANY_END_LOADING";
export const COMPANY_LOADED = "COMPANY_LOADED";
