import { PRODUCT_SEARCH_START_LOADING, PRODUCT_SEARCH_END_LOADING, PRODUCT_SEARCH_LOADED, SET_PRODUCT_FILTER } from "../constants/ProductConstants";
import { PRODUCT_START_LOADING, PRODUCT_END_LOADING, PRODUCT_LOADED } from "../constants/ProductConstants";

import { getApiData, postApiJsonData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadProductSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: PRODUCT_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Product/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: PRODUCT_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: PRODUCT_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: PRODUCT_SEARCH_END_LOADING });
        }
    };
}

export function setProductFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_PRODUCT_FILTER,
            payload: filter
        });
    };
}

export function loadProduct(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: PRODUCT_START_LOADING });

        try {

            var data = await getApiData("Product/GetProductFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: PRODUCT_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: PRODUCT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: PRODUCT_END_LOADING });
        }
    };
}

export function saveProduct(fbo, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: PRODUCT_START_LOADING });

        try {

            var isAdd = fbo.isAddMode;
            var data = await postApiJsonData("Product/SaveProductFBO", null, fbo);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/product-edit/' + data.responseEntities[0].product.id);
                    dispatch(commonActions.setSuccessMessage("Product Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: PRODUCT_LOADED,
                        payload: data.responseEntities[0]
                    });

                    dispatch(commonActions.setSuccessMessage("Product Saved Successfully!"));
                }
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: PRODUCT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: PRODUCT_END_LOADING });
        }
    };
}