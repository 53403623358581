import { cloneDeep, isEqual } from "lodash";
import Fuse from 'fuse.js'
import jwt_decode from "jwt-decode";
import queryString from 'query-string';

//Production Server
export const api = "https://pro-operationapi.azurewebsites.net/api/";
export const api_notification = "https://pro-operationapi.azurewebsites.net/ticketing-system/notifications";


//Testing Server
//export const api = "https://ticketingapicatv45.azurewebsites.net/api/";
//export const api_notification = "https://ticketingapicatv45.azurewebsites.net/ticketing-system/notifications";

//Local Server
//export const api = "https://localhost:5001/api/";
//export const api_notification = "https://localhost:5001/ticketing-system/notifications";

export const createDeepClone = (data) => {
  return cloneDeep(data);
}

export const compareObjects = (value, other) => {
  return isEqual(value, other);
}

export const buildApiUrl = (path, params) => {
  var paramsQueryString = queryString.stringify(params);
  return api + (path.indexOf("?") > -1 ? (path + "&") : (path + "?")) + paramsQueryString;
}

export const getUserToken = () => {
  var token = localStorage.getItem('ticketing_user_token');
  
  if(token) {
    var decodedToken = jwt_decode(token);

    var currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      window.location = "/logout";
    }
  }
  else {
    window.location = "/logout";
  }
  
  return token;
}

export const getConnectionError = () => {
  return { success: false, errorDesc: "Please check your internet connection and try again" };
}

export const getApiData = async(path, params, useToken) => {
  if(!navigator.onLine) {
    return getConnectionError();
  }
  else {
    var url = buildApiUrl(path, params);
    var token = '';
    if(useToken !== false) {
      token = getUserToken();
    }
    
    try{
      const apiRequest = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json"
          , "Authorization": "Bearer " + token
        }
      });
    
      var apiResponse = await apiRequest.json();
      if(apiRequest.ok === true) {
        return apiResponse;
      }
      else {
        return { success: false, errorDesc: apiResponse.title };
      }
    }
    catch (error) {
      if(error instanceof TypeError) {
        return getConnectionError();
      }
      else {
        return { success: false, errorDesc: error.toString() };
      }
    }
  } 
}

export const postApiJsonData = async(path, params, data, useToken) => {
  if(!navigator.onLine) {
    return getConnectionError();
  }
  else {
    var url = buildApiUrl(path, params);
    var token = '';
    if(useToken !== false) {
      token = getUserToken();
    }
      
    try{
      const apiRequest = await fetch(url, {
        method: "POST",
        headers: {
          "Accept": "application/json"
          , "Content-Type": "application/json"
          , "Authorization": "Bearer " + token
        },
        body: JSON.stringify(data)
      });

      var apiResponse = await apiRequest.json();
      
      if(apiRequest.ok === true) {
        return apiResponse;
      }
      else {
        return { success: false, errorDesc: apiResponse.title };
      }
    }
    catch (error) {
      if(error instanceof TypeError) {
        return getConnectionError();
      }
      else {
        return { success: false, errorDesc: error.toString() };
      }
    }
  }
}

export const postApiFormData = async(path, params, data, useToken) => {
  if(!navigator.onLine) {
    return getConnectionError();
  }
  else {
    var url = buildApiUrl(path, params);
    var token = '';
    if(useToken !== false) {
      token = getUserToken();
    }
    
    try{
      const apiRequest = await fetch(url, {
        method: "POST",
        headers: {
          "Accept": "application/json"
          , "Authorization": "Bearer " + token
        },
        body: data
      });

      var apiResponse = await apiRequest.json();
      if(apiRequest.ok === true) {
        return apiResponse;
      }
      else {
        return { success: false, errorDesc: apiResponse.title };
      }
    }
    catch (error) {
      if(error instanceof TypeError) {
        return getConnectionError();
      }
      else {
        return { success: false, errorDesc: error.toString() };
      }
    }
  }
}

export const downloadFile = async(path, params, useToken) => {
  if(!navigator.onLine) {
    return getConnectionError();
  }
  else {
    var url = buildApiUrl(path, params);
    var token = '';
    if(useToken !== false) {
      token = getUserToken();
    }
    
    try{
      const apiRequest = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json"
          , "Authorization": "Bearer " + token
        }
      });
    
      var apiResponse = await apiRequest.blob();
      if(apiRequest.ok === true) {
        return { success: true, file: apiResponse };
      }
      else {
        return { success: false, errorDesc: apiResponse.title };
      }
    }
    catch (error) {
      if(error instanceof TypeError) {
        return getConnectionError();
      }
      else {
        return { success: false, errorDesc: error.toString() };
      }
    }
  } 
}

export function fuzzySearch(options) {
  const fuse = new Fuse(options, {
      keys: ['name', 'groupName', 'items.name'],
      threshold: 0.3,
  });

  return (value) => {
      if (!value.length) {
          return options;
      }
      
      var list = fuse.search(value);

      return list.map(item => ({
        value: item.item.value,
        name: item.item.name
      }))
  };
}

export function getFileTitle(file) {
  return file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
}

export function getFileExtension(fileName) {
  if(fileName) {
    const nameParts = fileName.split('.');
    if(nameParts.length > 0) {
      return nameParts.pop();
    }
  }

  return "";
}