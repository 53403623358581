import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat";

import { createDeepClone } from "../utils/index";
import Loader from "../components/Loader";
import ActionList from "../components/ActionList";

import { useDispatch, useSelector } from "react-redux";
import * as paymentActions from "../actions/PaymentActions";

const PaymentEditScreen  = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.payment.loadingFBO);
    const fbo = useSelector((state) => state.payment.fbo);
    const paymentStatusList = useSelector((state) => state.login.data.paymentStatusList);
    const currencyList = useSelector((state) => state.login.data.currencyList);

    const [fboState, setFBOState] = React.useState(fbo);
    const [refreshAction, setRefreshAction] = React.useState(false);

    React.useEffect(() => {
	    dispatch(paymentActions.loadPayment(id, navigate));
    }, [id]);

    React.useEffect(() => {
		setFBOState(fbo);
        setRefreshAction(!refreshAction);
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onPaymentChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState.payment[name] = value;
        }
        else if(value === false) {
            copy_fboState.payment[name] = false;
        }
        else {
            copy_fboState.payment[name] = null;
        }
        
        setFBOState(copy_fboState);
    }

    const onSavePayment = () => {
        dispatch(paymentActions.savePayment(fboState));
    }

    const onViewTicket = (id) => {
        navigate('/request-edit/' + id);
    }
    
    return(
        <Fragment>
            <div className="row mb-4">
                <div className="col">
                    <h1 className="h3 mb-0 text-gray-800">
                        Payment
                    </h1>
                </div>
                <div className="col-auto">
                    {fboState && fboState.canSave &&
                    <button type="button" className="btn btn-primary float-right mr-1"
                        disabled={loading}
                        onClick={onSavePayment}
                    >
                        <i className="fas fa-save"></i> Save
                    </button>
                    }
                    <button type="button" className="btn btn-primary float-right mr-1"
                        disabled={loading}
                        onClick={() => onViewTicket(fboState.payment.ticketId)}
                    >
                        <i className="fas fa-eye"></i> View Ticket
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold">Payment Info</h6>
                                </div>
                                <div className="card-body">
                                    {loading && <Loader />}
                                    <div className="form-group row">
                                        <div className="col-sm-3">
                                            <label>Status</label>
                                            <select className="form-control form-control-user"
                                                value={(fboState && fboState.payment.status) || ""}
                                                onChange={e => onPaymentChange("status", e.target.value)}
                                            >
                                                {paymentStatusList && paymentStatusList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.code}>{item.description}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-sm-3">
                                            <label>Currency</label>
                                            <select className="form-control form-control-user"
                                                value={(fboState && fboState.payment.currency) || ""}
                                                onChange={e => onPaymentChange("currency", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                {currencyList && currencyList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.code}>{item.description}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-sm-3">
                                            <label>Amount</label>
                                            <input type="text" className="form-control form-control-user"
                                                value={(fboState && fboState.payment.amount) || ""}
                                                onChange={e => onPaymentChange("amount", e.target.value)}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <label>Payment Date</label>
                                            <input type="date" className="form-control form-control-user"
                                                value={(fboState && fboState.payment.paymentDate && dateFormat(fboState.payment.paymentDate, "yyyy-mm-dd")) || ""}
                                                onChange={e => onPaymentChange("paymentDate", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-3">
                                            <label>Invoice Date</label>
                                            <input type="date" className="form-control form-control-user"
                                                value={(fboState && fboState.payment.invoiceDate && dateFormat(fboState.payment.invoiceDate, "yyyy-mm-dd")) || ""}
                                                onChange={e => onPaymentChange("invoiceDate", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <label>Remarks</label>
                                            <input type="text" className="form-control form-control-user"
                                                value={(fboState && fboState.payment.remarks) || ""}
                                                onChange={e => onPaymentChange("remarks", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <label>Description</label>
                                            <input type="text" className="form-control form-control-user"
                                                value={(fboState && fboState.payment.description) || ""}
                                                onChange={e => onPaymentChange("description", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="row">
                        {fboState &&
                        <div className="col-lg-12">
                            <ActionList id={fboState.payment.id} name={"PAYMENT"} refreshAction={refreshAction} />
                        </div>
                        }
                    </div>
                </div>
            </div>
            
        </Fragment>
    );
}

export default PaymentEditScreen;