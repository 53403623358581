import { PAYMENT_SEARCH_START_LOADING, PAYMENT_SEARCH_END_LOADING, PAYMENT_SEARCH_LOADED, SET_PAYMENT_FILTER } from "../constants/PaymentConstants";
import { PAYMENT_START_LOADING, PAYMENT_END_LOADING, PAYMENT_LOADED } from "../constants/PaymentConstants";

import { getApiData, postApiJsonData, postApiFormData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadPaymentSearch(filters) {
    return async (dispatch) => {
        
        await dispatch({ type: PAYMENT_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("Payment/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: PAYMENT_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: PAYMENT_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: PAYMENT_SEARCH_END_LOADING });
        }
    };
}

export function setPaymentFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_PAYMENT_FILTER,
            payload: filter
        });
    };
}

export function loadPayment(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: PAYMENT_START_LOADING });

        try {

            var data = await getApiData("Payment/GetPaymentFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: PAYMENT_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: PAYMENT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: PAYMENT_END_LOADING });
        }
    };
}

export function savePayment(fbo) {
    return async (dispatch) => {
        
        await dispatch({ type: PAYMENT_START_LOADING });

        try {

            var data = await postApiJsonData("Payment/SavePaymentFBO", null, fbo);
            
            if(data.success) {
                await dispatch({
                    type: PAYMENT_LOADED,
                    payload: data.responseEntities[0]
                });

                dispatch(commonActions.setSuccessMessage("Payment Saved Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: PAYMENT_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: PAYMENT_END_LOADING });
        }
    };
}