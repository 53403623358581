import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row, ListGroup, ListGroupItem, Spinner } from 'reactstrap';

import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer"

import { useDispatch, useSelector } from "react-redux";
import * as actionActions from "../actions/ActionActions";

const ActionList = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.action.loading);
    const data = useSelector((state) => state.action.list);
    
    React.useEffect(() => {
		dispatch(actionActions.loadAction(props.id, props.name));
    }, [props.id, props.name, props.refreshAction]);

    return (
        <Fragment>
            <Card>
                <CardHeader className="border-0 d-flex align-items-center">
                    <CardLoader loading={loading} />
                    <h5 className="card-title mb-0 flex-grow-1">Actions</h5>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <ListGroup className="border-dashed" flush>
                        {data.length == 0 && <span>No actions found</span>}
                        {data.map((item, key) => (
                            <ListGroupItem className="ps-0" key={key}>
                                <Row className="align-items-center g-3">
                                    <Col className="col-auto">
                                        <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3 shadow">
                                            <div className="text-center">
                                                <h5 className="mb-0"><MyDateRenderer value={item.dateAdded} format="dd" /></h5>
                                                <div className="text-muted"><MyDateRenderer value={item.dateAdded} format="ddd" /></div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="col">
                                        <h5 className="text-muted mt-0 mb-1 fs-13"><MyDateRenderer value={item.dateAdded} format="HH:MM:ss" /></h5>
                                        <Link to="#" className="text-reset fs-14 mb-0"><b>{item.actionDescription}</b> by <i>{item.userNameAdded}</i></Link>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ActionList;