import React from "react";

const ComplianceLabel = (props) => {
    if(props.value === "NCR") {
        return(
            <span title="NCR">
                <div className="avatar-xxs img-thumbnail rounded-circle" style={{display:"inline-block", padding:"0"}}>
                    <div className="avatar-title text-uppercase border rounded-circle bg-danger">N</div>
                </div>
            </span>
        );
    }
    else if(props.value === "Verified") {
        return(
            <span title="Verified">
                <div className="avatar-xxs img-thumbnail rounded-circle" style={{display:"inline-block", padding:"0"}}>
                    <div className="avatar-title text-uppercase border rounded-circle bg-success">V</div>
                </div>
            </span>
        );
    }

    return "";
};

export default ComplianceLabel;