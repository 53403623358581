//Search
export const VENDOR_SEARCH_START_LOADING = "VENDOR_SEARCH_START_LOADING";
export const VENDOR_SEARCH_END_LOADING = "VENDOR_SEARCH_END_LOADING";
export const VENDOR_SEARCH_LOADED = "VENDOR_SEARCH_LOADED";
export const SET_VENDOR_FILTER = "SET_VENDOR_FILTER";

//Edit
export const VENDOR_START_LOADING = "VENDOR_START_LOADING";
export const VENDOR_END_LOADING = "VENDOR_END_LOADING";
export const VENDOR_LOADED = "VENDOR_LOADED";
