import { USER_SEARCH_START_LOADING, USER_SEARCH_END_LOADING, USER_SEARCH_LOADED, SET_USER_FILTER } from "../constants/UserConstants";
import { USER_START_LOADING, USER_END_LOADING, USER_LOADED } from "../constants/UserConstants";
import { USER_PROFILE_START_LOADING, USER_PROFILE_END_LOADING, USER_PROFILE_LOADED } from "../constants/UserConstants";
import { LOGIN_LOADED } from "../constants/LoginConstants";

import { getApiData, postApiJsonData, postApiFormData } from "../utils/index";
import * as commonActions from "./CommonActions";

export function loadUserSearch(filters, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: USER_SEARCH_START_LOADING });

        try {

            var data = await postApiJsonData("UserProfile/GetPaged", filters, null);
            
            if(data.success) {
                await dispatch({
                    type: USER_SEARCH_LOADED,
                    payload: {
                        dataList: data.responseEntities[0].dataList
                        , totalCount: data.responseEntities[0].totalCount
                    }
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: USER_SEARCH_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: USER_SEARCH_END_LOADING });
        }
    };
}

export function setUserFilter(filter) {
    return async (dispatch) => {
        await dispatch({
            type: SET_USER_FILTER,
            payload: filter
        });
    };
}

export function loadUser(id, navigate) {
    return async (dispatch) => {
        
        await dispatch({ type: USER_START_LOADING });

        try {

            var data = await getApiData("UserProfile/GetUserProfileFBO", { id });
            
            if(data.success) {
                await dispatch({
                    type: USER_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }

                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: USER_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: USER_END_LOADING });
        }
    };
}

export function saveUser(formData, isAdd, navigate, updateUserState) {
    return async (dispatch) => {
        
        await dispatch({ type: USER_START_LOADING });

        try {

            var data = await postApiFormData("UserProfile/SaveUserProfileFBO", null, formData);
            
            if(data.success) {
                if(isAdd) {
                    navigate('/user-edit/' + data.responseEntities[0].userProfile.id);
                    dispatch(commonActions.setSuccessMessage("User Created Successfully!"));
                }
                else {
                    await dispatch({
                        type: USER_LOADED,
                        payload: data.responseEntities[0]
                    });

                    if(updateUserState) {
                        const user_info = JSON.parse(localStorage.getItem('ticketing_user_info'));
                        if(user_info) {
                            user_info.firstName = data.responseEntities[0].userProfile.firstName;
                            user_info.lastName = data.responseEntities[0].userProfile.lastName;
                            user_info.fullName = data.responseEntities[0].userProfile.fullName;
                            
                            user_info.showDraft = data.responseEntities[0].showDraft;
                            user_info.showNew = data.responseEntities[0].showNew;
                            user_info.showInProgress = data.responseEntities[0].showInProgress;
                            user_info.showSalesAction = data.responseEntities[0].showSalesAction;
                            user_info.showPdReview = data.responseEntities[0].showPdReview;
                            user_info.showSampling = data.responseEntities[0].showSampling;
                            user_info.showScheduled = data.responseEntities[0].showScheduled;
                            user_info.showWaitingCorrectiveAction = data.responseEntities[0].showWaitingCorrectiveAction;
                            user_info.showPhase1Evaluation = data.responseEntities[0].showPhase1Evaluation;
                            user_info.showInspection = data.responseEntities[0].showInspection;
                            user_info.showPhase2Evaluation = data.responseEntities[0].showPhase2Evaluation;
                            user_info.showFinalDecision = data.responseEntities[0].showFinalDecision;
                            user_info.showCompleted = data.responseEntities[0].showCompleted;
                            user_info.showClosed = data.responseEntities[0].showClosed;
                            user_info.showStopped = data.responseEntities[0].showStopped;
                            user_info.showAborted = data.responseEntities[0].showAborted;

                            user_info.showIsoDraft = data.responseEntities[0].showIsoDraft;
                            user_info.showIsoNew = data.responseEntities[0].showIsoNew;
                            user_info.showIsoSalesAction = data.responseEntities[0].showIsoSalesAction;
                            user_info.showIsoCalculateManDays = data.responseEntities[0].showIsoCalculateManDays;
                            user_info.showIsoSalesSupport = data.responseEntities[0].showIsoSalesSupport;
                            user_info.showIsoCustomerApproved = data.responseEntities[0].showIsoCustomerApproved;
                            user_info.showIsoAuditInProgress = data.responseEntities[0].showIsoAuditInProgress;
                            user_info.showIsoTechnicalReview = data.responseEntities[0].showIsoTechnicalReview;
                            user_info.showIsoFinalDecision = data.responseEntities[0].showIsoFinalDecision;
                            user_info.showIsoCompleted = data.responseEntities[0].showIsoCompleted;
                            user_info.showIsoClosed = data.responseEntities[0].showIsoClosed;
                            user_info.showIsoAborted = data.responseEntities[0].showIsoAborted;

                            localStorage.setItem("ticketing_user_info", JSON.stringify(user_info));

                            await dispatch({
                                type: LOGIN_LOADED,
                                payload: user_info
                            });
                        }
                    }

                    dispatch(commonActions.setSuccessMessage("User Saved Successfully!"));
                }
            }
            else {
                if(data.errorCode == "401") {
                    navigate('/not-authorized/');
                }
                
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: USER_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: USER_END_LOADING });
        }
    };
}

export function loadUserProfile() {
    return async (dispatch) => {
        
        await dispatch({ type: USER_PROFILE_START_LOADING });

        try {

            var data = await getApiData("UserProfile/GetUserProfileEditFBO", null);
            
            if(data.success) {
                await dispatch({
                    type: USER_PROFILE_LOADED,
                    payload: data.responseEntities[0]
                });
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: USER_PROFILE_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: USER_PROFILE_END_LOADING });
        }
    };
}

export function saveUserProfile(fbo) {
    return async (dispatch) => {
        
        await dispatch({ type: USER_PROFILE_START_LOADING });

        try {

            var data = await postApiJsonData("UserProfile/SaveUserProfileEditFBO", null, fbo);
            
            if(data.success) {
                await dispatch({
                    type: USER_PROFILE_LOADED,
                    payload: data.responseEntities[0]
                });

                const user_info = JSON.parse(localStorage.getItem('ticketing_user_info'));
                if(user_info) {
                    user_info.firstName = data.responseEntities[0].firstName;
                    user_info.lastName = data.responseEntities[0].lastName;
                    user_info.fullName = data.responseEntities[0].fullName;
                    
                    user_info.showDraft = data.responseEntities[0].showDraft;
                    user_info.showNew = data.responseEntities[0].showNew;
                    user_info.showInProgress = data.responseEntities[0].showInProgress;
                    user_info.showSalesAction = data.responseEntities[0].showSalesAction;
                    user_info.showPdReview = data.responseEntities[0].showPdReview;
                    user_info.showSampling = data.responseEntities[0].showSampling;
                    user_info.showScheduled = data.responseEntities[0].showScheduled;
                    user_info.showWaitingCorrectiveAction = data.responseEntities[0].showWaitingCorrectiveAction;
                    user_info.showPhase1Evaluation = data.responseEntities[0].showPhase1Evaluation;
                    user_info.showInspection = data.responseEntities[0].showInspection;
                    user_info.showPhase2Evaluation = data.responseEntities[0].showPhase2Evaluation;
                    user_info.showFinalDecision = data.responseEntities[0].showFinalDecision;
                    user_info.showCompleted = data.responseEntities[0].showCompleted;
                    user_info.showClosed = data.responseEntities[0].showClosed;
                    user_info.showStopped = data.responseEntities[0].showStopped;
                    user_info.showAborted = data.responseEntities[0].showAborted;

                    user_info.showIsoDraft = data.responseEntities[0].showIsoDraft;
                    user_info.showIsoNew = data.responseEntities[0].showIsoNew;
                    user_info.showIsoSalesAction = data.responseEntities[0].showIsoSalesAction;
                    user_info.showIsoCalculateManDays = data.responseEntities[0].showIsoCalculateManDays;
                    user_info.showIsoSalesSupport = data.responseEntities[0].showIsoSalesSupport;
                    user_info.showIsoCustomerApproved = data.responseEntities[0].showIsoCustomerApproved;
                    user_info.showIsoAuditInProgress = data.responseEntities[0].showIsoAuditInProgress;
                    user_info.showIsoTechnicalReview = data.responseEntities[0].showIsoTechnicalReview;
                    user_info.showIsoFinalDecision = data.responseEntities[0].showIsoFinalDecision;
                    user_info.showIsoCompleted = data.responseEntities[0].showIsoCompleted;
                    user_info.showIsoClosed = data.responseEntities[0].showIsoClosed;
                    user_info.showIsoAborted = data.responseEntities[0].showIsoAborted;

                    localStorage.setItem("ticketing_user_info", JSON.stringify(user_info));

                    await dispatch({
                        type: LOGIN_LOADED,
                        payload: user_info
                    });
                }

                dispatch(commonActions.setSuccessMessage("Profile Saved Successfully!"));
            }
            else {
                dispatch(commonActions.setErrorMessage(data.errorDesc));
            }
            
			await dispatch({ type: USER_PROFILE_END_LOADING });
        } 
        catch (error) {
            dispatch(commonActions.setErrorMessage(error));
            await dispatch({ type: USER_PROFILE_END_LOADING });
        }
    };
}