import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

//import images
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";

import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import { toast } from 'react-toastify';

import MyDateDisplay from "../MyDateDisplay"
import { api_notification } from "../../utils/index";

import { useDispatch, useSelector } from "react-redux";
import * as notificationActions from "../../actions/NotificationActions";

const  NotificationDropdown = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.notification.loading);
    const fbo = useSelector((state) => state.notification.fbo);
    
    const [connection, setConnection] = React.useState(null);
    const [isNotificationDropdown, setIsNotificationDropdown] = React.useState(false);

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    const [activeTab, setActiveTab] = React.useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    
    React.useEffect(() => {
		if(connection == null) {
            dispatch(notificationActions.loadNotification(true));

            const connect = new HubConnectionBuilder()
                .configureLogging(LogLevel.Debug)
                .withUrl(api_notification, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets
                })
                .withAutomaticReconnect()
                .build();

            setConnection(connect);
        }
    }, []);

    React.useEffect(() => {
        if(fbo && fbo.count > 0 && fbo.show && fbo.enableNotification) {
            toast.info("You have new Notifications!", {
                position: "top-right"
            });
        }
    }, [fbo]);

    React.useEffect(() => {
		if (connection && connection.state !== "Connected") {
            connection
              .start()
              .then(() => {
                connection.on("ReceiveMessage", (message) => {
                    dispatch(notificationActions.loadNotification(true));
                });
              })
              .catch((error) => console.log(error));
          }
    }, [connection]);

    const onViewAllNotification = () => {
        toggleNotificationDropdown();
        navigate('/notifications');
    }

    const onViewNotification = (item) => {
        toggleNotificationDropdown();

        if(item.active === true) { 
            dispatch(notificationActions.saveNotification(item.id, false, null));
        }
    }

    return(
        <Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none">
                    <i className='bx bx-bell fs-22'></i>
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                        {(fbo && fbo.count) || "0"}
                        <span className="visually-hidden">unread messages</span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white">Notifications</h6>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <SimpleBar style={{ maxHeight: "400px" }} className="pe-2">
                        {fbo && fbo.notificationList.map(function(item, index){
                            return (
                                <div className={`text-reset notification-item d-block dropdown-item position-relative`} key={index}>
                                    <div className="d-flex">
                                        <div className="avatar-xs me-3">
                                            <span
                                                className="avatar-title bg-soft-danger text-danger rounded-circle fs-16">
                                                <i className='bx bx-alarm'></i>
                                            </span>
                                        </div>
                                        <div className="flex-1">
                                            <Link to={`${item.refName === "TICKET" ? "/request-edit/" : "/iso-request-edit/"}${item.refId}`} className="stretched-link"
                                                onClick={() => onViewNotification(item)}
                                            >
                                                <h6 className="mt-0 mb-2 fs-13 lh-base">{item.description}</h6>
                                            </Link>
                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted text-primary">
                                                <span><i className="mdi mdi-clock-outline"></i> <MyDateDisplay value={item.dateAdded} /></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {fbo && fbo.notificationList.length == 0 && 
                        <Fragment>
                            <div className="w-25 w-sm-50 pt-3 mx-auto">
                                <img src={bell} className="img-fluid" alt="user-pic" />
                            </div>
                            <div className="text-center pb-5 mt-2">
                                <h6 className="fs-18 fw-semibold lh-base">Hey! You have <br /> no new notifications </h6>
                            </div>
                        </Fragment>
                        }

                        <div className="my-3 text-center">
                            <button type="button" className="btn btn-soft-primary waves-effect waves-light"
                                onClick={onViewAllNotification}
                            >
                                View All Notifications <i className="ri-arrow-right-line align-middle"></i>
                            </button>
                        </div>
                    </SimpleBar>
                </DropdownMenu>
            </Dropdown>
        </Fragment>
    );
};

export default NotificationDropdown;